import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownRating extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            gRatings: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.ratingSelected !== prevProps.ratingSelected) {
            //this.getDataFromMiddlewareRatings()
            if(this.state.gRatings !== undefined ){
                if(this.props.ratingSelected !== null){
                    let item = this.state.gRatings.find(item => item.value === this.props.ratingSelected);
                    this.props.handlerFromParent(item);
                }
            }
        }
    }

    getDataFromMiddlewareRatings(){
        const url = process.env.REACT_APP_API_URL+"/Global/Ratings";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {
                const gRatings = res.data;
                const loading = false;

                if(gRatings !== undefined ){
                    if(this.props.ratingSelected !== null){
                        let item = gRatings.find(item => item.value === this.props.ratingSelected);
                        this.props.handlerFromParent(item);
                    }
                }

                this.setState({gRatings, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    componentDidMount() {
        this.getDataFromMiddlewareRatings()
    }

    render() {
        return (
            <Select
                placeholder="Rating Select"
                name="ratingSelect"
                autoFocus={false}
                multi={false}
                isSearchable={true}
                value={this.props.ratingSelected}
                options={this.state.gRatings}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownRating = connect(mapStateToProps)(DropDownRating);
const connectedDropDownRatingTrans = withNamespaces('common')(connectedDropDownRating);
export {connectedDropDownRatingTrans as DropDownRating};
