import React from "react";
import axios from "axios";
import Button from "../CustomButton/CustomButton.jsx";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {OverlayTrigger, Tooltip, InputGroup, Row, Grid, Col, FormGroup} from "react-bootstrap";
import ModalDelete from "../CustomModals/ModalDelete";
import {ModalProperty} from "../CustomModals/ModalProperty";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";
import Card from "../Card/Card";
import {loadImage} from "../../functions/LoadImages";

class DataProperties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            edit_property_tooltip: (<Tooltip id="edit_tooltip">Edit</Tooltip>),
            remove_property_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),

            redirect: false,
            property_id: 1,
            loading: true,
            propertiesArray: [],
            astSelectedDevice: [],
            showDeleteModal: false,
            showPropertyModal: false,
            searchProperty: "",
            filter: null,

            columns: [
                {
                    accessor: "property_id",
                    Header: "ID",
                    maxWidth: 80,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "property_name",
                    Header: "Name",
                    maxWidth: 450,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "property_logo",
                    Header: "property_logo",
                    sortable: true,
                    maxWidth: 200,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={loadImage.load_property_image(row.value)}
                                // width="90%"
                                height="60px"
                                alt={""}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                }}
                            />
                        </div>
                    )
                },
                {
                    accessor: "property_type",
                    Header: "Type",
                    sortable: true,
                    maxWidth: 80,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {/*{*/}
                            {/*    row.value === 'HOME' ? <i className="fa fa-building text-default"/> :*/}
                            {/*        <i className="fa fa-university text-default"/>*/}
                            {/*}*/}
                            {this.getIcon(row.value)}
                        </div>
                    )
                },
                {
                    accessor: "DealerName",
                    Header: "Owner",
                    sortable: true,
                    maxWidth: 200,
                    filterable: false
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "property_id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }
            ],
            data: []

        };

        this.setDisplayModal = this.setDisplayModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    getIcon(value) {
        if (value !== undefined) {
            if (value === "HOME") {
                return (
                    <i className="fa fa-home text-default"/>
                )
            } else if (value === "UNIVERSITY") {
                return (
                    <i className="fa fa-university text-default"/>
                )
            } else if (value === "HOSPITAL") {
                return (
                    <i className="fa fa-h-square text-default"/>
                )
            } else if (value === "MDU") {
                return (
                    <i className="fa fa-building text-default"/>
                )
            } else {
                return (
                    <i className="fa fa-building text-default"/>
                )
            }
        } else {
            return (
                <i className="fa fa-building text-default"/>
            )
        }


    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let dt = new Date();
        let utcDate = dt.toUTCString();

        if (name === "searchProperty") {

            this.setState({
                lastUpdate: utcDate,
                filtered: [''],
                filter: value,
                activePage: 1,
                searchProperty: value
            })

            this.loadPropertiesPost(value);
        }

    }

    setHelperButton(property_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.edit_property_tooltip}>
            <Button
                onClick={() => this.setState({showPropertyModal: true, property_id: property_id})}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-edit"/>
            </Button>
        </OverlayTrigger>)

    }

    setDeleteButton(property_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_property_tooltip}>
            <Button
                onClick={() => this.setState({showDeleteModal: true, property_id: property_id})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    setDisplayModal = (id, value) => {

        let device = this.findArrayElementByID(this.state.propertiesArray, id)

        this.setState({
            showModal: value,
            decoder_id: id,
            lastSelectedDevice: device

        })
    }

    componentDidMount() {

        this.loadProperties();

    }

    loadProperties() {

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL + "/Properties";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios.get(dataUrl, axiosConfig).then(res => {
            const propertiesArray = res.data;
            const loading = false;
            this.setState({propertiesArray, loading});
        }).catch(error => {
            this.setState({loading: false});
            //this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });
    }

    loadPropertiesPost(value) {

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL + "/Properties";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        var postData = {
            type: "list",
            searchProperty: value
        }

        axios.post(dataUrl, postData, axiosConfig).then(res => {
            const propertiesArray = res.data;
            const loading = false;
            this.setState({propertiesArray, loading});
        }).catch(error => {
            this.setState({loading: false});
            //this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

    }

    deleteUserAccount() {

        const deleteURL = process.env.REACT_APP_API_URL + "/Properties/" + this.state.property_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                this.loadProperties();
                this.props.handlerFromParent();
                this.setState({property_id: null});
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    setDeleteUserAccountModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteUserAccount();

        }


    }

    setEditPropertyModal = (id, value, action) => {

        this.setState({
            showPropertyModal: value,
            property_id: null
        })

        if (action === "reload") {
            this.loadProperties();
            this.props.handlerFromParent();
            this.clearSearchButton()
        }

    }

    renderDeleteUserAccountModal = () => {

        let bodyMessageVar = "this action will delete the property.";

        if (this.state.showDeleteModal) {
            return <ModalDelete
                headerMessage={"Delete Property"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDeleteUserAccountModal}
                handlerID={this.state.property_id}
            />
        }
    }

    renderPropertyModal = () => {

        let bodyMessageVar = "";

        if (this.state.showPropertyModal) {
            return <ModalProperty
                headerMessage={"Edit Property"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setEditPropertyModal}
                handlerID={this.state.property_id}
            />
        }
    }

    clearSearchButton() {

        let dt = new Date();
        let utcDate = dt.toUTCString();
        let value = "";

        // if (this.props.listState === "removed") {
        //     set.removed_page_filter(value)
        //     set.removed_page("1")
        // } else {
        //     set.active_page_filter(value)
        //     set.active_page("1")
        // }

        this.setState({
            lastUpdate: utcDate,
            filtered: [''],
            filter: value,
            activePage: 1,
            searchProperty: ""
        })

        this.loadProperties();

    }

    render() {
        return (
            <div>

                {/*{this.renderRedirect()}*/}
                {this.renderDeleteUserAccountModal()}
                {this.renderPropertyModal()}

                <Card
                    content={
                        <div>
                            <div>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div className="pull-left">
                                                <h5>
                                                    {this.props.listName}
                                                </h5>
                                            </div>
                                            <div className="pull-left">
                                                <Button bsStyle="info" fill bsSize="xs"
                                                        style={{marginLeft: "10px", marginTop: "10px"}}
                                                        onClick={() => this.setState({
                                                            showPropertyModal: true,
                                                            property_id: "new"
                                                        })}
                                                >
                                                    {/*<i className="fa fa-plus-circle fa-lg"/>*/}
                                                    Add new property
                                                </Button>
                                            </div>
                                            <div className="pull-right" style={{width: "250px"}}>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <input
                                                            className="form-control form-control-sm ml-3 w-75"
                                                            type="text"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            name="searchProperty"
                                                            onChange={this.handleInputChange}
                                                            value={this.state.searchProperty || ""}
                                                        />
                                                        <InputGroup.Addon>
                                                            <i style={{color: this.state.searchProperty !== "" ? "#f7263b" : "#343434"}}
                                                               className={this.state.searchProperty !== "" ? "fa fa-times" : "fa fa-search"}
                                                               onClick={this.clearSearchButton.bind(this)}/>
                                                        </InputGroup.Addon>
                                                    </InputGroup>
                                                </FormGroup>

                                            </div>

                                        </Col>
                                    </Row>
                                </Grid>
                            </div>

                            <legend/>


                            <ReactTableV6Simple
                                data={this.state.propertiesArray}
                                loading={this.state.loading}
                                columns={this.state.columns}
                                defaultPageSize={5}
                            />

                        </div>
                    }
                />

            </div>
        );

    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataProperties = connect(mapStateToProps)(DataProperties);
export {connectedDataProperties as DataProperties};