import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownTvGuideGraceNote extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: false,
            guideGraceNote: []
        };

    }

    handleSelect(action,value) {
        this.props.handlerFromParent(action,value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.reloadTvguideGraceNote !== prevProps.reloadTvguideGraceNote) {
            if(this.props.reloadTvguideGraceNote){
                this.loadData()
                this.handleSelect('reload',null)
            }
        }
    }

    componentDidMount() {

        this.loadData()
    }

    loadData(){
        const url = process.env.REACT_APP_API_URL+"/channels/guide-grace-note";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {
                const guideGraceNote = res.data;
                const loading = false;
                this.setState({guideGraceNote, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    render() {
        return (
            <div>
                {this.state && this.state.guideGraceNote &&
                <Select
                    placeholder="Channel Select"
                    name="guideGraceNoteSelect"
                    autoFocus={false}
                    multi={false}
                    isSearchable={true}
                    disabled={this.props.disabled}
                    value={this.props.guideGraceNoteSelected}
                    options={this.state.guideGraceNote}
                    onChange={value => this.handleSelect('onChange',value)}

                />
                }
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownTvGuideGraceNote = connect(mapStateToProps)(DropDownTvGuideGraceNote);
const connectedDropDownTvGuideGraceNoteTrans = withNamespaces('common')(connectedDropDownTvGuideGraceNote);
export {connectedDropDownTvGuideGraceNoteTrans as DropDownTvGuideGraceNote};

