import React, {Component} from "react";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class NavBarWeather extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingNavWeather: false,
            weatherNavData: [],
            weather_name: "",
            weather_country: "",
            weather_temp: "",
            weather_icon: ""
        };
    }

    componentDidMount() {

        if (process.env.REACT_APP_DISPLAY_WEATHER_WIDGET === "1") {
            this.getWeatherNavData()

            this.intervalID = setInterval(
                () => this.getWeatherNavData(),
                1000 * 60 * 10
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    getWeatherNavData() {

        this.setState({
            loadingNavWeather: true
        })

        let dataUrl = process.env.REACT_APP_API_URL + "/Weather";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }


        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(dataUrl, axiosConfig)
            .then(res => {
                const weatherNavData = res.data[0];
                const loadingNavWeather = false;

                this.setState({
                    weatherNavData,
                    loadingNavWeather,
                    weather_name: weatherNavData.weather_name === null ? "" : weatherNavData.weather_name,
                    weather_country: weatherNavData.weather_country === null ? "" : weatherNavData.weather_country,
                    weather_temp: weatherNavData.weather_temp === null ? "" : weatherNavData.weather_temp,
                    weather_icon: weatherNavData.weather_icon === null ? "" : weatherNavData.weather_icon
                });
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                //this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    renderWeather = () => {

        if (process.env.REACT_APP_DISPLAY_WEATHER_WIDGET === "1") {
            let unitsIcon = "wi wi-celsius";

            if (this.state.weather_country === 'US') {
                unitsIcon = "wi wi-fahrenheit";
            }

            return (
                <span className="App-Weather">
                {this.state.weather_name + " , " + this.state.weather_country + "    "}
                    <i className={this.getWeatherIcon(this.state.weather_icon)}/>
                    {"   " + this.state.weather_temp}
                    <i className={unitsIcon}/>
            </span>

            );
        }
    }

    getWeatherIcon(code) {

        let weatherIcon = "wi wi-day-sunny";

        //clear sky
        if (code === '01d') {
            weatherIcon = "wi wi-day-sunny";
        }
        if (code === '01n') {
            weatherIcon = "wi wi-night-clear";
        }

        //few clouds
        if (code === '02d') {
            weatherIcon = "wi wi-day-cloudy";
        }
        if (code === '02n') {
            weatherIcon = "wi wi-night-alt-cloudy";
        }

        //scattered clouds
        if (code === '03d') {
            weatherIcon = "wi wi-cloud";
        }
        if (code === '03n') {
            weatherIcon = "wi wi-cloud";
        }

        //broken clouds
        if (code === '04d') {
            weatherIcon = "wi wi-cloudy";
        }
        if (code === '04n') {
            weatherIcon = "wi wi-cloudy";
        }

        //	shower rain
        if (code === '09d') {
            weatherIcon = "wi wi-day-showers";
        }
        if (code === '09n') {
            weatherIcon = "wi wi-night-alt-showers";
        }

        //rain
        if (code === '10d') {
            weatherIcon = "wi wi-day-rain";
        }
        if (code === '10n') {
            weatherIcon = "wi wi-night-alt-rain";
        }

        //thunderstorm
        if (code === '11d') {
            weatherIcon = "wi wi-day-thunderstorm";
        }
        if (code === '11n') {
            weatherIcon = "wi wi-night-alt-thunderstorm";
        }

        //snow
        if (code === '13d') {
            weatherIcon = "wi wi-day-snow";
        }
        if (code === '13n') {
            weatherIcon = "wi wi-night-alt-snow";
        }

        //mist
        if (code === '50d') {
            weatherIcon = "wi wi-fog";
        }
        if (code === '50n') {
            weatherIcon = "wi wi-fog";
        }

        //console.log("WEATHER: "+code);
        //console.log("WEATHER: "+weatherIcon);

        return weatherIcon;

    }

    render() {
        return (
            <div>
                {this.renderWeather()}
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedNavBarWeather = connect(mapStateToProps)(NavBarWeather);
const connectedNavBarWeatherTrans = withNamespaces('common')(connectedNavBarWeather);
export {connectedNavBarWeatherTrans as NavBarWeather};

