import React from "react";
import axios from "axios";
import {ResponsivePie} from "@nivo/pie";

class CurrentLiveWatchingAgents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            secondsChart1002: 0,
            data: []
        };

    }

    tick() {

        this.setState(prevState => ({
            secondsChart1002: prevState.secondsChart1002 + 1
        }));

        if (this.state.secondsChart1002 === 60) {
            this.setState({
                secondsChart1002: 0,
                loading: true
            });
            this.updateData()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateData() {

        let dataUrl = process.env.REACT_APP_API_URL + '/WmsReports/ActiveStreams/agents-now'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            //this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(dataUrl, axiosConfig)
            .then(res => {
                const chartData = res.data;
                const loading = false;
                //console.log(chartData);
                this.setState({data: chartData, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });
    }


    componentDidMount() {

        this.interval = setInterval(() => this.tick(), 1000);
        this.updateData();

    }

    render() {
        return (
            <div>
                <legend>
                    <h5>Active - Players</h5>
                </legend>
                <div style={{height: '350px'}}>
                    <ResponsivePie
                        data={this.state.data}
                        keys={['AgentCount']}
                        indexBy="DeviceType"
                        margin={{top: 40, right: 80, bottom: 40, left: 80}}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{scheme: 'nivo'}}
                        borderWidth={1}
                        borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{from: 'color'}}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        sortByValue={true}
                    />
                </div>
            </div>
        );
    }
}

export default CurrentLiveWatchingAgents;