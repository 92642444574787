import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";
import {style} from "../../variables/Variables";
import NotificationSystem from "react-notification-system";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class ModalContentProvider extends Component {

    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {

            access: user.level,

            showModalContentProvider: true,

            content_provider_id: null,
            content_provider_name: null,
            content_provider_description: null,
            content_provider_logo: null,
            content_provider_status: 0,
            content_provider_logo_temp: false,
            content_provider_logo_folder_temp: "",

            contentProviderArray: [],

            _notificationSystem: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleContentProviderImageSelect = this.handleContentProviderImageSelect.bind(this);

    }

    handleContentProviderImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "content_provider_logo") {
            if (filename !== null) {
                this.setState({
                    content_provider_logo_temp: true,
                    content_provider_logo: filename,
                    content_provider_logo_folder_temp: folder
                });
            } else {
                this.setState({
                    content_provider_logo_temp: false,
                    content_provider_logo: null,
                    content_provider_logo_folder_temp: ""
                });
            }

        }

    }

    submitPosterImage(filename_poster) {

        if (this.state.content_provider_logo_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.content_provider_logo_folder_temp);
            bodyFormData.set('location', "content_provider");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({content_provider_logo_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalContentProvider: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    manageSaveDevice() {

        if (this.props.handlerID === "new") {
            this.createContentProvider();
        } else {
            this.editContentProvider();
        }

    }

    createContentProvider() {

        if (AxiosHeader.getConfig() !== null) {
            const url = process.env.REACT_APP_API_URL + "/ContentProviders";

            let filename_poster = ""

            if (this.state.content_provider_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.content_provider_logo
                this.setState({content_provider_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.content_provider_logo
            }


            let postData = {
                content_provider_name: this.state.content_provider_name,
                content_provider_description: this.state.content_provider_description,
                content_provider_logo: filename_poster,
                content_provider_status: this.state.content_provider_status,

            };

            axios.post(url, postData, AxiosHeader.getConfig())
                .then((res) => {

                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to Create Content Provider")
                    } else {
                        this.setState({showModalMessage: false})
                        //this.notification("success", "Content Provider Created")
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    editContentProvider() {

        if (AxiosHeader.getConfig() !== null) {

            this.setState({loading: true});
            const url = process.env.REACT_APP_API_URL + "/ContentProviders/" +this.props.handlerID;

            let filename_poster = ""

            if (this.state.content_provider_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.content_provider_logo
                this.setState({content_provider_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.content_provider_logo
            }

            let postData = {
                content_provider_id: this.props.handlerID,
                content_provider_name: this.state.content_provider_name,
                content_provider_description: this.state.content_provider_description,
                content_provider_logo: filename_poster,
                content_provider_status: this.state.content_provider_status,
            };

            axios.put(url, postData, AxiosHeader.getConfig())
                .then((res) => {
                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to updated Content Provider")
                        this.setState({loading: false});
                    } else {
                        //this.notification("success", "Content Provider Updated")
                        this.setState({showModalMessage: false, loading: false})
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }

                })
                .catch((err) => {
                    this.setState({loading: false});
                    console.log("AXIOS ERROR: ", err);
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    componentDidMount() {
        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.loadContentProvider();
    }

    loadContentProvider() {

        if (AxiosHeader.getConfig() !== null) {

            if (this.props.handlerID !== "new") {

                this.setState({loading: true});

                let dataUrl = process.env.REACT_APP_API_URL + "/ContentProviders/" + this.props.handlerID;

                axios.get(dataUrl, AxiosHeader.getConfig()).then(res => {

                    const contentProviderArray = res.data;

                    this.setState({
                        contentProviderArray,
                        loading: false,

                        content_provider_id: contentProviderArray[0].content_provider_id,
                        content_provider_name: contentProviderArray[0].content_provider_name,
                        content_provider_description: contentProviderArray[0].content_provider_description,
                        content_provider_logo: contentProviderArray[0].content_provider_logo,
                        content_provider_status: contentProviderArray[0].content_provider_status,
                    });

                }).catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
            }
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    notification(status, message) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }


    render() {
        return (
            <div>
                <NotificationSystem ref="notificationSystem" style={style}/>

                <Modal show={this.state.showModalContentProvider} onHide={this.handleClose} bsSize="lg"
                       animation={false}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={7}>
                                    <FormGroup>
                                        <ControlLabel>Provider ID</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.content_provider_id || ''}
                                            placeholder="Provider ID"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Status</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.content_provider_status || ''}
                                            placeholder="Status"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Provider Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="content_provider_name"
                                            value={this.state.content_provider_name || ''}
                                            placeholder="Provider Name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Provider Description</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="content_provider_description"
                                            value={this.state.content_provider_description || ''}
                                            placeholder="Provider Description"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col sm={5}>

                                    <FormGroup
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            padding: '10px',
                                            borderColor: '#377cb3'
                                        }}>

                                        <ControlLabel>Provider Logo</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.content_provider_logo}
                                            imageType="content-provider"
                                            imagePosition={"content_provider_logo"}
                                            imageTemp={this.state.content_provider_logo_temp}
                                            imageFolderTemp={this.state.content_provider_logo_folder_temp}
                                            handlerFromParent={this.handleContentProviderImageSelect}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalContentProvider = connect(mapStateToProps)(ModalContentProvider);
const connectedModalContentProviderTrans = withNamespaces('common')(connectedModalContentProvider);
export {connectedModalContentProviderTrans as ModalContentProvider};