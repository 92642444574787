import React from "react";
import axios from "axios";
import Button from "../CustomButton/CustomButton.jsx";
import {Redirect} from 'react-router'
import {userActions} from "../../authentication/_actions";
import ModalRestore from "../CustomModals/ModalRestore";
import ModalDelete from "../CustomModals/ModalDelete";
import connect from "react-redux/es/connect/connect";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";
import Card from "../Card/Card";
import {OverlayTrigger, Tooltip, InputGroup, Row, Grid, Col, FormGroup} from "react-bootstrap";

class DataApplicationsManagement extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            remove_application_tooltip: (<Tooltip id="remove_application_tooltip_">Remove</Tooltip>),
            restore_application_tooltip: (<Tooltip id="restore_application_tooltip">Restore</Tooltip>),
            edit_application_tooltip: (<Tooltip id="redit_application_tooltip">Edit</Tooltip>),

            showRestoreModal: false,
            showDeleteModal: false,

            redirect: false,
            application_id: null,
            loading: true,
            applicationArray: [],
            columns: [
                {
                    accessor: "id",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "application_name",
                    Header: "Name",
                    maxWidth: 200,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "application_sso_url",
                    Header: "SSO URL",
                    maxWidth: 300,
                    sortable: true,
                    filterable: false
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }
            ],
            data: [],
            searchApplication: ""

        };

        this.handleInputChange = this.handleInputChange.bind(this);


    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let dt = new Date();
        let utcDate = dt.toUTCString();

        if (name === "searchApplication") {

            this.setState({
                lastUpdate: utcDate,
                filtered: [''],
                filter: value,
                activePage: 1,
                searchApplication: value
            })

            this.updateTable(value);
        }

    }

    clearSearchButton() {

        let dt = new Date();
        let utcDate = dt.toUTCString();
        let value = "";

        // if (this.props.listState === "removed") {
        //     set.removed_page_filter(value)
        //     set.removed_page("1")
        // } else {
        //     set.active_page_filter(value)
        //     set.active_page("1")
        // }

        this.setState({
            lastUpdate: utcDate,
            filtered: [''],
            filter: value,
            activePage: 1,
            searchApplication: ""
        })

        this.updateTable();

    }


    //BUTTONS
    setHelperButton(application_id) {

        if (this.props.listState === "removed") {
            return (<OverlayTrigger placement="top" overlay={this.state.restore_application_tooltip}>
                <Button
                    onClick={() => this.setState({application_id: application_id, showRestoreModal: true})}

                    bsStyle="warning"
                    simple
                    icon
                >
                    <i className="fa fa-repeat"/>
                </Button>
            </OverlayTrigger>)
        } else {
            return (<OverlayTrigger placement="top" overlay={this.state.edit_application_tooltip}>
                <Button
                    onClick={() => {
                        this.setState({application_id: application_id})
                        this.setRedirect();
                    }}

                    bsStyle="info"
                    simple
                    icon
                >
                    <i className="fa fa-edit"/>
                </Button>
            </OverlayTrigger>)
        }

    }

    setDeleteButton(application_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_application_tooltip}>
            <Button
                onClick={() => this.setState({application_id: application_id, showDeleteModal: true})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    deleteApplication(application_id) {

        const deleteURL = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + application_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                //let array = [...this.state.applicationsArray]; // make a separate copy of the array
                //const index = array.indexOf(application_id)
                //array.splice(index, 1);
                //this.setState({applicationsArray: array});
                this.updateTable();
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                //this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    restoreApplication(application_id) {

        const deleteURL = process.env.REACT_APP_API_URL + "/ApplicationsManagement/restore/" + application_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                //let array = [...this.state.applicationArray]; // make a separate copy of the array
                //const index = array.indexOf(application_id)
                //array.splice(index, 1);
                //this.setState({applicationsArray: array});
                this.updateTable();
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                //this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path_edit = "/edit-application/" + this.state.application_id;
        let path_new = "/add-application/new";

        if (this.state.application_id === 'new' && this.state.redirect ){
            return <Redirect push to={path_new}/>
        }

        if (this.state.redirect) {
            return <Redirect push to={path_edit}/>
        }
    }

    updateTable(value) {

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL + "/ApplicationsManagement";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        var postData = {
            type: "list",
            searchProvider: value
        }

        axios.post(dataUrl, postData, axiosConfig).then(res => {
            const applicationArray = res.data;
            const loading = false;
            this.setState({applicationArray, loading});
        }).catch(error => {
            this.setState({loading: false});
            console.log(error);
        });

    }

    componentDidMount() {
        this.updateTable()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.lastUpdate !== prevProps.lastUpdate) {
            this.updateTable()
        }
    }

    //MODALS
    setRestoreModal = (id, value, action) => {

        this.setState({
            showRestoreModal: value
        })

        if (action === "restore") {
            this.restoreApplication(id);
        }
    }
    setDisplayDeleteModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteApplication(id)
        }
    }
    renderRestoreModal = () => {

        if (this.state.showRestoreModal) {
            console.log("Clicked Restore Modal...");
            return <ModalRestore
                headerMessage={"Restore Application"}
                bodyMessage={"this action will restore the Application"}
                handlerFromParent={this.setRestoreModal}
                handlerID={this.state.application_id}
            />
        }
    }
    renderDeleteModal = () => {

        let bodyMessageVar = "this action will disable the Application removed state. This action can be undone";

        if (this.props.listState === "removed") {
            bodyMessageVar = "this action will permanently delete Application";
        }

        if (this.state.showDeleteModal) {
            console.log("Clicked Delete Modal...");
            return <ModalDelete
                headerMessage={"Delete Application"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayDeleteModal}
                handlerID={this.state.application_id}
            />
        }
    }

    render() {

        return (
            <div>
                {this.renderRedirect()}
                {this.renderRestoreModal()}
                {this.renderDeleteModal()}

                <div>
                    <Card
                        content={
                            <div>
                                <div>
                                    <Grid fluid>
                                        <Row>
                                            <Col md={12}>
                                                <div className="pull-left">
                                                    <h5>
                                                        {this.props.listName}
                                                    </h5>
                                                </div>
                                                <div className="pull-left">
                                                    <Button bsStyle="info" fill bsSize="xs"
                                                            style={{marginLeft: "10px", marginTop: "10px"}}
                                                            onClick={() => this.setState({
                                                                redirect: true,
                                                                application_id: "new"
                                                            })}
                                                    >
                                                        Add new Application
                                                    </Button>
                                                </div>
                                                <div className="pull-right" style={{width: "250px"}}>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <input
                                                                className="form-control form-control-sm ml-3 w-75"
                                                                type="text"
                                                                placeholder="Search"
                                                                aria-label="Search"
                                                                name="searchApplication"
                                                                onChange={this.handleInputChange}
                                                                value={this.state.searchApplication || ""}
                                                            />
                                                            <InputGroup.Addon>
                                                                <i style={{color: this.state.searchApplication !== "" ? "#f7263b" : "#343434"}}
                                                                   className={this.state.searchApplication !== "" ? "fa fa-times" : "fa fa-search"}
                                                                   onClick={this.clearSearchButton.bind(this)}/>
                                                            </InputGroup.Addon>
                                                        </InputGroup>
                                                    </FormGroup>

                                                </div>

                                            </Col>
                                        </Row>
                                    </Grid>
                                </div>

                                <legend/>


                                <ReactTableV6Simple
                                    data={this.state.applicationArray}
                                    loading={this.state.loading}
                                    columns={this.state.columns}
                                    defaultPageSize={10}
                                />

                            </div>
                        }
                    />

                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataApplicationsManagement = connect(mapStateToProps)(DataApplicationsManagement);
export {connectedDataApplicationsManagement as DataApplicationsManagement};
