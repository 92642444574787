const playlistArray =[
    {
        "id": "1",
        "label": "M3U Plus",
        "label_ott": "M3U Plus - OTT",
        "value_ott": "m3u_plus/hls",
        "label_iptv": "M3U Plus - IPTV",
        "value_iptv": "m3u_plus/mpegts",
        "download" : true
    },
    {
        "id": "2",
        "label": "Standard M3U",
        "label_ott": "Standard M3U - OTT",
        "value_ott": "m3u/hls",
        "label_iptv": "Standard M3U  - IPTV",
        "value_iptv": "m3u/mpegts",
        "download" : true
    },
    {
        "id": "3",
        "label": "Enigma 2 OE",
        "label_ott": "Enigma 2 - OTT",
        "value_ott": "enigma22_script/hls",
        "label_iptv": "Enigma 2 - IPTV",
        "value_iptv": "enigma22_script/ts",
        "download" : false
    },
    {
        "id": "4",
        "label": "DreamBox OE 2.0",
        "label_ott": "DreamBox - OTT",
        "value_ott": "dreambox/hls",
        "label_iptv": "DreamBox - IPTV",
        "value_iptv": "dreambox/mpegts",
        "download" : true
    },
    {
        "id": "5",
        "label": "Giga Blue",
        "label_ott": "Giga Blue - OTT",
        "value_ott": "gigablue/hls",
        "label_iptv": "Giga Blue - IPTV",
        "value_iptv": "gigablue/mpegts",
        "download" : true
    },
    {
        "id": "6",
        "label": "Simple List",
        "label_ott": "Simple List - OTT",
        "value_ott": "simple/hls",
        "label_iptv": "Simple List - IPTV",
        "value_iptv": "simple/mpegts",
        "download" : true
    },
    {
        "id": "7",
        "label": "Octagon",
        "label_ott": "Octagon - OTT",
        "value_ott": "octagon/hls",
        "label_iptv": "Octagon - IPTV",
        "value_iptv": "octagon/mpegts",
        "download" : true
    },
    {
        "id": "8",
        "label": "Starlive v3 / Star Sat HD6060 / AZ Class",
        "label_ott": "Starlive v3 - OTT",
        "value_ott": "starlivev3/hls",
        "label_iptv": "Starlive v3 - IPTV",
        "value_iptv": "starlivev3/mpegts",
        "download" : false
    },
    {
        "id": "9",
        "label": "Starlive v5",
        "label_ott": "Starlive V5 - OTT",
        "value_ott": "starlivev5/hls",
        "label_iptv": "Starlive V5 - IPTV",
        "value_iptv": "starlivev5/mpegts",
        "download" : true
    },
    {
        "id": "10",
        "label": "MediaStar / StarLive / Geant / Tiger",
        "label_ott": "MediaStar - OTT",
        "value_ott": "mediastar/hls",
        "label_iptv": "MediaStar - IPTV",
        "value_iptv": "mediastar/mpegts",
        "download" : true
    },
    {
        "id": "11",
        "label": "Web TV List",
        "label_ott": "Web TV List - OTT",
        "value_ott": "webtvlist/hls",
        "label_iptv": "Web TV List - IPTV",
        "value_iptv": "webtvlist/mpegts",
        "download" : true
    },
    {
        "id": "12",
        "label": "Ariva",
        "label_ott": "Ariva - OTT",
        "value_ott": "ariva/hls",
        "label_iptv": "Ariva - IPTV",
        "value_iptv": "ariva/mpegts",
        "download" : true
    },
    {
        "id": "13",
        "label": "Spark",
        "label_ott": "Spark - OTT",
        "value_ott": "spark/hls",
        "label_iptv": "Spark - IPTV",
        "value_iptv": "spark/mpegts",
        "download" : true
    },
    {
        "id": "14",
        "label": "Geant / Starsat / Tiger / Qmax / Hyper / Royal (OLD)",
        "label_ott": "Geant - OTT",
        "value_ott": "gst/hls",
        "label_iptv": "Geant - IPTV",
        "value_iptv": "gst/mpegts",
        "download" : true
    },
    {
        "id": "15",
        "label": "Fortec 999 / Prifix 9400 / Starport",
        "label_ott": "Fortec 999 - OTT",
        "value_ott": "fps/hls",
        "label_iptv": "Fortec 999 - IPTV",
        "value_iptv": "fps/mpegts",
        "download" : true
    },
    {
        "id": "16",
        "label": "Revolution 60/60 | Sunplus",
        "label_ott": "Revolution 60/60 - OTT",
        "value_ott": "revosun/hls",
        "label_iptv": "Revolution 60/60 - IPTV",
        "value_iptv": "revosun/mpegts",
        "download" : true
    },
    {
        "id": "17",
        "label": "Zorro",
        "label_ott": "Zorro - OTT",
        "value_ott": "zorro/hls",
        "label_iptv": "Zorro - IPTV",
        "value_iptv": "zorro/mpegts",
        "download" : true
    }
];


module.exports = {
    playlistArray,
};
