import React, {Component} from "react";
import axios from "axios/index";
import StatsCard from "../../components/Card/StatsCard.jsx";
import {Row, Col} from "react-bootstrap";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Moment from "react-moment";
import moment from "moment";


class StatsTvGuide extends Component {

    constructor() {
        super();
        this.state = {
            loading: false,

            guideStatusValue: [],
            channel_count: 0,
            daysLeft: 0,

            last_update: '1900-01-01 00:00:00',
            first_date: '1900-01-01 00:00:00',
            last_date: '1900-01-01 00:00:00',
        };
    }


    componentDidMount() {

        const url = process.env.REACT_APP_API_URL + "/TvGuide/status";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {

                let guideStatusValue = res.data[0];
                let loading = false;
                let channel_count = guideStatusValue.ChannelCount;
                let first_date = guideStatusValue.MinDate !== null ? moment.utc(guideStatusValue.MinDate).local().format('LLL') : "";
                let last_date = guideStatusValue.MaxDate !== null ? moment.utc(guideStatusValue.MaxDate).local().format('LLL') : "";
                let last_update =  guideStatusValue.MinDate !== null ? moment.utc(guideStatusValue.MinDate).local().format('LLL') : "";
                let daysLeft = guideStatusValue.Diff

                this.setState({
                    guideStatusValue,
                    loading,
                    channel_count,
                    first_date,
                    last_date,
                    last_update,
                    daysLeft
                });
            });

    }

    render() {
        return (
            <Row>
                <Col lg={6} sm={6}>
                    <StatsCard
                        bigIcon={this.state.daysLeft > 5 ? <i className="fa fa-info text-success"/> :
                            <i className="fa fa-info text-danger"/>}
                        statsText="Channels in the EPG System"
                        statsValue={this.state.channel_count}
                        statsIcon={<i className="fa fa-refresh"/>}
                        statsIconText={"last update on " + this.state.last_update}
                    />
                </Col>
                <Col lg={6} sm={6}>
                    <StatsCard
                        bigIcon={this.state.daysLeft > 5 ? <i className="fa fa-calendar text-success"/> :
                            <i className="fa fa-calendar text-danger"/>}
                        statsText="Days of EPG in the system"
                        statsValue={<Moment diff={this.state.first_date} unit="days">{this.state.last_date}</Moment>}
                        statsIcon={<i className="fa fa-calendar-o"/>}
                        statsIconText={this.state.daysLeft < 0 ? "NO EPG AVAILABLE" : "From " + this.state.first_date + " till " + this.state.last_date}
                    />
                </Col>
            </Row>
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedStatsTvGuide = connect(mapStateToProps)(StatsTvGuide);
const connectedStatsTvGuideTrans = withNamespaces('common')(connectedStatsTvGuide);
export {connectedStatsTvGuideTrans as StatsTvGuide};