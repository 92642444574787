import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, HelpBlock, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import Switch from "react-bootstrap-switch";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";
import Select from "react-select";
import {DropDownContentProviders} from "../CustomDropDown/DropDownContentProviders";
import {DeviceApplicationCategoryComponent} from "../CustomComponents/DeviceApplicationCategoryComponent";

const NewReleaseOptions = [
    {value: 'vod_id', label: 'By Title ID'},
    {value: 'vod_year', label: 'By Year'},
    {value: 'vod_release_date', label: 'By Release Date'}
];

const MostPopularOptions = [
    {value: 'today', label: 'Today'},
    {value: 'week', label: 'This Week'},
    {value: 'month', label: 'This Month'},
    {value: 'year', label: 'This Year'}
];

const BecauseYouWatchedOptions = [
    {value: 'actor', label: 'Actor'},
    {value: 'director', label: 'Director'},
    {value: 'genre', label: 'Genre'}
];

const WatchingNowOptions = [
    {value: '30', label: 'Past 30 Minutes'},
    {value: '60', label: 'Past 60 Minutes'},
    {value: '90', label: 'Past 90 Minutes'},
    {value: '1440', label: 'Past 24 Hours'},
];

const LayoutOptions = [
    {value: 'TV_Layout_1', label: 'TV_Layout_1'},
    {value: 'TV_Layout_2', label: 'TV_Layout_2'},
    {value: 'TV_Layout_3', label: 'TV_Layout_3'},
    {value: 'TV_Layout_4', label: 'TV_Layout_4'},
    {value: 'TV_Layout_5', label: 'TV_Layout_5'},
    {value: 'TV_Layout_6', label: 'TV_Layout_6'},
    {value: 'Feature_Categories_Type_1', label: 'Feature_Categories_Type_1'},
    {value: 'Feature_Categories_Type_2', label: 'Feature_Categories_Type_2'},
    {value: 'Feature_Categories_Type_3', label: 'Feature_Categories_Type_3'},
    {value: 'Feature_Categories_Type_4', label: 'Feature_Categories_Type_4'},
    {value: 'Feature_Categories_Type_5', label: 'Feature_Categories_Type_5'},
    {value: 'Feature_Categories_Type_6', label: 'Feature_Categories_Type_6'},
    {value: 'Radio', label: 'Radio'},
    {value: 'Sports', label: 'Sports'},
    {value: 'Package_Management', label: 'Package Management'},
    {value: 'search', label: 'Search'},
    {value: 'External_URL', label: 'External URL'},
    {value: 'Settings', label: 'Settings'},
    {value: 'Log_Out', label: 'Log Out'},
];

const MainTypeOptions = [
    {value: 'category', label: 'Category'},
    {value: 'continue_watching', label: 'Continue Watching'},
    {value: 'continue_watching_movies', label: 'Continue Watching - Movies'},
    {value: 'continue_watching_series', label: 'Continue Watching - Series'},
    {value: 'recently_watched', label: 'Recently Watched'},
    {value: 'recently_watched_movies', label: 'Recently Watched - Movies'},
    {value: 'recently_watched_series', label: 'Recently Watched - Series'},
    {value: 'new_releases', label: 'New Releases'},
    {value: 'most_popular', label: 'Most Popular'},
    {value: 'because_you_watched', label: 'Because you have watched'},
    {value: 'watching_now', label: 'Being watched now'},
    {value: 'feature', label: 'Featured - Section'},
];

const ContentType = [
    {value: 'LIVE_TV', label: 'Live TV'},
    {value: 'LIVE_EVENTS', label: 'Live Events'},
    {value: 'LIVE_RADIO', label: 'Radio'},
    {value: 'LIVE_SPORTS', label: 'Sports'},
    {value: 'TVOD', label: 'TVOD'},
    {value: 'SVOD', label: 'SVOD'},
    {value: 'SERIES', label: 'TV Series'},
    {value: 'APPLICATIONS', label: 'Applications'},
    {value: 'MUSIC', label: 'Music'},
    {value: 'KARAOKE', label: 'Karaoke'},
];


const adminArray = ['super-admin', 'admin']


class ModalApplicationDeviceMenuCategoryItem extends Component {

    constructor(props) {
        super(props);

        this.state = {

            showModalCustomerDevice: true,

            statusSwitch: false,

            id: null,
            menu_item_category_id: null,
            menu_item_device_type: null,
            menu_item_name: null,
            menu_item_position: null,
            menu_item_poster: null,
            menu_item_status: null,
            menu_item_content_type: null,
            menu_item_type: null,
            menu_item_layout: null,
            menu_item_colour: null,
            menu_item_content_provider: null,

            menu_item_poster_temp: false,
            menu_item_poster_folder_temp: "",

            current_user: null,
            user_content_provider: null,

            menu_item_name_message: "",
            menu_item_name_state: null,
            menu_item_type_message: "",
            menu_item_type_state: null,
            menu_item_sub_type_message: "",
            menu_item_sub_type_state: null,
            menu_item_position_message: "",
            menu_item_position_state: null,
            menu_item_external_url: "",

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMenuItemImageSelect = this.handleMenuItemImageSelect.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);

    }

    handleChildCategorySelect(data) {

        if (data !== undefined) {

            if (data === null) {

                this.setState({
                    menu_item_category_id: null
                });

            } else {

                console.log("handleChildCategorySelect->" + data)

                if (data.value) {

                    console.log("handleChildCategorySelect->1->" + data)
                    this.setState({
                        menu_item_category_id: data === null ? null : data.value
                    });

                } else {

                    console.log("handleChildCategorySelect->2->" + data)
                    this.setState({
                        menu_item_category_id: data === null ? null : data
                    });

                }
            }
        }

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleTypeChange = (menuTypeDropDown) => {

        this.setState({
            menu_item_type: menuTypeDropDown === null ? null : menuTypeDropDown.value
        });

    }

    handleMenuItemImageSelect(folder, filename, imagePosition) {

        //console.log("" + folder);
        //console.log("" + filename);
        //console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "menu_item_poster") {
            this.setState({
                menu_item_poster_temp: filename !== null,
                menu_item_poster: filename !== null ? filename : null,
                menu_item_poster_folder_temp: folder
            });
        }

    }

    submitPosterImage(filename_poster) {

        if (this.state.menu_item_poster_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.menu_item_poster_folder_temp);
            bodyFormData.set('location', "device_menu");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({menu_item_poster_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalCustomerDevice: false})
        this.props.handlerFromParent(this.props.selectedMenuId, false, "nothing");
    }

    manageSaveDevice() {

        if (this.props.newMenuItem === true) {
            this.createMenuItem();
        } else {
            this.editMenuItem();
        }

    }

    createMenuItem() {

        let url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId;

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        let categoryIds = null;

        if(Array.isArray(this.state.menu_item_category_id)){
            categoryIds =  this.state.menu_item_category_id === null ? null : this.state.menu_item_category_id.join()
        }else{
            categoryIds = this.state.menu_item_category_id
        }

        let postData = {
            type: "add_device_category_menu",
            menu_item_category_id: categoryIds,
            menu_item_device_type: this.props.deviceType,
            menu_item_name: this.state.menu_item_name,
            menu_item_position: this.state.menu_item_position,
            menu_item_poster: filename_poster,
            menu_item_status: menuItemStatus,
            menu_item_type: this.state.menu_item_type,
            menu_item_sub_type: this.state.menu_item_sub_type,
            menu_item_application_id: this.props.applicationId,
            menu_item_parent_id: this.props.selectedMenuId,
            menu_item_colour: this.state.menu_item_colour,
            menu_item_layout: this.state.menu_item_layout,
            menu_item_external_url: this.state.menu_item_external_url,
            menu_item_is_category: 1,
            menu_item_content_provider: this.state.user_content_provider,
            menu_item_content_type: this.state.menu_item_content_type,
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.selectedMenuId, false, "reload");

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }

    editMenuItem() {

        let url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId ;

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        let categoryIds = null;

        if(Array.isArray(this.state.menu_item_category_id)){
            categoryIds =  this.state.menu_item_category_id === null ? null : this.state.menu_item_category_id.join()
        }else{
            categoryIds = this.state.menu_item_category_id
        }

        let postData = {
            type: "update_device_category_menu",
            menu_item_category_id: categoryIds,
            menu_item_device_type: this.props.deviceType,
            menu_item_name: this.state.menu_item_name,
            menu_item_position: this.state.menu_item_position,
            menu_item_poster: filename_poster,
            menu_item_status: menuItemStatus,
            menu_item_colour: this.state.menu_item_colour,
            menu_item_layout: this.state.menu_item_layout,
            menu_item_is_category: 1,
            menu_item_content_provider: this.state.user_content_provider,
            menu_item_type: this.state.menu_item_type,
            menu_item_sub_type: this.state.menu_item_sub_type,
            menu_item_parent_id: this.props.selectedMenuId,
            menu_item_application_id: this.props.applicationId,
            menu_item_external_url: this.state.menu_item_external_url,
            menu_item_content_type: this.state.menu_item_content_type,
            menu_item_id: this.state.id
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.selectedMenuId, false, "reload");
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }

    componentDidMount() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        if (this.props.newMenuItem === false) {

            let statusSwitch = false

            if (this.props.menuItemObject.menu_item_status === 1) {
                statusSwitch = true
            }

            let id = this.props.menuItemObject.id
            let menu_item_category_id = this.props.menuItemObject.menu_item_category_id
            let menu_item_device_type = this.props.deviceType
            let menu_item_name = this.props.menuItemObject.menu_item_name
            let menu_item_position = this.props.menuItemObject.menu_item_position
            let menu_item_poster = this.props.menuItemObject.menu_item_poster
            let menu_item_status = this.props.menuItemObject.menu_item_status
            let menu_item_type = this.props.menuItemObject.menu_item_type
            let menu_item_sub_type = this.props.menuItemObject.menu_item_sub_type
            let menu_item_colour = this.props.menuItemObject.menu_item_colour
            let menu_item_layout = this.props.menuItemObject.menu_item_layout
            let menu_item_is_category = this.props.menuItemObject.menu_item_is_category
            let menu_item_content_provider = this.props.menuItemObject.menu_item_content_provider
            let menu_item_external_url = this.props.menuItemObject.menu_item_external_url
            let menu_item_content_type = this.props.menuItemObject.menu_item_content_type

            if (menu_item_category_id !== null) {

                menu_item_category_id = menu_item_category_id.toString()

                if (menu_item_category_id.includes(",")) {

                    menu_item_category_id = menu_item_category_id.split(",")
                }
            }

            this.setState(
                {
                    id,
                    menu_item_category_id,
                    menu_item_device_type,
                    menu_item_name,
                    menu_item_position,
                    menu_item_poster,
                    menu_item_status,
                    menu_item_type,
                    statusSwitch,
                    menu_item_colour,
                    menu_item_layout,
                    current_user: user,
                    menu_item_sub_type,
                    menu_item_is_category,
                    menu_item_content_provider,
                    menu_item_external_url,
                    menu_item_content_type,
                    user_content_provider: menu_item_content_provider
                }
            )

        } else {
            this.setState({current_user: user})
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }

    }

    handleContentTypeChange = (type) => {

        this.setState({
            menu_item_content_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);

    }

    handleMainTypeChange = (type) => {

        this.setState({
            menu_item_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);

    }

    handleLayoutChange = (type) => {

        this.setState({
            menu_item_layout: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    handleNewRealeseChange = (type) => {

        this.setState({
            menu_item_sub_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    handleMostPopularChange = (type) => {

        this.setState({
            menu_item_sub_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    handleBecauseYouWatchedChange = (type) => {

        this.setState({
            menu_item_sub_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    handleWatchingNowChange = (type) => {

        this.setState({
            menu_item_sub_type: type === null ? null : type.value
        });
        console.log(`Option selected:`, type);


    }

    renderSubType = () => {

        if (this.state.menu_item_type === "new_releases") {
            return (
                <FormGroup controlId="formValidation_menu_item_sub_type"
                           validationState={this.state.menu_item_sub_type_state}>
                    <ControlLabel>Sub Type</ControlLabel>
                    <div>
                        <Select
                            value={this.state.menu_item_sub_type}
                            onChange={this.handleNewRealeseChange}
                            options={NewReleaseOptions}
                        />
                    </div>
                    <HelpBlock>{this.state.menu_item_sub_type_message}</HelpBlock>
                </FormGroup>
            )
        }

        if (this.state.menu_item_type === "most_popular") {
            return (
                <FormGroup controlId="formValidation_menu_item_sub_type"
                           validationState={this.state.menu_item_sub_type_state}>
                    <ControlLabel>Sub Type</ControlLabel>
                    <div>
                        <Select
                            value={this.state.menu_item_sub_type}
                            onChange={this.handleMostPopularChange}
                            options={MostPopularOptions}
                        />
                    </div>
                    <HelpBlock>{this.state.menu_item_sub_type_message}</HelpBlock>
                </FormGroup>
            )
        }

        if (this.state.menu_item_type === "because_you_watched") {
            return (
                <FormGroup controlId="formValidation_menu_item_sub_type"
                           validationState={this.state.menu_item_sub_type_state}>
                    <ControlLabel>Sub Type</ControlLabel>
                    <div>
                        <Select
                            value={this.state.menu_item_sub_type}
                            onChange={this.handleBecauseYouWatchedChange}
                            options={BecauseYouWatchedOptions}
                        />
                    </div>
                    <HelpBlock>{this.state.menu_item_sub_type_message}</HelpBlock>
                </FormGroup>
            )
        }

        if (this.state.menu_item_type === "watching_now") {
            return (
                <FormGroup controlId="formValidation_menu_item_sub_type"
                           validationState={this.state.menu_item_sub_type_state}>
                    <ControlLabel>Sub Type</ControlLabel>

                    <div>
                        <Select
                            value={this.state.menu_item_sub_type}
                            onChange={this.handleWatchingNowChange}
                            options={WatchingNowOptions}
                        />
                    </div>
                    <HelpBlock>{this.state.menu_item_sub_type_message}</HelpBlock>
                </FormGroup>
            )
        }

        if (this.state.menu_item_type === "category" && this.state.menu_item_content_type !== null) {

            return (
                <FormGroup>
                    <ControlLabel>Categories</ControlLabel>
                    <div>
                        <DeviceApplicationCategoryComponent
                            contentProvider={this.state.user_content_provider}
                            contentType={this.state.menu_item_content_type}
                            categoriesSelected={this.state.menu_item_category_id}
                            handlerFromParent={this.handleChildCategorySelect}
                        />
                    </div>
                </FormGroup>
            )
        }

        if (this.state.menu_item_layout === "External_URL") {

            return (
                <FormGroup>
                    <ControlLabel>External URL</ControlLabel>
                    <FormControl
                        type="text"
                        name="menu_item_external_url"
                        value={this.state.menu_item_external_url || ''}
                        placeholder="enter the external url"
                        onChange={this.handleInputChange}
                    />
                </FormGroup>
            )
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }


    render() {
        return (
            <div>
                <Modal show={this.state.showModalCustomerDevice} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <ControlLabel>ID</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.id || ''}
                                            placeholder="NEW"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Device Type</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_device_type"
                                            disabled
                                            value={this.state.menu_item_device_type || ''}
                                            placeholder="DEVICE_TYPE"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Media</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.menu_item_poster}
                                            imageType="device_menu"
                                            imagePosition={"menu_item_poster"}
                                            imageTemp={this.state.menu_item_poster_temp}
                                            imageFolderTemp={this.state.menu_item_poster_folder_temp}
                                            handlerFromParent={this.handleMenuItemImageSelect}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm={8}>
                                    <FormGroup>
                                        <ControlLabel>Status</ControlLabel>
                                        <p className="category"/>
                                        <Switch
                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                            name='statusSwitch'
                                            value={this.state.statusSwitch}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_name"
                                            value={this.state.menu_item_name || ''}
                                            placeholder="enter a name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Position</ControlLabel>
                                        <FormControl
                                            type="number"
                                            name="menu_item_position"
                                            value={this.state.menu_item_position || ''}
                                            placeholder="enter the position"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Color</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="menu_item_colour"
                                            value={this.state.menu_item_colour || ''}
                                            placeholder="enter a name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Layout</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_layout}
                                            onChange={this.handleLayoutChange}
                                            options={LayoutOptions}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Content</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_content_type}
                                            onChange={this.handleContentTypeChange}
                                            options={ContentType}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Item Type</ControlLabel>
                                        <Select
                                            value={this.state.menu_item_type}
                                            onChange={this.handleMainTypeChange}
                                            options={MainTypeOptions}
                                        />
                                    </FormGroup>


                                    {this.renderSubType()}

                                    {this.renderContentProvider()}


                                </Col>

                            </Row>
                        </Grid>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalApplicationDeviceMenuCategoryItem = connect(mapStateToProps)(ModalApplicationDeviceMenuCategoryItem);
const connectedModalApplicationDeviceMenuCategoryItemTrans = withNamespaces('common')(connectedModalApplicationDeviceMenuCategoryItem);
export {connectedModalApplicationDeviceMenuCategoryItemTrans as ModalApplicationDeviceMenuCategoryItem};
