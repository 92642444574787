import React, {Component} from "react";
import axios from "axios/index";
import DualListBox from 'react-dual-listbox';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {css} from "@emotion/core";
import {BeatLoader} from "react-spinners";

const override = css`
    position: absolute;
    left: 47%;
    top: 30%;
    display: block;
    z-index: 1000;
    `;


class DualListBoxContentProvidersSvodPackages extends Component {

    constructor(props) {
        super(props);

        this.handleChannelSelect = this.handleChannelSelect.bind(this);

        this.state = {
            loading: false,

            availableSvodPackages: [],
            selectedArray: []
        };
        this.onChange = this.onChange.bind(this);

    }

    handleChannelSelect(selected) {
        this.props.handlerFromParent(selected);
    }

    onChange(selected) {

        this.setState({selectedArray: selected});
        this.handleChannelSelect(selected)
    }

    componentDidMount() {
        this.loadAvailableContentProvidersFromMiddleware();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.contentProviders !== prevProps.contentProviders) {
            this.loadAvailableContentProvidersFromMiddleware();
        }
    }

    loadAvailableContentProvidersFromMiddleware() {

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        //console.log(this.props.contentProviders)

        let contentProviderArray = [];

        if(this.props.contentProviders !== ""){
            contentProviderArray = this.props.contentProviders
            contentProviderArray = contentProviderArray.filter(v => v);
        }

        let postData = {
            type: 'svodPackages',
            contentProviders: contentProviderArray
        };


        axios
            .post(url, postData, axiosConfig)
            .then(res => {

                let temp = res.data;

                let availableSvodPackages = [];
                let obj = {};

                for (let i = 0; i < temp.length; i++) {

                    obj = {
                        value: temp[i].value.toString(),
                        label: temp[i].label

                    }
                    availableSvodPackages.push(obj)
                }

                const loading = false;

                this.setState({availableSvodPackages, loading});
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        return (
            <div>

                <div className="sweet-loader">
                    <BeatLoader
                        css={override}
                        size={5}
                        color={'#2c67a4'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <DualListBox
                    options={this.state.availableSvodPackages}
                    canFilter
                    availableLabel={"Available"}
                    selectedLabel={"Selected"}
                    showHeaderLabels
                    simpleValue={true}
                    selected={this.props.assignedSvodPackages}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDualListBoxContentProvidersSvodPackages = connect(mapStateToProps)(DualListBoxContentProvidersSvodPackages);
const connectedDualListBoxContentProvidersSvodPackagesTrans = withNamespaces('common')(connectedDualListBoxContentProvidersSvodPackages);
export {connectedDualListBoxContentProvidersSvodPackagesTrans as DualListBoxContentProvidersSvodPackages};
