import React from "react";
import axios from "axios";
// import ReactTable from "react-table";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "../CustomButton/CustomButton.jsx";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";

import {ModalDeviceMenuItem} from "../CustomModals/ModalDeviceMenuItem";
import ModalDelete from "../CustomModals/ModalDelete";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";

class DataDeviceMenu extends React.Component {

    constructor(props) {

        super(props);
        this.state = {

            edit_menu_item_tooltip: (<Tooltip id="edit_menu_item_tooltip">Edit</Tooltip>),
            remove_menu_item_tooltip: (<Tooltip id="remove_menu_item_tooltip">Remove</Tooltip>),

            redirect: false,

            showEditMenuItemModal: false,
            showDeleteMenuItemModal: false,
            newItem: false,

            menu_id: 0,
            loading: true,

            deviceMenuItemArray: [],

            columns: [
                {
                    accessor: "id",
                    Header: "ID",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_status",
                    Header: "Status",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <Badge fill="true" style={row.value === 1 ? {background: '#87cb16', borderRadius: '2px',lineHeight: "2", width: "100%"} : {background: '#545557' , borderRadius: '2px',lineHeight: "2", width: "100%"}  } >
                                {
                                    row.value === 1 ? 'Active' : 'Disabled'
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    accessor: "menu_item_name",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_position",
                    Header: "Position",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "menu_item_poster",
                    Header: "Image",
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="50px" height="40" alt={""}/>
                        </div>
                    )
                },
                {
                    accessor: "menu_item_type",
                    Header: "Type",
                    sortable: true,
                    filterable: false
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }

            ]

        };

    }

    componentDidMount() {

        this.updateMenuItemList()
    }

    updateMenuItemList() {

        let dataUrl = process.env.REACT_APP_API_URL + "/Device/menu/" + this.props.deviceType;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(dataUrl, axiosConfig)
            .then(res => {
                const deviceMenuItemArray = res.data;
                const loading = false;
                this.setState({deviceMenuItemArray, loading});
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    addItem(){

        this.setState({
            showEditMenuItemModal: true,
            newItem: true
        })
    }

    loadImage(value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            let image = process.env.REACT_APP_ASSET_URL + "/device_menu/" + value
            return image;
        }

    }

    //BUTTONS
    setHelperButton(menu_id) {

        return (
            <OverlayTrigger placement="top" overlay={this.state.edit_menu_item_tooltip}>
                <Button
                    onClick={() => this.setState({menu_id: menu_id, showEditMenuItemModal: true})}
                    bsStyle="info"
                    simple
                    icon
                >
                    <i className="fa fa-edit"/>
                </Button>
            </OverlayTrigger>

        )

    }

    setDeleteButton(menu_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_menu_item_tooltip}>
            <Button
                onClick={() => this.setState({menu_id: menu_id, showDeleteMenuItemModal: true})}
                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    setAddEditMenuItemModal = (id, value, action) => {

        this.setState({
            showEditMenuItemModal: value,
            newItem: false
        })

        if (action === "reload") {
            this.updateMenuItemList();
        }

    }

    setDeleteMenuItemModal = (id, value, action) => {

        this.setState({
            showDeleteMenuItemModal: value,
            newItem: false
        })

        if (action === "delete") {
            this.deleteMenuItem();
        }

    }

    deleteMenuItem(){

        const deleteURL = process.env.REACT_APP_API_URL + "/Device/menu/"+this.state.menu_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                this.updateMenuItemList();
                this.setState({menu_id: 0});
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    //HELPERS
    findArrayElementByID(item_id) {
        return this.state.deviceMenuItemArray.find((element) => {
            return element.id === item_id;
        })
    }

    renderAddEditDeviceMenuItem = () => {

        let bodyMessageVar = "";
        let menuItemObject = [];

        if(!this.state.newItem){
            menuItemObject = this.findArrayElementByID(this.state.menu_id)
        }

        if (this.state.showEditMenuItemModal) {
            console.log("Clicked Menu Item Modal...");
            return <ModalDeviceMenuItem
                headerMessage={"Menu Item: "}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setAddEditMenuItemModal}
                menuItemObject={menuItemObject}
                newMenuItem={this.state.newItem}
                handlerID={this.state.menu_id}
                deviceType={this.props.deviceType}
            />
        }
    }

    renderDeleteMenuItemModal = () => {

        let bodyMessageVar = "this action will delete the device. This action can be undone";

        if (this.state.showDeleteMenuItemModal) {
            console.log("Clicked Menu Item Modal...");
            return <ModalDelete
                headerMessage={"Delete Menu Item"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDeleteMenuItemModal}
                handlerID={this.state.menu_id}
            />
        }
    }

    render() {
        return (
            <div>
                {this.renderAddEditDeviceMenuItem()}
                {this.renderDeleteMenuItemModal()}

                <br/>
                <legend>Menu Items</legend>
                <div>
                    <Button
                        bsStyle="info" fill
                        onClick={this.addItem.bind(this)}>
                        Add Menu Item
                    </Button>
                    <br/>
                </div>

                <br/>

                {/*<ReactTable*/}
                    {/*data={this.state.deviceMenuItemArray}*/}
                    {/*filterable*/}
                    {/*loading={this.state.loading}*/}
                    {/*columns={this.state.columns}*/}
                    {/*defaultPageSize={5}*/}
                    {/*showPaginationBottom*/}
                    {/*className="-striped -highlight"*/}
                {/*/>*/}

                <ReactTableV6Simple
                    data={this.state.deviceMenuItemArray}
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={5}
                />
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataDeviceMenu = connect(mapStateToProps)(DataDeviceMenu);
export {connectedDataDeviceMenu as DataDeviceMenu};
