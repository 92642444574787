import React from "react";
import {connect} from 'react-redux';
import {userActions} from '../../authentication/_actions';
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.props.dispatch(userActions.logout());

        this.state = {
            cardHidden: true,
            username: '',
            password: '',
            submitted: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {

        e.preventDefault();

        this.setState({submitted: true});
        const {username, password} = this.state;
        const {dispatch} = this.props;
        if (username && password) {
            dispatch(userActions.login(username, password));
        }

    }

    componentDidMount() {
        setTimeout(
            function () {
                this.setState({cardHidden: false});
            }.bind(this),
            200
        );
    }

    render() {
        const {loggingIn} = this.props;
        const {username, password, submitted} = this.state;
        return (
            <Grid>
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <Card
                                hidden={this.state.cardHidden}
                                content={
                                    <div>
                                        {/*<div className="text-center">*/}
                                        {/*<legend style={{fontFamily: "BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell'," +*/}
                                        {/*"'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' ", padding:"5px"}}>Log in</legend>*/}

                                        {/*<h2 style={{fontFamily: "BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell'," +*/}
                                        {/*"'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' "}}>Sign in to your account</h2>*/}

                                        <h5 style={{
                                            fontFamily: "BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell'," +
                                                "'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' "
                                        }}>Sign in to your account</h5>
                                        <legend/>
                                        {/*</div>*/}
                                        <FormGroup>
                                            <ControlLabel>Username</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon>
                                                    <Glyphicon glyph="user"/>
                                                </InputGroup.Addon>
                                                <FormControl placeholder="Enter your email address" type="email"
                                                             name="username" onChange={this.handleChange}/>
                                                {submitted && !username &&
                                                <div className="help-block">Username is required</div>
                                                }
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>Password</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon>
                                                    <Glyphicon glyph="lock"/>
                                                </InputGroup.Addon>
                                                <FormControl placeholder="Enter your password" type="password"
                                                             name="password"
                                                             value={password} autoComplete="password"
                                                             onChange={this.handleChange}/>
                                                {submitted && !password &&
                                                <div className="help-block">Password is required</div>
                                                }
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <legend/>
                                            <Button type="submit" bsStyle="primary" fill wd style={{width: "100%"}}>
                                                Log in
                                            </Button>
                                            {loggingIn}
                                        </FormGroup>
                                    </div>
                                }
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    const {loggingIn} = state.authentication;
    return {
        loggingIn
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export {connectedLoginPage as LoginPage};