import React, {Component} from "react";
import {Redirect} from "react-router";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import axios from "axios";
import {DataDeviceMenu} from "../../components/Middleware/DataDeviceMenu";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {ScaleLoader} from "react-spinners";
import { css } from "@emotion/core";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import Select from 'react-select';
import tvbox from "../../assets/img/tv-box.png";
import googlePlay from "../../assets/img/google_play.png";
import apk from "../../assets/img/apk.png";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const tvOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const radioOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const applicationsOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const videoOnDemandOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const videoCategoriesOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const tvShowsOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

class DeviceSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,

            deviceSettingsObject: null,
            saveDeviceType: "",

            showModalSave: false,

            android_mobile_device_type: null,
            android_mobile_terms_of_use: null,
            android_mobile_policy: null,
            android_mobile_version: null,

            android_tv_device_type: null,
            android_tv_terms_of_use: null,
            android_tv_policy: null,
            android_tv_version: null,

            roku_device_type: null,
            roku_terms_of_use: null,
            roku_policy: null,
            roku_version: null,

            ios_mobile_device_type: null,
            ios_mobile_terms_of_use: null,
            ios_mobile_policy: null,
            ios_mobile_version: null,

            tv_ordering: "",
            vod_ordering: "",
            radio_ordering: "",
            app_ordering: "",
            vod_cat_ordering: "",
            tv_shows_ordering: "",

            tvOrderDropDown: null,
            tvOrder: null,

            radioOrderDropDown: null,
            radioOrder: null,

            applicationsOrderDropDown: null,
            applicationsOrder: null,

            videoOnDemandOrderDropDown: null,
            videoOnDemandOrder: null,

            videoCategoriesOrderDropDown: null,
            videoCategoriesOrder: null,

            tvShowsOrderDropDown: null,
            tvShowsOrder: null,

            google_store_tv_version: null,
            google_store_tv_apk_url: null,
            google_tv_store_update: null,

            manual_store_tv_version: null,
            manual_store_tv_apk_url: null,
            manual_store_tv_update: null,

            amazon_store_tv_version: null,
            amazon_store_tv_apk_url: null,
            amazon_store_tv_update: null,



        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadio = this.handleRadio.bind(this);

    }

    handleTvOrderChange = (tvOrderDropDown) => {

        this.setState({
            tvOrderDropDown,
            tvOrder: tvOrderDropDown === null ? "asc" : tvOrderDropDown.value
        });
        // console.log(`Option selected:`, tvOrderDropDown);

    }

    handleRadioOrderChange = (radioOrderDropDown) => {

        this.setState({
            radioOrderDropDown,
            radioOrder: radioOrderDropDown === null ? "asc" : radioOrderDropDown.value
        });
        // console.log(`Option selected:`, radioOrderDropDown);

    }

    handleApplicationsOrderChange = (applicationsOrderDropDown) => {

        this.setState({
            applicationsOrderDropDown,
            applicationsOrder: applicationsOrderDropDown === null ? "asc" : applicationsOrderDropDown.value
        });
        // console.log(`Option selected:`, applicationsOrderDropDown);

    }

    handleVideoOnDemandOrderChange = (videoOnDemandOrderDropDown) => {

        this.setState({
            videoOnDemandOrderDropDown,
            videoOnDemandOrder: videoOnDemandOrderDropDown === null ? "asc" : videoOnDemandOrderDropDown.value
        });
        // console.log(`Option selected:`, videoOnDemandOrderDropDown);

    }

    handleVideoCategoryOrderChange = (videoCategoriesOrderDropDown) => {

        this.setState({
            videoCategoriesOrderDropDown,
            videoCategoriesOrder: videoCategoriesOrderDropDown === null ? "asc" : videoCategoriesOrderDropDown.value
        });
        // console.log(`Option selected:`, videoCategoriesOrderDropDown);

    }

    handleTvShowsOrderChange = (tvShowsOrderDropDown) => {

        this.setState({
            tvShowsOrderDropDown,
            tvShowsOrder: tvShowsOrderDropDown === null ? "asc" : tvShowsOrderDropDown.value
        });
        // console.log(`Option selected:`, tvShowsOrderDropDown);

    }

    handleRadio = event => {

        const target = event.target;

        //console.log(target.name)
        //console.log(target.value)

        this.setState({
            [target.name]: target.value
        });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

        this.loadAndroidMobileData();
        this.loadAndroidTVData();
        this.loadRokuData();
        this.loadIosMobileData();
        this.loadDeviceOrderConfiguration();
        this.loadGoogleStoreOptions();
        this.loadManualStoreOptions();
        this.loadGoogleApkData();
        this.loadAmazonData();
        this.loadManualData();


    }

    //LOAD
    loadGoogleStoreOptions() {

    }

    loadManualStoreOptions() {

    }

    loadDeviceOrderConfiguration() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/ordering";

        axios
            .get(url, axiosConfig)
            .then(res => {

                for (let i = 0; i < res.data.length; i++) {

                    // console.log(res.data[i].property_name + " " + res.data[i].property_value + " " + res.data[i].property_direction)

                    if (res.data[i].property_name === 'tv_ordering') {
                        this.setState({
                            tvOrder: res.data[i].property_direction,
                            tv_ordering: res.data[i].property_value
                        })
                    }
                    if (res.data[i].property_name === 'radio_ordering') {
                        this.setState({
                            radioOrder: res.data[i].property_direction,
                            radio_ordering: res.data[i].property_value
                        })
                    }
                    if (res.data[i].property_name === 'app_ordering') {
                        this.setState({
                            applicationsOrder: res.data[i].property_direction,
                            app_ordering: res.data[i].property_value
                        })
                    }
                    if (res.data[i].property_name === 'vod_ordering') {
                        this.setState({
                            videoOnDemandOrder: res.data[i].property_direction,
                            vod_ordering: res.data[i].property_value
                        })
                    }
                    if (res.data[i].property_name === 'vod_cat_ordering') {
                        this.setState({
                            videoCategoriesOrder: res.data[i].property_direction,
                            vod_cat_ordering: res.data[i].property_value
                        })
                    }
                    if (res.data[i].property_name === 'tv_shows_ordering') {
                        this.setState({
                            tvShowsOrder: res.data[i].property_direction,
                            tv_shows_ordering: res.data[i].property_value
                        })
                    }
                }

                this.setState({loading: false});

            });
    }

    loadAndroidMobileData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/settings/Android";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const deviceSettingsObject = res.data[0];
                const loading = false;

                //console.log(deviceSettingsObject);

                this.setState({
                    android_mobile_device_type: deviceSettingsObject.device_type,
                    android_mobile_terms_of_use: deviceSettingsObject.terms_of_use,
                    android_mobile_policy: deviceSettingsObject.policy,
                    android_mobile_version: deviceSettingsObject.version,

                    loading,
                    deviceSettingsObject
                });
            });
    }

    loadAndroidTVData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/settings/AndroidTV";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const deviceSettingsObject = res.data[0];
                const loading = false;

                //console.log(deviceSettingsObject);

                this.setState({
                    android_tv_device_type: deviceSettingsObject.device_type,
                    android_tv_terms_of_use: deviceSettingsObject.terms_of_use,
                    android_tv_policy: deviceSettingsObject.policy,
                    android_tv_version: deviceSettingsObject.version,

                    loading,
                    deviceSettingsObject
                });
            });
    }

    loadRokuData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/settings/Roku";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const deviceSettingsObject = res.data[0];
                const loading = false;

                //console.log(deviceSettingsObject);

                this.setState({
                    roku_device_type: deviceSettingsObject.device_type,
                    roku_terms_of_use: deviceSettingsObject.terms_of_use,
                    roku_policy: deviceSettingsObject.policy,
                    roku_version: deviceSettingsObject.version,

                    loading,
                    deviceSettingsObject
                });
            });
    }

    loadIosMobileData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/settings/iOS";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const deviceSettingsObject = res.data[0];
                const loading = false;

                //console.log(deviceSettingsObject);

                this.setState({
                    ios_mobile_device_type: deviceSettingsObject.device_type,
                    ios_mobile_terms_of_use: deviceSettingsObject.terms_of_use,
                    ios_mobile_policy: deviceSettingsObject.policy,
                    ios_mobile_version: deviceSettingsObject.version,

                    loading,
                    deviceSettingsObject
                });
            });
    }

    //LOAD APK
    loadGoogleApkData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/apk/google";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const apkSettingsObject = res.data[0];

                //console.log(apkSettingsObject);

                this.setState({
                    google_store_tv_version: apkSettingsObject.store_tv_version,
                    google_store_tv_apk_url: apkSettingsObject.store_tv_apk_url,
                    google_tv_store_update: apkSettingsObject.store_update,
                });
            });
    }

    loadAmazonData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/apk/amazon";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const apkSettingsObject = res.data[0];

                //console.log(apkSettingsObject);

                this.setState({
                    amazon_store_tv_version: apkSettingsObject.store_tv_version,
                    amazon_store_tv_apk_url: apkSettingsObject.store_tv_apk_url,
                    amazon_store_tv_update: apkSettingsObject.store_update,
                });
            });
    }

    loadManualData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/apk/manual";

        axios
            .get(url, axiosConfig)
            .then(res => {

                const apkSettingsObject = res.data[0];

                //console.log(apkSettingsObject);

                this.setState({
                    manual_store_tv_version: apkSettingsObject.store_tv_version,
                    manual_store_tv_apk_url: apkSettingsObject.store_tv_apk_url,
                    manual_tv_store_update: apkSettingsObject.store_update,
                });
            });
    }

    //SAVE
    saveAndroidSettings() {

        this.setState({saveDeviceType: "Android", showModalSave: true})

    }

    saveAndroidTvSettings() {

        this.setState({saveDeviceType: "AndroidTV", showModalSave: true})

    }

    saveIosSettings() {

        this.setState({saveDeviceType: "iOS", showModalSave: true})

    }

    saveRokuSettings() {

        this.setState({saveDeviceType: "Roku", showModalSave: true})

    }

    saveDeviceOrderConfiguration() {

        this.setState({saveDeviceType: "DeviceOrder", showModalSave: true})

    }

    saveGoogleAPKSettings() {
        // console.log("saveGoogleAPKSettings")
        this.setState({saveDeviceType: "apk_google", showModalSave: true})
    }

    saveAmazonAPKSettings() {
        // console.log("saveAmazonAPKSettings")
        this.setState({saveDeviceType: "apk_amazon", showModalSave: true})
    }

    saveManualAPKSettings() {
        // console.log("saveManualAPKSettings")
        this.setState({saveDeviceType: "apk_manual", showModalSave: true})
    }

    //UPDATE
    updateAndroidSettings() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";

        let postData = {
            device: {
                type: "update_device_settings_android",
                device_type: this.state.android_mobile_device_type,
                terms_of_use: this.state.android_mobile_terms_of_use,
                policy: this.state.android_mobile_policy,
                version: this.state.android_mobile_version

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            })
    }

    updateAndroidTvSettings() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";

        let postData = {
            device: {
                type: "update_device_settings_android_tv",
                device_type: this.state.android_tv_device_type,
                terms_of_use: this.state.android_tv_terms_of_use,
                policy: this.state.android_tv_policy,
                version: this.state.android_tv_version

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    updateIosSettings() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";


        let postData = {
            device: {
                type: "update_device_settings_ios",
                device_type: this.state.ios_mobile_device_type,
                terms_of_use: this.state.ios_mobile_terms_of_use,
                policy: this.state.ios_mobile_policy,
                version: this.state.ios_mobile_version

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    updateRokuSettings() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";


        let postData = {
            device: {
                type: "update_device_settings_roku",
                device_type: this.state.roku_device_type,
                terms_of_use: this.state.roku_terms_of_use,
                policy: this.state.roku_policy,
                version: this.state.roku_version

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    updateDeviceOrderConfiguration() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";


        let postData = {
            device: {
                type: "update_device_order_configuration",
                tv_ordering_value: this.state.tv_ordering,
                tv_ordering_direction: this.state.tvOrder,
                radio_ordering_value: this.state.radio_ordering,
                radio_ordering_direction: this.state.radioOrder,
                app_ordering_value: this.state.app_ordering,
                app_ordering_direction: this.state.applicationsOrder,
                vod_ordering_value: this.state.vod_ordering,
                vod_ordering_direction: this.state.videoOnDemandOrder,
                vod_cat_ordering_value: this.state.vod_cat_ordering,
                vod_cat_ordering_direction: this.state.videoCategoriesOrder,
                tv_shows_ordering_value: this.state.tv_shows_ordering,
                tv_shows_ordering_direction: this.state.tvShowsOrder

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    updateAPKConfiguration(type) {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Device";
        let postData = null;

        if (type === "update_google_apk") {
            postData = {
                device: {
                    type: type,
                    store_tv_version: this.state.google_store_tv_version,
                    store_tv_apk_url: this.state.google_store_tv_apk_url,
                    store_update: this.state.google_tv_store_update

                }
            }
        }

        if (type === "update_amazon_apk") {
            postData = {
                device: {
                    type: type,
                    store_tv_version: this.state.amazon_store_tv_version,
                    store_tv_apk_url: this.state.amazon_store_tv_apk_url,
                    store_update: this.state.amazon_store_tv_update

                }
            }
        }

        if (type === "update_manual_apk") {
            postData = {
                device: {
                    type: type,
                    store_tv_version: this.state.manual_store_tv_version,
                    store_tv_apk_url: this.state.manual_store_tv_apk_url,
                    store_update: this.state.manual_store_tv_update

                }
            }
        }

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {

            if (this.state.saveDeviceType === "Android") {
                this.updateAndroidSettings()
            }
            if (this.state.saveDeviceType === "AndroidTV") {
                this.updateAndroidTvSettings()
            }
            if (this.state.saveDeviceType === "iOS") {
                this.updateIosSettings()
            }
            if (this.state.saveDeviceType === "Roku") {
                this.updateRokuSettings()
            }
            if (this.state.saveDeviceType === "DeviceOrder") {
                this.updateDeviceOrderConfiguration()
            }
            if (this.state.saveDeviceType === "apk_google") {
                this.updateAPKConfiguration("update_google_apk")
            }
            if (this.state.saveDeviceType === "apk_amazon") {
                this.updateAPKConfiguration("update_amazon_apk")
            }
            if (this.state.saveDeviceType === "apk_manual") {
                this.updateAPKConfiguration("update_manual_apk")
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showModalSave) {
            return <ModalSave
                headerMessage={"Save Device Settings: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.saveDeviceType}
            />
        }
    }

    cancelDeviceSettingsEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/settings";

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

    }

    render() {
        return (
            <div className="main-content">

                {this.renderSaveModal()}
                {this.renderRedirect()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={2}>
                                                    <div className="center-block">
                                                        {/*<Button bsStyle="default" simple icon*/}
                                                                {/*onClick={this.cancelDeviceSettingsEdit.bind(this)}>*/}
                                                            {/*<i className="fa fa-undo fa-2x"/>*/}
                                                        {/*</Button>*/}
                                                    </div>
                                                </Col>
                                                <Col sm={8}>
                                                    <div className="center-block">
                                                        <img className="img-responsive  center-block"
                                                             style={{maxHeight: '60px', maxWidth: '100%'}}
                                                             src={tvbox}
                                                             alt={"tvbox"}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={2}>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={12}>
                                                    <PanelGroup accordion id="device-settings-panel">
                                                        <Panel eventKey="1">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-globe"/> Global Device Settings <b
                                                                    className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>
                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <legend>Live TV</legend>
                                                                        <Select
                                                                            value={this.state.tvOrder}
                                                                            onChange={this.handleTvOrderChange}
                                                                            options={tvOrder}
                                                                        />
                                                                        <Radio
                                                                            number="1dr"
                                                                            option="NAME"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="2dr"
                                                                            option="POSITION"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                        <Radio
                                                                            number="3dr"
                                                                            option="MAJOR_MINOR"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "MAJOR_MINOR"}
                                                                            label="Order by Major.Minor"
                                                                        />
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <legend>Radio</legend>
                                                                        <Select
                                                                            value={this.state.radioOrder}
                                                                            onChange={this.handleRadioOrderChange}
                                                                            options={radioOrder}
                                                                        />
                                                                        <Radio
                                                                            number="3dr"
                                                                            option="NAME"
                                                                            name="radio_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.radio_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="4dr"
                                                                            option="POSITION"
                                                                            name="radio_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.radio_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                    <Col md={3}>
                                                                        <legend>Applications</legend>
                                                                        <Select
                                                                            value={this.state.applicationsOrder}
                                                                            onChange={this.handleApplicationsOrderChange}
                                                                            options={applicationsOrder}
                                                                        />
                                                                        <Radio
                                                                            number="5dr"
                                                                            option="NAME"
                                                                            name="app_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.app_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="6dr"
                                                                            option="POSITION"
                                                                            name="app_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.app_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>


                                                                    <Col md={3}>
                                                                        <legend>Video On Demand</legend>
                                                                        <Select
                                                                            value={this.state.videoOnDemandOrder}
                                                                            onChange={this.handleVideoOnDemandOrderChange}
                                                                            options={videoOnDemandOrder}
                                                                        />
                                                                        <Radio
                                                                            number="7dr"
                                                                            option="NAME"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="8dr"
                                                                            option="POSITION"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                        <Radio
                                                                            number="9dr"
                                                                            option="RELEASE_DATE"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "RELEASE_DATE"}
                                                                            label="Order by Release Date"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <legend>Video Categories</legend>
                                                                        <Select
                                                                            value={this.state.videoCategoriesOrder}
                                                                            onChange={this.handleVideoCategoryOrderChange}
                                                                            options={videoCategoriesOrder}
                                                                        />
                                                                        <Radio
                                                                            number="10dr"
                                                                            option="NAME"
                                                                            name="vod_cat_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_cat_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="11dr"
                                                                            option="POSITION"
                                                                            name="vod_cat_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_cat_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                    <Col md={3}>
                                                                        <legend>TV Shows</legend>
                                                                        <Select
                                                                            value={this.state.tvShowsOrder}
                                                                            onChange={this.handleTvShowsOrderChange}
                                                                            options={tvShowsOrder}
                                                                        />
                                                                        <Radio
                                                                            number="12dr"
                                                                            option="NAME"
                                                                            name="tv_shows_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_shows_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="13dr"
                                                                            option="POSITION"
                                                                            name="tv_shows_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_shows_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="pull-right">
                                                                            <Button
                                                                                fill
                                                                                bsStyle="primary"
                                                                                type="submit"
                                                                                onClick={this.saveDeviceOrderConfiguration.bind(this)}
                                                                            >
                                                                                Save order configuration
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                            </Panel.Body>
                                                        </Panel>
                                                    </PanelGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }

                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                title={"Device Settings"}
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={12}>
                                                    <PanelGroup accordion id="device-settings-panel">

                                                        <Panel eventKey="2">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-android text-success"/> Android
                                                                    Settings <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveAndroidSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_mobile_device_type</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="android_mobile_device_type"
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="android_mobile_device_type"
                                                                                    value={this.state.android_mobile_device_type || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_mobile_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="version"
                                                                                    type="text"
                                                                                    name="android_mobile_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_mobile_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>terms_of_use</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="android_mobile_terms_of_use"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="android_mobile_terms_of_use"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_mobile_terms_of_use || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>policy</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="android_mobile_policy"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="android_mobile_policy"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_mobile_policy || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>

                                                                </Row>


                                                                <Row>
                                                                    <Col md={12}>
                                                                        {/*<ControlLabel>Main Menu - Items</ControlLabel>*/}
                                                                        <DataDeviceMenu
                                                                            deviceType={"android_mobile"}
                                                                        />
                                                                    </Col>
                                                                </Row>


                                                            </Panel.Body>
                                                        </Panel>

                                                        <Panel eventKey="3">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-apple text-default"/> iOS
                                                                    Settings <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveIosSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>ios_mobile_device_type</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="ios_mobile_device_type"
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="ios_mobile_device_type"
                                                                                    value={this.state.ios_mobile_device_type || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>ios_mobile_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="ios_mobile_version"
                                                                                    type="text"
                                                                                    name="ios_mobile_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.ios_mobile_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>ios_mobile_terms_of_use</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="ios_mobile_terms_of_use"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="ios_mobile_terms_of_use"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.ios_mobile_terms_of_use || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>ios_mobile_policy</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="ios_mobile_policy"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="ios_mobile_policy"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.ios_mobile_policy || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        {/*<ControlLabel>Main Menu - Items</ControlLabel>*/}
                                                                        <DataDeviceMenu
                                                                            deviceType={"ios_mobile"}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Panel.Body>
                                                        </Panel>

                                                        <Panel eventKey="4">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-amazon text-warning"/> Android
                                                                    TV
                                                                    Settings <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveAndroidTvSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_tv_device_type</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="android_tv_device_type"
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="android_tv_device_type"
                                                                                    value={this.state.android_tv_device_type || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_tv_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="android_tv_version"
                                                                                    type="text"
                                                                                    name="android_tv_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_tv_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_tv_terms_of_use</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="android_tv_terms_of_use"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="android_tv_terms_of_use"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_tv_terms_of_use || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>android_tv_policy</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="android_tv_policy"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="android_tv_policy"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.android_tv_policy || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        {/*<ControlLabel>Main Menu - Items</ControlLabel>*/}
                                                                        <DataDeviceMenu
                                                                            deviceType={"android_tv"}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Panel.Body>
                                                        </Panel>

                                                        <Panel eventKey="5">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-television text-roku"/> Roku
                                                                    Settings <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveRokuSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>roku_device_type</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="roku_device_type"
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="roku_device_type"
                                                                                    value={this.state.roku_device_type || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>roku_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="roku_version"
                                                                                    type="text"
                                                                                    name="roku_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.roku_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>roku_terms_of_use</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="roku_terms_of_use"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="roku_terms_of_use"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.roku_terms_of_use || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>roku_policy</ControlLabel>
                                                                                <FormControl
                                                                                    componentClass="textarea"
                                                                                    placeholder="roku_policy"
                                                                                    rows={10}
                                                                                    type="text"
                                                                                    name="roku_policy"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.roku_policy || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        {/*<ControlLabel>Main Menu - Items</ControlLabel>*/}
                                                                        <DataDeviceMenu
                                                                            deviceType={"roku"}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </Panel.Body>
                                                        </Panel>

                                                    </PanelGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }

                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                title={"APK Management"}
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={12}>
                                                    <PanelGroup accordion id="apk-settings-panel">

                                                        <Panel eventKey="6">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle>
                                                                    <i className="text-info">
                                                                        <img
                                                                            src={googlePlay}
                                                                            style={{maxHeight: '25px', maxWidth: '90%'}}
                                                                            alt={"googlePlay"}
                                                                        />
                                                                    </i>
                                                                    Google Play
                                                                    <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveGoogleAPKSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>TYPE</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="google_type"
                                                                                    value={"GOOGLE"}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>google_store_tv_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="google_store_tv_version"
                                                                                    type="text"
                                                                                    name="google_store_tv_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.google_store_tv_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>google_store_tv_apk_url</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="google_store_tv_apk_url"
                                                                                    type="text"
                                                                                    name="google_store_tv_apk_url"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.google_store_tv_apk_url || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>google_tv_store_update</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="google_tv_store_update"
                                                                                    type="text"
                                                                                    name="google_tv_store_update"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.google_tv_store_update || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>


                                                            </Panel.Body>
                                                        </Panel>

                                                        <Panel eventKey="7">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle>
                                                                    <i className="text-info">
                                                                        <img
                                                                            src={apk}
                                                                            style={{maxHeight: '25px', maxWidth: '90%'}}
                                                                            alt={"apk"}
                                                                        />
                                                                    </i>
                                                                    Store
                                                                    <b className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveManualAPKSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>TYPE</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="manual_store_type"
                                                                                    value={"MANUAL"}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>manual_store_tv_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="manual_store_tv_version"
                                                                                    type="text"
                                                                                    name="manual_store_tv_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.manual_store_tv_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>manual_store_tv_apk_url</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="manual_store_tv_apk_url"
                                                                                    type="text"
                                                                                    name="manual_store_tv_apk_url"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.manual_store_tv_apk_url || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>manual_store_tv_update</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="manual_store_tv_update"
                                                                                    type="text"
                                                                                    name="manual_store_tv_update"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.manual_store_tv_update || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>


                                                            </Panel.Body>
                                                        </Panel>

                                                        <Panel eventKey="8">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-amazon text-warning"/> Amazon <b
                                                                    className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>

                                                            <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                                <div className="row">
                                                                    <div className="col-md-1 col-md-offset-11">
                                                                        <Button
                                                                            bsStyle="info" fill
                                                                            onClick={this.saveAmazonAPKSettings.bind(this)}>
                                                                            Save
                                                                        </Button>
                                                                    </div>

                                                                </div>

                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>TYPE</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    disabled
                                                                                    name="amazon_store_type"
                                                                                    value={"AMAZON"}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>amazon_store_tv_version</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="amazon_store_tv_version"
                                                                                    type="text"
                                                                                    name="amazon_store_tv_version"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.amazon_store_tv_version || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>amazon_store_tv_apk_url</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="amazon_store_tv_apk_url"
                                                                                    type="text"
                                                                                    name="amazon_store_tv_apk_url"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.amazon_store_tv_apk_url || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup>
                                                                                <ControlLabel>amazon_store_tv_update</ControlLabel>
                                                                                <FormControl
                                                                                    placeholder="amazon_store_tv_update"
                                                                                    type="text"
                                                                                    name="amazon_store_tv_update"
                                                                                    onChange={this.handleInputChange}
                                                                                    value={this.state.amazon_store_tv_update || ''}
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Col>
                                                                </Row>


                                                            </Panel.Body>
                                                        </Panel>

                                                    </PanelGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>

                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDeviceSettings = connect(mapStateToProps)(DeviceSettings);
const connectedDeviceSettingsTrans = withNamespaces('common')(connectedDeviceSettings);
export {connectedDeviceSettingsTrans as DeviceSettings};