import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import Button from "../../components/CustomButton/CustomButton";
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Card from "../../components/Card/Card";
import content from "../../assets/img/content.png";

class MediaManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
            image_asset_01: null,
            image_asset_02: null,
            image_asset_03: null,
            image_asset_04: null,

            image_asset_01_temp: null,
            image_asset_02_temp: null,
            image_asset_03_temp: null,
            image_asset_04_temp: null,

            image_asset_01_folder_temp: null,
            image_asset_02_folder_temp: null,
            image_asset_03_folder_temp: null,
            image_asset_04_folder_temp: null
        }

        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        // console.log("" + folder);
        // console.log("" + filename);
        // console.log("" + imagePosition);
        // console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "global_image_asset_01") {
            this.setState({
                image_asset_01_temp: filename !== null,
                image_asset_01: filename,
                image_asset_01_folder_temp: folder
            });
        }
        if (imagePosition === "global_image_asset_02") {
            this.setState({
                image_asset_02_temp: filename !== null,
                image_asset_02: filename,
                image_asset_02_folder_temp: folder
            });
        }
        if (imagePosition === "global_image_asset_03") {
            this.setState({
                image_asset_03_temp: filename !== null,
                image_asset_03: filename,
                image_asset_03_folder_temp: folder
            });
        }
        if (imagePosition === "global_image_asset_04") {
            this.setState({
                image_asset_04_temp: filename !== null,
                image_asset_04: filename,
                image_asset_04_folder_temp: folder
            });
        }
    }

    componentDidMount() {
        this.loadDeviceAssets();
    }

    submitImage(filename, location) {

        const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        let bodyFormData = new FormData();

        if (location === "global_image_asset_01") {
            bodyFormData.set('filepond[]', this.state.image_asset_01_folder_temp);
            bodyFormData.set('location', "global");
            bodyFormData.set('filename', filename);

        } else if (location === "global_image_asset_02") {
            bodyFormData.set('filepond[]', this.state.image_asset_02_folder_temp);
            bodyFormData.set('location', "global");
            bodyFormData.set('filename', filename);

        } else if (location === "global_image_asset_03") {
            bodyFormData.set('filepond[]', this.state.image_asset_03_folder_temp);
            bodyFormData.set('location', "global");
            bodyFormData.set('filename', filename);

        } else if (location === "global_image_asset_04") {
            bodyFormData.set('filepond[]', this.state.image_asset_04_folder_temp);
            bodyFormData.set('location', "global");
            bodyFormData.set('filename', filename);

        } else {
            //FAILED
        }

        axios.post(url, bodyFormData)
            .then((res) => {

                if (location === "global_image_asset_01") {
                    this.setState({image_asset_01_temp: false})
                } else if (location === "global_image_asset_02") {
                    this.setState({image_asset_02_temp: false})
                } else if (location === "global_image_asset_03") {
                    this.setState({image_asset_03_temp: false})
                } else if (location === "global_image_asset_04") {
                    this.setState({image_asset_04_temp: false})
                } else {
                    //FAILED
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })


    }

    updateDeviceImages() {

        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Device/Assets";

        let filename_image_asset_01 = ""
        if (this.state.image_asset_01_temp) {
            let timeUTC = new Date().getTime()
            filename_image_asset_01 = timeUTC + "_dev_asset01_" + this.state.image_asset_01
            this.setState({image_asset_01: filename_image_asset_01})
            // console.log("*****" + filename_image_asset_01)
            this.submitImage(filename_image_asset_01, "global_image_asset_01");
        } else {
            filename_image_asset_01 = this.state.image_asset_01
        }

        let filename_image_asset_02 = ""
        if (this.state.image_asset_02_temp) {
            let timeUTC = new Date().getTime()
            filename_image_asset_02 = timeUTC + "_dev_asset02_" + this.state.image_asset_02
            this.setState({image_asset_02: filename_image_asset_02})
            // console.log("*****" + filename_image_asset_02)
            this.submitImage(filename_image_asset_02, "global_image_asset_02");
        } else {
            filename_image_asset_02 = this.state.image_asset_02
        }

        let filename_image_asset_03 = ""
        if (this.state.image_asset_03_temp) {
            let timeUTC = new Date().getTime()
            filename_image_asset_03 = timeUTC + "_dev_asset03_" + this.state.image_asset_03
            this.setState({image_asset_03: filename_image_asset_03})
            // console.log("*****" + filename_image_asset_03)
            this.submitImage(filename_image_asset_03, "global_image_asset_03");
        } else {
            filename_image_asset_03 = this.state.image_asset_03
        }

        let filename_image_asset_04 = ""
        if (this.state.image_asset_04_temp) {
            let timeUTC = new Date().getTime()
            filename_image_asset_04 = timeUTC + "_dev_asset04_" + this.state.image_asset_04
            this.setState({image_asset_04: filename_image_asset_04})
            // console.log("*****" + filename_image_asset_04)
            this.submitImage(filename_image_asset_04, "global_image_asset_04");
        } else {
            filename_image_asset_04 = this.state.image_asset_04
        }


        // Send a PUT request
        var postData = {
            device: {
                type: 'device_assets',
                device_image_01: filename_image_asset_01,
                device_image_02: filename_image_asset_02,
                device_image_03: filename_image_asset_03,
                device_image_04: filename_image_asset_04
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    loadDeviceAssets() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Device/Assets";

        axios
            .get(url, axiosConfig)
            .then(res => {
                // console.log(res.data[0])
                this.setState({
                    image_asset_01: res.data[0].image_asset_01,
                    image_asset_02: res.data[0].image_asset_02,
                    image_asset_03: res.data[0].image_asset_03,
                    image_asset_04: res.data[0].image_asset_04
                })
            }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="main-content">

                <Grid fluid>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="center">
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '50px', maxWidth: '100%'}}
                                                         src={content}
                                                         alt={"content"}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                title={<legend>Device Assets</legend>}
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Image Asset 01</ControlLabel>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.image_asset_01}
                                                        imageType="global"
                                                        imagePosition="global_image_asset_01"
                                                        imageTemp={this.state.image_asset_01_temp}
                                                        imageFolderTemp={this.state.image_asset_01_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Image Asset 02</ControlLabel>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.image_asset_02}
                                                        imageType="global"
                                                        imagePosition="global_image_asset_02"
                                                        imageTemp={this.state.image_asset_02_temp}
                                                        imageFolderTemp={this.state.image_asset_02_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Image Asset 03</ControlLabel>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.image_asset_03}
                                                        imageType="global"
                                                        imagePosition="global_image_asset_03"
                                                        imageTemp={this.state.image_asset_03_temp}
                                                        imageFolderTemp={this.state.image_asset_03_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Image Asset 04</ControlLabel>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.image_asset_04}
                                                        imageType="global"
                                                        imagePosition="global_image_asset_04"
                                                        imageTemp={this.state.image_asset_04_temp}
                                                        imageFolderTemp={this.state.image_asset_04_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="pull-right">
                                                    <Button
                                                        fill
                                                        bsStyle="primary"
                                                        onClick={this.updateDeviceImages.bind(this)}
                                                    >
                                                        Apply changes
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>


                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedMediaManagement = connect(mapStateToProps)(MediaManagement);
const connectedMediaManagementTrans = withNamespaces('common')(connectedMediaManagement);
export {connectedMediaManagementTrans as MediaManagement};
