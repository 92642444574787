import React from "react";
import axios from "axios";
import ReactTable from "react-table";
import {connect} from 'react-redux';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import Button from "../CustomButton/CustomButton.jsx";
import moment from 'moment';
import {withNamespaces} from "react-i18next";
import {ModalViewTvGuideProgram} from "../CustomModals/ModalViewTvGuideProgram";
import {userActions} from "../../authentication/_actions";
import Card from "../Card/Card";

class DataTvGuideNow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            info_tooltip: (<Tooltip id="info_tooltip">Info</Tooltip>),

            program_id: null,
            loading: false,
            guideArray: [],
            columns: [],
            data: [],
            showModalTvGuide: false,

            sorted: [],
            filtered: [],

            pages: null,
            page: 1,
            pageSize: 10,

            lastUpdate: null,

        };


    }

    loadColumnsTvGuideNow() {

        this.setState({
            columns: [
                {
                    accessor: "ID",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "ppv",
                    Header: "ppv",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {row.value === "Yes" ? <i className="fa fa-dollar"> </i> : ""}
                        </div>
                    )
                },
                {
                    accessor: "iconurl",
                    Header: "Channel Logo",
                    maxWidth: 110,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="65px" height="38px" alt={""}
                            />
                        </div>
                    )
                },
                {
                    accessor: "channel_name",
                    Header: "Channel Name",
                    maxWidth: 200,
                    sortable: true,
                    filterable: true
                },
                {
                    accessor: "title",
                    Header: "title",
                    sortable: true,
                    filterable: true,
                    maxWidth: 200
                },
                {
                    accessor: "icon_poster",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="60px" height="80px" alt={""}
                            />
                        </div>
                    )
                },
                {
                    accessor: "desc_str",
                    Header: "Description",
                    maxWidth: 200,
                    sortable: true,
                    filterable: true,
                    style: {'whiteSpace': 'unset', 'maxHeight': '83px'}
                },

                {
                    accessor: "start_date",
                    Header: "start_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )

                },
                {
                    accessor: "stop_date",
                    Header: "stop_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                },
                {
                    Header: 'Progress',
                    accessor: 'progress',
                    sortable: false,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#dadada',
                                borderRadius: '2px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${row.value}%`,
                                    height: '100%',
                                    backgroundColor: row.value > 66 ? '#ffbf00' : '#85cc00',
                                    borderRadius: '2px',
                                    transition: 'all .2s ease-out'
                                }}
                            >
                                <div style={{textAlign: 'center'}}>
                                    <span>{row.value}%</span>
                                </div>
                            </div>

                        </div>
                    )
                },
                {
                    accessor: "ID",
                    Header: "More Info",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                        </div>
                    )
                }]
        })
    }

    //BUTTONS
    setHelperButton(program_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.info_tooltip}>
            <Button
                onClick={() => {
                    this.setState({
                        program_id: program_id,
                        showModalTvGuide: true,
                    })
                }}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-info"/>
            </Button>
        </OverlayTrigger>)

    }

    loadImage(value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            return value;
        }

    }

    getTvGuidePlayingNow() {

        this.loadColumnsTvGuideNow();
        this.setState({
            loading: true
        })

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        let postData = {
            tvguide: {
                type: "now"
            }
        };

        let dataUrl = process.env.REACT_APP_API_URL + "/TvGuide";

        axios.post(dataUrl, postData, axiosConfig)
            .then((res) => {
                const guideArray = res.data;
                const loading = false;
                this.setState({guideArray, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
            });

    }

    componentDidMount() {

        this.getTvGuidePlayingNow();
    }

    renderViewTvGuideProgram = () => {

        if (this.state.showModalTvGuide) {
            return <ModalViewTvGuideProgram
                headerMessage={"View Program Info"}
                bodyMessage={""}
                handlerFromParent={this.setViewTvGuideProgramModal}
                programId={this.state.program_id}
            />
        }
    }

    setViewTvGuideProgramModal = (value, action) => {

        this.setState({
            showModalTvGuide: value
        })

    }


    render() {
        return (
            <div>
                {this.renderViewTvGuideProgram()}
                <Card
                    content={
                        <div>
                            <ReactTable
                                data={this.state.guideArray}
                                filterable
                                loading={this.state.loading}
                                columns={this.state.columns}
                                defaultPageSize={10}
                                showPaginationBottom
                                className="-striped -highlight"
                            />
                        </div>
                    }
                />
            </div>
        );

    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataTvGuideNow = connect(mapStateToProps)(DataTvGuideNow);
const connectedDataTvGuideNowTrans = withNamespaces('common')(connectedDataTvGuideNow);
export {connectedDataTvGuideNowTrans as DataTvGuideNow};