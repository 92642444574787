import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {Col, FormGroup, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
import Card from "../Card/Card";

class UserPermissions extends Component {

    constructor(props) {
        super(props);

        this.state = {

            customerSelected: [],
            reportsSelected: [],
            devicesSelected: [],
            dashboardSelected: [],

            customerAccessArray: [],
            reportsAccessArray: [],
            devicesAccessArray: [],
            dashboardAccessArray: [],

            renderDashboard: false,
            renderCustomers: false,
            renderReports: false,
            renderDevices: false
        }
    }

    componentDidMount() {

        //this.populatePermissions(permissions_csr)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // Typical usage (don't forget to compare props):
        if (this.props.accountPermissions !== prevProps.accountPermissions) {
            this.populatePermissions(this.props.accountPermissions)

        }
    }

    mergePermissions(location,object){

        let dashboardObject = {"dashboard": Object.assign({}, this.state.dashboardSelected)} // CREATE OBJECT dashboard and add JSON KEYS
        let reportsObject = {"reports": Object.assign({}, this.state.reportsSelected)} // CREATE OBJECT dashboard and add JSON KEYS
        let customersObject = {"customers": Object.assign({}, this.state.customerSelected)} // CREATE OBJECT customer and add JSON KEYS
        let devicesObject = {"devices": Object.assign({}, this.state.devicesSelected)} // CREATE OBJECT dashboard and add JSON KEYS

        let mergedObjects = {...dashboardObject, ...reportsObject,...customersObject,...devicesObject}; // MERGE TO OBJECTS TO FORM ONE BIG ONE

        this.props.handlerFromParent(mergedObjects);

    }

    handleSelectCustomer = (e) => {

        let customerSelected = this.state.customerSelected;
        customerSelected[e.target.name] = e.target.checked
        this.setState({customerSelected});
        this.mergePermissions('customers',customerSelected);

    }

    handleSelectDashboard = (e) => {

        let dashboardSelected = this.state.dashboardSelected;
        dashboardSelected[e.target.name] = e.target.checked
        this.setState({dashboardSelected});
        this.mergePermissions('dashboard',dashboardSelected);

    }

    handleSelectReports = (e) => {

        let reportsSelected = this.state.reportsSelected;
        reportsSelected[e.target.name] = e.target.checked
        this.setState({reportsSelected});
        this.mergePermissions('reports',reportsSelected);

    }

    handleSelectDevices = (e) => {

        let devicesSelected = this.state.devicesSelected;
        devicesSelected[e.target.name] = e.target.checked
        this.setState({devicesSelected});
        this.mergePermissions('devices',devicesSelected);

    }

    populatePermissions(permissions) {


        if (permissions !== undefined && permissions !== null) {

            let dashboardAccessObj, reportsAccessObj, customersAccessObj, devicesAccessObj = null;
            let renderDashboard, renderCustomers, renderReports, renderDevices = false;

            let i = 0;

            for (let [key, value] of Object.entries(permissions)) {

                //console.log(`${key}: ${value}`);

                if (key === "devices") {
                    devicesAccessObj = Object.values(permissions)[i];
                    //console.log(devicesAccessObj)
                    renderDevices = true
                    this.populateDevices(devicesAccessObj)
                }
                if (key === "customers") {
                    customersAccessObj = Object.values(permissions)[i];
                    //console.log(customersAccessObj)
                    renderCustomers = true
                    this.populateCustomers(customersAccessObj)
                }
                if (key === "dashboard") {
                    dashboardAccessObj = Object.values(permissions)[i];
                    //console.log(dashboardAccessObj)
                    renderDashboard = true
                    this.populateDashboard(dashboardAccessObj)
                }
                if (key === "reports") {
                    reportsAccessObj = Object.values(permissions)[i];
                    //console.log(reportsAccessObj)
                    renderReports = true
                    this.populateReports(reportsAccessObj)
                }

                i++;
            }


            this.setState({renderDashboard, renderCustomers, renderReports, renderDevices })
        }

    }

    populateDashboard(dashboardAccessObj) {

        let dashboardAccessArray = [];
        let dashboardAccessArraySelected = [];

        for (const [key, value] of Object.entries(dashboardAccessObj)) {

            dashboardAccessArray.push({
                key: key,
                value: value
            });

            //console.log(key+"->"+value)
            if (value) {
                dashboardAccessArraySelected[key] = true
            } else {
                dashboardAccessArraySelected[key] = false
            }
        }

        this.setState({
            dashboardAccessArray,
            dashboardSelected: dashboardAccessArraySelected
        })
    }

    populateCustomers(customersAccessObj) {

        let customerAccessArray = [];
        let customerAccessArraySelected = [];

        for (const [key, value] of Object.entries(customersAccessObj)) {

            customerAccessArray.push({
                key: key,
                value: value
            });

            //console.log(key+"->"+value)
            if (value) {
                customerAccessArraySelected[key] = true
            } else {
                customerAccessArraySelected[key] = false
            }
        }

        this.setState({
            customerAccessArray,
            customerSelected: customerAccessArraySelected
        })
    }

    populateDevices(devicesAccessObj) {

        let devicesAccessArray = [];
        let deviceAccessArraySelected = [];

        for (const [key, value] of Object.entries(devicesAccessObj)) {

            devicesAccessArray.push({
                key: key,
                value: value
            });

            //console.log(key+"->"+value)
            if (value) {
                deviceAccessArraySelected[key] = true
            } else {
                deviceAccessArraySelected[key] = false
            }
        }

        this.setState({
            devicesAccessArray,
            devicesSelected: deviceAccessArraySelected
        })

    }

    populateReports(reportsAccessObj) {


        let reportsAccessArray = [];
        let reportsAccessArraySelected = [];

        for (const [key, value] of Object.entries(reportsAccessObj)) {

            reportsAccessArray.push({
                key: key,
                value: value
            });

            //console.log(key+"->"+value)
            if (value) {
                reportsAccessArraySelected[key] = true
            } else {
                reportsAccessArraySelected[key] = false
            }
        }

        this.setState({
            reportsAccessArray,
            reportsSelected: reportsAccessArraySelected
        })

    }

    renderPermissionPanelDashboard = () => {

        if (this.state.renderDashboard) {
            return (
                <Panel eventKey="1" defaultExpanded >
                    <Panel.Heading>
                        <Panel.Title toggle> Permissions: Dashboard <b className="caret"/></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible style={{boxShadow: "none"}}>
                        <div className="checkbox-inline">
                            <Grid fluid>
                                <Row>
                                    {
                                        this.state.dashboardAccessArray.map((item) => {
                                            return (
                                                <Col sm={6} md={6} lg={6} key={item.key}>
                                                    <FormGroup>
                                                        <input
                                                            name={item.key}
                                                            key={item.key}
                                                            type="checkbox"
                                                            checked={this.state.dashboardSelected[item.key]}
                                                            onChange={this.handleSelectDashboard.bind(this)}
                                                        />
                                                        <label>{item.key}</label>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }

                                </Row>
                            </Grid>
                        </div>
                    </Panel.Body>
                </Panel>
            )

        }


    }

    renderPermissionPanelCustomers = () => {

        if (this.state.renderCustomers) {
            return (
                <Panel eventKey="2" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle> Permissions: customers <b className="caret"/></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible style={{boxShadow: "none"}}>
                        <div className="checkbox-inline">
                            <Grid fluid>
                                <Row>
                                    {
                                        this.state.customerAccessArray.map((item) => {
                                            return (
                                                <Col sm={6} md={6} lg={6} key={item.key}>
                                                    <FormGroup>
                                                        <input
                                                            name={item.key}
                                                            key={item.key}
                                                            type="checkbox"
                                                            checked={this.state.customerSelected[item.key]}
                                                            onChange={this.handleSelectCustomer.bind(this)}
                                                        />
                                                        <label>{item.key}</label>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }

                                </Row>
                            </Grid>
                        </div>
                    </Panel.Body>
                </Panel>
            )
        }
    }

    renderPermissionPanelDevices = () => {
        if (this.state.renderDevices) {
            return (
                <Panel eventKey="3" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle> Permissions: Devices <b className="caret"/></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible style={{boxShadow: "none"}}>
                        <div className="checkbox-inline">
                            <Grid fluid>
                                <Row>
                                    {
                                        this.state.devicesAccessArray.map((item) => {
                                            return (
                                                <Col sm={6} md={6} lg={6} key={item.key}>
                                                    <FormGroup>
                                                        <input
                                                            name={item.key}
                                                            key={item.key}
                                                            type="checkbox"
                                                            checked={this.state.devicesSelected[item.key]}
                                                            onChange={this.handleSelectDevices.bind(this)}
                                                        />
                                                        <label>{item.key}</label>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }

                                </Row>
                            </Grid>
                        </div>
                    </Panel.Body>
                </Panel>
            )
        }
    }

    renderPermissionPanelReports = () => {
        if (this.state.renderReports) {
            return (
                <Panel eventKey="4" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle> Permissions: Reports <b className="caret"/></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible style={{boxShadow: "none"}}>
                        <div className="checkbox-inline">
                            <Grid fluid>
                                <Row>
                                    {
                                        this.state.reportsAccessArray.map((item) => {
                                            return (
                                                <Col sm={6} md={6} lg={6} key={item.key}>
                                                    <FormGroup>
                                                        <input
                                                            name={item.key}
                                                            key={item.key}
                                                            type="checkbox"
                                                            checked={this.state.reportsSelected[item.key]}
                                                            onChange={this.handleSelectReports.bind(this)}
                                                        />
                                                        <label>{item.key}</label>
                                                    </FormGroup>
                                                </Col>

                                            );
                                        })
                                    }

                                </Row>
                            </Grid>
                        </div>
                    </Panel.Body>
                </Panel>
            )
        }
    }

    renderPermissions() {

        if (this.props.accountType === "csr_customer") {
            return (
                <Card
                    title={""}
                    content={
                        <div>
                            <Grid fluid>
                                <PanelGroup id="accordion-example">
                                    {this.renderPermissionPanelDashboard()}
                                    {this.renderPermissionPanelCustomers()}
                                    {this.renderPermissionPanelDevices()}
                                    {/*{this.renderPermissionPanelReports()}*/}
                                </PanelGroup>
                            </Grid>
                        </div>
                    }
                />
            );

        }


    }

    render() {
        return (
            <div>
                {this.renderPermissions()}
            </div>
        )

    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedUserPermissions = connect(mapStateToProps)(UserPermissions);
const connectedUserPermissionsTrans = withNamespaces('common')(connectedUserPermissions);
export {connectedUserPermissionsTrans as UserPermissions};