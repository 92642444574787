import React from "react";
import axios from "axios";
import {ResponsiveBar} from "@nivo/bar";

class CurrentLiveWatchingAverage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            secondsChart1001: 0,
            data: []
        };

    }

    tick() {

        //console.log("tick");

        this.setState(prevState => ({
            secondsChart1001: prevState.secondsChart1001 + 1
        }));

        if (this.state.secondsChart1001 === 60) {
            this.setState({
                secondsChart1001: 0,
                loading: true
            });
            this.updateData()
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    updateData() {

        //console.log("updateData");

        let dataUrl = process.env.REACT_APP_API_URL + '/WmsReports/ActiveStreams/active-now'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            //this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(dataUrl, axiosConfig)
            .then(res => {
                const chartData = res.data;
                const loading = false;
                //console.log(chartData);
                this.setState({data: chartData, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });
    }


    componentDidMount() {

        this.interval = setInterval(() => this.tick(), 1000);
        this.updateData();

    }

    render() {
        return (
            <div>
                <legend>
                    <h5>Active - Average</h5>
                </legend>
                <div style={{height: '350px'}}>
                    <ResponsiveBar
                        data={this.state.data}
                        keys={['AverageTime']}
                        indexBy="StreamName"
                        margin={{top: 10, right: 130, bottom: 50, left: 150}}
                        padding={0.3}
                        colors={{scheme: 'paired'}}
                        minValue={0}
                        maxValue={'auto'}
                        layout={"horizontal"}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'h20'
                                },
                                id: 'lines'
                            }
                        ]}
                        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Average Time',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        // axisLeft={{
                        //     tickSize: 5,
                        //     tickPadding: 5,
                        //     tickRotation: 0,
                        //     legend: 'Total Recordings',
                        //     legendPosition: 'middle',
                        //     legendOffset: -40
                        // }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // label={d => `${d.id} - ${d.value} %`}
                        //labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={false}
                        // motionStiffness={300}
                        // motionDamping={40}
                    />
                </div>
            </div>

        );
    }
}

export default CurrentLiveWatchingAverage;