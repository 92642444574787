import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {DataApplicationsManagement} from "../../components/Middleware/DataApplicationsManagement";
import applicationManagement from "../../assets/img/app-development.png";

class ApplicationManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

    }


    render() {
        return (
            <div className="main-content">

                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={5}>
                                            </Col>
                                            <Col sm={2}>
                                                <div className="center">
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '60px', maxWidth: '100%'}}
                                                         src={applicationManagement}
                                                         alt={"applicationManagement"}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={5}>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <DataApplicationsManagement
                                        listName={"List of Applications"}
                                    />

                                }
                            />
                        </Col>
                    </Row>


                </Grid>
            </div>
        )
    }
}

export default ApplicationManagement;
