import React, {Component} from "react";
import {Redirect} from "react-router";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    HelpBlock, ButtonGroup, Well, Glyphicon
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {DropDownMode} from "../../components/CustomDropDown/DropDownMode";
import {DropDownResolution} from "../../components/CustomDropDown/DropDownResolution";
import {DropDownTvCategory} from "../../components/CustomDropDown/DropDownTvCategory";
import {DropDownTvGuide} from "../../components/CustomDropDown/DropDownTvGuide";
import {DropDownTvGuideGraceNote} from "../../components/CustomDropDown/DropDownTvGuideGraceNote";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import ModalSave from "../../components/CustomModals/ModalSave";
import {withNamespaces} from "react-i18next";
import {userActions} from "../../authentication/_actions";
import {ScaleLoader} from 'react-spinners';
import connect from "react-redux/es/connect/connect";
import {DropDownTvGenres} from "../../components/CustomDropDown/DropDownTvGenres";
import eas_logo from "../../assets/img/eas_svg.png";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {DropDownCountries} from "../../components/CustomDropDown/DropDownCountries";
import {CalendarLiveChannels} from "../../components/Calendar/CalendarLiveChannels";
import {css} from "@emotion/core";
import InputGroup from "react-bootstrap/es/InputGroup";
import Select from "react-select";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
`;

const languageSelection = [
    {value: 'EN', label: 'English'},
    {value: 'ES', label: 'Spanish'},
    {value: 'FR', label: 'French'},
    {value: 'AR', label: 'Arabic'},
    {value: 'CN', label: 'Chinese'},
    {value: 'DE', label: 'German'},
    {value: 'AF', label: 'African'},
];

const tokenSelection = [
    {value: 'WMSPANEL', label: 'WmsPanel'},
    {value: 'FLUSSONIC', label: 'Flussonic'}
];

const adminArray = ['super-admin', 'admin']

class LiveChannelsEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {

            channel: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",
            showSaveModal: false,
            file_poster_server_id: "",
            file_poster_name: "",
            channelRedirect: false,

            encryptionStatus: false,
            timeShiftStatus: false,
            catchupStatus: false,
            restartStatus: false,
            parentalStatus: false,
            channelStatus: false,
            mobileStatus: false,
            castingStatus: false,
            pvrStatus: false,
            vastStatus: false,
            blackOutStatus: false,
            countryBlockStatus: false,
            p2pStatus: false,
            webLoginStatus: false,

            channel_mode_id: 0,
            channel_resolution_id: 0,
            channel_iptv_url: null,
            channel_ott_url: null,
            channel_ott_backup_url: null,
            channel_catchup_url: null,
            channel_start_over_url: null,
            channel_blackout_url: null,
            channel_name_short: null,
            channel_name_long: null,
            channel_position: 0,
            channel_bkp_position: null,
            channel_VideoPID: null,
            channel_AudioPID: null,
            channel_cat_id: 0,

            channel_tvguide_id: 0,
            channel_guide_data: null,

            channel_tvguide_gracenote_id: 0,
            channel_guide_gracenote_data: null,

            channel_description: null,
            channel_image: null,
            channel_image_wide: null,
            channel_pre_roll: null,
            channel_mid_roll: null,
            channel_post_roll: null,
            channel_casting_status: null,

            channel_user_added: 0,
            channel_date_added: null,
            channel_date_updated: null,
            channel_user_updated: 0,
            channel_date_deleted: null,
            channel_user_deleted: null,

            channel_image_temp: false,
            channel_image_folder_temp: "",

            channel_image_wide_temp: false,
            channel_image_wide_folder_temp: "",

            //VALIDATIONS
            channel_position_state: null,
            channel_name_short_validate_state: null,
            channel_name_long_validate_state: null,
            channel_cat_id_state: null,

            channel_position_message: "",
            channel_name_short_message: "",
            channel_name_long_message: "",
            channel_cat_id_message: "",

            //ENCRYPTION
            channel_vmx_contentid: null,
            channel_vmx_dvr_contentid: null,

            //DVR
            channel_npvr_id: null,
            channel_dvr_duration: null,

            //MOBILE
            channel_mobile_enabled: null,

            channel_genre_id: [],
            channel_genre_names: [],

            channel_blackout_countries: null,

            _notificationSystem: null,

            videoSource: null,
            videoState: false,
            videoType: null,

            channel_country_block_status: false,
            channel_country_block_countries: null,

            addAllCountriesInCountryBlock: false,
            addAllCountriesInBlackList: false,
            channel_restart: null,
            channel_notes: null,
            channel_language: null,
            channel_streaming_token: null,

            addNewTvguideGraceNote: false,
            reloadTvguideGraceNote: false,

            gracenote_id: null,
            gracenote_name: null,

            channel_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null


        };

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChildModeSelect = this.handleChildModeSelect.bind(this);
        this.handleChildResolutionSelect = this.handleChildResolutionSelect.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildChannelGuideSelect = this.handleChildChannelGuideSelect.bind(this);
        this.handleChildChannelGuideGraceNoteSelect = this.handleChildChannelGuideGraceNoteSelect.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildTvGenreSelect = this.handleChildTvGenreSelect.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleStreamingTokenChange = this.handleStreamingTokenChange.bind(this);

        this.handleChildVideoPreview = this.handleChildVideoPreview.bind(this);
        // this.buttonVideoPreview = this.buttonVideoPreview.bind(this);
        this.handleChildCountrySelect = this.handleChildCountrySelect.bind(this);
        this.handleChildCountryBlockSelect = this.handleChildCountryBlockSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);


    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleChildCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        //console.log(data);
        this.setState({
            channel_blackout_countries: data === null ? 0 : countryIDs, addAllCountriesInBlackList: false
        });
    }

    handleChildCountryBlockSelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        //console.log(data);
        this.setState({
            channel_country_block_countries: data === null ? 0 : countryIDs, addAllCountriesInCountryBlock: false
        });
    }

    handleChildVideoPreview(message, status, type) {

        if (message === "failed to load") {

            this.setState({
                videoSource: "https://",
                videoState: "paused",
                videoType: type
            });
        }

        if (message === "paused") {

            this.setState({
                videoState: "paused",
                videoType: type
            });
        }

        if (message === "playing") {

            this.setState({
                videoState: "playing",
                videoType: type
            });
        }

    }

    buttonVideoPreviewIptv() {

        if (this.state.videoState === "playing") {
            this.setState({
                videoSource: this.state.channel_iptv_url,
                videoState: "paused",
                videoType: "iptv"
            });
        } else {
            this.setState({
                videoSource: this.state.channel_iptv_url,
                videoState: "playing",
                videoType: "iptv"
            });
        }

    }

    buttonVideoPreviewOtt() {

        if (this.state.videoState === "playing") {
            this.setState({
                videoSource: this.state.channel_ott_url,
                videoState: "paused",
                videoType: "ott"
            });
        } else {
            this.setState({
                videoSource: this.state.channel_ott_url,
                videoState: "playing",
                videoType: "ott"
            });
        }

    }

    buttonVideoPreviewOttBackup() {

        if (this.state.videoState === "playing") {
            this.setState({
                videoSource: this.state.channel_ott_backup_url,
                videoState: "paused",
                videoType: "ott"
            });
        } else {
            this.setState({
                videoSource: this.state.channel_ott_backup_url,
                videoState: "playing",
                videoType: "ott"
            });
        }

    }

    buttonVideoPreviewCatchUp() {

        if (this.state.videoState === "playing") {
            this.setState({
                videoSource: this.state.channel_catchup_url,
                videoState: "paused",
                videoType: "catchup"
            });
        } else {
            this.setState({
                videoSource: this.state.channel_catchup_url,
                videoState: "playing",
                videoType: "catchup"
            });
        }

    }

    buttonVideoPreviewStartOver() {

        if (this.state.videoState === "playing") {
            this.setState({
                videoSource: this.state.channel_start_over_url,
                videoState: "paused",
                videoType: "startover"
            });
        } else {
            this.setState({
                videoSource: this.state.channel_start_over_url,
                videoState: "playing",
                videoType: "startover"
            });
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        window.scroll(0, 0)

        this.setState({channelRedirect: false, redirect: false})

        let user = JSON.parse(localStorage.getItem('user'));

        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Channels/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition()
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const channel = res.data[0];
                    const loading = false;


                    let genreIDs = []

                    if (channel.channel_genre_id !== null) {
                        let temp = channel.channel_genre_id.toString();
                        genreIDs = temp.split(",")
                    }

                    let countries = []
                    if (channel.channel_blackout_countries !== null) {
                        let tempCountry = channel.channel_blackout_countries.toString();
                        countries = tempCountry.split(",")
                    }

                    let countriesBlocked = []
                    if (channel.channel_country_block_countries !== null) {
                        let tempCountry = channel.channel_country_block_countries.toString();
                        countriesBlocked = tempCountry.split(",")
                    }

                    this.setState({
                        back_button_text: "Back",
                        channel,
                        loading,

                        channelStatus: channel.channel_status === 1,
                        encryptionStatus: channel.channel_encryption === 1,
                        timeShiftStatus: channel.channel_timeshift_status === 1,
                        catchupStatus: channel.channel_catchup_status === 1,
                        parentalStatus: channel.channel_parental === 1,
                        mobileStatus: channel.channel_mobile_enabled === 1,
                        castingStatus: channel.channel_casting_status === 1,
                        pvrStatus: channel.channel_pvr_status === 1,
                        vastStatus: channel.channel_vast_status === 1,
                        blackOutStatus: channel.channel_blackout_status === 1,
                        countryBlockStatus: channel.channel_country_block_status === 1,
                        restartStatus: channel.channel_restart === 1,
                        p2pStatus: channel.channel_p2p_status === 1,
                        webLoginStatus: channel.channel_web_login_status === 1,

                        channel_mode_id: channel.channel_mode_id === null ? 0 : channel.channel_mode_id,
                        channel_resolution_id: channel.channel_resolution_id === null ? 0 : channel.channel_resolution_id,
                        channel_genre_id: genreIDs,

                        channel_iptv_url: channel.channel_iptv_url === null ? "" : channel.channel_iptv_url,
                        channel_ott_url: channel.channel_ott_url === null ? "" : channel.channel_ott_url,
                        channel_ott_backup_url: channel.channel_ott_backup_url === null ? "" : channel.channel_ott_backup_url,
                        channel_catchup_url: channel.channel_catchup_url === null ? "" : channel.channel_catchup_url,
                        channel_start_over_url: channel.channel_start_over_url === null ? "" : channel.channel_start_over_url,
                        channel_name_short: channel.channel_name_short === null ? "" : channel.channel_name_short,
                        channel_name_long: channel.channel_name_long === null ? "" : channel.channel_name_long,
                        channel_position: channel.channel_position === null ? 0 : channel.channel_position,
                        channel_VideoPID: channel.channel_VideoPID === null ? "" : channel.channel_VideoPID,
                        channel_AudioPID: channel.channel_AudioPID === null ? "" : channel.channel_AudioPID,
                        channel_cat_id: channel.channel_cat_id === null ? 0 : channel.channel_cat_id,
                        channel_tvguide_id: channel.channel_tvguide_id === null ? 0 : channel.channel_tvguide_id,
                        channel_tvguide_gracenote_id: channel.channel_tvguide_gracenote_id === null ? 0 : channel.channel_tvguide_gracenote_id,
                        channel_description: channel.channel_description === null ? "" : channel.channel_description,
                        channel_bkp_position: channel.channel_bkp_position === null ? "" : channel.channel_bkp_position,

                        channel_id: channel.channel_id,
                        channel_ref_id: channel.channel_ref_id === null ? "" : channel.channel_ref_id,
                        channel_ppgroup_id: channel.channel_ppgroup_id,
                        channel_image: channel.channel_image,
                        channel_image_wide: channel.channel_image_wide,
                        channel_timeshift_status: channel.channel_timeshift_status,
                        channel_timeshift_offset: channel.channel_timeshift_offset,
                        channel_catchup_status: channel.channel_catchup_status,
                        channel_parental: channel.channel_parental,
                        channel_website: channel.channel_website,
                        channel_encryption: channel.channel_encryption,
                        channel_locked: channel.channel_locked,
                        channel_vmx_contentid: channel.channel_vmx_contentid,

                        channel_epg_name: channel.channel_epg_name,
                        channel_country_lock: channel.channel_country_lock,

                        channel_vmx_dvr_contentid: channel.channel_vmx_dvr_contentid,
                        channel_npvr_id: channel.channel_npvr_id,

                        channel_pvr_status: channel.channel_pvr_status,
                        channel_restart: channel.channel_restart,
                        channel_mobile_enabled: channel.channel_mobile_enabled,

                        channel_EPGHits: channel.channel_EPGHits,
                        channel_STBHits: channel.channel_STBHits,
                        channel_DirectHits: channel.channel_DirectHits,
                        channel_user_added: channel.channel_user_added,
                        channel_date_added: channel.channel_date_added,
                        channel_date_updated: channel.channel_date_updated,
                        channel_user_updated: channel.channel_user_updated,
                        channel_date_deleted: channel.channel_date_deleted,
                        channel_user_deleted: channel.channel_user_deleted,

                        resolution_name: channel.resolution_name,
                        cat_name_short: channel.cat_name_short,
                        cat_image: channel.cat_image,

                        channel_pre_roll: channel.channel_pre_roll,
                        channel_mid_roll: channel.channel_mid_roll,
                        channel_casting_status: channel.channel_casting_status,
                        channel_dvr_duration: channel.channel_dvr_duration,

                        channel_blackout_countries: countries,
                        channel_blackout_url: channel.channel_blackout_url,

                        channel_country_block_status: channel.channel_country_block_status === 1,
                        channel_country_block_countries: countriesBlocked,
                        channel_epg_id: channel.channel_epg_id,
                        channel_notes: channel.channel_notes,
                        channel_language: channel.channel_language,
                        channel_streaming_token: channel.channel_streaming_token,
                        current_user: user,
                        user_content_provider: channel.channel_content_provider,
                        custom_data: channel.custom_data

                    });
                }).catch(error => {
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });
        }


    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Channels/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const channelPosition = res.data[0];

                this.setState({
                    channel_position: channelPosition.ChannelPosition

                });
            }).catch(error => {
            this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

    }

    submitPosterImage(filename_poster) {

        if (this.state.channel_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.channel_image_folder_temp);
            bodyFormData.set('location', "live_tv");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({channel_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitWideImage(filename_wide) {

        if (this.state.channel_image_wide_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.channel_image_wide_folder_temp);
            bodyFormData.set('location', "live_tv");
            bodyFormData.set('filename', filename_wide);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({channel_image_wide_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    updateChannel() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.channel_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_wide_" + this.state.channel_image_wide
            this.setState({channel_image_wide: filename_wide})
            console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.channel_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.channel_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_poster_" + this.state.channel_image
            this.setState({channel_image: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.channel_image
        }


        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Channels/" + handle;

        let tempChannelStatus = this.state.channelStatus === true ? 1 : 0;
        let tempEncryptionStatus = this.state.encryptionStatus === true ? 1 : 0;
        let tempTimeShiftStatus = this.state.timeShiftStatus === true ? 1 : 0;
        let tempCatchupStatus = this.state.catchupStatus === true ? 1 : 0;
        let tempParentalStatus = this.state.parentalStatus === true ? 1 : 0;
        let tempMobileStatus = this.state.mobileStatus === true ? 1 : 0;
        let tempCastingStatus = this.state.castingStatus === true ? 1 : 0;
        let tempPvrStatus = this.state.pvrStatus === true ? 1 : 0;
        let tempVastStatus = this.state.vastStatus === true ? 1 : 0;
        let blackOutStatus = this.state.blackOutStatus === true ? 1 : 0;
        let countryBlockStatus = this.state.countryBlockStatus === true ? 1 : 0;
        let restartStatus = this.state.restartStatus === true ? 1 : 0;
        let p2pStatus = this.state.p2pStatus === true ? 1 : 0;
        let webLoginStatus = this.state.webLoginStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            channel: {
                channel_id: this.state.channel_id,
                channel_status: tempChannelStatus,
                channel_position: this.state.channel_position,
                channel_bkp_position: this.state.channel_bkp_position,
                channel_name_short: this.state.channel_name_short,
                channel_name_long: this.state.channel_name_long,
                channel_description: this.state.channel_description,
                channel_cat_id: this.state.channel_cat_id,
                channel_genre_id: this.state.channel_genre_id.join(),
                channel_genre_names: this.state.channel_genre_names.join(),
                channel_iptv_url: this.state.channel_iptv_url,
                channel_ott_url: this.state.channel_ott_url,
                channel_ott_backup_url: this.state.channel_ott_backup_url,
                channel_catchup_url: this.state.channel_catchup_url,
                channel_start_over_url: this.state.channel_start_over_url,
                channel_ref_id: this.state.channel_ref_id,
                channel_epg_id: this.state.channel_tvguide_id,
                channel_tvguide_gracenote_id: this.state.channel_tvguide_gracenote_id,
                channel_ppgroup_id: this.state.channel_ppgroup_id,
                channel_image: filename_poster,
                channel_image_wide: filename_wide,
                channel_mode_id: this.state.channel_mode_id,
                channel_resolution_id: this.state.channel_resolution_id,
                channel_AudioPID: this.state.channel_AudioPID,
                channel_VideoPID: this.state.channel_VideoPID,
                channel_timeshift_status: tempTimeShiftStatus,
                channel_timeshift_offset: this.state.channel_timeshift_offset,
                channel_catchup_status: tempCatchupStatus,
                channel_parental: tempParentalStatus,
                channel_website: this.state.channel_website,
                channel_encryption: tempEncryptionStatus,
                channel_locked: this.state.channel_locked,

                channel_vmx_contentid: this.state.channel_vmx_contentid,
                channel_vmx_dvr_contentid: this.state.channel_vmx_dvr_contentid,
                channel_npvr_id: this.state.channel_npvr_id,
                channel_dvr_duration: this.state.channel_dvr_duration,

                channel_mobile_enabled: tempMobileStatus,
                channel_pre_roll: this.state.channel_pre_roll,
                channel_mid_roll: this.state.channel_mid_roll,
                channel_casting_status: tempCastingStatus,
                channel_pvr_status: tempPvrStatus,
                channel_restart: restartStatus,
                channel_vast_status: tempVastStatus,
                channel_blackout_countries: this.state.channel_blackout_countries === null ? null : this.state.channel_blackout_countries.join(),
                channel_blackout_status: blackOutStatus,
                channel_blackout_url: this.state.channel_blackout_url,

                channel_country_block_status: countryBlockStatus,
                channel_country_block_countries: this.state.channel_country_block_countries === null ? null : this.state.channel_country_block_countries.join(),

                channel_notes: this.state.channel_notes,
                channel_language: this.state.channel_language,
                channel_streaming_token: this.state.channel_streaming_token,
                channel_p2p_status: p2pStatus,
                channel_web_login_status: webLoginStatus,
                channel_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };


        axios.put(url, postData, axiosConfig)
            .then((res) => {

                this.setState({saving: false})
                this.setState({loading: false})

                // channel_cat_id_error: "A TV category must be assigned"
                // channel_name_long_error: "Channel name long is empty"
                // channel_name_short_error: "Channel name short is empty"
                // channel_position_error: null

                if (res.data.error !== undefined) {

                    this.setState({
                        channel_name_short_validate_state: res.data.error.channel_name_short_error !== null ? "error" : null,
                        channel_name_long_validate_state: res.data.error.channel_name_long_error !== null ? "error" : null,
                        channel_cat_id_state: res.data.error.static_epg_duration !== null ? "error" : null,
                        channel_position_state: res.data.error.channel_position_error !== null ? "error" : null,

                        channel_name_short_message: res.data.error.channel_name_short_error !== null ? res.data.error.channel_name_short_error : "",
                        channel_name_long_message: res.data.error.channel_name_long_error !== null ? res.data.error.channel_name_long_error : "",
                        channel_cat_id_message: res.data.error.channel_cat_id_error !== null ? res.data.error.channel_cat_id_error : "",
                        channel_position_message: res.data.error.channel_position_error !== null ? res.data.error.channel_position_error : ""
                    })

                    this.notification("failed")

                } else {
                    this.setState({
                            channel_name_short_validate_state: null,
                            channel_name_long_validate_state: null,
                            channel_cat_id_state: null,
                            channel_position_state: null,

                            channel_name_short_message: "",
                            channel_name_long_message: "",
                            channel_cat_id_message: "",
                            channel_position_message: "",
                        }
                    )
                    this.notification("success")

                }

            })
            .catch(error => {
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
                this.notification("failed")
            });

    }

    createChannel() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.channel_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_wide_" + this.state.channel_image_wide
            this.setState({channel_image_wide: filename_wide})
            console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.channel_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.channel_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_poster_" + this.state.channel_image
            this.setState({channel_image: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.channel_image
        }


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Channels";

        let tempChannelStatus = this.state.channelStatus === true ? 1 : 0;
        let tempEncryptionStatus = this.state.encryptionStatus === true ? 1 : 0;
        let tempTimeShiftStatus = this.state.timeShiftStatus === true ? 1 : 0;
        let tempCatchupStatus = this.state.catchupStatus === true ? 1 : 0;
        let tempParentalStatus = this.state.parentalStatus === true ? 1 : 0;
        let tempMobileStatus = this.state.mobileStatus === true ? 1 : 0;
        let tempCastingStatus = this.state.castingStatus === true ? 1 : 0;
        let tempPvrStatus = this.state.pvrStatus === true ? 1 : 0;
        let tempVastStatus = this.state.vastStatus === true ? 1 : 0;
        let blackOutStatus = this.state.blackOutStatus === true ? 1 : 0;
        let countryBlockStatus = this.state.countryBlockStatus === true ? 1 : 0;
        let restartStatus = this.state.restartStatus === true ? 1 : 0;
        let p2pStatus = this.state.p2pStatus === true ? 1 : 0;
        let webLoginStatus = this.state.webLoginStatus === true ? 1 : 0;

        var postData = {
            channel: {
                channel_status: tempChannelStatus,
                channel_position: this.state.channel_position,
                channel_bkp_position: this.state.channel_bkp_position,
                channel_name_short: this.state.channel_name_short,
                channel_name_long: this.state.channel_name_long,
                channel_description: this.state.channel_description,
                channel_cat_id: this.state.channel_cat_id,
                channel_genre_id: this.state.channel_genre_id.join(),
                channel_genre_names: this.state.channel_genre_names.join(),
                channel_iptv_url: this.state.channel_iptv_url,
                channel_ott_url: this.state.channel_ott_url,
                channel_ott_backup_url: this.state.channel_ott_backup_url,
                channel_catchup_url: this.state.channel_catchup_url,
                channel_start_over_url: this.state.channel_start_over_url,
                channel_ref_id: this.state.channel_ref_id,
                channel_epg_id: this.state.channel_tvguide_id,
                channel_tvguide_gracenote_id: this.state.channel_tvguide_gracenote_id,
                channel_ppgroup_id: this.state.channel_ppgroup_id,
                channel_image: filename_poster,
                channel_image_wide: filename_wide,
                channel_mode_id: this.state.channel_mode_id,
                channel_resolution_id: this.state.channel_resolution_id,
                channel_AudioPID: this.state.channel_AudioPID,
                channel_VideoPID: this.state.channel_VideoPID,
                channel_timeshift_status: tempTimeShiftStatus,
                channel_timeshift_offset: this.state.channel_timeshift_offset,
                channel_catchup_status: tempCatchupStatus,
                channel_parental: tempParentalStatus,
                channel_website: this.state.channel_website,
                channel_encryption: tempEncryptionStatus,
                channel_locked: this.state.channel_locked,

                channel_vmx_contentid: this.state.channel_vmx_contentid,
                channel_vmx_dvr_contentid: this.state.channel_vmx_dvr_contentid,
                channel_npvr_id: this.state.channel_npvr_id,
                channel_dvr_duration: this.state.channel_dvr_duration,

                channel_mobile_enabled: tempMobileStatus,
                channel_pre_roll: this.state.channel_pre_roll,
                channel_mid_roll: this.state.channel_mid_roll,
                channel_casting_status: tempCastingStatus,
                channel_pvr_status: tempPvrStatus,
                channel_restart: restartStatus,
                channel_vast_status: tempVastStatus,
                channel_blackout_countries: this.state.channel_blackout_countries === null ? null : this.state.channel_blackout_countries.join(),
                channel_blackout_status: blackOutStatus,
                channel_blackout_url: this.state.channel_blackout_url,

                channel_country_block_status: countryBlockStatus,
                channel_country_block_countries: this.state.channel_country_block_countries === null ? null : this.state.channel_country_block_countries.join(),

                channel_notes: this.state.channel_notes,
                channel_language: this.state.channel_language,
                channel_streaming_token: this.state.channel_streaming_token,
                channel_p2p_status: p2pStatus,
                channel_web_login_status: webLoginStatus,
                channel_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data

            }
        };


        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false})
                this.setState({loading: false})


                // channel_cat_id_error: "A TV category must be assigned"
                // channel_name_long_error: "Channel name long is empty"
                // channel_name_short_error: "Channel name short is empty"
                // channel_position_error: null

                if (res.data.error !== undefined) {

                    this.setState({
                        channel_name_short_validate_state: res.data.error.channel_name_short_error !== null ? "error" : null,
                        channel_name_long_validate_state: res.data.error.channel_name_long_error !== null ? "error" : null,
                        channel_cat_id_state: res.data.error.channel_cat_id_error !== null ? "error" : null,
                        channel_position_state: res.data.error.channel_position_error !== null ? "error" : null,

                        channel_name_short_message: res.data.error.channel_name_short_error !== null ? res.data.error.channel_name_short_error : "",
                        channel_name_long_message: res.data.error.channel_name_long_error !== null ? res.data.error.channel_name_long_error : "",
                        channel_cat_id_message: res.data.error.channel_cat_id_error !== null ? res.data.error.channel_cat_id_error : "",
                        channel_position_message: res.data.error.channel_position_error !== null ? res.data.error.channel_position_error : ""
                    })

                    this.notification("failed")

                } else {

                    const channel = res.data;

                    this.setState({
                            channel_name_short_validate_state: null,
                            channel_name_long_validate_state: null,
                            channel_cat_id_state: null,
                            channel_position_state: null,

                            channel_name_short_message: "",
                            channel_name_long_message: "",
                            channel_cat_id_message: "",
                            channel_position_message: "",

                            channel_id: channel.channel_id
                        }
                    )


                    this.notification("success")
                    this.setChannelRedirect();

                }


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    createGraceNoteChannel() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Channels";

        var postData = {
            channel: {
                type: 'add-gracenote-channel',
                gracenote_id: this.state.gracenote_id,
                gracenote_name: this.state.gracenote_name,
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                this.setState({
                    saving: false,
                    loading: false,
                    addNewTvguideGraceNote: false,
                    reloadTvguideGraceNote: true
                })

                if (res.data.error !== undefined) {
                    this.notification("failed")

                } else {

                    this.setState({
                        saving: false,
                        loading: false,
                    })

                    this.notification("success")
                }
            })
            .catch((err) => {
                this.setState({
                    saving: false,
                    loading: false,
                    addNewTvguideGraceNote: false,
                    reloadTvguideGraceNote: true
                })
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Channel has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update channel</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    handleChildTvGenreSelect(data) {

        let genreIDs = []
        let genreNames = []

        for (let i = 0; i < data.length; i++) {
            genreIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            genreNames.push(data[i].label)
        }

        this.setState({
            channel_genre_id: data === null ? null : genreIDs,
            channel_genre_names: data === null ? [] : genreNames
        });
    }

    handleLanguageChange = (languageDropDown) => {

        this.setState({
            channel_language: languageDropDown === null ? null : languageDropDown.value
        });

    }

    handleStreamingTokenChange = (streamingTokenDropDown) => {

        this.setState({
            channel_streaming_token: streamingTokenDropDown === null ? null : streamingTokenDropDown.value
        });

    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'encryptionSwitch') {
            this.setState({encryptionStatus: state})
        }
        if (elem.props.name === 'timeShiftSwitch') {
            this.setState({timeShiftStatus: state})
        }
        if (elem.props.name === 'catchupSwitch') {
            this.setState({catchupStatus: state})
        }
        if (elem.props.name === 'parentalSwitch') {
            this.setState({parentalStatus: state})
        }
        if (elem.props.name === 'statusSwitch') {
            this.setState({channelStatus: state})
        }
        if (elem.props.name === 'mobileStatus') {
            this.setState({mobileStatus: state})
        }
        if (elem.props.name === 'castingStatus') {
            this.setState({castingStatus: state})
        }
        if (elem.props.name === 'pvrSwitch') {
            this.setState({pvrStatus: state})
        }
        if (elem.props.name === 'vastSwitch') {
            this.setState({vastStatus: state})
        }
        if (elem.props.name === 'blackOutSwitch') {
            this.setState({blackOutStatus: state})
        }
        if (elem.props.name === 'countryBlockSwitch') {
            this.setState({countryBlockStatus: state})
        }
        if (elem.props.name === 'restartSwitch') {
            this.setState({restartStatus: state})
        }
        if (elem.props.name === 'p2pSwitch') {
            this.setState({p2pStatus: state})
        }
        if (elem.props.name === 'webLoginSwitch') {
            this.setState({webLoginStatus: state})
        }
    }

    handleChildResolutionSelect(data) {

        //console.log(data);
        this.setState({
            channel_resolution_id: data === null ? 0 : data.value
        });
    }

    handleChildModeSelect(data) {

        //console.log(data);
        this.setState({
            channel_mode_id: data === null ? 0 : data.value
        });
    }

    handleChildCategorySelect(data) {

        //console.log(data);
        this.setState({
            channel_cat_id: data === null ? 0 : data.value
        });
    }

    handleChildChannelGuideSelect(data) {
        //console.log(data);
        this.setState({
            channel_guide_data: data,
            channel_tvguide_id: data === null ? 0 : data.value,
        });
    }

    handleChildChannelGuideGraceNoteSelect(action, data) {


        if (action === 'onChange') {
            this.setState({
                channel_guide_gracenote_data: data,
                channel_tvguide_gracenote_id: data === null ? 0 : data.value,
                reloadTvguideGraceNote: false
            });
        } else {
            this.setState({
                reloadTvguideGraceNote: false
            });
        }
    }

    handleChildImageSelect(folder, filename, imagePosition) {

        //console.log("" + folder);
        //console.log("" + filename);
        //console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "channel_image") {
            this.setState({
                channel_image_temp: filename !== null,
                channel_image: filename,
                channel_image_folder_temp: folder
            });
        }
        if (imagePosition === "channel_image_wide") {
            this.setState({
                channel_image_wide_temp: filename !== null,
                channel_image_wide: filename,
                channel_image_wide_folder_temp: folder
            });
        }

    }

    cancelLiveTVEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setChannelRedirect = () => {
        this.setState({
            channelRedirect: true,
            back_button_text: "Back"
        })
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createChannel();
            } else {
                this.updateChannel();
            }
        }
    }

    renderRedirect = () => {

        let path = "/live/channels";
        let pathChannel = "/edit-channel/" + this.state.channel_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.channelRedirect) {
            return <Redirect push to={pathChannel}/>
        }

    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Live Channel: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.channel_id}
            />
        }
    }

    renderEasImage = () => {

        if (this.state.channel_mode_id === 9) {
            return (
                <div>
                    <img className="img-responsive center-block"
                         style={{maxHeight: '40px', maxWidth: '90%'}}
                         src={eas_logo}
                         alt={"eas_logo"}
                    />
                </div>
            );
        }
    }

    renderDvrDuration = () => {

        if (this.state.timeShiftStatus) {
            return (

                <FormGroup>
                    <ControlLabel>{this.props.t('channels_edit.channel_dvr_duration', {framework: "react-i18next"})}</ControlLabel>
                    <FormControl placeholder="DVR Duration"
                                 type="text"
                                 disabled={this.state.loading}
                                 name="channel_dvr_duration"
                                 onChange={this.handleInputChange}
                                 value={this.state.channel_dvr_duration || ""}
                    />
                </FormGroup>

            );
        }
    }

    renderNPVRFields = () => {

        if (this.state.pvrStatus) {
            return (

                <FormGroup>
                    <ControlLabel>{this.props.t('channels_edit.channel_npvr_id', {framework: "react-i18next"})}</ControlLabel>
                    <FormControl placeholder="channel_npvr_id"
                                 type="text"
                                 disabled={this.state.loading}
                                 name="channel_npvr_id"
                                 onChange={this.handleInputChange}
                                 value={this.state.channel_npvr_id || ""}
                    />
                </FormGroup>

            );
        }
    }

    renderVASTFields = () => {

        if (this.state.vastStatus) {
            return (

                <div>
                    {/*<FormGroup>*/}
                    {/*<div className="input-group">*/}
                    {/*<span*/}
                    {/*className="input-group-addon">{this.props.t('channels_edit.channel_pre_roll', {framework: "react-i18next"})}</span>*/}
                    {/*<input*/}
                    {/*type="text"*/}
                    {/*className="form-control"*/}
                    {/*placeholder="PRE ROLL"*/}
                    {/*disabled={this.state.loading}*/}
                    {/*onChange={this.handleInputChange}*/}
                    {/*name="channel_pre_roll"*/}
                    {/*value={this.state.channel_pre_roll || ""}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</FormGroup>*/}
                    {/*<FormGroup>*/}
                    {/*<div className="input-group">*/}
                    {/*<span*/}
                    {/*className="input-group-addon">{this.props.t('channels_edit.channel_mid_roll', {framework: "react-i18next"})}</span>*/}
                    {/*<input*/}
                    {/*type="text"*/}
                    {/*className="form-control"*/}
                    {/*placeholder="MID ROLL"*/}
                    {/*disabled={this.state.loading}*/}
                    {/*onChange={this.handleInputChange}*/}
                    {/*name="channel_mid_roll"*/}
                    {/*value={this.state.channel_mid_roll || ""}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</FormGroup>*/}

                    <FormGroup>
                        <ControlLabel>PRE ROLL</ControlLabel>
                        <InputGroup>
                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                <Glyphicon glyph="object-align-right"/>
                            </InputGroup.Addon>
                            <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Enter the pre roll url"
                                onChange={this.handleInputChange}
                                name="channel_pre_roll"
                                value={this.state.channel_pre_roll || ''}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>MID ROLL</ControlLabel>
                        <InputGroup>
                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                <Glyphicon glyph="object-align-vertical"/>
                            </InputGroup.Addon>
                            <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Enter the mid roll url"
                                onChange={this.handleInputChange}
                                name="channel_mid_roll"
                                value={this.state.channel_mid_roll || ''}
                            />
                        </InputGroup>
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>POST ROLL</ControlLabel>
                        <InputGroup>
                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                <Glyphicon glyph="object-align-left"/>
                            </InputGroup.Addon>
                            <FormControl
                                type="text"
                                className="form-control"
                                placeholder="Enter the post roll url"
                                onChange={this.handleInputChange}
                                name="channel_post_roll"
                                value={this.state.channel_post_roll || ''}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>

            );
        }
    }

    renderBlackOutFields = () => {

        if (this.state.blackOutStatus) {
            return (

                <div>
                    <Row>
                        <FormGroup>
                            <ControlLabel>{this.props.t('channels_edit.channel_blackout_countries', {framework: "react-i18next"})}</ControlLabel>
                            <DropDownCountries
                                countrySelected={this.state.channel_blackout_countries}
                                name='blackOutSelect'
                                handlerFromParent={this.handleChildCountrySelect}
                                hadlerAddAllCountries={this.state.addAllCountriesInBlackList}

                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup>
                            <Button
                                onClick={this.onClickAddAllCountriesInBlackList.bind(this)}
                            >
                                Add All countries
                            </Button>
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup>
                            <ControlLabel>{this.props.t('channels_edit.channel_blackout_url', {framework: "react-i18next"})}</ControlLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={this.props.t('channels_edit.channel_blackout_url_placeholder', {framework: "react-i18next"})}
                                onChange={this.handleInputChange}
                                name="channel_blackout_url"
                                disabled={this.state.loading}
                                value={this.state.channel_blackout_url || ""}
                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <Well><Glyphicon glyph="question-sign"/> By enabling the Blackout
                            functionality any countries added in the "Blackout Country list"
                            field will be redirected to the "Blackout URL"
                        </Well>
                    </Row>
                </div>

            );
        }
    }

    renderCountryBlockFields = () => {

        if (this.state.countryBlockStatus) {
            return (

                <div>
                    <Row>
                        <FormGroup>
                            <ControlLabel>Countries to block</ControlLabel>
                            <DropDownCountries
                                countrySelected={this.state.channel_country_block_countries}
                                name='countryBlockSelect'
                                handlerFromParent={this.handleChildCountryBlockSelect}
                                hadlerAddAllCountries={this.state.addAllCountriesInCountryBlock}
                            />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup>
                            <Button
                                onClick={this.onClickAddAllCountriesCountryBlock.bind(this)}
                            >
                                Add All countries
                            </Button>
                        </FormGroup>
                    </Row>
                    <Row>
                        <Well><Glyphicon glyph="question-sign"/> By enabling the Country Block
                            functionality,the live channels will be hidden for the countries specified.
                        </Well>
                    </Row>
                </div>

            );
        }
    }

    renderEncryptionFields = () => {

        if (this.state.encryptionStatus) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>{this.props.t('channels_edit.channel_vmx_contentid', {framework: "react-i18next"})}</ControlLabel>
                        <FormControl placeholder="Enter Verimatrix content sms id"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="channel_vmx_contentid"
                                     onChange={this.handleInputChange}
                                     value={this.state.channel_vmx_contentid || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Well> <Glyphicon glyph="info-sign"/> Status: unknown
                        </Well>
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>{this.props.t('channels_edit.channel_vmx_dvr_contentid', {framework: "react-i18next"})}</ControlLabel>
                        <FormControl placeholder="Enter Verimatrix dvr content sms id"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="channel_vmx_dvr_contentid"
                                     onChange={this.handleInputChange}
                                     value={this.state.channel_vmx_dvr_contentid || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Well> <Glyphicon glyph="info-sign"/> Status: unknown
                        </Well>
                    </FormGroup>

                </div>
            );
        }
    }

    renderTvGuideGraceNote = () => {

        if (process.env.REACT_APP_TVGUIDE_GRACENOTE === "1") {
            return (
                <div>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel>{"TVGUIDE - GRACENOTE"}
                                    <Button
                                        bsStyle={this.state.addNewTvguideGraceNote ? "default" : "success"}
                                        simple
                                        icon
                                        disabled={this.state.loading}
                                        onClick={this.onClickAddTvGuideGraceNote.bind(this)}
                                    >
                                        {
                                            this.state.addNewTvguideGraceNote ?
                                                <i className="glyphicon glyphicon-remove"/> :
                                                <i className="glyphicon glyphicon-plus"/>
                                        }
                                    </Button>
                                </ControlLabel>
                                <DropDownTvGuideGraceNote
                                    guideGraceNoteSelected={this.state.channel_tvguide_gracenote_id}
                                    name='guideGraceNoteSelect'
                                    disabled={this.state.loading}
                                    handlerFromParent={this.handleChildChannelGuideGraceNoteSelect}
                                    reloadTvguideGraceNote={this.state.reloadTvguideGraceNote}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        {this.renderAddNewTvGuideGraceNote()}
                    </Row>
                </div>

            )
        }

    }

    renderAddNewTvGuideGraceNote = () => {

        if (this.state.addNewTvguideGraceNote) {
            return (
                <div>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>GraceNote ID</ControlLabel>
                            <FormControl
                                placeholder="Enter GraceNote ID"
                                type="text"
                                onChange={this.handleInputChange}
                                name="gracenote_id"
                                disabled={this.state.loading}
                                value={this.state.gracenote_id || ''}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>GraceNote Name</ControlLabel>
                            <FormControl
                                placeholder="Enter the channel name"
                                type="text"
                                onChange={this.handleInputChange}
                                name="gracenote_name"
                                disabled={this.state.loading}
                                value={this.state.gracenote_name || ''}/>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel> </ControlLabel>
                            <Button
                                onClick={this.onClickSaveNewTvGuideGraceNote.bind(this)}
                                bsStyle="success"
                                disabled={this.state.loading}
                                fill wd
                                name={"addGraceNote Button"}>
                                {this.state.loading ? 'Loading...' : "Add new channel"}
                            </Button>
                        </FormGroup>
                    </Col>
                </div>
            );
        }
    }


    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Content Provider</ControlLabel>
                                <DropDownContentProviders
                                    contentProviderSelected={this.state.user_content_provider}
                                    name='contentProviderSelect'
                                    handlerFromParent={this.handleChildContentProviderSelect}
                                    disabled={this.state.loading}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                        </Col>
                    </Row>
                )
            }
        }

    }

    onClickAddTvGuideGraceNote() {

        if (this.state.addNewTvguideGraceNote) {
            this.setState({addNewTvguideGraceNote: false})
        }

        if (!this.state.addNewTvguideGraceNote) {
            this.setState({addNewTvguideGraceNote: true})
        }


    }

    onClickSaveNewTvGuideGraceNote() {

        if (this.state.addNewTvguideGraceNote) {

            this.createGraceNoteChannel();

            // this.setState({
            //     addNewTvguideGraceNote: false,
            //     reloadTvguideGraceNote: true
            // })
        }

    }

    onClickAddAllCountriesCountryBlock() {

        this.setState({addAllCountriesInCountryBlock: true})

    }

    onClickAddAllCountriesInBlackList() {

        this.setState({addAllCountriesInBlackList: true})

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}


                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col md={8}>
                            <Card
                                content={
                                    <div>
                                        <h5>{this.state.channel_name_short + " - General Settings"}</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <Col sm={2}>
                                                        <FormGroup>
                                                            <p className="category">{this.props.t('channels_edit.channelStatus', {framework: "react-i18next"})}</p>
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                defaultValue={false}
                                                                name='statusSwitch'
                                                                disabled={this.state.loading}
                                                                value={this.state.channelStatus}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={10}>
                                                        {this.renderEasImage()}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup
                                                            controlId="formValidationPosition"
                                                            validationState={this.state.channel_position_state}
                                                        >
                                                            <ControlLabel>{this.props.t('channels_edit.channel_position', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl

                                                                placeholder={this.props.t('channels_edit.channel_position_placeholder', {framework: "react-i18next"})}
                                                                type="number"
                                                                name={"channel_position"}
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.channel_position || ""}/>
                                                            <HelpBlock><Glyphicon
                                                                glyph="warning-sign"/>{this.state.channel_position_message}
                                                            </HelpBlock>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.channel_bkp_position', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl
                                                                placeholder={this.props.t('channels_edit.channel_bkp_position_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                name="channel_bkp_position"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_bkp_position || ''}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup
                                                            controlId="formValidationShortName"
                                                            validationState={this.state.channel_name_short_validate_state}
                                                        >
                                                            <ControlLabel>{this.props.t('channels_edit.channel_name_short', {framework: "react-i18next"})} (less
                                                                than 5 characters)</ControlLabel>
                                                            <FormControl
                                                                placeholder={this.props.t('channels_edit.channel_name_short_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                name="channel_name_short"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_name_short || ""}/>
                                                            <HelpBlock><Glyphicon
                                                                glyph="warning-sign"/>{this.state.channel_name_short_message}
                                                            </HelpBlock>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup
                                                            controlId="formValidationLongName"
                                                            validationState={this.state.channel_name_long_validate_state}
                                                        >
                                                            <ControlLabel>{this.props.t('channels_edit.channel_name_long', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl
                                                                placeholder={this.props.t('channels_edit.channel_name_long_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                onChange={this.handleInputChange}
                                                                name="channel_name_long"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_name_long || ""}/>
                                                            <HelpBlock><Glyphicon
                                                                glyph="warning-sign"/>{this.state.channel_name_long_message}
                                                            </HelpBlock>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.channel_description', {framework: "react-i18next"})}</ControlLabel>
                                                            <FormControl componentClass="textarea"
                                                                         type="text"
                                                                         name="channel_description"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         placeholder={this.props.t('channels_edit.channel_description_placeholder', {framework: "react-i18next"})}
                                                                         value={this.state.channel_description || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup
                                                            controlId="formValidationCategory"
                                                            validationState={this.state.channel_cat_id_state}
                                                        >
                                                            <ControlLabel>{this.props.t('channels_edit.channel_cat_id', {framework: "react-i18next"})}</ControlLabel>
                                                            <DropDownTvCategory
                                                                categorySelected={this.state.channel_cat_id}
                                                                name='categorySelect'
                                                                disabled={this.state.loading}
                                                                handlerFromParent={this.handleChildCategorySelect}
                                                            />
                                                            <HelpBlock><Glyphicon
                                                                glyph="warning-sign"/> {this.state.channel_cat_id_message}
                                                            </HelpBlock>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.channel_tvguide_id', {framework: "react-i18next"})}</ControlLabel>
                                                            <DropDownTvGuide
                                                                guideChannelSelected={this.state.channel_tvguide_id}
                                                                name='guideSelect'
                                                                disabled={this.state.loading}
                                                                handlerFromParent={this.handleChildChannelGuideSelect}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {this.renderTvGuideGraceNote()}

                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <ControlLabel className="category">Language</ControlLabel>
                                                            <Select
                                                                value={this.state.channel_language}
                                                                onChange={this.handleLanguageChange}
                                                                options={languageSelection}
                                                                // disabled={this.state.newPackage}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <ControlLabel
                                                                className="category">{this.props.t('channels_edit.channel_genre_id', {framework: "react-i18next"})}</ControlLabel>
                                                            <DropDownTvGenres
                                                                genreSelected={this.state.channel_genre_id}
                                                                name='genreSelect'
                                                                disabled={this.state.loading}
                                                                handlerFromParent={this.handleChildTvGenreSelect}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {this.renderContentProvider()}

                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            {/*Playback Settings*/}
                            <Card
                                content={
                                    <div>
                                        <h5>Playback Settings</h5>
                                        <legend/>
                                        {/*<legend>Playback Settings</legend>*/}
                                        <Grid fluid>

                                            <Col md={6}>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_iptv_url', {framework: "react-i18next"})} </ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="film"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={this.props.t('channels_edit.channel_iptv_url_placeholder', {framework: "react-i18next"})}
                                                                onChange={this.handleInputChange}
                                                                name="channel_iptv_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_iptv_url || ""}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    bsStyle={this.state.videoState === "playing" && this.state.videoType === 'iptv' ? "info" : "default"}
                                                                    fill
                                                                    disabled={this.state.loading}
                                                                    // onClick={this.buttonVideoPreview(this.state.channel_iptv_url,"iptv")}
                                                                    onClick={this.buttonVideoPreviewIptv.bind(this)}
                                                                >
                                                                    {this.state.videoState === "playing" && this.state.videoType === 'iptv' ?
                                                                        <i className="glyphicon glyphicon-pause"/> :
                                                                        <i className="glyphicon glyphicon-play"/>}
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_ott_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="film"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={this.props.t('channels_edit.channel_ott_url_placeholder', {framework: "react-i18next"})}
                                                                onChange={this.handleInputChange}
                                                                name="channel_ott_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_ott_url || ""}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    bsStyle={this.state.videoState === "playing" && this.state.videoType === 'ott' ? "info" : "default"}
                                                                    fill
                                                                    disabled={this.state.loading}
                                                                    onClick={this.buttonVideoPreviewOtt.bind(this)}
                                                                >
                                                                    {this.state.videoState === "playing" && this.state.videoType === 'ott' ?
                                                                        <i className="glyphicon glyphicon-pause"/> :
                                                                        <i className="glyphicon glyphicon-play"/>}
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>OTT BACKUP URL</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="film"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="OTT backup url"
                                                                onChange={this.handleInputChange}
                                                                name="channel_ott_backup_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_ott_backup_url || ""}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    bsStyle={this.state.videoState === "playing" && this.state.videoType === 'ott' ? "info" : "default"}
                                                                    fill
                                                                    disabled={this.state.loading}
                                                                    onClick={this.buttonVideoPreviewOttBackup.bind(this)}
                                                                >
                                                                    {this.state.videoState === "playing" && this.state.videoType === 'ott' ?
                                                                        <i className="glyphicon glyphicon-pause"/> :
                                                                        <i className="glyphicon glyphicon-play"/>}
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_catchup_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="film"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={this.props.t('channels_edit.channel_catchup_url_placeholder', {framework: "react-i18next"})}
                                                                onChange={this.handleInputChange}
                                                                name="channel_catchup_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_catchup_url || ""}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    bsStyle={this.state.videoState === "playing" && this.state.videoType === 'catchup' ? "info" : "default"}
                                                                    fill
                                                                    disabled={this.state.loading}
                                                                    onClick={this.buttonVideoPreviewCatchUp.bind(this)}
                                                                >
                                                                    {this.state.videoState === "playing" && this.state.videoType === 'catchup' ?
                                                                        <i className="glyphicon glyphicon-pause"/> :
                                                                        <i className="glyphicon glyphicon-play"/>}
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_start_over_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="film"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={this.props.t('channels_edit.channel_start_over_url_placeholder', {framework: "react-i18next"})}
                                                                onChange={this.handleInputChange}
                                                                name="channel_start_over_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.channel_start_over_url || ""}
                                                            />
                                                            <InputGroup.Button>
                                                                <Button
                                                                    bsStyle={this.state.videoState === "playing" && this.state.videoType === 'startover' ? "info" : "default"}
                                                                    fill
                                                                    disabled={this.state.loading}
                                                                    onClick={this.buttonVideoPreviewStartOver.bind(this)}
                                                                >
                                                                    {this.state.videoState === "playing" && this.state.videoType === 'startover' ?
                                                                        <i className="glyphicon glyphicon-pause"/> :
                                                                        <i className="glyphicon glyphicon-play"/>}
                                                                </Button>
                                                            </InputGroup.Button>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                            </Col>

                                            <Col md={6}>
                                                <FormGroup>
                                                    <VideoPlayer
                                                        handlerFromParent={this.handleChildVideoPreview}
                                                        handlerSource={this.state.videoSource}
                                                        handlerState={this.state.videoState}
                                                        handlerType={this.state.videoType}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>Other Settings</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <p className="category">
                                                            <ControlLabel>{this.props.t('channels_edit.channel_parental_status', {framework: "react-i18next"})}</ControlLabel>
                                                        </p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='parentalSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.parentalStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <p className="category">
                                                            <ControlLabel>{this.props.t('channels_edit.channel_mobile_status', {framework: "react-i18next"})}</ControlLabel>
                                                        </p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='mobileStatus'
                                                            disabled={this.state.loading}
                                                            value={this.state.mobileStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <p className="category">
                                                            <ControlLabel>{this.props.t('channels_edit.channel_casting_status', {framework: "react-i18next"})}</ControlLabel>
                                                        </p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='castingStatus'
                                                            disabled={this.state.loading}
                                                            value={this.state.castingStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <p className="category"><ControlLabel>P2P</ControlLabel></p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='p2pSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.p2pStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <p className="category"><ControlLabel>Require
                                                            Login</ControlLabel></p>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='webLoginSwitch'
                                                            disabled={this.state.loading}
                                                            value={this.state.webLoginStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_VideoPID', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Video PID"
                                                                     type="text"
                                                                     disabled={this.state.loading}
                                                                     name="channel_VideoPID"
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.channel_VideoPID || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_AudioPID', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl placeholder="Audio PID"
                                                                     type="text"
                                                                     disabled={this.state.loading}
                                                                     name="channel_AudioPID"
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.channel_AudioPID || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_resolution_id', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownResolution
                                                            resolutionSelected={this.state.channel_resolution_id}
                                                            name='resolutionSelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildResolutionSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('channels_edit.channel_mode_id', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownMode
                                                            modeSelected={this.state.channel_mode_id}
                                                            name='modeSelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildModeSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                content={
                                    <div>
                                        <h5>External System Capabilities and settings</h5>
                                        <legend/>
                                        {/*<legend>External System Capabilities and settings</legend>*/}
                                        <Grid fluid>
                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>TimeShift</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='timeShiftSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.timeShiftStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <ControlLabel>Catchup</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='catchupSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.catchupStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <ControlLabel>Restart</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='restartSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.restartStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderDvrDuration()}
                                                                </Col>
                                                            </Row>
                                                        </Grid>
                                                    }
                                                />
                                            </Row>


                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>{this.props.t('channels_edit.encryptionStatus', {framework: "react-i18next"})}</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='encryptionSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.encryptionStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderEncryptionFields()}
                                                                </Col>
                                                            </Row>
                                                        </Grid>

                                                    }
                                                />
                                            </Row>


                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Token Authentication</ControlLabel>
                                                                        <br/>
                                                                        <FormGroup>
                                                                            <Select
                                                                                value={this.state.channel_streaming_token}
                                                                                onChange={this.handleStreamingTokenChange}
                                                                                options={tokenSelection}
                                                                            />
                                                                        </FormGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Grid>

                                                    }
                                                />
                                            </Row>

                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>{this.props.t('channels_edit.channel_pvr_status', {framework: "react-i18next"})}</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='pvrSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.pvrStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderNPVRFields()}
                                                                </Col>

                                                            </Row>
                                                        </Grid>
                                                    }
                                                />
                                            </Row>

                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>{this.props.t('channels_edit.channel_vast_status', {framework: "react-i18next"})}</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='vastSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.vastStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderVASTFields()}
                                                                </Col>

                                                            </Row>
                                                        </Grid>
                                                    }
                                                />
                                            </Row>

                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>{this.props.t('channels_edit.channel_blackout_status', {framework: "react-i18next"})}</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='blackOutSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.blackOutStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderBlackOutFields()}
                                                                </Col>

                                                            </Row>
                                                        </Grid>
                                                    }
                                                />
                                            </Row>

                                            <Row>
                                                <Card
                                                    content={
                                                        <Grid fluid>
                                                            <Row>
                                                                <Col md={2}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Country Block</ControlLabel>
                                                                        <br/>
                                                                        <Switch
                                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                                            name='countryBlockSwitch'
                                                                            disabled={this.state.loading}
                                                                            value={this.state.countryBlockStatus}
                                                                            defaultValue={false}
                                                                        />
                                                                    </FormGroup>

                                                                </Col>
                                                                <Col md={10}>
                                                                    {this.renderCountryBlockFields()}
                                                                </Col>

                                                            </Row>
                                                        </Grid>
                                                    }
                                                />
                                            </Row>

                                        </Grid>
                                    </div>
                                }
                            />


                            {/*INFO*/}
                            <Card
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <h5>{this.props.t('channels_edit.channel_legend_middleware_info', {framework: "react-i18next"})}</h5>
                                                <legend/>
                                                {/*<legend>{this.props.t('channels_edit.channel_legend_middleware_info', {framework: "react-i18next"})}</legend>*/}
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('channels_edit.channel_user_added', {framework: "react-i18next"})}</ControlLabel>
                                                                <FormControl placeholder=""
                                                                             name="channel_user_added"
                                                                             disabled
                                                                             value={this.state.channel_user_added || ''}
                                                                             type="text"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('channels_edit.channel_date_added', {framework: "react-i18next"})}</ControlLabel>
                                                                <FormControl placeholder=""
                                                                             name="channel_date_added"
                                                                             disabled
                                                                             value={this.state.channel_date_added || ''}
                                                                             type="text"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6}>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('channels_edit.channel_user_updated', {framework: "react-i18next"})}</ControlLabel>
                                                                <FormControl placeholder=""
                                                                             name="channel_user_updated"
                                                                             disabled
                                                                             value={this.state.channel_user_updated || ''}
                                                                             type="text"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <FormGroup>
                                                                <ControlLabel>{this.props.t('channels_edit.channel_date_updated', {framework: "react-i18next"})}</ControlLabel>
                                                                <FormControl placeholder=""
                                                                             name="channel_date_updated"
                                                                             disabled
                                                                             value={this.state.channel_date_updated || ''}
                                                                             type="text"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ControlLabel>Notes</ControlLabel>
                                                        <FormControl componentClass="textarea"
                                                                     type="text"
                                                                     name="channel_notes"
                                                                     disabled={this.state.loading}
                                                                     onChange={this.handleInputChange}
                                                                     placeholder="Enter channel general information and notes"
                                                                     value={this.state.channel_notes || ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />

                        </Col>
                        <Col md={4}>
                            <Row>
                                <Card
                                    content={
                                        <div id="out-test" className="text-center center center-block">
                                            <div id="test" style={{textAlignLast: "center"}}>
                                                <ButtonGroup aria-label="Basic example">
                                                    <Button
                                                        onClick={() => this.setState({showSaveModal: true})}
                                                        bsStyle="info"
                                                        disabled={this.state.loading}
                                                        fill wd
                                                        name={"saveButton"}>
                                                        {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                                    </Button>

                                                    <Button bsStyle="default" fill wd
                                                            name={"cancelButton"}
                                                            disabled={this.state.loading}
                                                            onClick={this.cancelLiveTVEdit.bind(this)}>
                                                        {this.state.back_button_text}
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    }
                                />
                            </Row>
                            <Row>
                                <Card
                                    content={
                                        <div>
                                            <h5>Assets</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.image_normal', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.channel_image}
                                                                imageType="live_tv"
                                                                imagePosition={"channel_image"}
                                                                imageTemp={this.state.channel_image_temp}
                                                                imageFolderTemp={this.state.channel_image_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.image_wide', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.channel_image_wide}
                                                                imageType="live_tv"
                                                                imagePosition={"channel_image_wide"}
                                                                imageTemp={this.state.channel_image_wide_temp}
                                                                imageFolderTemp={this.state.channel_image_wide_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Row>

                            <Row>
                                <Card
                                    tableFullWidth
                                    content={
                                        <div>
                                            <Grid fluid>
                                                <h5>Schedule</h5>
                                                <legend/>
                                                {/*<legend>Schedule</legend>*/}
                                                <Col md={12}>
                                                    <Row>
                                                        <CalendarLiveChannels
                                                            channelId={this.state.channel_epg_id}
                                                        />
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Row>
                        </Col>
                    </Row>
                </Grid>


            </div>
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedLiveChannelsEdit = connect(mapStateToProps)(LiveChannelsEdit);
const connectedLiveChannelsEditTrans = withNamespaces('common')(connectedLiveChannelsEdit);
export {connectedLiveChannelsEditTrans as LiveChannelsEdit};
