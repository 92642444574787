// import config from 'config';
import {authHeader, history} from '../_helpers';
import md5 from 'crypto-js/md5';

var apiUrl = process.env.REACT_APP_API_URL;

export const userService = {
    login,
    refreshToken,
    logout,
    logoutUnauthorized,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(username, password) {

    //console.log("****login");
    password = md5(password).toString();

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"authenticate": {"username": username, "password": password}}),
    };

    return fetch(`${apiUrl}/Authentication`, requestOptions)
        .then(handleResponse)
        .then(user => {
            //console.log(user);
            //console.log("****HANDLE RESPONSE **** login");
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function refreshToken(username, token) {

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"authenticate": {"username": username, "token": token, "type": "refresh"}}),
    };

    return fetch(`${apiUrl}/Authentication`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    //console.error("****logout");
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function logoutUnauthorized() {
    //console.error("****logoutUnauthorized");
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    history.push('/');
}

function getAll() {
    //console.log("****getAll");
    // The home page component is displayed after signing in to the application,
    // it shows the signed in user's name plus a list of all registered users in the tutorial application.
    // The users are loaded into redux state by dispatching the redux action userActions.getAll() from the componentDidMount()
    // react lifecycle hook.
    // Users can also be deleted from the user list,
    // when the delete link is clicked it triggers the redux action userActions.delete(id) to be dispatched.
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/Authentication`, requestOptions).then(handleResponse);
}

function getById(id) {
    //console.log("****getById");

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/Authentication/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    //console.log("****register");

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    };

    return fetch(`${apiUrl}/Authentication/register`, requestOptions).then(handleResponse);
}

function update(user) {
    //console.log("****update");
    // const requestOptions = {
    //     method: 'PUT',
    //     headers: { ...authHeader(), 'Content-Type': 'application/json' },
    //     body: JSON.stringify(user)
    // };
    //
    // return fetch(`${apiUrl}/Authentication/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {

    //console.log("****_delete");

    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/Authentication/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {

    //console.log("****handleResponse");

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}