import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownSvodPriceList extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            loading: true,
            vodPriceArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        const url = process.env.REACT_APP_API_URL+"/PriceGroupSvod/dropdown";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {
                const vodPriceArray = res.data;
                const loading = false;
                this.setState({vodPriceArray, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    render() {
        return (
            <Select
                placeholder="Price group select"
                name="vodPriceSelect"
                autoFocus={false}
                isSearchable={true}
                multi={true}
                value={this.props.priceGroupsSelected}
                options={this.state.vodPriceArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownSvodPriceList = connect(mapStateToProps)(DropDownSvodPriceList);
const connectedDropDownSvodPriceListTrans = withNamespaces('common')(connectedDropDownSvodPriceList);
export {connectedDropDownSvodPriceListTrans as DropDownSvodPriceList};

