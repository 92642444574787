import React, {Component} from "react";
import axios from "axios/index";
import DualListBox from 'react-dual-listbox';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

//https://github.com/jakezatecky/react-dual-listbox
//https://github.com/jakezatecky/react-dual-listbox/issues/41

class DualListBoxPackageSvodSeries extends Component {

    constructor(props) {
        super(props);

        this.handleSeriesSelect = this.handleSeriesSelect.bind(this);

        this.state = {
            loading: false,

            availableSvodContent: [],
            selectedArray: []
        };
        this.onChange = this.onChange.bind(this);

    }

    handleSeriesSelect(selected) {
        this.props.handlerFromParent(selected);
    }

    onChange(selected) {

        this.setState({selectedArray: selected});
        this.handleSeriesSelect(selected)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.svodPackageSeriesProvider !== prevProps.svodPackageSeriesProvider) {
            this.loadAvailableSeriesFromMiddleware()
        }

    }

    componentDidMount() {

        this.loadAvailableSeriesFromMiddleware();

    }

    loadAvailableSeriesFromMiddleware() {

        const url = process.env.REACT_APP_API_URL + "/VodSeries?provider="+this.props.svodPackageSeriesProvider;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };


        axios
            .get(url, axiosConfig)
            .then(res => {

                let temp = res.data;

                let availableSvodContent = [];
                let obj = {};

                for (let i = 0; i < temp.length; i++) {
                    //console.log(temp[i].value.toString())

                    obj = {
                        value: temp[i].vod_series_id.toString(),
                        label: temp[i].vod_series_name_long

                    }
                    availableSvodContent.push(obj)
                }

                const loading = false;

                this.setState({availableSvodContent, loading});
            })
            .catch(error => {
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    render() {
        return (
            <DualListBox
                options={this.state.availableSvodContent}
                canFilter
                availableLabel={"Available"}
                selectedLabel={"Selected"}
                showHeaderLabels
                simpleValue={true}
                selected={this.props.seriesInPackage}
                onChange={this.onChange}
            />
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDualListBoxPackageSvodSeries = connect(mapStateToProps)(DualListBoxPackageSvodSeries);
const connectedDualListBoxPackageSvodSeriesTrans = withNamespaces('common')(connectedDualListBoxPackageSvodSeries);
export {connectedDualListBoxPackageSvodSeriesTrans as DualListBoxPackageSvodSeries};
