const reactTime = {
    myTime: function (value) {
            return value;
    }
}

const reactCustom = {

    getExpirationStatus: function (value) {

        if (value > 5 && value <= 10) {
            return "warning-1"
        } else if (value <= 5 && value > 0) {
            return "warning-2"
        }
        else if (value <= 0) {
            return "danger"
        } else {
            return "success"
        }
    }
}

const reactWmsCustom = {

    checkMissingAudioVideo: function (value) {

        if (value === "" || value === undefined || value === null){
            return "missing";
        }else{
            return value
        }
    },
    checkLowBitrate: function (value) {

        if (value <= 250 ){
            return "warning";
        }else{
            return value
        }
    }
}

const AxiosHeader = {

    getConfig: function () {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""
        let axiosConfig = null

        if (user) {

            token = user.token

            axiosConfig = {
                headers: {
                    'X-Id-Token': token,
                    'Content-Type': 'application/json',
                }
            }

            return axiosConfig;

        } else {
            return null;
        }
    }
}


export {
    reactTime,
    reactCustom,
    reactWmsCustom,
    AxiosHeader
};