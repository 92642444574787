import React, {Component} from "react";
import {userActions} from '../../authentication/_actions';
import {history} from "../../authentication/_helpers";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import jwt_decode from "jwt-decode";
import moment from "moment";

class NavBarClock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleString()
        };
    }

    componentDidMount() {

        this.tokenCheck()

        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );

        this.intervalJWT = setInterval(
            () => this.tokenCheck(),
            5000
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
        clearInterval(this.intervalJWT);
    }

    tokenCheck(){

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
            let decoded = jwt_decode(token);
            let diff = decoded.exp - moment().unix();
            if(diff < 0){
                this.logoutAction()
            }
        }
        else{
            //this.props.dispatch(userActions.logoutUnauthorized());
            this.logoutAction()
        }


    }

    logoutAction(){
        this.props.dispatch(userActions.logout());
        history.push('/user/login-page');
    }

    tick() {

        const date = new Date();
        let time = moment.utc(date).local().format('YYYY-MM-DD | HH:mm:ss | Z')

        this.setState({time});
    }

    render() {
        return (
            <span className="App-clock">
                {this.state.time}
            </span>
        );
    }

}

function mapStateToProps(state) {

    const { authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedNavBarClock = connect(mapStateToProps)(NavBarClock);
const connectedNavBarClockTrans = withNamespaces('common')(connectedNavBarClock);
export {connectedNavBarClockTrans as NavBarClock};
