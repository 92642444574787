import React, {Component} from "react";

class Footer extends Component {
    render() {
        return (
            <footer
                className={
                    "footer" +
                    (this.props.transparent !== undefined ? " footer-transparent" : "")
                }
            >
                <div
                    className={
                        "container" + (this.props.fluid !== undefined ? "-fluid" : "")
                    }
                >
                    <nav className="pull-left">
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                        </ul>
                    </nav>
                    <p className="copyright pull-right">
                        <a href={process.env.REACT_APP_EXTERNAL_URL}>{process.env.REACT_APP_EXTERNAL_NAME}</a>
                    </p>
                    <p className="copyright pull-right">
                        &copy; {1900 + new Date().getYear()}{" "}
                        <a href={process.env.REACT_APP_HOME_URL}>{process.env.REACT_APP_NAME + " Middleware"}</a>,
                        Commit:<code>{process.env.REACT_APP_COMMIT}</code> Version:<code>{process.env.REACT_APP_VERSION}</code>
                        {/*, made with{" "} <i className="fa fa-heart heart" /> {"for a better IPTV"}*/}
                    </p>
                </div>
            </footer>
        );
    }
}

export default Footer;