import React from "react";
import axios from "axios";
import ReactTable from "react-table";
import Button from "../CustomButton/CustomButton.jsx";
import {Redirect} from 'react-router'
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";

import ModalDelete from "../CustomModals/ModalDelete";
import {ModalEditSeason} from "../CustomModals/ModalEditSeason";
import {ModalManualySelectSeason} from "../CustomModals/ModalManualySelectSeason";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DataVodSeasons extends React.Component {

    constructor(props) {

        super(props);
        this.handleSeasonLoad = this.handleSeasonLoad.bind(this);

        this.state = {

            remove_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),
            edit_tooltip: (<Tooltip id="redit_tooltip">Edit</Tooltip>),

            showRestoreModal: false,
            showDeleteModal: false,
            showEditSeasonModal: false,
            showEditSeasonModalType: "",

            showEditManualSeasonModal: false,

            selectedIndex: null,
            rowEdit: null,

            vod_seasons_id: 0,
            redirect: false,
            loading: true,
            vodSeasonsArray: [],
            columns: [
                {
                    accessor: "vod_seasons_id",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: false,
                    filterable: false
                },
                {
                    accessor: "vod_seasons_number",
                    Header: "#",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "vod_seasons_poster_path",
                    Header: "Poster",
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {this.setReactTableImage(row.value)}
                        </div>
                    )
                },
                {
                    accessor: "vod_seasons_status",
                    Header: "Status",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <Badge fill="true" style={row.value === 1 ? {
                                background: '#87cb16',
                                borderRadius: '2px'
                            } : {background: '#545557', borderRadius: '2px'}}>
                                {
                                    row.value === 1 ? 'Active' : 'Disabled'
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    accessor: "vod_seasons_name",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },

                {
                    accessor: "vod_seasons_air_date",
                    Header: "Air Date",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "vod_seasons_episode_count",
                    Header: "Episode Count",
                    sortable: true,
                    filterable: false
                },
                {
                    Header: 'actions',
                    sortable: false,
                    filterable: false,
                    accessor: "vod_seasons_id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }

            ],

            tmdb_status: false,
            vod_seasons_poster_path_temp: "",
            vod_seasons_poster_path_folder_temp: ""
        };

    }

    componentDidMount() {
        this.loadFromMiddleware()
    }

    handleSeasonLoad(season, seasonName, status, seasonID) {

        //console.log(season)
        this.props.handlerFromParent(season, seasonName, status, seasonID);
    }

    submitTmdbPosterImage($image) {

        //console.log("TMDB-> SUBMIT IMAGE")

        const url = process.env.REACT_APP_TMDB_IMAGE_API_URL;
        const imgUrl = "https://image.tmdb.org/t/p/w500" + $image;

        let bodyFormData = new FormData();
        bodyFormData.set('filename', $image);
        bodyFormData.set('location', "vod_series");
        bodyFormData.set('url', imgUrl);

        axios.post(url, bodyFormData)
            .then((res) => {
                //this.setState({channel_image_temp: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })

    }

    //REDIRECTS
    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/edit-vod-cat/" + this.state.vod_cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }
    }

    //TMDB
    loadTMDBSeasonsToMiddleware() {

        if (sessionStorage.getItem('series_main_tmdb_id') !== undefined && sessionStorage.getItem('series_main_tmdb_id') !== 0) {
            const url = "https://api.themoviedb.org/3/tv/" + sessionStorage.getItem('series_main_tmdb_id') + "?api_key=1a310ae853a5b25f2ca396e205bcfa6d";

            axios
                .get(url)
                .then(res => {

                    let seasonsData = res.data.seasons
                    const loading = false;

                    for (let i = 0; i < seasonsData.length; i++) {
                        this.createSeason(seasonsData[i]);
                    }

                    this.setState({
                        vodSeasonsArray: seasonsData,
                        loading
                    });

                    this.loadFromMiddleware()
                });
        }
    }

    //LOAD TO MIDDLEWARE
    loadFromMiddleware() {

        if (sessionStorage.getItem('series_edit') !== undefined && sessionStorage.getItem('series_edit') !== 0) {

            const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason/" + sessionStorage.getItem('series_edit');

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const vodSeasonsArray = res.data;
                    const loading = false;

                    this.setState({
                        vodSeasonsArray: vodSeasonsArray,
                        loading,
                        selectedIndex: null, rowEdit: null

                    });

                    if (vodSeasonsArray.length > 0) {
                        this.handleSeasonLoad(0, vodSeasonsArray[0].vod_seasons_name, true, vodSeasonsArray[0].vod_seasons_id)
                    }

                }).catch(error => {
                this.setState({loading: false, selectedIndex: null, rowEdit: null});
                //this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });
        }
    }

    createSeason(data) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason";

        let vod_seasons_name = data.name;
        let vod_seasons_number = data.season_number;
        let vod_seasons_episode_count = data.episode_count;
        let vod_seasons_air_date = data.air_date;
        let vod_seasons_tmdb_id = data.id;
        let vod_seasons_imdb_id = null;
        let vod_seasons_overview = data.overview;
        let vod_seasons_poster_path = data.poster_path;
        let vod_seasons_type = null;
        let vod_seasons_series_id = this.props.seriesID;

        let postData = {
            vod_season: {
                vod_seasons_name: vod_seasons_name,
                vod_seasons_number: vod_seasons_number,
                vod_seasons_episode_count: vod_seasons_episode_count,
                vod_seasons_air_date: vod_seasons_air_date,
                vod_seasons_tmdb_id: vod_seasons_tmdb_id,
                vod_seasons_imdb_id: vod_seasons_imdb_id,
                vod_seasons_overview: vod_seasons_overview,
                vod_seasons_poster_path: vod_seasons_poster_path,
                vod_seasons_type: vod_seasons_type,
                vod_seasons_series_id: vod_seasons_series_id,

            }
        };

        //console.log(postData);
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            },

        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            }).catch(error => {
            this.setState({loading: false});
            this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

        this.submitTmdbPosterImage(vod_seasons_poster_path)
    }

    //BUTTON ACTIONS
    handleRefreshSeasons() {
        this.loadFromMiddleware()
    }

    handleSaveSeason() {
        this.loadTMDBSeasonsToMiddleware()
    }

    handleAddNewSeason() {
        //ADD NEW SEASON PROCESS
        this.setState({vod_seasons_id: 0, showEditSeasonModal: true, showEditSeasonModalType: "new"})
    }

    handleAddManualyNewSeason() {
        //ADD NEW SEASON PROCESS
        this.setState({vod_seasons_id: 0, showEditManualSeasonModal: true, showEditSeasonModalType: "new"})
    }

    //BUTTONS
    setHelperButton(vod_seasons_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.edit_tooltip}>
            <Button
                onClick={() => {
                    this.setState({
                        vod_seasons_id: vod_seasons_id,
                        showEditSeasonModal: true,
                        showEditSeasonModalType: "edit"
                    })
                }}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-edit"/>
            </Button>
        </OverlayTrigger>)


    }

    setReactTableImage(vod_seasons_poster_path) {

        return (
            <img
                src={process.env.REACT_APP_ASSET_URL + "/vod_series/" + vod_seasons_poster_path}
                width="60px" alt={""}/>
        )

    }

    setDeleteButton(vod_seasons_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_tooltip}>
            <Button
                onClick={() => this.setState({vod_seasons_id: vod_seasons_id, showDeleteModal: true})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    //ACTIONS
    deleteSeason(vod_seasons_id) {

        const deleteURL = process.env.REACT_APP_API_URL + "/VodSeriesSeason/" + vod_seasons_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };


        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                let array = [...this.state.vodSeasonsArray]; // make a separate copy of the array
                const index = array.indexOf(vod_seasons_id)
                array.splice(index, 1);
                this.setState({vodSeasonsArray: array});
                this.handleRefreshSeasons()
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    //MODALS
    setDisplayDeleteModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteSeason(id)
        }
    }

    setEditSeasonModal = (value, action) => {

        this.setState({showEditSeasonModal: value})

        if (action === "save") {
            //console.log("save..............")
            this.handleRefreshSeasons()
        }

    }

    setEditManualSeasonModal = (value, action) => {

        this.setState({showEditManualSeasonModal: value})

        if (action === "save") {
            //console.log("save..............")
            this.handleRefreshSeasons()
        }

    }

    renderDeleteModal = () => {

        let bodyMessageVar = "this action will delete the Season and all its episodes. This action can not be undone";

        if (this.state.showDeleteModal) {
            //console.log("Clicked Delete Modal...");
            return <ModalDelete
                headerMessage={"Delete Season and all Episodes"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayDeleteModal}
                handlerID={this.state.vod_seasons_id}
            />
        }
    }

    renderEditSeason = () => {

        if (this.state.showEditSeasonModal) {
            //console.log("Clicked ModalEditSeason...");
            return <ModalEditSeason
                headerMessage={"Edit / Create Season"}
                bodyMessage={""}
                handlerFromParent={this.setEditSeasonModal}
                handlerID={this.state.vod_seasons_id}
                handlerSeriesID={sessionStorage.getItem('series_edit')}
                handlerType={this.state.showEditSeasonModalType}
            />
        }
    }

    renderManualSelectSeason = () => {

        if (this.state.showEditManualSeasonModal) {
            //console.log("Clicked ModalEditSeason...");
            return <ModalManualySelectSeason
                headerMessage={"Select Season"}
                bodyMessage={""}
                handlerFromParent={this.setEditManualSeasonModal}
                handlerID={this.state.vod_seasons_id}
                handlerSeriesID={sessionStorage.getItem('series_edit')}
                handlerType={this.state.showEditSeasonModalType}
            />
        }
    }

    renderAutoPopulateButton = () => {

        if (this.state.vodSeasonsArray <= 0) {
            return (<Button
                bsStyle="info"
                fill wd
                name={"saveSeasonAutomatically"}
                onClick={this.handleSaveSeason.bind(this)}>
                Add Seasons (Auto)
            </Button>);
        }

    }

    render() {
        return (
            <div>
                {this.renderRedirect()}
                {this.renderDeleteModal()}
                {this.renderEditSeason()}
                {this.renderManualSelectSeason()}

                <div className="buttons-with-margin">
                    <Button
                        bsStyle="default"
                        fill wd
                        name={"refreshSeasons"}
                        onClick={this.handleRefreshSeasons.bind(this)}>
                        Refresh
                    </Button>

                    {this.renderAutoPopulateButton()}

                    <Button
                        bsStyle="info"
                        fill wd
                        name={"refreshSeasons"}
                        onClick={this.handleAddNewSeason.bind(this)}>
                        Add new season
                    </Button>
                    <Button
                        bsStyle="success"
                        fill wd
                        name={"refreshSeasons"}
                        onClick={this.handleAddManualyNewSeason.bind(this)}>
                        Manually select season
                    </Button>
                </div>


                <ReactTable
                    data={this.state.vodSeasonsArray}
                    filterable
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={5}
                    showPaginationBottom
                    className="-striped -highlight"
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: e => {
                                    //console.log("inside");
                                    //console.log(rowInfo);
                                    if (rowInfo.index !== this.state.rowEdit) {
                                        this.setState({
                                            rowEdit: rowInfo.index
                                        });
                                    } else {
                                        this.setState({
                                            rowEdit: null
                                        });
                                    }
                                    //console.log(rowInfo.index);
                                    //console.log(this.state.rowEdit);
                                    this.handleSeasonLoad(rowInfo.original.vod_seasons_number, rowInfo.original.vod_seasons_name, true, rowInfo.original.vod_seasons_id)
                                },
                                // style: {
                                //     background:
                                //         rowInfo.index === this.state.rowEdit ? "#00afec" : "white",
                                //     color:
                                //         rowInfo.index === this.state.rowEdit ? "white" : "black"
                                // }
                                style: {
                                    background:
                                        rowInfo.index === this.state.rowEdit ? "#00afec" : "",
                                    color:
                                        rowInfo.index === this.state.rowEdit ? "white" : ""
                                }

                            };
                        } else {
                            return {};
                        }
                    }}
                    // getTdProps={(state, rowInfo, column, instance) => {
                    //     return {
                    //         onClick: (e, handleOriginal) => {
                    //             // IMPORTANT! React-Table uses onClick internally to trigger
                    //             // events like expanding SubComponents and pivots.
                    //             // By default a custom 'onClick' handler will override this functionality.
                    //             // If you want to fire the original onClick handler, call the
                    //             // 'handleOriginal' function.
                    //             //console.log('A Td Element was clicked!')
                    //             //console.log('it produced this event:', e)
                    //             //console.log('It was in this column:', column)
                    //             //console.log('It was in this row:', rowInfo)
                    //             //console.log('It was in this row:', rowInfo.original.vod_seasons_number)
                    //             this.handleSeasonLoad(rowInfo.original.vod_seasons_number, rowInfo.original.vod_seasons_name, true, rowInfo.original.vod_seasons_id)
                    //             //console.log('It was in this table instance:', instance)
                    //             if (handleOriginal) {
                    //                 handleOriginal()
                    //             }
                    //         }
                    //     }
                    // }}
                />
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataVodSeasons = connect(mapStateToProps)(DataVodSeasons);
const connectedDataVodSeasonsTrans = withNamespaces('common')(connectedDataVodSeasons);
export {connectedDataVodSeasonsTrans as DataVodSeasons};
