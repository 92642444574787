import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import Switch from "react-bootstrap-switch";
import Card from "../Card/Card";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ModalEditEpisode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditEpisode: true,

            vodEpisodeStatusSwitch: false,
            vod_series_id: 0,

            vodEpisodeArray: [],
            vod_id: null,
            vod_status: null,
            vod_name_short: null,
            vod_position: 0,
            vod_description: null,
            vod_year: null,
            vod_image: null,
            vod_trailer_iptv_url: null,
            vod_trailer_ott_url: null,
            vod_iptv_url: null,
            vod_ott_url: null,
            vod_pre_roll: null,
            vod_mid_roll: null,
            vod_tmdb_id: null,
            vod_vut_billing_id: null,

            vod_image_temp: false,
            vod_image_folder_temp: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'vodEpisodeStatusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'vodEpisodeStatusSwitch') {
            this.setState({vodEpisodeStatusSwitch: state})
        }
    }

    handleClose = () => {
        this.setState({showEditEpisode: false})
        this.props.handlerFromParent(false, "nothing");
    }

    handleSave = () => {

        if (this.props.handlerType === "edit") {
            this.updateEpisode()
        }
        else {
            this.createEpisode()
        }
        this.setState({showEditEpisode: false})
        this.props.handlerFromParent(false, "save");
    }

    componentDidMount() {

        if (this.props.handlerType === "edit" && this.props.handlerID !== 0) {
            this.loadFromMiddlewareEpisodeData(this.props.handlerID)
        }
    }

    //LOAD FROM MIDDLEWARE
    loadFromMiddlewareEpisodeData(vod_id) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode/episode/" + vod_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url,axiosConfig)
            .then(res => {

                const vodEpisodeArray = res.data[0];
                const loading = false;

                //console.log(vodEpisodeArray);

                let vodEpisodeStatusSwitch = vodEpisodeArray.vod_status === 1

                this.setState({
                    vodEpisodeArray: vodEpisodeArray,
                    loading,
                    vodEpisodeStatusSwitch,
                    vod_id: vod_id,
                    vod_name_short: vodEpisodeArray.vod_name_short,
                    vod_position: vodEpisodeArray.vod_position,
                    vod_description: vodEpisodeArray.vod_description,
                    vod_year: vodEpisodeArray.vod_year,

                    vod_image: vodEpisodeArray.vod_image,

                    vod_trailer_iptv_url: vodEpisodeArray.vod_trailer_iptv_url,
                    vod_trailer_ott_url: vodEpisodeArray.vod_trailer_ott_url,

                    vod_iptv_url: vodEpisodeArray.vod_iptv_url,
                    vod_ott_url: vodEpisodeArray.vod_ott_url,

                    vod_pre_roll: vodEpisodeArray.vod_pre_roll,
                    vod_mid_roll: vodEpisodeArray.vod_mid_roll,
                    vod_tmdb_id: vodEpisodeArray.vod_tmdb_id,
                    vod_vut_billing_id: vodEpisodeArray.vod_vut_billing_id
                });
                //console.log(vodSeasonArray);
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createEpisode() {

        this.submitPosterImage()

        const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode";

        let vodEpisodeStatusSwitch = this.state.vodEpisodeStatusSwitch === true ? 1 : 0

        var postData = {
            vod_episode: {
                vod_position: this.state.vod_position,
                vod_name_short: this.state.vod_name_short,
                vod_name_long: this.state.vod_name_long,
                vod_description: this.state.vod_description,
                vod_image: this.state.vod_image,
                vod_status: vodEpisodeStatusSwitch,
                vod_year: this.state.vod_year,
                vod_seriesid: sessionStorage.getItem('series_edit'),
                vod_seasonid: sessionStorage.getItem('series_season_id'),
                vod_tmdb_id: null,
                vod_vut_billing_id: this.vod_vut_billing_id,

                vod_cat_id: this.props.handlerSeriesCatID === null ? null : this.props.handlerSeriesCatID.join(),
                vod_vut_category_display: this.props.handlerSeriesCategories,

                vod_trailer_ott_url: this.state.vod_trailer_ott_url,
                vod_ott_url: this.state.vod_ott_url,

                vod_pre_roll: this.state.vod_pre_roll,
                vod_mid_roll: this.state.vod_mid_roll,

                vod_vut_type: 'SVOD'
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    updateEpisode() {

        this.submitPosterImage()

        const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode";

        let vodEpisodeStatusSwitch = this.state.vodEpisodeStatusSwitch === true ? 1 : 0

        var postData = {
            vod_episode: {
                vod_id: this.state.vod_id,
                vod_position: this.state.vod_position,
                vod_name_short: this.state.vod_name_short,
                vod_name_long: this.state.vod_name_long,
                vod_description: this.state.vod_description,
                vod_image: this.state.vod_image,
                vod_status: vodEpisodeStatusSwitch,
                vod_year: this.state.vod_year,
                vod_seriesid: sessionStorage.getItem('series_edit'),
                vod_seasonid: sessionStorage.getItem('series_season_id'),
                vod_tmdb_id: this.state.vod_tmdb_id,
                vod_vut_billing_id: this.state.vod_vut_billing_id,

                vod_cat_id: this.props.handlerSeriesCatID === null ? null : this.props.handlerSeriesCatID.join(),
                vod_vut_category_display: this.props.handlerSeriesCategories,

                vod_trailer_ott_url: this.state.vod_trailer_ott_url,
                vod_ott_url: this.state.vod_ott_url,

                vod_pre_roll: this.state.vod_pre_roll,
                vod_mid_roll: this.state.vod_mid_roll,

                vod_vut_type: 'SVOD'
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        // console.log("" + folder);
        // console.log("" + filename);
        // console.log("" + imagePosition);
        // console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "vod_image") {

            this.setState({
                vod_image_temp: true,
                vod_image: filename,
                vod_image_folder_temp: folder
            });
        }

    }

    submitPosterImage() {

        if (this.state.vod_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_image_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', this.state.vod_image);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    render() {
        return (
            <div className="modal-edit-episode">
                <Modal show={this.state.showEditEpisode} onHide={this.handleClose} bsSize="large">
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + " - " + this.props.handlerID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p> {this.props.bodyMessage}</p>

                        <Card
                            content={
                                <div>
                                    <legend>{this.state.vod_name_short || ""} - General Settings</legend>
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={4}>
                                                <ImageUpload
                                                    imageName={this.state.vod_image}
                                                    imageType="vod_series"
                                                    imagePosition={"vod_image"}
                                                    imageSubLocation={"vod_episode"}
                                                    imageTemp={this.state.vod_image_temp}
                                                    imageFolderTemp={this.state.vod_image_folder_temp}
                                                    imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                                    handlerFromParent={this.handleChildImageSelect}
                                                    imageTmdbType={"poster_path"}

                                                />
                                            </Col>
                                            <Col sm={8}>
                                                <Row>
                                                    <Col sm={12}>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <p className="category">Status</p>
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                defaultValue={false}
                                                                name='vodEpisodeStatusSwitch'
                                                                value={this.state.vodEpisodeStatusSwitch}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>Position</ControlLabel>
                                                            <FormControl placeholder="Position"
                                                                         type="number"
                                                                         name={"vod_position"}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.vod_position || 0}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_name_short</ControlLabel>
                                                            <FormControl placeholder="Enter Name of episode"
                                                                         type="text"
                                                                         onChange={this.handleInputChange}
                                                                         name="vod_name_short"
                                                                         value={this.state.vod_name_short || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_trailer_ott_url</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="vod_trailer_ott_url"
                                                                onChange={this.handleInputChange}
                                                                placeholder="vod_trailer_ott_url"
                                                                value={this.state.vod_trailer_ott_url || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_ott_url</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="vod_ott_url"
                                                                onChange={this.handleInputChange}
                                                                placeholder="vod_ott_url"
                                                                value={this.state.vod_ott_url || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_pre_roll</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="vod_pre_roll"
                                                                onChange={this.handleInputChange}
                                                                placeholder="vod_pre_roll"
                                                                value={this.state.vod_pre_roll || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_mid_roll</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="vod_mid_roll"
                                                                onChange={this.handleInputChange}
                                                                placeholder="vod_mid_roll"
                                                                value={this.state.vod_mid_roll || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>AIR DATE</ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="vod_year"
                                                                onChange={this.handleInputChange}
                                                                placeholder="vod_year"
                                                                value={this.state.vod_year || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>vod_description</ControlLabel>
                                                            <FormControl componentClass="textarea"
                                                                         type="text"
                                                                         rows={5}
                                                                         name="vod_description"
                                                                         onChange={this.handleInputChange}
                                                                         placeholder="Enter the description"
                                                                         value={this.state.vod_description || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Grid>
                                </div>
                            }
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button bsStyle="warning" fill onClick={this.handleSave}>
                            save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalEditEpisode = connect(mapStateToProps)(ModalEditEpisode);
const connectedModalEditEpisodeTrans = withNamespaces('common')(connectedModalEditEpisode);
export {connectedModalEditEpisodeTrans as ModalEditEpisode};