import React, {Component} from "react";
import locationMarker from "./pin.svg";
import MapGL, {Marker, Popup, NavigationControl} from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import connect from "react-redux/es/connect/connect";
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import {Link} from "react-router-dom";

const style = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center'
};

const style1k = {
    width: '40px',
    height: '40px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const style100k = {
    width: '60px',
    height: '60px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};


class MapBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewport: {
                width: 400,
                height: 400,
                latitude: process.env.REACT_APP_MAPBOX_LAT,
                longitude: process.env.REACT_APP_MAPBOX_LONG,
                zoom: process.env.REACT_APP_MAPBOX_ZOOM
            },
            wifiHotspots: [],
            displayPopUp: false,
            popUpData: null
        };
    }

    _onViewportChange = viewport => {

        if (viewport.longitude > 0) {
            viewport.longitude = 0;
        }

        this.setState({viewport});

        // console.log(viewport.latitude);
        // console.log(viewport.longitude);
    }

    componentDidMount() {
        this.fetchStationAPI();
    }

    fetchStationAPI = () => {

        let url = process.env.REACT_APP_API_URL + '/Reports/CustomersOnMap'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const chartData = res.data;

                this.setState({
                    wifiHotspots: chartData
                });

            });

    };

    loadWifiMarkers = () => {
        return this.state.wifiHotspots.map(spot => {
            return (
                <Marker
                    key={spot.user_id}
                    latitude={parseFloat(spot.user_lat)}
                    longitude={parseFloat(spot.user_long)}
                    // onClick={() => console.log(this)}
                >
                    <img
                        className={"location-icon"}
                        src={locationMarker}
                        height={"30px"}
                        width={"30px"}
                        onClick={() => this.initPopUp(spot)}
                        alt={""}
                    />

                </Marker>
            );
        });
    };

    initPopUp(spot) {
        // console.log(spot);
        this.setState({displayPopUp: true, popUpData: spot})
    }

    closePopUp() {
        this.setState({displayPopUp: false})
    }


    loadPopUp = () => {

        if (this.state.displayPopUp) {
            return (
                <Popup longitude={this.state.popUpData.user_long}
                       latitude={this.state.popUpData.user_lat}
                       closeButton={true}
                       closeOnClick={false}>

                    <div>
                        {"Customer: " + this.state.popUpData.user_id}
                        <br/>
                        {"Name: " + this.state.popUpData.user_name + " " + (this.state.popUpData.user_lastname === null ? "" : this.state.popUpData.user_lastname)}
                        <br/>
                        <Link to={"/edit-customer/" + this.state.popUpData.user_id}>more...</Link>
                    </div>


                </Popup>
            );
        }

    }

    renderMarkers = ({longitude, latitude, pointCount}) => {

        if (pointCount > 1000) {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style1k, background: '#f28a25'}}>{pointCount}</div>
                </Marker>
            );
        } else if (pointCount > 100000) {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style100k, background: '#f28a25'}}>{pointCount}</div>
                </Marker>
            );
        } else {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style, background: '#f28a25'}}>{pointCount}</div>
                </Marker>
            );
        }


    };

    render() {

        return (
            <div>
                <MapGL
                    style={{width: '100%', height: '500px'}}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    accessToken={process.env.REACT_APP_MAPBOX_KEY}
                    scrollZoom={false}
                    onViewportChange={this._onViewportChange}
                    onClick={this.closePopUp.bind(this)}
                    zoom={this.state.viewport.zoom}
                    {...this.state.viewport}
                >
                    <Cluster
                        radius={40}
                        extent={512}
                        nodeSize={64}
                        //component={ClusterMarker}>
                        component={this.renderMarkers}>

                        {this.loadWifiMarkers()}

                    </Cluster>

                    {this.loadPopUp()}

                    <NavigationControl showCompass showZoom position='top-right'/>

                </MapGL>

            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedMapBox = connect(mapStateToProps)(MapBox);
export {connectedMapBox as MapBox};

// export default MapBox;