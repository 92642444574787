import {
    deviceCheckBox,
    customerCheckBox,
    channelsCheckBox,
    tvodCheckBox,
    svodCheckBox,
    avodCheckBox,
    vodCategories,
    tvSeries,
    speedTestCheckBox,
    customerVodTicketsCheckBox,
    customerPpvTicketsCheckBox,
    customersPackageStatus,
    applicationsCheckBox,
    radioCheckBox,
    customersNpvrHistory,
    customersNpvrRecordings,
    musicCheckbox,
    karaokeCheckbox,
    easAlertsCheckbox
} from "../variables/ExportColumns";

const exportMain = {

    loadDevices: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = deviceCheckBox

        //SET URL
        tempGetURL = "/Exports/Devices"

        deviceCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-devices"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };

    },
    loadApplications: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //SET URL
        tempGetURL = "/Exports/Applications"
        tempArray = applicationsCheckBox

        applicationsCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-applications"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadRadio: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = radioCheckBox

        //SET URL
        tempGetURL = "/Exports/Radio"

        radioCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-radio"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadMusic: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = musicCheckbox

        //SET URL
        tempGetURL = "/Exports/Music"

        musicCheckbox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-music"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadKaraoke: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = karaokeCheckbox

        //SET URL
        tempGetURL = "/Exports/Karaoke"

        karaokeCheckbox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-karaoke"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadChannels: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = channelsCheckBox

        //SET URL
        tempGetURL = "/Exports/Channels"

        channelsCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-channels"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadTvod: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = tvodCheckBox

        //SET URL
        tempGetURL = "/Exports/Tvod"

        tvodCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-tvod"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadSvod: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = svodCheckBox

        //SET URL
        tempGetURL = "/Exports/Svod"

        svodCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-svod"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadAvod: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = avodCheckBox

        //SET URL
        tempGetURL = "/Exports/Avod"

        avodCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-avod"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadSeries: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = tvSeries

        //SET URL
        tempGetURL = "/Exports/SeriesAll"

        tvSeries.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-series-all"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadSeriesEpisodes: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        // tempArray = channelsCheckBox

        //SET URL
        tempGetURL = "/Exports/EpisodesAll"

        // channelsCheckBox.forEach((item) => {
        //
        //     if (item.defaultValue) {
        //         tempSelected[item.id] = true
        //     } else {
        //         tempSelected[item.id] = false
        //     }
        //
        // })

        tempFileName = "export-series-all"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadSeriesSeasons: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        // tempArray = channelsCheckBox

        //SET URL
        tempGetURL = "/Exports/SeasonAll"

        // channelsCheckBox.forEach((item) => {
        //
        //     if (item.defaultValue) {
        //         tempSelected[item.id] = true
        //     } else {
        //         tempSelected[item.id] = false
        //     }
        //
        // })

        tempFileName = "export-series-all"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadVodCategories: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = vodCategories

        //SET URL
        tempGetURL = "/Exports/VodCategories"

        vodCategories.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-vod-cat"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },

    loadCustomers: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customerCheckBox

        //SET URL
        tempGetURL = "/Exports/Customers"

        customerCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-customers"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomersPackageExpire: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        tempArray = customersPackageStatus
        customersPackageStatus.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempGetURL = "/Exports/CustomerPackageStatus"
        tempFileName = "export-customers-packages"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerDevices: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        tempGetURL = "/Exports/Customer/" + customer_id + "/Devices"
        tempFileName = "export-devices-" + customer_id + "_"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerSpeedTest: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = speedTestCheckBox

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/SpeedTest"

        speedTestCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-customer-speed-test"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerPpv: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customerPpvTicketsCheckBox

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/Ppv"

        customerPpvTicketsCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-ppv-cust-" + customer_id + "_"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerVod: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customerVodTicketsCheckBox

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/Vod"

        customerVodTicketsCheckBox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-vod-" + customer_id + "_";

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerNpvrJobs: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customersNpvrRecordings

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/Npvr"

        customersNpvrRecordings.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-npvr-jobs" + customer_id + "_"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerNpvrRecordingsForJob: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customersNpvrRecordings

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/Npvr"

        customersNpvrRecordings.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-npvr-jobs" + customer_id + "_"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadCustomerNpvrHIstory: function (customer_id) {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = customersNpvrHistory

        //SET URL
        tempGetURL = "/Exports/Customer/" + customer_id + "/Npvr/History"

        customersNpvrHistory.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-npvr"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },
    loadEasAlerts: function () {

        let tempArray = [];
        let tempSelected = [];
        let tempGetURL = "";
        let tempFileName = "";

        //PASS ARRAY
        tempArray = easAlertsCheckbox

        //SET URL
        tempGetURL = "/Exports/Alerts"

        easAlertsCheckbox.forEach((item) => {

            if (item.defaultValue) {
                tempSelected[item.id] = true
            } else {
                tempSelected[item.id] = false
            }

        })

        tempFileName = "export-alerts"

        return {
            tempArray: tempArray,
            tempSelected: tempSelected,
            tempGetURL: tempGetURL,
            tempFileName: tempFileName
        };
    },


}


export {
    exportMain
};