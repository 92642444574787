import React, {Component} from "react";
import {connect} from 'react-redux';
import {userActions} from '../../authentication/_actions';
import {Nav, NavDropdown, MenuItem, NavItem, Image} from "react-bootstrap";
import {history} from "../../authentication/_helpers";
import {withNamespaces} from "react-i18next"
import {NavBarClock} from "../CustomNavBarItems/NavBarClock"
import {NavBarWeather} from "../CustomNavBarItems/NavBarWeather"
// import NavBarEasAlert from "../CustomNavBarItems/NavBarEasAlert"
// import NavBarInternetConnection from "../CustomNavBarItems/NavBarInternetConnection"

class HeaderLinks extends Component {

    constructor(props) {
        super(props)
        const {i18n} = this.props
        this.state = {language: i18n.language}

        this.handleChangeLanguage = this.handleChangeLanguage.bind(this)
    }

    componentDidMount() {
        //this.props.dispatch(userActions.getAll());
    }

    handleClick() {
        //console.log("Clicked");
        //reset login status
        this.props.dispatch(userActions.logout());
        history.push('/user/login-page');

    }

    handleChangeLanguage(lng) {
        const {i18n} = this.props
        i18n.changeLanguage(lng)
        this.setState({language: lng})
        localStorage.setItem('language', lng);
    }

    render() {
        return (
            <div>
                <Nav pullRight>

                    <NavItem disabled>
                        <NavBarWeather/>
                    </NavItem>

                    <NavItem disabled>
                        <NavBarClock/>
                    </NavItem>

                    {/*<NavItem disabled>*/}
                        {/*<NavBarInternetConnection/>*/}
                    {/*</NavItem>*/}

                    <NavDropdown
                        eventKey={3}
                        title={<Image src={process.env.REACT_APP_ASSET_URL+"/flags/"+this.state.language.toUpperCase()+".png"} fluid="true" />}
                        id="language-nav-dropdown"
                    >
                        <MenuItem eventKey={3.1} onClick={() => this.handleChangeLanguage('en')}>English</MenuItem>
                        <MenuItem eventKey={3.2} onClick={() => this.handleChangeLanguage('es')}>Spanish</MenuItem>
                        <MenuItem eventKey={3.3} onClick={() => this.handleChangeLanguage('fr')}>French</MenuItem>
                    </NavDropdown>

                    {/*<NavBarEasAlert/>*/}

                    <NavDropdown
                        eventKey={4}
                        title={
                            <div>
                                <i className="fa fa-list"/>
                                <p className="hidden-md hidden-lg">
                                    More
                                    <b className="caret"/>
                                </p>
                            </div>
                        }
                        noCaret
                        id="basic-nav-dropdown-3"
                        bsClass="dropdown-with-icons dropdown"
                    >
                        <MenuItem eventKey={4.5} onClick={this.handleClick.bind(this)}>
                            <div className="text-danger">
                                <i className="pe-7s-close-circle"/> Log out
                            </div>
                        </MenuItem>

                    </NavDropdown>
                </Nav>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedHeaderLinkPage = connect(mapStateToProps)(HeaderLinks);
const connectedHeaderLinkPageTrans = withNamespaces('common')(connectedHeaderLinkPage)
export {connectedHeaderLinkPageTrans as HeaderLinks};
