import React, {Component} from "react";
import {Redirect} from "react-router";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import axios from "axios";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {ScaleLoader} from "react-spinners";
import {css} from "@emotion/core";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import googlePlay from "../../assets/img/google_play.png";
import apk from "../../assets/img/apk.png";
// import {DataApplicationDeviceMenu} from "../Middleware/DataApplicationDeviceMenu";
// import {DataApplicationDeviceSubMenu} from "../Middleware/DataApplicationDeviceSubMenu";
// import {DataDeviceMenu} from "../Middleware/DataDeviceMenu";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

class DeviceApkManagementComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,

            deviceSettingsObject: null,
            saveDeviceType: "",

            showModalSave: false,

            google_store_tv_version: null,
            google_store_tv_apk_url: null,
            google_tv_store_update: null,

            manual_store_tv_version: null,
            manual_store_tv_apk_url: null,
            manual_store_tv_update: null,

            amazon_store_tv_version: null,
            amazon_store_tv_apk_url: null,
            amazon_store_tv_update: null,

            android_mobile_device_type: null,
            android_mobile_terms_of_use: null,
            android_mobile_policy: null,
            android_mobile_version: null,

            android_tv_device_type: null,
            android_tv_terms_of_use: null,
            android_tv_policy: null,
            android_tv_version: null,

            roku_device_type: null,
            roku_terms_of_use: null,
            roku_policy: null,
            roku_version: null,

            web_device_type: null,
            web_terms_of_use: null,
            web_policy: null,
            web_version: null,

            ios_mobile_device_type: null,
            ios_mobile_terms_of_use: null,
            ios_mobile_policy: null,
            ios_mobile_version: null,

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.handleSelectedMenuItem = this.handleSelectedMenuItem.bind(this);

    }

    handleRadio = event => {

        const target = event.target;

        this.setState({
            [target.name]: target.value
        });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSelectedMenuItem(index, data) {

        if (data !== undefined && data !== null) {

            let menuId = data.original?.id;

            if (menuId !== undefined) {

                this.setState({selectedMenuId: menuId})
                //console.log(menuId)
            }
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.applicationId !== prevProps.applicationId) {
            this.loadApkData();
            this.loadPolicyAndTermsData();
        }
    }


    //LOAD APK
    loadApkData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        if (this.props.applicationId) {

            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-apk";

            axios
                .get(url, axiosConfig)
                .then(res => {

                    //console.log(res.data);
                    let apkArray = res.data;

                    for (let i = 0; i < apkArray.length; i++) {

                        if (apkArray[i].type === 'google') {
                            this.setState({
                                google_store_tv_version: apkArray[i].store_tv_version,
                                google_store_tv_apk_url: apkArray[i].store_tv_apk_url,
                                google_tv_store_update: apkArray[i].store_update,
                            });
                        }

                        if (apkArray[i].type === 'manual') {
                            this.setState({
                                manual_store_tv_version: apkArray[i].store_tv_version,
                                manual_store_tv_apk_url: apkArray[i].store_tv_apk_url,
                                manual_tv_store_update: apkArray[i].store_update,
                            });
                        }

                        if (apkArray[i].type === 'amazon') {
                            this.setState({
                                amazon_store_tv_version: apkArray[i].store_tv_version,
                                amazon_store_tv_apk_url: apkArray[i].store_tv_apk_url,
                                amazon_store_tv_update: apkArray[i].store_update,
                            });
                        }
                    }

                    this.setState({loading: false});

                });
        }
    }

    //LOAD
    loadPolicyAndTermsData() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        if (this.props.applicationId && this.props.applicationId !== 0) {

            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-terms";

            axios
                .get(url, axiosConfig)
                .then(res => {

                    let termsArray = res.data;
                    //console.log(termsArray);

                    for (let i = 0; i < termsArray.length; i++) {

                        //console.log((termsArray[i].device_type));

                        if (termsArray[i].device_type === 'Roku') {
                            this.setState({
                                roku_device_type: termsArray[i].device_type,
                                roku_terms_of_use: termsArray[i].terms_of_use,
                                roku_policy: termsArray[i].policy,
                                roku_version: termsArray[i].version,
                            });
                        }

                        if (termsArray[i].device_type === 'Android') {
                            this.setState({
                                android_mobile_device_type: termsArray[i].device_type,
                                android_mobile_terms_of_use: termsArray[i].terms_of_use,
                                android_mobile_policy: termsArray[i].policy,
                                android_mobile_version: termsArray[i].version,
                            });
                        }

                        if (termsArray[i].device_type === 'iOS') {
                            this.setState({
                                ios_mobile_device_type: termsArray[i].device_type,
                                ios_mobile_terms_of_use: termsArray[i].terms_of_use,
                                ios_mobile_policy: termsArray[i].policy,
                                ios_mobile_version: termsArray[i].version,
                            });
                        }

                        if (termsArray[i].device_type === 'AndroidTV') {
                            this.setState({
                                android_tv_device_type: termsArray[i].device_type,
                                android_tv_terms_of_use: termsArray[i].terms_of_use,
                                android_tv_policy: termsArray[i].policy,
                                android_tv_version: termsArray[i].version,
                            });
                        }

                        if (termsArray[i].device_type === 'Web') {
                            this.setState({
                                web_device_type: termsArray[i].device_type,
                                web_terms_of_use: termsArray[i].terms_of_use,
                                web_policy: termsArray[i].policy,
                                web_version: termsArray[i].version,
                            });
                        }
                    }


                    this.setState({
                        loading: false,
                    });

                });
        }

    }

    //UPDATE
    updatePolicyAndTerms() {

        if (this.props.applicationId && this.props.applicationId !== 0) {

            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-terms";

            this.setState({loading: true})
            let postData = null;
            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {
                    'X-Id-Token': token,
                    'Content-Type': 'application/json'
                }
            };

            postData = {
                type: "device-terms",
                data: [
                    {
                        device_type: 'Roku',
                        terms_of_use: this.state.roku_terms_of_use,
                        policy: this.state.roku_policy,
                        version: this.state.roku_version,
                        applicationId: this.props.applicationId
                    },
                    {
                        device_type: 'Android',
                        terms_of_use: this.state.android_mobile_terms_of_use,
                        policy: this.state.android_mobile_policy,
                        version: this.state.android_mobile_version,
                        applicationId: this.props.applicationId
                    },
                    {
                        device_type: 'AndroidTV',
                        terms_of_use: this.state.android_tv_terms_of_use,
                        policy: this.state.android_tv_policy,
                        version: this.state.android_tv_version,
                        applicationId: this.props.applicationId
                    },
                    {
                        device_type: 'iOS',
                        terms_of_use: this.state.ios_mobile_terms_of_use,
                        policy: this.state.ios_mobile_policy,
                        version: this.state.ios_mobile_version,
                        applicationId: this.props.applicationId
                    },
                    {
                        device_type: 'Web',
                        terms_of_use: this.state.web_terms_of_use,
                        policy: this.state.web_policy,
                        version: this.state.web_version,
                        applicationId: this.props.applicationId
                    }
                ]
            }


            axios.put(url, postData, axiosConfig)
                .then((res) => {
                    this.setState({showModalSave: false, loading: false})

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({showModalSave: false, loading: false})
                })

        }
    }

    updateAPKConfiguration() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-apk";

        let postData = null;

        postData = {

            data: [
                {
                    type: 'google',
                    applicationId: this.props.applicationId,
                    store_tv_version: this.state.google_store_tv_version,
                    store_tv_apk_url: this.state.google_store_tv_apk_url,
                    store_update: this.state.google_tv_store_update
                },
                {
                    type: 'amazon',
                    applicationId: this.props.applicationId,
                    store_tv_version: this.state.amazon_store_tv_version,
                    store_tv_apk_url: this.state.amazon_store_tv_apk_url,
                    store_update: this.state.amazon_store_tv_update
                },
                {
                    type: 'manual',
                    applicationId: this.props.applicationId,
                    store_tv_version: this.state.manual_store_tv_version,
                    store_tv_apk_url: this.state.manual_store_tv_apk_url,
                    store_update: this.state.manual_store_tv_update
                }
            ]
        }


        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //const menuItems = res.data;
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.updateAPKConfiguration()
            this.updatePolicyAndTerms()
        }
    }

    renderSaveModal = () => {

        if (this.state.showModalSave) {
            return <ModalSave
                headerMessage={"Save Settings: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
            />
        }
    }

    renderRedirect = () => {

        let path = "/settings-app-management";

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

    }

    render() {
        return (
            <div>

                {this.renderSaveModal()}
                {this.renderRedirect()}


                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col sm={12}>
                                        <PanelGroup accordion id="device-settings-panel"
                                        >

                                            <Panel eventKey="2">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-android text-success"/> Android
                                                        Terms & Policy <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_mobile_device_type</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="android_mobile_device_type"
                                                                        type="text"
                                                                        disabled
                                                                        name="android_mobile_device_type"
                                                                        value={this.state.android_mobile_device_type || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_mobile_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="version"
                                                                        type="text"
                                                                        name="android_mobile_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_mobile_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>terms_of_use</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="android_mobile_terms_of_use"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="android_mobile_terms_of_use"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_mobile_terms_of_use || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>policy</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="android_mobile_policy"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="android_mobile_policy"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_mobile_policy || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>

                                                    </Row>
                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="3">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-apple text-default"/> iOS Terms & Policy
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>ios_mobile_device_type</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="ios_mobile_device_type"
                                                                        type="text"
                                                                        disabled
                                                                        name="ios_mobile_device_type"
                                                                        value={this.state.ios_mobile_device_type || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>ios_mobile_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="ios_mobile_version"
                                                                        type="text"
                                                                        name="ios_mobile_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.ios_mobile_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>ios_mobile_terms_of_use</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="ios_mobile_terms_of_use"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="ios_mobile_terms_of_use"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.ios_mobile_terms_of_use || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>ios_mobile_policy</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="ios_mobile_policy"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="ios_mobile_policy"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.ios_mobile_policy || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="4">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-amazon text-warning"/> Android
                                                        TV Terms & Policy
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_tv_device_type</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="android_tv_device_type"
                                                                        type="text"
                                                                        disabled
                                                                        name="android_tv_device_type"
                                                                        value={this.state.android_tv_device_type || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_tv_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="android_tv_version"
                                                                        type="text"
                                                                        name="android_tv_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_tv_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_tv_terms_of_use</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="android_tv_terms_of_use"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="android_tv_terms_of_use"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_tv_terms_of_use || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>android_tv_policy</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="android_tv_policy"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="android_tv_policy"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.android_tv_policy || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>

                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="5">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-television text-roku"/> Roku Terms & Policy
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>roku_device_type</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="roku_device_type"
                                                                        type="text"
                                                                        disabled
                                                                        name="roku_device_type"
                                                                        value={this.state.roku_device_type || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>roku_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="roku_version"
                                                                        type="text"
                                                                        name="roku_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.roku_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>roku_terms_of_use</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="roku_terms_of_use"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="roku_terms_of_use"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.roku_terms_of_use || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>roku_policy</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="roku_policy"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="roku_policy"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.roku_policy || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="6">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-globe"/> Web Terms & Policy
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>Device Type</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="web_device_type"
                                                                        type="text"
                                                                        disabled
                                                                        name="web_device_type"
                                                                        value={this.state.web_device_type || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>Version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="web_version"
                                                                        type="text"
                                                                        name="web_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.web_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>Terms of Use</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="web_terms_of_use"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="web_terms_of_use"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.web_terms_of_use || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>Policy</ControlLabel>
                                                                    <FormControl
                                                                        componentClass="textarea"
                                                                        placeholder="web_policy"
                                                                        rows={10}
                                                                        type="text"
                                                                        name="web_policy"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.web_policy || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>
                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showModalSave: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({redirect: true})}
                            >
                                Back
                            </Button>
                        </div>
                    }

                />

                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col sm={12}>
                                        <PanelGroup accordion id="apk-settings-panel">

                                            <Panel eventKey="6">
                                                <Panel.Heading>
                                                    <Panel.Title toggle>
                                                        <i className="text-info">
                                                            <img
                                                                src={googlePlay}
                                                                style={{maxHeight: '25px', maxWidth: '90%'}}
                                                                alt={"googlePlay"}
                                                            />
                                                        </i>
                                                        Google Play
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>


                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>TYPE</ControlLabel>
                                                                    <FormControl
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        name="google_type"
                                                                        value={"GOOGLE"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>google_store_tv_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="google_store_tv_version"
                                                                        type="text"
                                                                        name="google_store_tv_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.google_store_tv_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>google_store_tv_apk_url</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="google_store_tv_apk_url"
                                                                        type="text"
                                                                        name="google_store_tv_apk_url"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.google_store_tv_apk_url || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>google_tv_store_update</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="google_tv_store_update"
                                                                        type="text"
                                                                        name="google_tv_store_update"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.google_tv_store_update || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="7">
                                                <Panel.Heading>
                                                    <Panel.Title toggle>
                                                        <i className="text-info">
                                                            <img
                                                                src={apk}
                                                                style={{maxHeight: '25px', maxWidth: '90%'}}
                                                                alt={"apk"}
                                                            />
                                                        </i>
                                                        Store
                                                        <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>TYPE</ControlLabel>
                                                                    <FormControl
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        name="manual_store_type"
                                                                        value={"MANUAL"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>manual_store_tv_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="manual_store_tv_version"
                                                                        type="text"
                                                                        name="manual_store_tv_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.manual_store_tv_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>manual_store_tv_apk_url</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="manual_store_tv_apk_url"
                                                                        type="text"
                                                                        name="manual_store_tv_apk_url"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.manual_store_tv_apk_url || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>manual_store_tv_update</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="manual_store_tv_update"
                                                                        type="text"
                                                                        name="manual_store_tv_update"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.manual_store_tv_update || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="8">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-amazon text-warning"/> Amazon <b
                                                        className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>TYPE</ControlLabel>
                                                                    <FormControl
                                                                        placeholder=""
                                                                        type="text"
                                                                        disabled
                                                                        name="amazon_store_type"
                                                                        value={"AMAZON"}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>amazon_store_tv_version</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="amazon_store_tv_version"
                                                                        type="text"
                                                                        name="amazon_store_tv_version"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.amazon_store_tv_version || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={12}>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>amazon_store_tv_apk_url</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="amazon_store_tv_apk_url"
                                                                        type="text"
                                                                        name="amazon_store_tv_apk_url"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.amazon_store_tv_apk_url || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <ControlLabel>amazon_store_tv_update</ControlLabel>
                                                                    <FormControl
                                                                        placeholder="amazon_store_tv_update"
                                                                        type="text"
                                                                        name="amazon_store_tv_update"
                                                                        onChange={this.handleInputChange}
                                                                        value={this.state.amazon_store_tv_update || ''}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Col>
                                                    </Row>


                                                </Panel.Body>
                                            </Panel>

                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="info" fill wd
                                    name={"saveButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({showModalSave: true})}
                            >
                                Save
                            </Button>
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({redirect: true})}
                            >
                                Back
                            </Button>
                        </div>
                    }
                />


            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDeviceApkManagementComponent = connect(mapStateToProps)(DeviceApkManagementComponent);
const connectedDeviceApkManagementComponentTrans = withNamespaces('common')(connectedDeviceApkManagementComponent);
export {connectedDeviceApkManagementComponentTrans as DeviceApkManagementComponent};
