import React from "react";
import axios from "axios";
import {connect} from 'react-redux';
import {userActions} from '../../authentication/_actions';
import Button from "../CustomButton/CustomButton.jsx";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import ModalDelete from "../CustomModals/ModalDelete";
import ModalMusicPlayer from "../CustomModals/ModalMusicPlayer";
import {ModalMusicTrack} from "../CustomModals/ModalMusicTrack";
import moment from "moment";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";

class DataMusicTracks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            remove_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),
            preview_tooltip: (<Tooltip id="preview_tooltip">Listen</Tooltip>),
            edit_tooltip: (<Tooltip id="edit_tooltip">Edit</Tooltip>),

            showDeleteModal: false,
            showAddEditMusicTrackModal: false,
            showMusicPlayerModal: false,

            album_streaming_url: null,
            newTrack: false,

            track_id: 1,
            loading: false,
            albumTracks: [],
            columns: [],
            trackData: null

        };

    }

    componentDidMount() {

        //console.log(this.props.handlerID);
        if (this.props.handlerID === null) {
            this.loadMiddlewareColumns()
        }
    }

    loadMiddlewareColumns() {

        this.setState({
            columns: [
                {
                    accessor: "music_track_position",
                    Header: "#",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "music_track_name",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "music_track_duration",
                    Header: "Duration",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <span>
                            {this.formatDuration(row.value)}
                        </span>
                    )
                },
                {
                    Header: 'actions',
                    maxWidth: 120,
                    sortable: false,
                    filterable: false,
                    accessor: "id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setPlayButton(row)}
                            {this.setHelperButton(row)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }
            ]
        })
    }

    loadTempColumns() {
        this.setState({
            columns: [
                {
                    accessor: "music_track_position",
                    Header: "#",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "name",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "duration",
                    Header: "Duration",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false
                },
                {
                    Header: 'actions',
                    maxWidth: 120,
                    sortable: false,
                    filterable: false,
                    accessor: "id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setPlayButton(row)}
                            {this.setHelperButton(row)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }
            ]
        })
    }

    loadMusicTracksFromMiddleware() {

        this.setState({loading: true});
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        let dataUrl = process.env.REACT_APP_API_URL + "/Music/tracks/" + this.props.handlerID;

        axios.get(dataUrl, axiosConfig).then(response => {
            const albumTracks = response.data;
            const loading = false;
            this.setState({albumTracks, loading});
        }).catch(error => {
            this.setState({loading: false});
            this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

    }

    formatDuration(date) {

        return moment.utc(date * 1000).format('HH:mm:ss');

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.handlerID !== prevProps.handlerID) {
            //this.loadMiddlewareColumns()
            this.loadMusicTracksFromMiddleware()
            //console.log(this.props.handlerID);
        }

        if (this.props.handlerMbidStatus !== prevProps.handlerMbidStatus) {
            if (this.props.handlerMbidStatus) {
                this.loadTempColumns()
                this.setState({albumTracks: this.props.handlerTempTracks});
            }
        }

    }

    //BUTTONS
    setHelperButton(trackData) {

        return (
            <OverlayTrigger placement="top" overlay={this.state.edit_tooltip}>
                <Button
                    onClick={() => this.setState({
                        trackData: trackData,
                        showAddEditMusicTrackModal: true,
                        newTrack: false
                    })}

                    bsStyle="info"
                    simple
                    icon
                >
                    <i className="fa fa-edit"/>
                </Button>
            </OverlayTrigger>
        )
    }

    setPlayButton(trackData) {

        return (
            <OverlayTrigger placement="top" overlay={this.state.preview_tooltip}>
                <Button
                    onClick={() => this.setState({trackData: trackData, showMusicPlayerModal: true})}

                    bsStyle="success"
                    simple
                    icon
                >
                    <i className="fa fa-play"/>
                </Button>
            </OverlayTrigger>
        )
    }

    setDeleteButton(track_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_tooltip}>
            <Button
                onClick={() => this.setState({track_id: track_id, showDeleteModal: true})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    deleteMusicTrack(track_id) {

        const deleteURL = process.env.REACT_APP_API_URL + "/Music/track/" + track_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                let array = [...this.state.albumTracks]; // make a separate copy of the array
                const index = array.indexOf(track_id)
                array.splice(index, 1);
                this.setState({albumTracks: array});
                this.loadMusicTracksFromMiddleware();
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());

            })


    }

    setDisplayDeleteModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteMusicTrack(id)
        }

    }

    setDisplayMusicPlayerModal = () => {

        this.setState({
            showMusicPlayerModal: false
        })

    }

    setDisplayAddEditMusicModal = (id, value, action) => {

        this.setState({
            showAddEditMusicTrackModal: false,
            newTrack: false,
            track_id: null
        })

        if (action === "reload") {
            this.loadMusicTracksFromMiddleware()
        }

    }

    renderDeleteMusicTrackModal = () => {

        let bodyMessageVar = "this action will remove the music track. This action can be undone";

        if (this.state.showDeleteModal) {
            //console.log("Clicked Delete Modal...");
            return <ModalDelete
                headerMessage={"Delete Music Track"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayDeleteModal}
                handlerID={this.state.track_id}
            />
        }
    }

    renderMusicPlayerModal = () => {

        if (this.state.showMusicPlayerModal) {
            return <ModalMusicPlayer
                headerMessage={"Playing..."}
                bodyMessage={""}
                handlerFromParent={this.setDisplayMusicPlayerModal}
                handlerID={this.state.track_id}
            />
        }
    }

    addMusicTrack() {
        this.setState({showAddEditMusicTrackModal: true, newTrack: true, trackData: null})
    }

    renderAddEditMusicTrackModal = () => {

        let headerMessage = "";
        let bodyMessageVar = "";

        if (this.state.newTrack) {
            headerMessage = "Add New Track"
        } else {
            headerMessage = "Edit track - "
        }

        if (this.state.showAddEditMusicTrackModal) {

            return <ModalMusicTrack
                headerMessage={headerMessage}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayAddEditMusicModal}
                handlerID={this.props.handlerID}
                handlerState={this.state.newTrack}
                handlerObject={this.state.trackData}
            />
        }
    }

    renderAddNewTrackButton = () => {

        //ALBUM IS NOT FROM LASTF FM
        if (this.props.handlerMbidID === null) {
            return (
                <div>
                    <Button
                        bsStyle="success" fill
                        onClick={this.addMusicTrack.bind(this)}>
                        Add New Track
                    </Button>
                    <br/>
                </div>
            )
        }

        //ALBUM IS FROM LASTF FM BUT HAS NO TRACKS
        if (this.props.handlerMbidID !== null && this.state.albumTracks.length === 0) {
            return (
                <div>
                    <Button
                        bsStyle="success" fill
                        onClick={this.addMusicTrack.bind(this)}>
                        Add New Track
                    </Button>
                    <br/>
                </div>
            )
        }


    }

    render() {

        return (
            <div>
                {this.renderDeleteMusicTrackModal()}
                {this.renderAddEditMusicTrackModal()}
                {this.renderMusicPlayerModal()}
                {this.renderAddNewTrackButton()}

                <ReactTableV6Simple
                    data={this.state.albumTracks}
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={10}
                />

            </div>
        );

    }


}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedDataMusicTracks = connect(mapStateToProps)(DataMusicTracks);
export {connectedDataMusicTracks as DataMusicTracks};