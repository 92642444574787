import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownResolution extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: false,
            resolution: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);

    }

    componentDidMount() {

        const url = process.env.REACT_APP_API_URL+"/Channels/resolution";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {

                const resolution = res.data;
                const loading = false;
                this.setState({resolution, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    render() {
        return (
            <Select
                placeholder="Resolution Select"
                name="resolutionSelect"
                autoFocus={false}
                multi={false}
                value={this.props.resolutionSelected}
                options={this.state.resolution}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownResolution = connect(mapStateToProps)(DropDownResolution);
const connectedDropDownResolutionTrans = withNamespaces('common')(connectedDropDownResolution);
export {connectedDropDownResolutionTrans as DropDownResolution};

