import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import {store} from 'authentication/_helpers';
import {App} from 'views/Main/App';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/custom.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/weather-icons.min.css";
import "./components/Tmdb/search.css";

import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";

// i18next.init({
//     interpolation: {escapeValue: false},  // React already does escaping
// });
//

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        fr: {
            common: common_fr
        },
        es: {
            common: common_es
        },
    },
});

render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <App/>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);
