import React from "react";
import axios from "axios/index";
import connect from "react-redux/es/connect/connect";
import {userActions} from "../../../authentication/_actions";
import {Bar} from 'react-chartjs-2';
import {withNamespaces} from "react-i18next";

class ChartUserRegistration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            tempData: {}
        };

    }

    componentDidMount() {

        this.updateData();
    }

    updateChart(data_current, data_previous) {

        let labels = this.props.t('charts_user_registration.month_array', {
            framework: "react-i18next",
            returnObjects: true
        })

        let current_year = new Date().getFullYear()
        let previous_year = current_year - 1

        let tempData = {
            labels: labels,
            datasets: [
                {
                    label: this.props.t('charts_user_registration.registrations_text', {framework: "react-i18next"}) + " " + previous_year,
                    borderColor: 'rgba(192, 192, 192, 1)',
                    //backgroundColor: 'rgba(192, 192, 192, 0.8)',
                    backgroundColor: '#fd8609',
                    data: data_previous
                },
                {
                    label: this.props.t('charts_user_registration.registrations_text', {framework: "react-i18next"}) + " " + current_year,
                    borderColor: 'rgba(75, 192, 192, 1)',
                    //backgroundColor: 'rgba(75, 192, 192, 0.8)',
                    backgroundColor: '#4555b0',
                    data: data_current
                }
            ]
        };

        this.setState({tempData});
    }

    updateData() {

        let url = process.env.REACT_APP_API_URL + '/Reports/UserRegistrationsYearMonth'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                let current_year = new Date().getFullYear()
                let previous_year = current_year - 1


                let data_current = [];
                let data_previous = [];

                if (res.data[current_year] !== undefined) {

                    for (let i = 0; i < res.data[current_year].length; i++) {

                        data_current.push(res.data[current_year][i].NewRegistrations)
                    }
                }

                if (res.data[previous_year] !== undefined) {

                    for (let i = 0; i < res.data[previous_year].length; i++) {

                        data_previous.push(res.data[previous_year][i].NewRegistrations)
                    }
                }


                this.updateChart(data_current, data_previous);

            });

    }


    render() {
        return (
            <div className="ChartGraphUserRegistration">
                <Bar
                    data={this.state.tempData}
                    width={200}
                    height={300}
                    options={{
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                left: 50,
                                right: 50
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedChartUserRegistration = connect(mapStateToProps)(ChartUserRegistration);
const connectedChartUserRegistrationTrans = withNamespaces('common')(connectedChartUserRegistration);
export {connectedChartUserRegistrationTrans as ChartUserRegistration};