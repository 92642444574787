import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid,  Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import {userActions} from "../../authentication/_actions";

class ModalMusicTrack extends Component {

    constructor(props) {
        super(props);

        this.state = {

            showModalMusicTrack: true,

            music_album_id: null,
            music_track_duration: null,
            music_track_lyrics: null,
            music_track_name: null,
            music_track_offline_download_url: null,
            music_track_position: null,
            music_track_streaming_url: null,
            track_id: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleClose = () => {
        this.setState({showModalMusicTrack: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    manageSaveTrack() {

        if (this.props.handlerState === true) {
            this.createTrack();
        } else {
            this.editTrack();
        }
    }

    createTrack() {

        const url = process.env.REACT_APP_API_URL + "/Music";

        let postData = {
            music_album: {
                type: "create_music_track",
                music_track_position: this.state.music_track_position,
                music_track_name: this.state.music_track_name,
                music_track_duration: this.state.music_track_duration,
                music_track_streaming_url: this.state.music_track_streaming_url,
                music_track_offline_download_url: this.state.music_track_offline_download_url,
                music_track_lyrics: this.state.music_track_lyrics,
                music_album_id: this.props.handlerID
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                this.props.handlerFromParent(this.props.handlerID, false, "reload");

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })
    }

    editTrack() {

        const url = process.env.REACT_APP_API_URL + "/Music";

        let postData = {
            music_album: {
                type: "update_music_track",
                music_track_id: this.state.track_id,
                music_track_position: this.state.music_track_position,
                music_track_name: this.state.music_track_name,
                music_track_duration: this.state.music_track_duration,
                music_track_streaming_url: this.state.music_track_streaming_url,
                music_track_offline_download_url: this.state.music_track_offline_download_url,
                music_track_lyrics: this.state.music_track_lyrics,
                music_album_id: this.state.music_album_id
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //this.setState({loading: false})
                this.setState({showModalMusicTrack: false})
                this.props.handlerFromParent(this.props.handlerID, false, "reload");
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })
    }

    componentDidMount() {

        console.log(this.props.handlerObject)

        if (this.props.handlerState === false) {

            let music_album_id = this.props.handlerObject.original.music_album_id
            let music_track_duration = this.props.handlerObject.original.music_track_duration
            let music_track_lyrics = this.props.handlerObject.original.music_track_lyrics
            let music_track_name = this.props.handlerObject.original.music_track_name
            let music_track_offline_download_url = this.props.handlerObject.original.music_track_offline_download_url
            let music_track_position = this.props.handlerObject.original.music_track_position
            let music_track_streaming_url = this.props.handlerObject.original.music_track_streaming_url
            let track_id = this.props.handlerObject.original.id


            this.setState(
                {
                    music_album_id,
                    music_track_duration,
                    music_track_lyrics,
                    music_track_name,
                    music_track_offline_download_url,
                    music_track_position,
                    music_track_streaming_url,
                    track_id
                }
            )

        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'music_track_duration') {
            let mac = this.formatDuration(value)
            this.setState({
                [name]: mac
            });
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    //pattern="^[0-9]{2}:[0-9]{2}$"
    formatDuration(userInput) {

        let durationFormat = userInput;

        // Remove all but alphanumeric characters
        durationFormat = durationFormat.replace(/\D/ig, '');

        // Append a colon after every two characters
        durationFormat = durationFormat.replace(/(.{2})/g, "$1:");

        console.log(durationFormat)
        return durationFormat;

    }

    render() {
        return (
            <div>
                <Modal show={this.state.showModalMusicTrack} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        {/*<Modal.Title>{this.props.headerMessage}</Modal.Title>*/}
                        <h5 style={{marginBottom: '5px'}}>
                            {this.props.headerMessage +""+this.state.music_track_name}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={2}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>ID</ControlLabel>
                                            <FormControl
                                                name="music_album_id"
                                                value={this.state.music_album_id || ''}
                                                disabled
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                                <Col sm={1}>
                                </Col>
                                <Col sm={9}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Track Name</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="music_track_name"
                                                value={this.state.music_track_name || ''}
                                                placeholder=""
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={5}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Position</ControlLabel>
                                            <FormControl
                                                type="number"
                                                name="music_track_position"
                                                value={this.state.music_track_position || ''}
                                                placeholder="0"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                                <Col sm={1}>
                                </Col>
                                <Col sm={5}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Duration</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="music_track_duration"
                                                value={this.state.music_track_duration || ''}
                                                placeholder="00:00"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Streaming URL</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="music_track_streaming_url"
                                                value={this.state.music_track_streaming_url || ''}
                                                placeholder="https://"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Offline URL</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="music_track_offline_download_url"
                                                value={this.state.music_track_offline_download_url || ''}
                                                placeholder="https://"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Lyrics</ControlLabel>
                                            <FormControl
                                                componentClass="textarea"
                                                type="text"
                                                name="music_track_lyrics"
                                                value={this.state.music_track_lyrics || ''}
                                                rows={5}
                                                placeholder="..."
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>


                        </Grid>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="primary" fill onClick={this.manageSaveTrack.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalMusicTrack = connect(mapStateToProps)(ModalMusicTrack);
const connectedModalMusicTrackTrans = withNamespaces('common')(connectedModalMusicTrack);
export {connectedModalMusicTrackTrans as ModalMusicTrack};