import React, {Component} from "react";
import {ControlLabel, FormControl, FormGroup, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {playlistArray} from "../../variables/PlaylistVariables"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

class ModalPlaylist extends Component {

    constructor(props) {
        super(props);

        this.state = {

            copy_tooltip: (<Tooltip id="copy_tooltip">Copy url to clipboard</Tooltip>),
            download_tooltip: (<Tooltip id="download_tooltip">Download Playlist</Tooltip>),
            email_tooltip: (<Tooltip id="email_tooltip">Email Playlist to customer</Tooltip>),

            showModalPlaylist: true,
            playlistUrl: null,
            customerPlaylistUrl: null,

            _notificationSystem: null
        };

    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

    }

    handleClose = () => {
        this.setState({showModalPlaylist: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    downloadPlaylist() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let dataUrl = this.state.playlistUrl;
        //console.log(dataUrl)

        if(dataUrl !== null){

            fetch(dataUrl, {
                method: 'GET',
                headers: {'X-Id-Token': token}
            })
                .then(response => {

                    let headerLine = response.headers.get('content-disposition')
                    let filename = "";

                    const regex = /filename=(.+)/; // match 'filename=' and capture everything that follows
                    let parseFilename = headerLine.match(regex);
                    if (parseFilename) {
                        filename = parseFilename[1];  // captured group, in this case, '7'
                    }

                    if(filename !== ""){
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = filename;
                            a.click();

                            this.notification("download")
                        });
                    }

                });
        }else{
            this.notification("failed_download")
        }

    }

    onSelect(event) {
        //console.log(event.target.value)

        let playlistUrl = process.env.REACT_APP_HOME_URL + "/api/middleware/Playlist/" + event.target.value + "/" + this.props.customer;
        let customerPlaylistUrl = process.env.REACT_APP_HOME_URL + "/api/ui/playlist/v1/Playlist/" + event.target.value + '';

        this.setState({playlistUrl, customerPlaylistUrl})
    }

    notification(status) {

        if (status === "clipboard") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Playlist copied</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "email") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Email send</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "download") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Downloading....</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed_download") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Failed, please select an option</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 5,
            });
        }

    }

    handleCopyToClipboard(){

        this.setState({copy:true})
        this.notification("clipboard")
    }

    handleEmailPlaylist(){

        this.notification("email")
    }

    renderCustomerExport = () => {

        if(this.props.handlerSource === "customer"){
            return (
                <FormGroup>
                    <ControlLabel style={{'marginTop': '10px'}}>Customer Playlist</ControlLabel>
                    <FormControl placeholder="playlist" style={{'marginTop': '10px'}}
                                 type="text"
                                 disabled
                                 name="user_address_number"
                                 onChange={this.handleInputChange}
                                 value={this.state.customerPlaylistUrl || ""}
                    />

                    <CopyToClipboard text={this.state.customerPlaylistUrl}
                                     onCopy={this.handleCopyToClipboard.bind(this)}>
                        <OverlayTrigger placement="top" overlay={this.state.copy_tooltip}>
                            <Button style={{'marginTop': '10px'}}>
                                <i className="fa fa-copy"/>
                            </Button>
                        </OverlayTrigger>
                    </CopyToClipboard>

                    <OverlayTrigger placement="top" overlay={this.state.email_tooltip}>
                        <Button
                            style={{'marginTop': '10px'}}
                            onClick={this.handleEmailPlaylist.bind(this)}>

                            <i className="fa fa-envelope"/>
                        </Button>
                    </OverlayTrigger>


                </FormGroup>
            )
        }

    }

    render() {
        return (
            <div>

                <NotificationSystem ref="notificationSystem" style={style}/>

                <Modal show={this.state.showModalPlaylist} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{"Download Playlist"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-12">
                            <select id="download_type" className="form-control" data-toggle="select1"
                                    onChange={this.onSelect.bind(this)}>
                                <option value="">Select an ouput format:</option>

                                {
                                    playlistArray.map((playlists) => {
                                        return (
                                            <optgroup label={playlists.label} key={playlists.id}>
                                                <option value={playlists.value_ott}>{playlists.label_ott}</option>
                                                <option value={playlists.value_iptv}>{playlists.label_iptv}</option>
                                            </optgroup>
                                        );
                                    })
                                }

                            </select>
                        </div>

                        <div className="col-12" style={{'marginTop': '10px'}}>

                            <FormGroup>
                                <ControlLabel style={{'marginTop': '10px'}}>Test Playlist</ControlLabel>
                                <FormControl placeholder="playlist" style={{'marginTop': '10px'}}
                                             type="text"
                                             disabled
                                             name="user_address_number"
                                             onChange={this.handleInputChange}
                                             value={this.state.playlistUrl || ""}
                                />

                                <CopyToClipboard text={this.state.playlistUrl}
                                                 onCopy={this.handleCopyToClipboard.bind(this)}>
                                    <OverlayTrigger placement="top" overlay={this.state.copy_tooltip}>
                                        <Button style={{'marginTop': '10px'}}>
                                            <i className="fa fa-copy"/>
                                        </Button>
                                    </OverlayTrigger>
                                </CopyToClipboard>

                                <OverlayTrigger placement="top" overlay={this.state.download_tooltip}>
                                    <Button
                                        style={{'marginTop': '10px'}}
                                        onClick={this.downloadPlaylist.bind(this)}>
                                        <i className="fa fa-download"/>
                                    </Button>
                                </OverlayTrigger>

                            </FormGroup>

                            {this.renderCustomerExport()}

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalPlaylist = connect(mapStateToProps)(ModalPlaylist);
const connectedModalPlaylistTrans = withNamespaces('common')(connectedModalPlaylist);
export {connectedModalPlaylistTrans as ModalPlaylist};