import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditKaraoke extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            karaokeArray: [],

            loading: false,
            redirect: false,

            back_button_text: "Cancel",

            showSaveModal: false,
            karaokeRedirect: false,
            karaokeStatus: false,

            karaoke_id: null,
            karaoke_status: null,
            karaoke_position: null,
            karaoke_title: null,
            karaoke_artist: null,
            karaoke_media_url: null,
            karaoke_poster_image: null,
            karaoke_wide_image: null,
            karaoke_genre: null,
            karaoke_pre_roll: null,

            karaoke_poster_image_temp: false,
            karaoke_poster_image_folder_temp: "",

            karaoke_wide_image_temp: false,
            karaoke_wide_image_folder_temp: "",

            karaoke_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Karaoke/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition();
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const karaokepArray = res.data[0];
                    const loading = false;

                    let karaokeStatus = karaokepArray.karaoke_status === 1

                    this.setState({
                        back_button_text: "Back",
                        karaokepArray,
                        karaokeStatus,
                        loading,

                        karaoke_id: karaokepArray.karaoke_id,
                        karaoke_status: karaokeStatus,
                        karaoke_position: karaokepArray.karaoke_position,
                        karaoke_title: karaokepArray.karaoke_title,
                        karaoke_artist: karaokepArray.karaoke_artist,
                        karaoke_media_url: karaokepArray.karaoke_media_url,
                        karaoke_poster_image: karaokepArray.karaoke_poster_image,
                        karaoke_wide_image: karaokepArray.karaoke_wide_image,
                        karaoke_genre: karaokepArray.karaoke_genre,
                        karaoke_pre_roll: karaokepArray.karaoke_pre_roll,
                        current_user: user,
                        user_content_provider: karaokepArray.karaoke_content_provider,
                        custom_data: karaokepArray.custom_data

                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });
        }


    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Karaoke/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {
                const karaokeArray = res.data[0];
                this.setState({
                    karaoke_position: karaokeArray.KaraokePosition
                });
            })
            .catch(error => {
                this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'karaokeStatus') {
            this.setState({karaokeStatus: state})
        }
    }

    submitPosterImage(filename_poster) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.karaoke_poster_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.karaoke_poster_image_folder_temp);
            bodyFormData.set('location', "karaoke_list");
            bodyFormData.set('filename', filename_poster);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({karaoke_poster_image_temp: false})
                })
                .catch((err) => {
                    console.log("karaoke_image - AXIOS ERROR: ", err);
                })
        }

    }

    submitWideImage(filename_wide) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.karaoke_wide_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.karaoke_wide_image_folder_temp);
            bodyFormData.set('location', "karaoke_list");
            bodyFormData.set('filename', filename_wide);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({karaoke_wide_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    updateKaraoke() {


        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Karaoke/" + handle;

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""
        if (this.state.karaoke_wide_image_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.karaoke_wide_image
            this.setState({karaoke_wide_image: filename_wide})
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.karaoke_wide_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""
        if (this.state.karaoke_poster_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.karaoke_poster_image
            this.setState({karaoke_poster_image: filename_poster})
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.karaoke_poster_image
        }


        let karaokeStatus = this.state.karaokeStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            karaoke: {
                karaoke_id: this.state.karaoke_id,
                karaoke_status: karaokeStatus,
                karaoke_position: this.state.karaoke_position,
                karaoke_title: this.state.karaoke_title,
                karaoke_artist: this.state.karaoke_artist,
                karaoke_media_url: this.state.karaoke_media_url,
                karaoke_genre: this.state.karaoke_genre,
                karaoke_pre_roll: this.state.karaoke_pre_roll,
                karaoke_poster_image: filename_poster,
                karaoke_wide_image: filename_wide,
                karaoke_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    createKaraoke() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Karaoke";

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""
        if (this.state.karaoke_wide_image_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.karaoke_wide_image
            this.setState({karaoke_wide_image: filename_wide})
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.karaoke_wide_image
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""
        if (this.state.karaoke_poster_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.karaoke_poster_image
            this.setState({karaoke_poster_image: filename_poster})
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.karaoke_poster_image
        }


        let karaokeStatus = this.state.karaokeStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            karaoke: {
                karaoke_status: karaokeStatus,
                karaoke_position: this.state.karaoke_position,
                karaoke_title: this.state.karaoke_title,
                karaoke_artist: this.state.karaoke_artist,
                karaoke_media_url: this.state.karaoke_media_url,
                karaoke_genre: this.state.karaoke_genre,
                karaoke_pre_roll: this.state.karaoke_pre_roll,
                karaoke_poster_image: filename_poster,
                karaoke_wide_image: filename_wide,
                karaoke_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                const karaokeArray = res.data;
                this.setState({loading: false, karaoke_id: karaokeArray.karaoke_id})
                this.setKarokeRedirect();
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        console.log("" + folder);
        console.log("" + filename);
        console.log("" + imagePosition);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "karaoke_image") {
            this.setState({
                karaoke_poster_image_temp: filename !== null,
                karaoke_poster_image: filename,
                karaoke_poster_image_folder_temp: folder
            });
        }
        if (imagePosition === "karaoke_image_wide") {
            this.setState({
                karaoke_wide_image_temp: filename !== null,
                karaoke_wide_image: filename,
                karaoke_wide_image_folder_temp: folder
            });
        }


    }

    cancelKarokeEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setKarokeRedirect = () => {
        this.setState({
            karaokeRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/karaoke";
        let pathKaraoke = "/edit-karaoke/" + this.state.karaoke_id;

        if (this.state.redirect) {
            this.setState({
                redirect: false,
                karaokeRedirect: false
            })
            return <Redirect push to={path}/>
        }

        if (this.state.karaokeRedirect) {
            this.setState({
                redirect: false,
                karaokeRedirect: false
            })
            return <Redirect push to={pathKaraoke}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createKaraoke();
            } else {
                this.updateKaraoke();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Karaoke: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.karaoke_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Karaoke has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Karaoke</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                // textCenter
                                title="Karaoke Title Information"
                                // category=""
                                // tableFullWidth
                                content={
                                    <div>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <FormGroup style={{maxWidth: "300px",maxHeight: "300px"}}>
                                                        <ControlLabel>Normal</ControlLabel>
                                                        <ImageUpload
                                                            imageName={this.state.karaoke_poster_image}
                                                            imageType="karaoke_list"
                                                            imagePosition={"karaoke_image"}
                                                            imageTemp={this.state.karaoke_poster_image_temp}
                                                            imageFolderTemp={this.state.karaoke_poster_image_folder_temp}
                                                            handlerFromParent={this.handleChildImageSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6}>
                                                    <FormGroup style={{maxWidth: "300px",maxHeight: "300px"}}>
                                                        <ControlLabel>Wide</ControlLabel>
                                                        <ImageUpload
                                                            imageName={this.state.karaoke_wide_image}
                                                            imageType="karaoke_list"
                                                            imagePosition={"karaoke_image_wide"}
                                                            imageTemp={this.state.karaoke_wide_image_temp}
                                                            imageFolderTemp={this.state.karaoke_wide_image_folder_temp}
                                                            handlerFromParent={this.handleChildImageSelect}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Grid>

                                        <legend/>

                                        <FormGroup>
                                            <p className="category">Status</p>
                                            <Switch
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                name='karaokeStatus'
                                                disabled={this.state.loading}
                                                value={this.state.karaokeStatus}
                                                defaultValue={false}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">Position</p>
                                            <FormControl placeholder="Enter the position"
                                                         type="number"
                                                         name="karaoke_position"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.karaoke_position || ""}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">Title</p>
                                            <FormControl placeholder="Enter the title"
                                                         type="text"
                                                         name="karaoke_title"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.karaoke_title || ""}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">Artist</p>
                                            <FormControl placeholder="Enter the Artist"
                                                         type="text"
                                                         name="karaoke_artist"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.karaoke_artist || ""}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">Genre</p>
                                            <FormControl placeholder="Enter the genre"
                                                         type="text"
                                                         name="karaoke_genre"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.karaoke_genre || ""}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">MEDIA URL</p>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill default><i
                                                        className="fa fa-info"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter the media url"
                                                             type="text"
                                                             name="karaoke_media_url"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.karaoke_media_url || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <p className="category">PRE ROLL</p>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill default><i
                                                        className="fa fa-info"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter the pre roll url"
                                                             type="text"
                                                             name="karaoke_pre_roll"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.karaoke_pre_roll || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        {this.renderContentProvider()}
                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelKarokeEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditKaraoke = connect(mapStateToProps)(EditKaraoke);
const connectedEditKaraokeTrans = withNamespaces('common')(connectedEditKaraoke);
export {connectedEditKaraokeTrans as EditKaraoke};
