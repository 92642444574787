import { middlewareConstants } from '../_constants';

export function middleware(state = {}, action) {
  switch (action.type) {
    case middlewareConstants.REFRESH:
      return {
        type: 'middleware-refresh',
        message: action.message
      };
    case middlewareConstants.CLEAR:
      return {};
    default:
      return state
  }
}