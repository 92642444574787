import React, {Component} from "react";
import axios from "axios/index";
import DualListBox from 'react-dual-listbox';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {css} from "@emotion/core";
import {BeatLoader} from "react-spinners";

const override = css`
    position: absolute;
    left: 47%;
    top: 30%;
    display: block;
    z-index: 1000;
    `;

class DualListBoxPackageEvent extends Component {

    constructor(props) {
        super(props);

        this.handleChannelSelect = this.handleChannelSelect.bind(this);

        this.state = {
            loading: false,

            availableEvents: [],
            selectedArray: [],
            eventProvider: null
        };
        this.onChange = this.onChange.bind(this);

    }

    handleChannelSelect(selected) {
        this.props.handlerFromParent(selected);
    }

    onChange(selected) {

        this.setState({selectedArray: selected});
        this.handleChannelSelect(selected)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.eventProvider !== prevProps.eventProvider) {
            this.loadAvailableEventsFromMiddleware()
        }
    }

    componentDidMount() {
        this.loadAvailableEventsFromMiddleware();
    }

    loadAvailableEventsFromMiddleware() {

        const url = process.env.REACT_APP_API_URL + "/PackageEvent/events?provider=" + this.props.eventProvider;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };


        axios
            .get(url, axiosConfig)
            .then(res => {

                let temp = res.data;

                let availableEvents = [];
                let obj = {};

                for (let i = 0; i < temp.length; i++) {

                    obj = {
                        value: temp[i].value.toString(),
                        label: temp[i].label

                    }
                    availableEvents.push(obj)
                }

                const loading = false;

                this.setState({availableEvents, loading});
            })
            .catch(error => {
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    render() {
        return (
            <div>

                <div className="sweet-loader">
                    <BeatLoader
                        css={override}
                        size={5}
                        color={'#2c67a4'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <DualListBox
                    options={this.state.availableEvents}
                    canFilter
                    availableLabel={"Available"}
                    selectedLabel={"Selected"}
                    showHeaderLabels
                    simpleValue={true}
                    selected={this.props.eventsInPackage}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDualListBoxPackageEvent = connect(mapStateToProps)(DualListBoxPackageEvent);
const connectedDualListBoxPackageEventTrans = withNamespaces('common')(connectedDualListBoxPackageEvent);
export {connectedDualListBoxPackageEventTrans as DualListBoxPackageEvent};
