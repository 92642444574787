import React from "react";
import axios from "axios";
import Button from "../CustomButton/CustomButton.jsx";
import {Redirect} from 'react-router'
// import {ExportExcelButton} from "../CustomExport/ExportExcelButton";
import {ExportData} from "../CustomExport/ExportData";
import {Col, Grid, OverlayTrigger, Row, Tooltip, Badge} from "react-bootstrap";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";
import {reactCustom} from "../../functions/GlobalFunctions";

class DataCustomersPackageStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            edit_tooltip: (<Tooltip id="redit_tooltip">Edit</Tooltip>),

            redirect: false,
            user_id: null,
            loading: true,
            customerArray: [],
            lastUpdate: null,
            columns: []
        };

    }

    componentDidMount() {

        this.loadReactLabels()
        this.loadMiddlewareData()
    }

    loadReactLabels() {

        this.setState({
            columns: [
                {
                    accessor: "user_id",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "user_status",
                    Header: "Account Status",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <Badge fill="true" style={row.value === 1 ? {
                                background: '#87cb16',
                                borderRadius: '2px',
                                lineHeight: "2"
                            } : {background: '#545557', borderRadius: '2px',lineHeight: "2"}}>
                                {
                                    row.value === 1 ? 'Active' : 'Disabled'
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    accessor: "user_name",
                    Header: "user_name",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "user_username",
                    Header: "user_username",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "user_email",
                    Header: "user_email",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "PackageStatus",
                    Header: "Status",
                    sortable: true,
                    filterable: false,
                    Cell: ({row, original}) => (
                        <div className="center">
                            <Badge fill="true" style={
                                reactCustom.getExpirationStatus(original.DaysToExpire) === "success" ? {
                                        background: '#77c413',
                                        borderRadius: '2px',
                                        lineHeight: "2",
                                        width: "110px"
                                    } :
                                    reactCustom.getExpirationStatus(original.DaysToExpire) === "warning-1" ? {
                                            background: '#FFC312',
                                            borderRadius: '2px',
                                            lineHeight: "2",
                                            width: "110px"
                                        } :
                                        reactCustom.getExpirationStatus(original.DaysToExpire) === "warning-2" ? {
                                                background: '#EE5A24',
                                                borderRadius: '2px',
                                                lineHeight: "2",
                                                width: "110px"
                                            } :
                                            reactCustom.getExpirationStatus(original.DaysToExpire) === "danger" ? {
                                                background: '#f7263b',
                                                borderRadius: '2px',
                                                lineHeight: "2",
                                                width: "110px"
                                            } : {
                                                background: '#f7263b',
                                                borderRadius: '2px',
                                                lineHeight: "2",
                                                width: "110px"
                                            }}
                            >
                                {
                                    original.PackageStatus
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    Header: 'actions',
                    maxWidth: 200,
                    sortable: false,
                    filterable: false,
                    accessor: "user_id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                        </div>
                    )
                }
            ],
        });
    }

    loadMiddlewareData(){

        this.setState({loading: true});

        let dataUrl = process.env.REACT_APP_API_URL+"/Customer/PackageStatus";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(dataUrl, axiosConfig)
            .then(res => {
                const customerArray = res.data;
                const loading = false;
                this.setState({customerArray,loading});
            })
            .catch(error =>  {
                this.setState({loading: false});
                console.log(error);
            });
    }

    //BUTTONS
    setHelperButton(user_id) {

            return (<OverlayTrigger placement="top" overlay={this.state.edit_tooltip}>
                <Button
                    onClick={() => {
                        this.setState({user_id: user_id})
                        this.setRedirect();
                    }}

                    bsStyle="info"
                    simple
                    icon
                >
                    <i className="fa fa-edit"/>
                </Button>
            </OverlayTrigger>)

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/edit-customer/" + this.state.user_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }
    }

    render() {
        return (
            <div>
                {this.renderRedirect()}
                <div>
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <div className="pull-left">
                                    <ExportData
                                        listState={this.props.listState}
                                        exportDataFor={"customers_expire"}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>

                <p/>

                <div>
                    <ReactTableV6Simple
                        data={this.state.customerArray}
                        loading={this.state.loading}
                        columns={this.state.columns}
                        defaultPageSize={5}
                    />

                </div>

            </div>
        );

    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataCustomersPackageStatus= connect(mapStateToProps)(DataCustomersPackageStatus);
const connectedDataCustomersPackageStatusTrans = withNamespaces('common')(connectedDataCustomersPackageStatus);
export {connectedDataCustomersPackageStatusTrans as DataCustomersPackageStatus};