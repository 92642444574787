import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import Switch from "react-bootstrap-switch";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ModalEditSeason extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditSeason: true,

            tmdb_status: false,
            vodSeasonStatusSwitch: false,
            vod_series_id: null,

            vodSeasonArray: [],
            vod_seasons_id: null,
            vod_seasons_status: null,
            vod_seasons_name: null,
            vod_seasons_number: null,
            vod_seasons_episode_count: null,
            vod_seasons_air_date: null,
            vod_seasons_tmdb_id: null,
            vod_seasons_imdb_id: null,
            vod_seasons_overview: null,
            vod_seasons_poster_path: null,
            vod_seasons_type: null,
            vod_seasons_series_id: null,
            vod_seasons_trailer_url: null,

            vod_seasons_poster_path_temp: false,
            vod_seasons_poster_path_folder_temp: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'vodSeasonStatusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'vodSeasonStatusSwitch') {
            this.setState({vodSeasonStatusSwitch: state})
        }
    }

    handleClose = () => {
        this.setState({showEditSeason: false})
        this.props.handlerFromParent(false, "nothing");
    }

    handleSave = () => {

        if (this.props.handlerType === "edit") {
            this.updateSeason()
        } else {
            this.createSeason()
        }
        this.setState({showEditSeason: false})
        this.props.handlerFromParent(false, "save");
    }

    componentDidMount() {

        if (this.props.handlerType === "edit" && this.props.handlerID !== 0) {
            this.loadFromMiddlewareSeasonData(this.props.handlerID)
        }

    }


    //LOAD FROM MIDDLEWARE
    loadFromMiddlewareSeasonData(vod_seasons_id) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason/season/" + vod_seasons_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const vodSeasonArray = res.data[0];
                const loading = false;

                let vodSeasonStatusSwitch = vodSeasonArray.vod_seasons_status === 1

                this.setState({
                    vodSeasonArray: vodSeasonArray,
                    loading,
                    vodSeasonStatusSwitch,
                    vod_seasons_status: vodSeasonArray.vod_seasons_status,
                    vod_seasons_name: vodSeasonArray.vod_seasons_name,
                    vod_seasons_number: vodSeasonArray.vod_seasons_number,
                    vod_seasons_episode_count: vodSeasonArray.vod_seasons_episode_count,
                    vod_seasons_air_date: vodSeasonArray.vod_seasons_air_date,
                    vod_seasons_tmdb_id: vodSeasonArray.vod_seasons_tmdb_id,
                    vod_seasons_imdb_id: vodSeasonArray.vod_seasons_imdb_id,
                    vod_seasons_overview: vodSeasonArray.vod_seasons_overview,
                    vod_seasons_poster_path: vodSeasonArray.vod_seasons_poster_path,
                    vod_seasons_type: vodSeasonArray.vod_seasons_type,
                    vod_seasons_series_id: vodSeasonArray.vod_seasons_series_id,
                    vod_seasons_trailer_url: vodSeasonArray.vod_seasons_trailer_url,

                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    createSeason() {

        this.submitPosterImage()

        const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason";

        let vodSeasonStatusSwitch = this.state.vodSeasonStatusSwitch === true ? 1 : 0

        let postData = {
            vod_season: {
                vod_seasons_status: vodSeasonStatusSwitch,
                vod_seasons_name: this.state.vod_seasons_name,
                vod_seasons_number: this.state.vod_seasons_number,
                vod_seasons_episode_count: this.state.vod_seasons_episode_count,
                vod_seasons_air_date: this.state.vod_seasons_air_date,
                vod_seasons_tmdb_id: this.state.vod_seasons_tmdb_id,
                vod_seasons_imdb_id: this.state.vod_seasons_imdb_id,
                vod_seasons_overview: this.state.vod_seasons_overview,
                vod_seasons_poster_path: this.state.vod_seasons_poster_path,
                vod_seasons_type: this.state.vod_seasons_type,
                vod_seasons_trailer_url: this.state.vod_seasons_trailer_url,
                vod_seasons_series_id: this.props.handlerSeriesID,

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };


        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    updateSeason() {

        this.submitPosterImage()

        const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason";

        let vodSeasonStatusSwitch = this.state.vodSeasonStatusSwitch === true ? 1 : 0

        let postData = {
            vod_season: {
                vod_seasons_id: this.props.handlerID,
                vod_seasons_status: vodSeasonStatusSwitch,
                vod_seasons_name: this.state.vod_seasons_name,
                vod_seasons_number: this.state.vod_seasons_number,
                vod_seasons_episode_count: this.state.vod_seasons_episode_count,
                vod_seasons_air_date: this.state.vod_seasons_air_date,
                vod_seasons_tmdb_id: this.state.vod_seasons_tmdb_id,
                vod_seasons_imdb_id: this.state.vod_seasons_imdb_id,
                vod_seasons_overview: this.state.vod_seasons_overview,
                vod_seasons_poster_path: this.state.vod_seasons_poster_path,
                vod_seasons_type: this.state.vod_seasons_type,
                vod_seasons_trailer_url: this.state.vod_seasons_trailer_url,
                vod_seasons_series_id: this.props.handlerSeriesID,

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };


        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "vod_seasons_poster_path") {
            this.setState({
                vod_seasons_poster_path_temp: true,
                vod_seasons_poster_path: filename,
                vod_seasons_poster_path_folder_temp: folder
            });
        }

    }

    submitPosterImage() {

        if (this.state.vod_seasons_poster_path_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_seasons_poster_path_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', this.state.vod_seasons_poster_path);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_seasons_poster_path_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    render() {
        return (
            <div className="modal-edit-season">
                <Modal show={this.state.showEditSeason} onHide={this.handleClose} bsSize="large">
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        {/*<Modal.Title>{this.props.headerMessage + " - " + this.props.handlerID}</Modal.Title>*/}
                        <h5 style={{marginBottom: '5px'}}>{this.props.headerMessage + " - " + this.props.handlerID}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        {/*<p> {this.props.bodyMessage}</p>*/}
                        <h5>{this.state.vod_seasons_name || ""} - General Settings</h5>
                        <legend/>
                        {/*<legend>{this.state.vod_series_name_long || ""} - General Settings</legend>*/}
                        <Grid fluid>
                            <Row>
                                <Col sm={4}>
                                    <ImageUpload
                                        imageName={this.state.vod_seasons_poster_path}
                                        imageType="vod_series"
                                        imagePosition={"vod_seasons_poster_path"}
                                        imageSubLocation={"vod_season"}
                                        imageTemp={this.state.vod_seasons_poster_path_temp}
                                        imageFolderTemp={this.state.vod_seasons_poster_path_folder_temp}
                                        imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                        handlerFromParent={this.handleChildImageSelect}
                                        imageTmdbType={"poster_path"}

                                    />
                                </Col>
                                <Col sm={8}>
                                    <Row>
                                        <Col sm={12}>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <p className="category">Status</p>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    defaultValue={false}
                                                    name='vodSeasonStatusSwitch'
                                                    value={this.state.vodSeasonStatusSwitch}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Position</ControlLabel>
                                                <FormControl placeholder="Position"
                                                             type="number"
                                                             name={"vod_seasons_number"}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.vod_seasons_number || 0}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Season Name</ControlLabel>
                                                <FormControl placeholder="Enter Name of season"
                                                             type="text"
                                                             onChange={this.handleInputChange}
                                                             name="vod_seasons_name"
                                                             value={this.state.vod_seasons_name || ""}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Episode Count</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    name="vod_seasons_episode_count"
                                                    onChange={this.handleInputChange}
                                                    placeholder="Episode Count"
                                                    value={this.state.vod_seasons_episode_count || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Air Date</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="vod_seasons_air_date"
                                                    onChange={this.handleInputChange}
                                                    placeholder="air date"
                                                    value={this.state.vod_seasons_air_date || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Trailer</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="vod_seasons_trailer_url"
                                                    onChange={this.handleInputChange}
                                                    placeholder="enter the trailer url"
                                                    value={this.state.vod_seasons_trailer_url || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <ControlLabel>Overview</ControlLabel>
                                                <FormControl componentClass="textarea"
                                                             type="text"
                                                             rows={5}
                                                             name="vod_seasons_overview"
                                                             onChange={this.handleInputChange}
                                                             placeholder="Enter the description"
                                                             value={this.state.vod_seasons_overview || ""}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Grid>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button bsStyle="warning" fill onClick={this.handleSave}>
                            save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
            ;
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalEditSeason = connect(mapStateToProps)(ModalEditSeason);
const connectedModalEditSeasonTrans = withNamespaces('common')(connectedModalEditSeason);
export {connectedModalEditSeasonTrans as ModalEditSeason};
