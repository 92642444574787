import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, HelpBlock, ButtonGroup, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {DropDownRadioCategory} from "../../components/CustomDropDown/DropDownRadioCategory";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import InputGroup from "react-bootstrap/es/InputGroup";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditRadio extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            radioArray: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",

            radioRedirect: false,
            radioStatus: false,
            showSaveModal: false,

            radio_cat_id: null,
            radio_id: null,
            radio_status: false,
            radio_position: null,
            radio_name_short: null,
            radio_name_long: null,
            radio_description: null,
            radio_rpgroup_id: null,
            radio_url: null,
            radio_pre_roll: null,
            radio_mid_roll: null,
            radio_post_roll: null,
            radio_ref_id: null,
            radio_image: null,
            radio_hits: null,
            radio_country_lock: null,
            radio_user_added: null,
            radio_date_added: null,
            radio_date_updated: null,
            radio_date_deleted: null,
            radio_user_deleted: null,
            radio_user_updated: null,

            radio_image_temp: false,
            radio_image_wide_temp: false,

            radio_image_folder_temp: "",
            radio_image_wide_folder_temp: "",

            //VALIDATIONS
            radio_position_validate_state: null,
            radio_name_short_validate_state: null,
            radio_name_long_validate_state: null,

            radio_position_message: "",
            radio_name_short_message: "",
            radio_name_long_message: "",

            radio_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }


    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Radio/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition();
        } else {

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    if (res.data.error === 401) {
                        this.props.dispatch(userActions.logoutUnauthorized());
                    }

                    const radioArray = res.data[0];
                    const loading = false;

                    let radioStatus = radioArray.radio_status === 0 ? false : true;

                    this.setState({
                        back_button_text: "Back",
                        radioArray,
                        radioStatus,
                        loading,

                        radio_id: radioArray.radio_id,
                        radio_status: radioStatus,
                        radio_position: radioArray.radio_position,
                        radio_name_short: radioArray.radio_name_short,
                        radio_name_long: radioArray.radio_name_long,
                        radio_description: radioArray.radio_description,
                        radio_url: radioArray.radio_url,
                        radio_pre_roll: radioArray.radio_pre_roll,
                        radio_mid_roll: radioArray.radio_mid_roll,
                        radio_post_roll: radioArray.radio_post_roll,
                        radio_image: radioArray.radio_image,
                        radio_image_wide: radioArray.radio_image_wide,
                        radio_cat_id: radioArray.radio_cat_id,
                        current_user: user,
                        user_content_provider: radioArray.radio_content_provider,
                        custom_data: radioArray.custom_data,


                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                });
        }

    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/Radio/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const radioPositionData = res.data[0];

                this.setState({
                    radio_position: radioPositionData.RadioPosition

                });
            })
            .catch(error => {
                console.log(error);
                //this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    handleChildCategorySelect(data) {

        console.log(data);
        this.setState({
            radio_cat_id: data === null ? 0 : data.value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'radioStatus') {
            this.setState({radioStatus: state})
        }
    }

    submitPosterImage(filename_poster) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.radio_image_temp) {

            //let filename = this.state.radio_id + "_" + this.state.radio_image;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.radio_image_folder_temp);
            bodyFormData.set('location', "live_radio");
            bodyFormData.set('filename', filename_poster);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({radio_image_temp: false})
                })
                .catch((err) => {
                    console.log("live_radio - AXIOS ERROR: ", err);
                })
        }


    }

    submitWideImage(filename_wide) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.radio_image_wide_temp) {

            //let filename = this.state.radio_id + "_" + this.state.radio_image_wide;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.radio_image_wide_folder_temp);
            bodyFormData.set('location', "live_radio");
            bodyFormData.set('filename', filename_wide);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({radio_image_wide_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    updateRadio() {

        //this.submitAllImages();

        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Radio/" + handle;

        let radio_status = this.state.radioStatus === true ? 1 : 0;

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.radio_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.radio_image_wide
            this.setState({radio_image_wide: filename_wide})
            console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.radio_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.radio_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.radio_image
            this.setState({radio_image: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.radio_image
        }

        // Send a PUT request
        var postData = {
            radio: {
                radio_id: this.state.radio_id,
                radio_status: radio_status,
                radio_position: this.state.radio_position,
                radio_name_short: this.state.radio_name_short,
                radio_name_long: this.state.radio_name_long,
                radio_description: this.state.radio_description,
                radio_url: this.state.radio_url,
                radio_pre_roll: this.state.radio_pre_roll,
                radio_mid_roll: this.state.radio_mid_roll,
                radio_post_roll: this.state.radio_post_roll,
                radio_image: filename_poster,
                radio_image_wide: filename_wide,
                radio_cat_id: this.state.radio_cat_id,
                radio_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                if (res.data.error === 301) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 302) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 303) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 304) {
                    this.notification("failed")
                    this.setState({
                        radio_name_long_message: res.data.message,
                        radio_name_long_validate_state: "error",
                        radio_name_short_message: "",
                        radio_name_short_validate_state: null
                    })
                } else {
                    this.notification("success")
                    this.setState({
                        radio_name_short_message: "",
                        radio_name_short_validate_state: null,
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })

                }

                this.setState({saving: false})
                this.setState({loading: false})

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
                //this.props.dispatch(userActions.logoutUnauthorized())
            })

    }

    createRadio() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Radio";

        let radio_status = this.state.radioStatus === true ? 1 : 0;

        //RENAME FUNCTIONS START POSTER WIDE
        let filename_wide = ""

        if (this.state.radio_image_wide_temp) {
            let timeUTC = new Date().getTime()
            filename_wide = timeUTC + "_" + this.state.radio_image_wide
            this.setState({radio_image_wide: filename_wide})
            console.log("*****" + filename_wide)
            this.submitWideImage(filename_wide);
        } else {
            filename_wide = this.state.radio_image_wide
        }

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.radio_image_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.radio_image
            this.setState({radio_image: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.radio_image
        }


        var postData = {
            radio: {
                radio_status: radio_status,
                radio_position: this.state.radio_position,
                radio_name_short: this.state.radio_name_short,
                radio_name_long: this.state.radio_name_long,
                radio_description: this.state.radio_description,
                radio_url: this.state.radio_url,
                radio_pre_roll: this.state.radio_pre_roll,
                radio_mid_roll: this.state.radio_mid_roll,
                radio_post_roll: this.state.radio_post_roll,
                radio_image: filename_poster,
                radio_image_wide: filename_wide,
                radio_cat_id: this.state.radio_cat_id,
                radio_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.setState({saving: false})
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 302) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 303) {
                    this.notification("failed")
                    this.setState({
                        radio_name_short_message: res.data.message,
                        radio_name_short_validate_state: "error",
                        radio_name_long_message: "",
                        radio_name_long_validate_state: null
                    })
                } else if (res.data.error === 304) {
                    this.notification("failed")
                    this.setState({
                        radio_name_long_message: res.data.message,
                        radio_name_long_validate_state: "error",
                        radio_name_short_message: "",
                        radio_name_short_validate_state: null
                    })
                } else {
                    const radio = res.data;
                    this.setState({
                            radio_id: radio.radio_id,
                            radio_name_short_message: "",
                            radio_name_short_validate_state: null,
                            radio_name_long_message: "",
                            radio_name_long_validate_state: null
                        }
                    )
                    this.notification("success")
                    this.setRadioRedirect();
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.notification("failed")
                //this.props.dispatch(userActions.logoutUnauthorized())
            })

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        console.log("" + folder);
        console.log("" + filename);
        console.log("" + imagePosition);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "radio_image") {
            this.setState({
                radio_image_temp: filename !== null,
                radio_image: filename,
                radio_image_folder_temp: folder
            });
        }
        if (imagePosition === "radio_image_wide") {
            this.setState({
                radio_image_wide_temp: filename !== null,
                radio_image_wide: filename,
                radio_image_wide_folder_temp: folder
            });
        }


    }

    cancelRadioEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setRadioRedirect = () => {
        this.setState({
            radioRedirect: true,
            back_button_text: "Back"
        })
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createRadio();
            } else {
                this.updateRadio();
            }
        }
    }

    renderRedirect = () => {

        let path = "/live/radio";
        let pathRadio = "/edit-radio/" + this.state.radio_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.radioRedirect) {
            return <Redirect push to={pathRadio}/>
        }

    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Radio Station: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.radio_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Radio Station has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update radio station</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <FormGroup>
                            <ControlLabel>Content Provider</ControlLabel>
                            <DropDownContentProviders
                                contentProviderSelected={this.state.user_content_provider}
                                name='contentProviderSelect'
                                handlerFromParent={this.handleChildContentProviderSelect}
                                disabled={this.state.loading}
                            />
                        </FormGroup>
                    </Row>

                )
            }
        }

    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>
                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>

                    <Row>

                        <Col md={8}>

                            <Card
                                // title={<legend>Radio Station - Configuration</legend>}
                                // tableFullWidth
                                content={
                                    <div>
                                        <h5>Radio Station - Configuration</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        {/*<ControlLabel>{this.props.t('radio_edit.radioStatus', {framework: "react-i18next"})}</ControlLabel>*/}
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='radioStatus'
                                                            disabled={this.state.loading}
                                                            value={this.state.radioStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        controlId="formValidationPosition"
                                                        validationState={this.state.radio_position_validate_state}
                                                    >
                                                        <ControlLabel>{this.props.t('radio_edit.radio_position', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('radio_edit.radio_position_placeholder', {framework: "react-i18next"})}
                                                            type="number"
                                                            name="radio_position"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.radio_position || ""}
                                                        />
                                                        <HelpBlock>{this.state.radio_position_message}</HelpBlock>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        controlId="formValidationShortName"
                                                        validationState={this.state.radio_name_short_validate_state}
                                                    >
                                                        <ControlLabel>{this.props.t('radio_edit.radio_name_short', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('radio_edit.radio_name_short', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="radio_name_short"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.radio_name_short || ""}
                                                        />
                                                        <HelpBlock>{this.state.radio_name_short_message}</HelpBlock>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup
                                                        controlId="formValidationLongName"
                                                        validationState={this.state.radio_name_long_validate_state}
                                                    >
                                                        <ControlLabel>{this.props.t('radio_edit.radio_name_long', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('radio_edit.radio_name_long_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="radio_name_long"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.radio_name_long || ""}
                                                        />
                                                        <HelpBlock>{this.state.radio_name_long_message}</HelpBlock>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('radio_edit.radio_description', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('radio_edit.radio_description_placeholder', {framework: "react-i18next"})}
                                                            componentClass="textarea"
                                                            type="text"
                                                            rows={3}
                                                            name="radio_description"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.radio_description || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('radio_edit.radio_cat_id', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownRadioCategory
                                                            categorySelected={this.state.radio_cat_id}
                                                            name='categorySelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildCategorySelect}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('radio_edit.radio_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="music"/>
                                                            </InputGroup.Addon>
                                                            <input
                                                                placeholder={this.props.t('radio_edit.radio_url', {framework: "react-i18next"})}
                                                                className="form-control"
                                                                type="text"
                                                                name="radio_url"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.radio_url || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                                {this.renderContentProvider()}

                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                            <Card
                                // title={<legend>VAST Configuration</legend>}
                                // tableFullWidth
                                content={
                                    <div>
                                        <h5>VAST Configuration</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('radio_edit.radio_pre_roll', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-right"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('radio_edit.radio_pre_roll_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="radio_pre_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.radio_pre_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('radio_edit.radio_mid_roll', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-vertical"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('radio_edit.radio_mid_roll_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="radio_mid_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.radio_mid_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>POST Roll</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                                <Glyphicon glyph="object-align-left"/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder="Enter post roll url"
                                                                type="text"
                                                                name="radio_post_roll"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.radio_post_roll || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                            </Col>
                                        </Grid>
                                    </div>
                                }
                            />

                        </Col>

                        <Col md={4}>
                            <Row>
                                <Card
                                    // tableFullWidth
                                    content={
                                        <div id="out-test" className="text-center center center-block">
                                            <div id="test" style={{textAlignLast: "center"}}>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => this.setState({showSaveModal: true})}
                                                        bsStyle="info"
                                                        fill wd
                                                        disabled={this.state.loading}
                                                        name={"saveButton"}>
                                                        {this.props.t('global.save', {framework: "react-i18next"})}
                                                    </Button>

                                                    <Button bsStyle="default"
                                                            fill wd
                                                            name={"cancelButton"}
                                                            disabled={this.state.loading}
                                                            onClick={this.cancelRadioEdit.bind(this)}>
                                                        {this.state.back_button_text}
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>
                                    }
                                />
                            </Row>

                            <Row>
                                <Card
                                    // title={<legend>Assets</legend>}
                                    // tableFullWidth
                                    content={
                                        <div>
                                            <h5>Assets</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.image_normal', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.radio_image}
                                                                imageType="live_radio"
                                                                imagePosition={"radio_image"}
                                                                imageTemp={this.state.radio_image_temp}
                                                                imageFolderTemp={this.state.radio_image_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.image_wide', {framework: "react-i18next"})}</ControlLabel>
                                                            <ImageUpload
                                                                imageName={this.state.radio_image_wide}
                                                                imageType="live_radio"
                                                                imagePosition={"radio_image_wide"}
                                                                imageTemp={this.state.radio_image_wide_temp}
                                                                imageFolderTemp={this.state.radio_image_wide_folder_temp}
                                                                handlerFromParent={this.handleChildImageSelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />
                            </Row>
                        </Col>

                    </Row>

                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedEditRadio = connect(mapStateToProps)(EditRadio);
const connectedEditRadioTrans = withNamespaces('common')(connectedEditRadio);
export {connectedEditRadioTrans as EditRadio};
