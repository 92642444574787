import React, {Component} from "react";
import {Col, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {DataApplicationDeviceMenu} from "../../components/Middleware/DataApplicationDeviceMenu";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {ScaleLoader} from "react-spinners";
import {css} from "@emotion/core";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {Redirect} from "react-router";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;


class DeviceMenuComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,

            showModalSave: false,
            selectedMenuId: null,
            selectedMenuType: null,
            activeKey: null,

        }

        this.handleSelectedMenuItem = this.handleSelectedMenuItem.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

    }

    handleSelect(activeKey) {
        this.setState({activeKey, selectedMenuId: null});
    }

    handleSelectedMenuItem(index, data, action, type) {

        console.log(data)

        if (action === 'rowSelected') {
            console.log(action)
            if (data !== undefined && data !== null) {

                let menuId = data.original?.id;
                let menuType = data.original?.menu_item_type;

                if (menuId !== undefined) {
                    this.setState({selectedMenuId: menuId, selectedMenuType: menuType})
                }
            }
        }

        if (action === 'rowUpdated') {
            console.log(action)
            console.log(this.state.selectedMenuId)
            console.log(this.state.selectedMenuType)
            this.setState({selectedMenuType: type})
        }


    }

    renderRedirect = () => {

        let path = "/settings-app-management";

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

    }

    renderDeviceMenu = (deviceType) => {

        return (
            <DataApplicationDeviceMenu
                deviceType={deviceType}
                applicationId={this.props.applicationId}
                handlerFromParent={this.handleSelectedMenuItem}
            />
        );
    }

    render() {
        return (
            <div>

                {this.renderRedirect()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Card
                    content={
                        <div>
                            <Grid fluid>
                                <Row>
                                    <Col sm={12}>
                                        <PanelGroup accordion id="device-settings-panel"
                                                    defaultActiveKey="2" onSelect={this.handleSelect}>

                                            <Panel eventKey="2">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-android text-success"/> Android
                                                        Menu <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>
                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>

                                                        <Col md={12}>
                                                            {this.renderDeviceMenu("android_mobile")}
                                                        </Col>

                                                    </Row>
                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="3">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-apple text-default"/> iOS
                                                        Settings <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                    <Row>
                                                        <Col md={12}>
                                                            {this.renderDeviceMenu("ios_mobile")}
                                                        </Col>

                                                    </Row>

                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="4">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-amazon text-warning"/> Android
                                                        TV
                                                        Settings <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>

                                                    <Row>
                                                        <Col md={12}>
                                                            {this.renderDeviceMenu("android_tv")}
                                                        </Col>

                                                    </Row>

                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="5">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-television text-roku"/> Roku
                                                        Settings <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>

                                                        <Col md={12}>
                                                            {this.renderDeviceMenu("roku")}
                                                        </Col>
                                                    </Row>

                                                </Panel.Body>
                                            </Panel>

                                            <Panel eventKey="6">
                                                <Panel.Heading>
                                                    <Panel.Title toggle><i
                                                        className="fa fa-globe "/> Web
                                                        Settings <b className="caret"/>
                                                    </Panel.Title>
                                                </Panel.Heading>

                                                <Panel.Body collapsible style={{boxShadow: "none"}}>
                                                    <Row>

                                                        <Col md={12}>
                                                            {this.renderDeviceMenu("web")}
                                                        </Col>
                                                    </Row>

                                                </Panel.Body>
                                            </Panel>

                                        </PanelGroup>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    }
                    ftTextCenter
                    legend={
                        <div className="buttons-with-margin">
                            <Button bsStyle="default" fill wd
                                    name={"cancelButton"}
                                    disabled={this.state.loading}
                                    onClick={() => this.setState({redirect: true})}
                            >
                                Back
                            </Button>
                        </div>
                    }

                />

            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDeviceMenuComponent = connect(mapStateToProps)(DeviceMenuComponent);
const connectedDeviceMenuComponentTrans = withNamespaces('common')(connectedDeviceMenuComponent);
export {connectedDeviceMenuComponentTrans as DeviceMenuComponent};
