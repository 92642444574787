import React, {Component} from "react";
// import {Redirect} from "react-router";
import {Col, Grid, Panel, PanelGroup, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import axios from "axios";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {ScaleLoader} from "react-spinners";
import { css } from "@emotion/core";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Radio from '../../components/CustomRadio/CustomRadio.jsx';
import Select from 'react-select';

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const tvOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const radioOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const applicationsOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const videoOnDemandOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const videoCategoriesOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

const tvShowsOrder = [
    {value: 'ASC', label: 'Ascending'},
    {value: 'DESC', label: 'Descending'}
];

class DeviceOrderingComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,

            deviceSettingsObject: null,
            saveDeviceType: "",

            showModalSave: false,

            android_mobile_device_type: null,
            android_mobile_terms_of_use: null,
            android_mobile_policy: null,
            android_mobile_version: null,

            android_tv_device_type: null,
            android_tv_terms_of_use: null,
            android_tv_policy: null,
            android_tv_version: null,

            roku_device_type: null,
            roku_terms_of_use: null,
            roku_policy: null,
            roku_version: null,

            ios_mobile_device_type: null,
            ios_mobile_terms_of_use: null,
            ios_mobile_policy: null,
            ios_mobile_version: null,

            tv_ordering: "",
            vod_ordering: "",
            radio_ordering: "",
            app_ordering: "",
            vod_cat_ordering: "",
            tv_shows_ordering: "",

            tvOrderDropDown: null,
            tvOrder: null,

            radioOrderDropDown: null,
            radioOrder: null,

            applicationsOrderDropDown: null,
            applicationsOrder: null,

            videoOnDemandOrderDropDown: null,
            videoOnDemandOrder: null,

            videoCategoriesOrderDropDown: null,
            videoCategoriesOrder: null,

            tvShowsOrderDropDown: null,
            tvShowsOrder: null,

            google_store_tv_version: null,
            google_store_tv_apk_url: null,
            google_tv_store_update: null,

            manual_store_tv_version: null,
            manual_store_tv_apk_url: null,
            manual_store_tv_update: null,

            amazon_store_tv_version: null,
            amazon_store_tv_apk_url: null,
            amazon_store_tv_update: null,



        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRadio = this.handleRadio.bind(this);

    }

    handleTvOrderChange = (tvOrderDropDown) => {

        this.setState({
            tvOrderDropDown,
            tvOrder: tvOrderDropDown === null ? "asc" : tvOrderDropDown.value
        });
        // console.log(`Option selected:`, tvOrderDropDown);

    }

    handleRadioOrderChange = (radioOrderDropDown) => {

        this.setState({
            radioOrderDropDown,
            radioOrder: radioOrderDropDown === null ? "asc" : radioOrderDropDown.value
        });
        // console.log(`Option selected:`, radioOrderDropDown);

    }

    handleApplicationsOrderChange = (applicationsOrderDropDown) => {

        this.setState({
            applicationsOrderDropDown,
            applicationsOrder: applicationsOrderDropDown === null ? "asc" : applicationsOrderDropDown.value
        });
        // console.log(`Option selected:`, applicationsOrderDropDown);

    }

    handleVideoOnDemandOrderChange = (videoOnDemandOrderDropDown) => {

        this.setState({
            videoOnDemandOrderDropDown,
            videoOnDemandOrder: videoOnDemandOrderDropDown === null ? "asc" : videoOnDemandOrderDropDown.value
        });
        // console.log(`Option selected:`, videoOnDemandOrderDropDown);

    }

    handleVideoCategoryOrderChange = (videoCategoriesOrderDropDown) => {

        this.setState({
            videoCategoriesOrderDropDown,
            videoCategoriesOrder: videoCategoriesOrderDropDown === null ? "asc" : videoCategoriesOrderDropDown.value
        });
        // console.log(`Option selected:`, videoCategoriesOrderDropDown);

    }

    handleTvShowsOrderChange = (tvShowsOrderDropDown) => {

        this.setState({
            tvShowsOrderDropDown,
            tvShowsOrder: tvShowsOrderDropDown === null ? "asc" : tvShowsOrderDropDown.value
        });
        // console.log(`Option selected:`, tvShowsOrderDropDown);

    }

    handleRadio = event => {

        const target = event.target;

        //console.log(target.name)
        //console.log(target.value)

        this.setState({
            [target.name]: target.value
        });
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

        if(this.props.applicationId !== 0){
            this.loadDeviceOrderConfiguration();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.applicationId !== prevProps.applicationId) {
            this.loadDeviceOrderConfiguration();
        }
    }

    //LOAD
    loadDeviceOrderConfiguration() {

        this.setState({loading: true});

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };


        if (this.props.applicationId && this.props.applicationId !== 0) {

            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-ordering";

            axios
                .get(url, axiosConfig)
                .then(res => {

                    for (let i = 0; i < res.data.length; i++) {

                        // console.log(res.data[i].property_name + " " + res.data[i].property_value + " " + res.data[i].property_direction)

                        if (res.data[i].property_name === 'tv_ordering') {
                            this.setState({
                                tvOrder: res.data[i].property_direction,
                                tv_ordering: res.data[i].property_value
                            })
                        }
                        if (res.data[i].property_name === 'radio_ordering') {
                            this.setState({
                                radioOrder: res.data[i].property_direction,
                                radio_ordering: res.data[i].property_value
                            })
                        }
                        if (res.data[i].property_name === 'app_ordering') {
                            this.setState({
                                applicationsOrder: res.data[i].property_direction,
                                app_ordering: res.data[i].property_value
                            })
                        }
                        if (res.data[i].property_name === 'vod_ordering') {
                            this.setState({
                                videoOnDemandOrder: res.data[i].property_direction,
                                vod_ordering: res.data[i].property_value
                            })
                        }
                        if (res.data[i].property_name === 'vod_cat_ordering') {
                            this.setState({
                                videoCategoriesOrder: res.data[i].property_direction,
                                vod_cat_ordering: res.data[i].property_value
                            })
                        }
                        if (res.data[i].property_name === 'tv_shows_ordering') {
                            this.setState({
                                tvShowsOrder: res.data[i].property_direction,
                                tv_shows_ordering: res.data[i].property_value
                            })
                        }
                    }

                    this.setState({loading: false});

                });

        }


    }

    //SAVE
    saveDeviceOrderConfiguration() {

        this.setState({saveDeviceType: "DeviceOrder", showModalSave: true})
    }

    //UPDATE

    updateDeviceOrderConfiguration() {

        this.setState({loading: true})

        let postData = {
            data: {
                type: "update_device_order_configuration",
                application_id: this.props.applicationId,
                tv_ordering_value: this.state.tv_ordering,
                tv_ordering_direction: this.state.tvOrder,
                radio_ordering_value: this.state.radio_ordering,
                radio_ordering_direction: this.state.radioOrder,
                app_ordering_value: this.state.app_ordering,
                app_ordering_direction: this.state.applicationsOrder,
                vod_ordering_value: this.state.vod_ordering,
                vod_ordering_direction: this.state.videoOnDemandOrder,
                vod_cat_ordering_value: this.state.vod_cat_ordering,
                vod_cat_ordering_direction: this.state.videoCategoriesOrder,
                tv_shows_ordering_value: this.state.tv_shows_ordering,
                tv_shows_ordering_direction: this.state.tvShowsOrder

            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        if (this.props.applicationId && this.props.applicationId !== 0) {

            const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement/" + this.props.applicationId + "/device-ordering";

            axios.put(url, postData, axiosConfig)
                .then((res) => {
                    this.setState({showModalSave: false, loading: false, saveDeviceType: ""})

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({showModalSave: false, loading: false, saveDeviceType: ""})
                });

        }



    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {

            if (this.state.saveDeviceType === "DeviceOrder") {
                this.updateDeviceOrderConfiguration()
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showModalSave) {
            return <ModalSave
                headerMessage={"Save Device Settings: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.saveDeviceType}
            />
        }
    }


    render() {
        return (
            <div>

                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <Col sm={12}>
                                                    <PanelGroup accordion id="device-settings-panel" defaultActiveKey="1">
                                                        <Panel eventKey="1">
                                                            <Panel.Heading>
                                                                <Panel.Title toggle><i
                                                                    className="fa fa-globe"/> Global Device Settings <b
                                                                    className="caret"/>
                                                                </Panel.Title>
                                                            </Panel.Heading>
                                                            <Panel.Body  style={{boxShadow: "none"}}>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <legend>Live TV</legend>
                                                                        <Select
                                                                            value={this.state.tvOrder}
                                                                            onChange={this.handleTvOrderChange}
                                                                            options={tvOrder}
                                                                        />
                                                                        <Radio
                                                                            number="1dr"
                                                                            option="NAME"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="2dr"
                                                                            option="POSITION"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                        <Radio
                                                                            number="3dr"
                                                                            option="MAJOR_MINOR"
                                                                            name="tv_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_ordering === "MAJOR_MINOR"}
                                                                            label="Order by Major.Minor"
                                                                        />
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <legend>Radio</legend>
                                                                        <Select
                                                                            value={this.state.radioOrder}
                                                                            onChange={this.handleRadioOrderChange}
                                                                            options={radioOrder}
                                                                        />
                                                                        <Radio
                                                                            number="3dr"
                                                                            option="NAME"
                                                                            name="radio_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.radio_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="4dr"
                                                                            option="POSITION"
                                                                            name="radio_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.radio_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                    <Col md={3}>
                                                                        <legend>Applications</legend>
                                                                        <Select
                                                                            value={this.state.applicationsOrder}
                                                                            onChange={this.handleApplicationsOrderChange}
                                                                            options={applicationsOrder}
                                                                        />
                                                                        <Radio
                                                                            number="5dr"
                                                                            option="NAME"
                                                                            name="app_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.app_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="6dr"
                                                                            option="POSITION"
                                                                            name="app_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.app_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>


                                                                    <Col md={3}>
                                                                        <legend>Video On Demand</legend>
                                                                        <Select
                                                                            value={this.state.videoOnDemandOrder}
                                                                            onChange={this.handleVideoOnDemandOrderChange}
                                                                            options={videoOnDemandOrder}
                                                                        />
                                                                        <Radio
                                                                            number="7dr"
                                                                            option="NAME"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="8dr"
                                                                            option="POSITION"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                        <Radio
                                                                            number="9dr"
                                                                            option="RELEASE_DATE"
                                                                            name="vod_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_ordering === "RELEASE_DATE"}
                                                                            label="Order by Release Date"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={3}>
                                                                        <legend>Video Categories</legend>
                                                                        <Select
                                                                            value={this.state.videoCategoriesOrder}
                                                                            onChange={this.handleVideoCategoryOrderChange}
                                                                            options={videoCategoriesOrder}
                                                                        />
                                                                        <Radio
                                                                            number="10dr"
                                                                            option="NAME"
                                                                            name="vod_cat_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_cat_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="11dr"
                                                                            option="POSITION"
                                                                            name="vod_cat_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.vod_cat_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                    <Col md={3}>
                                                                        <legend>TV Shows</legend>
                                                                        <Select
                                                                            value={this.state.tvShowsOrder}
                                                                            onChange={this.handleTvShowsOrderChange}
                                                                            options={tvShowsOrder}
                                                                        />
                                                                        <Radio
                                                                            number="12dr"
                                                                            option="NAME"
                                                                            name="tv_shows_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_shows_ordering === "NAME"}
                                                                            label="Order by Name"
                                                                        />
                                                                        <Radio
                                                                            number="13dr"
                                                                            option="POSITION"
                                                                            name="tv_shows_ordering"
                                                                            onChange={this.handleRadio}
                                                                            checked={this.state.tv_shows_ordering === "POSITION"}
                                                                            label="Order by Position"
                                                                        />
                                                                    </Col>

                                                                </Row>

                                                            </Panel.Body>
                                                        </Panel>
                                                    </PanelGroup>
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button
                                            fill wd
                                            bsStyle="info"
                                            type="submit"
                                            onClick={this.saveDeviceOrderConfiguration.bind(this)}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                            // onClick={this.cancelApplicationEdit.bind(this)}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                }

                            />
                        </Col>
                    </Row>

                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDeviceOrderingComponent = connect(mapStateToProps)(DeviceOrderingComponent);
const connectedDeviceOrderingComponentTrans = withNamespaces('common')(connectedDeviceOrderingComponent);
export {connectedDeviceOrderingComponentTrans as DeviceOrderingComponent};
