import React, {Component} from "react";
import {Grid} from "react-bootstrap";
import {DataTvGuideNow} from "../../components/Middleware/DataTvGuideNow.jsx";
import {StatsTvGuide} from "../../components/CustomStatsCard/StatsTvGuide";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ListTvGuideNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }


    render() {
        return (
            <div className="main-content">
                <Grid fluid>
                    <StatsTvGuide/>
                    <DataTvGuideNow/>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedListTvGuideNow = connect(mapStateToProps)(ListTvGuideNow);
const connectedListTvGuideNowTrans = withNamespaces('common')(connectedListTvGuideNow);
export {connectedListTvGuideNowTrans as ListTvGuideNow};
