import React, {Component} from "react";
import {Grid, Col, Row} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import {ChartUserRegistration} from "../../components/Charts/customers/ChartUserRegistration";
import {ChartDeviceRegistration} from "../../components/Charts/devices/ChartDeviceRegistration";
import {ChartSubscribersOverview} from "../../components/Charts/customers/ChartSubscribersOverview";
import {ChartSubscribersOverviewStbPie} from "../../components/Charts/customers/ChartSubscribersOverviewStbPie";
// import {ChartTop10MoviesAllTime} from "../../components/Charts/VideoOnDemand/ChartTop10MoviesAllTime";
// import {ChartTop10SeriesAllTime} from "../../components/Charts/VideoOnDemand/ChartTop10SeriesAllTime";
import {withNamespaces} from "react-i18next";
import {MapBox} from "../../components/MapBox/MapBox";
import ListCustomersPackageStatus from "../Customers/ListCustomersPackageStatus";
import CurrentLiveWatchingSessions from "../../components/Charts/wms/current/CurrentLiveWatchingSessions";
import CurrentLiveWatchingAverage from "../../components/Charts/wms/current/CurrentLiveWatchingAverage";
import CurrentLiveWatchingAgents from "../../components/Charts/wms/current/CurrentLiveWatchingAgents";
import ChartTop10MoviesAllTimeNivo from "../../components/Charts/VideoOnDemand/ChartTop10MoviesAllTimeNivo";
import ChartTop10SeriesAllTimeNivo from "../../components/Charts/VideoOnDemand/ChartTop10SeriesAllTimeNivo";

class Dashboard extends Component {

    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            user_firstname: user.firstName === null ? '' : user.firstName,
            user_lastname: user.lastName === null ? '' : user.lastName,
            username: user.username,
            access: user.level,
            user_id: user.id,
            permissions: user.permissions
        }
    }

    componentDidMount() {

    }

    renderCustomerStatus = () => {

        if (this.state.permissions.dashboard.datatable_expirining_customers) {
            return (
                <ListCustomersPackageStatus/>
            );
        }

    }

    renderMainDashboard = () => {

        return (
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        {this.renderCustomerStatus()}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_stb_subscribers ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <h5>{this.props.t('charts_stb_subscribers.title', {framework: "react-i18next"})}</h5>
                                            <ChartSubscribersOverview/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_user_registration ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <h5>{this.props.t('charts_user_registration.title', {framework: "react-i18next"})}</h5>
                                            <ChartUserRegistration/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        {this.state.permissions.dashboard.piechart_stb_overview_pie ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <h5>{this.props.t('charts_stb_overview.title', {framework: "react-i18next"})}</h5>
                                            <ChartSubscribersOverviewStbPie/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                    <Col md={6}>
                        {this.state.permissions.dashboard.piechart_device_distribution ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <h5>{this.props.t('charts_pie_device_distribution.title', {framework: "react-i18next"})}</h5>
                                            <ChartDeviceRegistration/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.maps_stb_subscriber ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <h5>{this.props.t('charts_stb_subscribers.title', {framework: "react-i18next"})}</h5>
                                            <MapBox/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_top10MoviesAllTime ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            {/*<h5>{this.props.t('charts_video_on_demand.top10MoviesAllTime', {framework: "react-i18next"})}</h5>*/}
                                            <ChartTop10MoviesAllTimeNivo/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_top10SeriesAllTime ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            {/*<h5>{this.props.t('charts_video_on_demand.top10SeriesAllTime', {framework: "react-i18next"})}</h5>*/}
                                            <ChartTop10SeriesAllTimeNivo/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_live_sessions ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <CurrentLiveWatchingSessions/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.barchart_live_average ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <CurrentLiveWatchingAverage/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {this.state.permissions.dashboard.piechart_live_agents ?
                            <Card
                                content={
                                    <Row>
                                        <Col md={12}>
                                            <CurrentLiveWatchingAgents/>
                                        </Col>
                                    </Row>
                                }
                            />
                            : ""}
                    </Col>
                </Row>


            </Grid>
        )

    }

    render() {
        return (
            <div className="main-content">

                {this.renderMainDashboard()}

            </div>
        );
    }
}

export default withNamespaces('common')(Dashboard);

