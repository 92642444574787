import React, {Component} from "react";
import {withNamespaces} from "react-i18next";
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import {Col, ControlLabel, FormControl, FormGroup, Grid, HelpBlock, Row, ButtonGroup} from "react-bootstrap";
import Card from "../../components/Card/Card";
import Button from "../../components/CustomButton/CustomButton.jsx";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import Switch from "react-bootstrap-switch";
import Select from 'react-select';
import {ScaleLoader} from "react-spinners";
import {Redirect} from "react-router";
import {connect} from 'react-redux';
import {css} from "@emotion/core";
import md5 from "crypto-js/md5";
import {UserPermissions} from "../../components/UserPermissions/UserPermissions"
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const accountType = [
    {value: 'super-admin', label: 'Super Admin'},
    {value: 'admin', label: 'Admin'},
    {value: 'live_manager', label: 'Live Manager'},
    {value: 'vod_manager', label: 'VOD Manager'},
    {value: 'csr_customer', label: 'CSR Customer View'},
    {value: 'reports_manager', label: 'Reports Manager'},
    {value: 'billing', label: 'Billing Manager'},
    {value: 'dealer', label: 'Dealer Account'}
];

const adminArray = ['super-admin', 'admin']
const contentProviderArray = ['live_manager', 'vod_manager','reports_manager']

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;


class EditUserAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {

            _notificationSystem: null,
            loading: true,

            account_type: null,
            statusSwitch: false,

            user_id: null,
            user_name: null,
            user_lastname: null,
            user_email: null,
            user_username: null,
            user_password: '',
            user_password_confirm: '',

            userArray: null,
            redirect: false,
            redirectUser: false,
            saveModal: false,
            back_button_text: "Cancel",

            validate_user_name: null,
            validate_user_name_message: "",

            validate_user_username: null,
            validate_user_username_message: "",

            validate_user_password: null,
            validate_user_password_message: "",

            validate_user_password_confirm: null,
            validate_user_password_confirm_message: "",

            validate_user_type: null,
            validate_user_type_message: "",
            user_permissions: null,
            tempSelected: [],

            tempPermissions: null,

            settingsPermissions: null,
            dashboardPermissions: null,
            customerPermissions: null,
            mergedObjects: null,

            user_content_provider: null,
            current_user: null

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildPermissionsSelect = this.handleChildPermissionsSelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleAccountTypeChange = (accountType) => {

        this.setState({
            accountType,
            account_type: accountType === null ? "admin" : accountType.value
        });
        //console.log(`Option selected:`, accountType);

    }

    componentDidMount() {

        let  current_user = JSON.parse(localStorage.getItem('user'));

        this.setState({_notificationSystem: this.refs.notificationSystem,current_user:current_user})
        this.loadUserFromMiddleware();
    }

    loadUserFromMiddleware() {

        this.setState({loading: true})
        const {handle} = this.props.match.params

        if (handle === "new") {

            this.setState({loading: false, user_id: "new"})

        } else {

            let dataUrl = process.env.REACT_APP_API_URL + "/Users/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios.get(dataUrl, axiosConfig)
                .then(res => {

                    const userArray = res.data[0];
                    const loading = false;

                    let statusSwitch = userArray.user_status === 1

                    this.setState({

                            userArray,
                            loading,

                            user_id: userArray.user_id,
                            user_name: userArray.user_name,
                            user_lastname: userArray.user_lastname,
                            user_email: userArray.user_email,
                            user_username: userArray.user_username,
                            account_type: userArray.user_usertype,
                            statusSwitch,
                            back_button_text: "Back",
                            user_permissions: userArray.user_permissions === null ? userArray.level_permissions : userArray.user_permissions,
                            user_content_provider: userArray.user_content_provider
                            // user_permissions: userArray.level_permissions
                        }
                    );

                    //this.populatePermissions(userArray.user_permissions)

                })
                .catch(error => {
                    this.setState({loading: false});

                    // if (error.response.status === 401) {
                    //     this.props.dispatch(userActions.logoutUnauthorized());
                    // }
                    this.notification("failed", error.response.status)
                    //console.log(error);
                });
        }

    }

    notification(status, message) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>User has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{"failed to update user - status: " + message}</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    createUser() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/Users";

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0;
        let password = this.state.user_password

        if (password !== '') {
            password = md5(this.state.user_password).toString();
        } else {
            password = null
        }

        let postData = {
            user: {
                type: "create_user",
                user_name: this.state.user_name,
                user_lastname: this.state.user_lastname,
                user_username: this.state.user_username,
                user_md5password: password,
                user_status: statusSwitch,
                user_usertype: this.state.account_type,
                user_permissions: JSON.stringify(this.state.user_permissions),
                user_content_provider: this.state.user_content_provider
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.setState({loading: false})

                if (res.data.state === false) {
                    this.notification("failed")
                    this.setState({
                        validate_user_type_message: res.data.account_type_validate_message,
                        validate_user_type: res.data.account_type_validate_state,
                        validate_user_name_message: res.data.account_name_validate_message,
                        validate_user_name: res.data.account_name_validate_state,
                        validate_user_username_message: res.data.account_username_validate_message,
                        validate_user_username: res.data.account_username_validate_state
                    })
                } else {
                    const user = res.data;
                    this.setState({
                            user_id: user[0].user_id,
                            validate_user_type_message: "",
                            validate_user_type: null,
                            validate_user_name_message: "",
                            validate_user_name: null,
                            validate_user_username_message: "",
                            validate_user_username: null,
                            back_button_text: "Back"
                        }
                    )

                    this.setUserRedirect();
                    this.notification("success")
                }

            })
            .catch(error => {
                this.setState({loading: false});
                //console.log(error);
                this.notification("failed", error.response.status)
            });

    }

    updateUser() {

        this.setState({loading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        const url = process.env.REACT_APP_API_URL + "/Users";

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0;
        let password = this.state.user_password

        if (password !== '') {
            password = md5(this.state.user_password).toString();
        } else {
            password = null
        }


        let postData = {
            user: {
                type: "update_user",
                user_id: this.state.user_id,
                user_name: this.state.user_name,
                user_lastname: this.state.user_lastname,
                user_email: this.state.user_email,
                user_username: this.state.user_username,
                user_md5password: password,
                user_status: statusSwitch,
                user_usertype: this.state.account_type,
                user_permissions: this.state.mergedObjects === null ? JSON.stringify(this.state.user_permissions) : JSON.stringify(this.state.mergedObjects),
                user_content_provider: this.state.user_content_provider

            }
        };

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.setState({loading: false})

                if (res.data.state === false) {
                    this.notification("failed")
                    this.setState({
                        validate_user_type_message: res.data.account_type_validate_message,
                        validate_user_type: res.data.account_type_validate_state,
                        validate_user_name_message: res.data.account_name_validate_message,
                        validate_user_name: res.data.account_name_validate_state,
                        validate_user_username_message: res.data.account_username_validate_message,
                        validate_user_username: res.data.account_username_validate_state
                    })
                } else {
                    this.setState({
                            validate_user_type_message: "",
                            validate_user_type: null,
                            validate_user_name_message: "",
                            validate_user_name: null,
                            validate_user_username_message: "",
                            validate_user_username: null,
                        }
                    )

                    this.notification("success")
                }
            })
            .catch(error => {
                this.setState({loading: false});
                //console.log(error);
                this.notification("failed", error.response.status)
            });

    }

    checkPasswords() {

        let passwordStatus = true

        if (this.state.user_password !== this.state.user_password_confirm) {

            passwordStatus = false

            this.setState({

                validate_user_password_confirm: "error",
                validate_user_password_confirm_message: "passwords do not match"
            })

        } else if (this.state.user_password === this.state.user_password_confirm) {
            this.setState({
                validate_user_password: null,
                validate_user_password_message: "",

                validate_user_password_confirm: null,
                validate_user_password_confirm_message: ""
            })
        } else {
            this.setState({
                validate_user_password: null,
                validate_user_password_message: "",

                validate_user_password_confirm: null,
                validate_user_password_confirm_message: ""
            })
        }

        return passwordStatus
    }

    cancelCustomerEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        //console.log("setRedirect =>")
        this.setState({
            redirect: true
        })
    }

    setUserRedirect = () => {
        this.setState({
            redirectUser: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/settings-user";
        let pathUser = "/edit-user/" + this.state.user_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.redirectUser) {

            this.setState({
                redirect: false,
                redirectUser: false,
                back_button_text: "Back"
            })

            return <Redirect push to={pathUser}/>
        }

    }

    saveButtonUser() {

        if (this.checkPasswords()) {

            if (this.state.user_id === 'new') {
                this.createUser();
            } else {
                this.updateUser();
            }
        } else {
            this.notification("failed", "passwords dont much")
        }

    }

    handleChildPermissionsSelect(data) {

        this.setState({mergedObjects: data})
        //console.log(data)

    }

    renderContentProvider = () => {

        if(this.state.current_user){
            if(adminArray.includes(this.state.current_user.level)){
                if(contentProviderArray.includes(this.state.account_type)){
                        return (
                            <Row>
                                <FormGroup>
                                    <ControlLabel>Content Provider</ControlLabel>
                                    <DropDownContentProviders
                                        contentProviderSelected={this.state.user_content_provider}
                                        name='contentProviderSelect'
                                        handlerFromParent={this.handleChildContentProviderSelect}
                                        disabled={this.state.loading}
                                    />
                                </FormGroup>
                            </Row>
                        )

                }
            }
        }

    }

    render() {
        return (
            <div className="main-content">
                {this.renderRedirect()}

                <NotificationSystem ref="notificationSystem" style={style}/>

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col md={6}>
                            <Card
                                title={""}
                                content={
                                    <form>
                                    <div>
                                        <Grid fluid>
                                            <Row>
                                                <FormGroup>
                                                    <ControlLabel>Account Status</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"statusSwitch"}
                                                        value={this.state.statusSwitch}
                                                        disabled={this.state.loading}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup
                                                    controlId="formValidation6"
                                                    validationState={this.state.validate_user_type}
                                                >
                                                    <ControlLabel>Account Type *</ControlLabel>
                                                    <Select
                                                        value={this.state.account_type}
                                                        disabled={this.state.loading}
                                                        onChange={this.handleAccountTypeChange}
                                                        options={accountType}
                                                    />
                                                    <HelpBlock>{this.state.validate_user_type_message}</HelpBlock>
                                                </FormGroup>
                                            </Row>
                                            {this.renderContentProvider()}
                                            <Row>
                                                <FormGroup
                                                    controlId="formValidationFirstName"
                                                    validationState={this.state.validate_user_name}
                                                >
                                                    <ControlLabel>First Name *</ControlLabel>
                                                    <FormControl
                                                        placeholder="Enter Short Name"
                                                        type="text"
                                                        name={"user_name"}
                                                        disabled={this.state.loading}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.user_name || ''}/>
                                                    <HelpBlock>{this.state.validate_user_name_message}</HelpBlock>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup>
                                                    <ControlLabel>Last Name</ControlLabel>
                                                    <FormControl
                                                        placeholder="Enter Short Name"
                                                        type="text"
                                                        name={"user_lastname"}
                                                        disabled={this.state.loading}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.user_lastname || ''}/>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup
                                                    controlId="formValidationUsername"
                                                    validationState={this.state.validate_user_username}
                                                >
                                                    <ControlLabel>username (Please provide an email) *</ControlLabel>
                                                    <FormControl
                                                        placeholder="Enter Username"
                                                        type="email"
                                                        autoComplete="username"
                                                        name={"user_username"}
                                                        disabled={this.state.loading}
                                                        onChange={this.handleInputChange}
                                                        value={this.state.user_username || ''}/>
                                                    <HelpBlock>{this.state.validate_user_username_message}</HelpBlock>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup
                                                    controlId="formValidation3"
                                                    validationState={this.state.validate_user_password}
                                                >
                                                    <ControlLabel>Password</ControlLabel>
                                                    <FormControl placeholder="Enter password"
                                                                 type="password"
                                                                 disabled={this.state.loading}
                                                                 name="user_password"
                                                                 autoComplete="new-password"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.user_password || ''}
                                                    />
                                                    <HelpBlock>{this.state.validate_user_password_message}</HelpBlock>
                                                </FormGroup>
                                            </Row>
                                            <Row>
                                                <FormGroup
                                                    controlId="formValidation4"
                                                    validationState={this.state.validate_user_password_confirm}
                                                >
                                                    <ControlLabel>Confirm Password</ControlLabel>
                                                    <FormControl placeholder="Confirm password"
                                                                 type="password"
                                                                 disabled={this.state.loading}
                                                                 name="user_password_confirm"
                                                                 autoComplete="new-password"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.user_password_confirm || ''}
                                                    />
                                                    <HelpBlock>{this.state.validate_user_password_confirm_message}</HelpBlock>
                                                </FormGroup>
                                            </Row>
                                        </Grid>
                                    </div>
                                    </form>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <ButtonGroup>
                                            <Button
                                                fill
                                                bsStyle="info"
                                                type="submit"
                                                disabled={this.state.loading}
                                                onClick={this.saveButtonUser.bind(this)}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                fill
                                                bsStyle="default"
                                                type="submit"
                                                disabled={this.state.loading}
                                                onClick={this.cancelCustomerEdit.bind(this)}
                                            >
                                                {this.state.back_button_text}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                }
                            />
                        </Col>
                        <Col md={6}>
                            <UserPermissions
                                accountType={this.state.account_type}
                                accountPermissions={this.state.user_permissions}
                                handlerFromParent={this.handleChildPermissionsSelect}
                            />
                        </Col>
                    </Row>
                </Grid>


            </div>
        )
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditUserAccount = connect(mapStateToProps)(EditUserAccount);
const connectedEditUserAccountTrans = withNamespaces('common')(connectedEditUserAccount);
export {connectedEditUserAccountTrans as EditUserAccount};