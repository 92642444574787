import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import Card from "../Card/Card";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ModalViewTvGuideProgram extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showViewProgram: true,

            tvProgramArray: [],

            progress: 0,
            ID: 0,
            unique_id: null,
            channel_id: null,
            title: null,
            sub_title: null,
            desc_str: null,
            start_str: null,
            stop_str: null,
            channel_num: null,
            length: null,
            start_date: null,
            stop_date: null,
            date_str: null,
            detailnumber: null,
            showid: null,
            seriesid: null,
            episode_num_xmltv_ns: null,
            episode_num_onscreen: null,
            date: null,
            previously_shown: null,
            new_programme: null,
            subtitles: null,
            category: null,
            language: null,
            director: null,
            actors: null,
            star_rating: null,
            rating_VCHIP: null,
            rating_advisory: null,
            icon_poster: null,
            icon_titlecard: null,
            icon_still: null,
            ppv: null,
            ppv_price: null

        };

    }


    handleClose = () => {
        this.setState({showViewProgram: false})
        this.props.handlerFromParent(false, "nothing");
    }


    componentDidMount() {

        //console.log(this.props.programId)
        this.loadFromMiddlewareTvGuideProgramData(this.props.programId)

    }

    //LOAD FROM MIDDLEWARE
    loadFromMiddlewareTvGuideProgramData(programId) {

        const url = process.env.REACT_APP_API_URL + "/TvGuide/program/" + programId;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url,axiosConfig)
            .then(res => {

                const tvProgramArray = res.data[0];
                const loading = false;

                //console.log(tvProgramArray)

                this.setState({
                    tvProgramArray,
                    loading,

                    progress: tvProgramArray.progress,
                    ID: tvProgramArray.ID,
                    unique_id: tvProgramArray.unique_id,
                    channel_id: tvProgramArray.channel_id,
                    title: tvProgramArray.title,
                    sub_title: tvProgramArray.sub_title,
                    desc_str: tvProgramArray.desc_str,
                    start_str: tvProgramArray.start_str,
                    stop_str: tvProgramArray.stop_str,
                    channel_num: tvProgramArray.channel_num,
                    length: tvProgramArray.length,
                    start_date: tvProgramArray.start_date,
                    stop_date: tvProgramArray.stop_date,
                    date_str: tvProgramArray.date_str,
                    detailnumber: tvProgramArray.detailnumber,
                    showid: tvProgramArray.showid,
                    seriesid: tvProgramArray.seriesid,
                    episode_num_xmltv_ns: tvProgramArray.episode_num_xmltv_ns,
                    episode_num_onscreen: tvProgramArray.episode_num_onscreen,
                    date: tvProgramArray.date,
                    previously_shown: tvProgramArray.previously_shown,
                    new_programme: tvProgramArray.new_programme,
                    subtitles: tvProgramArray.subtitles,
                    category: tvProgramArray.category,
                    language: tvProgramArray.language,
                    director: tvProgramArray.director,
                    actors: tvProgramArray.actors,
                    star_rating: tvProgramArray.star_rating,
                    rating_VCHIP: tvProgramArray.rating_VCHIP,
                    rating_advisory: tvProgramArray.rating_advisory,
                    icon_poster: tvProgramArray.icon_poster,
                    icon_titlecard: tvProgramArray.icon_titlecard,
                    icon_still: tvProgramArray.icon_still,
                    ppv: tvProgramArray.ppv,
                    ppv_price: tvProgramArray.ppv_price

                });
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            });

    }

    loadImage(value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/noimage.png"
            return image;
        } else {
            // let image = process.env.REACT_APP_ASSET_URL + "/live_tv/" + value
            return value;
        }

    }

    render() {
        return (
            <div className="modal-edit-season">
                <Modal show={this.state.showViewProgram} onHide={this.handleClose} bsSize="large">
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + " - " + this.props.programId}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p> {this.props.bodyMessage}</p>

                        <Card
                            content={
                                <div>
                                    <legend>{this.state.title}</legend>
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={4}>
                                                <div className="center">
                                                    <img
                                                        src={this.loadImage(this.state.icon_poster)}
                                                        width="240px" alt={""}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={8}>
                                                <Row>
                                                    <Col sm={12}>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>Channel ID</ControlLabel>
                                                            <FormControl placeholder="channel_id"
                                                                         type="text"
                                                                         disabled
                                                                         name={"channel_id"}
                                                                         value={this.state.channel_id || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>Title</ControlLabel>
                                                            <FormControl placeholder="No Title"
                                                                         type="text"
                                                                         disabled
                                                                         name={"title"}
                                                                         value={this.state.title || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12}>
                                                        <FormGroup>
                                                            <ControlLabel>Description</ControlLabel>
                                                            <FormControl componentClass="textarea"
                                                                         type="text"
                                                                         rows={5}
                                                                         disabled
                                                                         name="desc_str"
                                                                         onChange={this.desc_str}
                                                                         placeholder="No description available"
                                                                         value={this.state.desc_str || ""}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>Start</ControlLabel>
                                                            <FormControl placeholder="Start on Date"
                                                                         type="text"
                                                                         disabled
                                                                         name={"start_date"}
                                                                         value={this.state.start_date || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>Stop</ControlLabel>
                                                            <FormControl placeholder="Stop on Date"
                                                                         type="text"
                                                                         disabled
                                                                         name={"stop_date"}
                                                                         value={this.state.stop_date || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>Show ID</ControlLabel>
                                                            <FormControl placeholder="Show ID"
                                                                         type="text"
                                                                         disabled
                                                                         name={"showid"}
                                                                         value={this.state.showid || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>Series ID</ControlLabel>
                                                            <FormControl placeholder="Series ID"
                                                                         type="text"
                                                                         disabled
                                                                         name={"seriesid"}
                                                                         value={this.state.seriesid || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>episode_num_xmltv_ns</ControlLabel>
                                                            <FormControl placeholder="Episode Number"
                                                                         type="text"
                                                                         disabled
                                                                         name={"episode_num_xmltv_ns"}
                                                                         value={this.state.episode_num_xmltv_ns || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormGroup>
                                                            <ControlLabel>episode_num_onscreen</ControlLabel>
                                                            <FormControl placeholder="Episode Number on Screen"
                                                                         type="text"
                                                                         disabled
                                                                         name={"episode_num_onscreen"}
                                                                         value={this.state.episode_num_onscreen || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={2}>
                                                        <FormGroup>
                                                            <ControlLabel>NEW</ControlLabel>
                                                            <FormControl placeholder="new_programme"
                                                                         type="text"
                                                                         disabled
                                                                         name={"new_programme"}
                                                                         value={this.state.new_programme === 1  ? "NEW" : 'OLD'}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <FormGroup>
                                                            <ControlLabel>category</ControlLabel>
                                                            <FormControl placeholder="category"
                                                                         type="text"
                                                                         disabled
                                                                         name={"category"}
                                                                         value={this.state.category || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={2}>
                                                        <FormGroup>
                                                            <ControlLabel>PPV</ControlLabel>
                                                            <FormControl placeholder="ppv"
                                                                         type="text"
                                                                         disabled
                                                                         name={"ppv"}
                                                                         value={this.state.ppv === "Yes"  ? "Yes" : 'No'}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <FormGroup>
                                                            <ControlLabel>PPV Suggested price</ControlLabel>
                                                            <FormControl placeholder="category"
                                                                         type="text"
                                                                         disabled
                                                                         name={"ppv_price"}
                                                                         value={this.state.ppv_price || ""}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>

                                    </Grid>
                                </div>
                            }
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalViewTvGuideProgram = connect(mapStateToProps)(ModalViewTvGuideProgram);
const connectedModalViewTvGuideProgramTrans = withNamespaces('common')(connectedModalViewTvGuideProgram);
export {connectedModalViewTvGuideProgramTrans as ModalViewTvGuideProgram};