import React from "react";
import axios from "axios";
import ReactTable from "react-table";
import {connect} from 'react-redux';
import {Tooltip, OverlayTrigger,Grid,Row,Col} from 'react-bootstrap';
import Button from "../CustomButton/CustomButton.jsx";
import {withNamespaces} from "react-i18next";
import {ModalViewTvGuideStatic} from "../CustomModals/ModalViewTvGuideStatic";
import {userActions} from "../../authentication/_actions";
import Card from "../Card/Card";
import ModalDelete from "../CustomModals/ModalDelete";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DataTvGuideStatic extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            remove_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),
            edit_tooltip: (<Tooltip id="edit_tooltip">Edit</Tooltip>),

            static_id: null,
            loading: false,
            guideArray: [],
            columns: [],
            showModalTvGuide: false,
            showDeleteModal: false

        };
    }

    loadColumnsTvGuide() {

        this.setState({
            columns: [
                {
                    accessor: "static_epg_id",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "static_epg_channel_id",
                    Header: "Channel ID",
                    maxWidth: 120,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "static_epg_channel_name",
                    Header: "Name",
                    sortable: false,
                    filterable: false
                },

                {
                    accessor: "static_epg_title",
                    Header: "Title",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "static_epg_description",
                    Header: "Description",
                    sortable: true,
                    filterable: false,
                },
                {
                    accessor: "static_epg_duration",
                    Header: "Duration",
                    maxWidth: 80,
                    sortable: true,
                    filterable: false,
                },
                {
                    accessor: "static_epg_id",
                    Header: "More Info",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }]
        })
    }

    //BUTTONS
    setHelperButton(static_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.edit_tooltip}>
            <Button
                onClick={() => {
                    this.setState({
                        static_id: static_id,
                        showModalTvGuide: true,
                    })
                }}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-edit"/>
            </Button>
        </OverlayTrigger>)

    }

    setDeleteButton(static_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_tooltip}>
            <Button
                onClick={() => this.setState({static_id: static_id, showDeleteModal: true})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    componentDidMount() {
        this.getTvGuideStaticData();
    }

    getTvGuideStaticData() {

        this.loadColumnsTvGuide();

        this.setState({
            loading: true
        })

        if (AxiosHeader.getConfig() !== null) {

            let dataUrl = process.env.REACT_APP_API_URL + "/TvGuide/static";

            axios.get(dataUrl, AxiosHeader.getConfig())
                .then((res) => {
                    const guideArray = res.data;
                    const loading = false;
                    this.setState({guideArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    deleteStaticProgram(program_id) {

        if (AxiosHeader.getConfig() !== null) {

            let deleteURL = process.env.REACT_APP_API_URL + "/TvGuide/static/" + program_id;

            axios.delete(deleteURL, AxiosHeader.getConfig())
                .then((res) => {
                    this.getTvGuideStaticData();
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    renderViewTvGuideStaticProgram = () => {

        if (this.state.showModalTvGuide) {
            return <ModalViewTvGuideStatic
                headerMessage={"View Program Info"}
                bodyMessage={""}
                handlerFromParent={this.setViewTvGuideStaticProgramModal}
                staticId={this.state.static_id}
            />
        }
    }

    setViewTvGuideStaticProgramModal = (value, action) => {

        if (action === 'reload') {
            this.setState({
                showModalTvGuide: value,
                static_id: null
            })
            this.getTvGuideStaticData();
        } else {
            this.setState({
                showModalTvGuide: value,
                static_id: null
            })
        }

    }

    setDisplayDeleteModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteStaticProgram(id);
            //console.log(id);
        }
    }

    renderDeleteModal = () => {

        let bodyMessageVar = "this action will delete the static program and remove it from all associated channels";

        if (this.state.showDeleteModal) {
            return <ModalDelete
                headerMessage={"Delete Static TvGuide Program"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayDeleteModal}
                handlerID={this.state.static_id}
            />
        }
    }


    render() {
        return (
            <div>
                {this.renderViewTvGuideStaticProgram()}
                {this.renderDeleteModal()}
                <Card
                    content={
                        <div>
                            <div>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div className="pull-left">
                                                <h5>TV Guide - Static Programs</h5>
                                            </div>
                                            <div className="pull-left">
                                                <Button bsStyle="info" fill bsSize="xs"
                                                        style={{marginLeft: "10px", marginTop: "10px"}}
                                                        onClick={() => this.setState({
                                                            showModalTvGuide: true,
                                                            static_id: "new"
                                                        })}
                                                >
                                                    Add new
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>

                            <legend/>

                            <ReactTable
                                data={this.state.guideArray}
                                filterable
                                loading={this.state.loading}
                                columns={this.state.columns}
                                defaultPageSize={10}
                                showPaginationBottom
                                className="-striped -highlight"
                            />
                        </div>
                    }
                />
            </div>
        );

    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataTvGuideStatic = connect(mapStateToProps)(DataTvGuideStatic);
const connectedDataTvGuideStaticTrans = withNamespaces('common')(connectedDataTvGuideStatic);
export {connectedDataTvGuideStaticTrans as DataTvGuideStatic};