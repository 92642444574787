import React, {Component} from 'react'
import logo_lastfm from './logo_lastfm.svg'
import Autosuggest from 'react-autosuggest'
import {URL_ALBUM_SEARCH, API_KEY} from './const_lastfm';
import axios from 'axios'

class SearchBarTvShows extends Component {
    constructor(props) {
        super(props);

        this.handleChildTmdbSelect = this.handleChildTmdbSelect.bind(this);

        this.state = {
            value: '',
            suggestions: []
        };
    }

    //COMMUNICATE WITH PARENT
    handleChildTmdbSelect(data) {
        this.props.handlerFromParent(data);
    }


    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            return this.handleSubmit(this.state.value);
        }
    }

    handleSubmit = (searchText) => {
        //this.props.dispatch(push('/search/' + searchText));
        this.setState({value: ''});
    }

    getSuggestionValue = (suggestion) => {
        return suggestion.name;
    };

    onSuggestionsFetchRequested = ({value}) => {
        const trimmedValue = value.trim();

        if (trimmedValue.length > 0) {
            let url = URL_ALBUM_SEARCH + trimmedValue + API_KEY;

            fetch(url)
                .then(response => response.json())
                .then(json => json.results)
                .then(data => {

                    let results = data.albummatches.album.map(album => {

                        //console.log(album.mbid)
                        let temp = {}
                        temp.id = album.mbid
                        temp.name = album.name
                        temp.img = album.image[0]['#text']
                        temp.year = '----'
                        return temp

                    });

                    //if(album.mbid !== undefined && album.mbid !== null && album.mbid !== "" ){
                    //console.log(results)
                    //Remove Albums with no ID
                    //results.splice(results.findIndex(v => v.mbid === ""), 1);
                    //results = results.filter(v => v.mbid.length > 1);
                    //console.log(results)

                    this.setState({
                        suggestions: results
                    });

                }).catch(error => console.log('Exception to get Suggestions'))
        }
        else {
            this.setState({
                suggestions: []
            })
        }
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    renderSuggestion = (suggestion) => {

        return (
            <div>
                <img className="searchResult-image"
                     src={suggestion.img == null ? logo_lastfm : suggestion.img}
                     alt={suggestion.img == null ? logo_lastfm : suggestion.img}
                />
                <div className="searchResult-text">
                    <div className="searchResult-name">
                        {suggestion.name}
                    </div>
                    {suggestion.year}
                </div>
            </div>
        );

    };

    onSuggestionSelected = (event, {suggestion, method}) => {
        if (method === 'enter')
            event.preventDefault();
        this.fetchTvMusicAlbumDetail(suggestion.id)
        this.setState({value: ''});
    };


    fetchTvMusicAlbumDetail(id) {

        const url = 'https://ws.audioscrobbler.com/2.0/?method=album.getinfo&api_key=5a87187be1c22d94b6425dc7c7dec67f&format=json&mbid=' + id;

        axios
            .get(url)
            .then(res => {
                //console.log(res)
                this.handleChildTmdbSelect(res)
            });
    }

    render() {

        const {value, suggestions} = this.state;
        const inputProps = {
            value,
            onChange: this.onChange,
            onKeyPress: this.handleKeyDown,
            placeholder: 'Search Music Album'
        };

        return (
            <div>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}/>
            </div>
        );

    }
}

//export default connect()(SearchBar);
export default SearchBarTvShows;


