import React, {Component} from "react";
import MapGL, {Marker, Popup, NavigationControl} from '@urbica/react-map-gl';
import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import connect from "react-redux/es/connect/connect";
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import {ButtonGroup} from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {ModalProperty} from "../CustomModals/ModalProperty";

const style = {
    width: '20px',
    height: '20px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center'
};

const style1k = {
    width: '40px',
    height: '40px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const style100k = {
    width: '60px',
    height: '60px',
    color: '#fff',
    background: '#1978c8',
    borderRadius: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const mapStyles = {
    light: 'mapbox://styles/mapbox/light-v9',
    dark: 'mapbox://styles/mapbox/dark-v9',
    streets: 'mapbox://styles/mapbox/streets-v11'
};

class MapBoxProperties extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewport: {
                width: 400,
                height: 400,
                latitude: "38.3569685120961",
                longitude: "-96.0371918253943",
                zoom: 4
            },
            propertiesOnMap: [],
            displayPopUp: false,
            popUpData: null,
            styleId: 'streets',
            showPropertyModal: false,
            property_id: null
        };

        this.setMapStyle = this.setMapStyle.bind(this);
    }

    _onViewportChange = viewport => {

        if (viewport.longitude > 0) {
            viewport.longitude = 0;
        }

        this.setState({viewport});
    }

    setMapStyle(style) {
        this.setState({
            styleId: style
        })
    }

    componentDidMount() {
        this.fetchProperties();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.lastUpdate !== prevProps.lastUpdate) {
            console.log("componentDidUpdate ->" + this.props.lastUpdate )
            this.fetchProperties();
        }
    }

    fetchProperties = () => {

        let url = process.env.REACT_APP_API_URL + '/Properties'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const chartData = res.data;

                this.setState({
                    propertiesOnMap: chartData
                });

            });

    };

    loadPropertyMarkers = () => {
        return this.state.propertiesOnMap.map(spot => {
            return (
                <Marker
                    key={spot.property_id+spot.property_lat+spot.property_long}
                    latitude={parseFloat(spot.property_lat)}
                    longitude={parseFloat(spot.property_long)}
                >
                    {this.getMarker(spot.property_type, spot)}
                </Marker>
            );
        });
    };

    getMarker(property_type, spot) {


        if (property_type === "HOME") {
            return (
                <i className="fa fa-home fa-2x text-default" style={{color: "blueviolet"}}
                   onClick={() => this.initPopUp(spot)}/>
            )
        } else if (property_type === "UNIVERSITY") {
            return (
                <i className="fa fa-university fa-2x text-default" style={{color: "blueviolet"}}
                   onClick={() => this.initPopUp(spot)}/>
            )
        }else if (property_type === "HOSPITAL") {
            return (
                <i className="fa fa-h-square fa-2x text-default" style={{color: "blueviolet"}}
                   onClick={() => this.initPopUp(spot)}/>
            )
        }else if (property_type === "MDU") {
            return (
                <i className="fa fa-building fa-2x text-default" style={{color: "blueviolet"}}
                   onClick={() => this.initPopUp(spot)}/>
            )
        } else {
            return (
                <i className="fa fa-building fa-2x text-default" style={{color: "blueviolet"}}
                   onClick={() => this.initPopUp(spot)}/>
            )
        }


    }

    initPopUp(spot) {
        this.setState({displayPopUp: true, popUpData: spot})
    }

    closePopUp() {
        this.setState({displayPopUp: false})
    }

    loadPopUp = () => {

        if (this.state.displayPopUp) {
            return (
                <Popup longitude={this.state.popUpData.property_long}
                       latitude={this.state.popUpData.property_lat}
                       closeButton={true}
                       closeOnClick={false}
                       className={"mapbox_property"}
                >
                    <div>
                        {"Property id: " + this.state.popUpData.property_id}
                        <br/>
                        {"Name: " +(this.state.popUpData.property_name === null ? "" : this.state.popUpData.property_name)}
                        <br/>
                        {"Owner: " +(this.state.popUpData.DealerName === null ? "" : this.state.popUpData.DealerName)}
                        <br/>
                        <Button style={{padding: "0px 0px"}} bsStyle="primary" simple bsSize="sm" onClick={() => this.setState({showPropertyModal: true, property_id: this.state.popUpData.property_id})}>more...</Button>
                    </div>
                </Popup>
            );
        }

    }

    setEditPropertyModal = (id, value, action) => {

        this.setState({
            showPropertyModal: value,
            property_id: null
        })
    }

    renderPropertyModal = () => {

        let bodyMessageVar = "";

        if (this.state.showPropertyModal) {
            return <ModalProperty
                headerMessage={"Edit Property"}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setEditPropertyModal}
                handlerID={this.state.property_id}
            />
        }
    }

    renderMarkers = ({longitude, latitude, pointCount}) => {

        if (pointCount > 1000) {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style1k, background: '#7500db'}}>{pointCount}</div>
                </Marker>
            );
        } else if (pointCount > 100000) {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style100k, background: '#7500db'}}>{pointCount}</div>
                </Marker>
            );
        } else {
            return (
                <Marker longitude={longitude} latitude={latitude}>
                    <div style={{...style, background: '#7500db'}}>{pointCount}</div>
                </Marker>
            );
        }


    };

    render() {

        return (
            <div>
                {this.renderPropertyModal()}
                <ButtonGroup style={{marginBottom: "10px"}}>
                    <Button onClick={() => this.setMapStyle('light')} fill bsStyle="primary" bsSize="xs">Light</Button>
                    <Button onClick={() => this.setMapStyle('dark')} fill bsStyle="default" bsSize="xs">Dark</Button>
                    <Button onClick={() => this.setMapStyle('streets')} fill bsStyle="info" bsSize="xs">Streets</Button>
                </ButtonGroup>
                <legend/>

                <MapGL
                    style={{width: '100%', height: '500px'}}
                    // mapStyle="mapbox://styles/mapbox/streets-v11"
                    // mapStyle="mapbox://styles/mapbox/light-v9"
                    // mapStyle="mapbox://styles/mapbox/dark-v9"
                    mapStyle={mapStyles[this.state.styleId]}

                    accessToken={process.env.REACT_APP_MAPBOX_KEY}
                    scrollZoom={false}
                    onViewportChange={this._onViewportChange}
                    onClick={this.closePopUp.bind(this)}
                    zoom={this.state.viewport.zoom}
                    {...this.state.viewport}
                >
                    <Cluster
                        radius={40}
                        extent={512}
                        nodeSize={64}
                        component={this.renderMarkers}>
                        {this.loadPropertyMarkers()}

                    </Cluster>
                    {this.loadPopUp()}
                    <NavigationControl showCompass showZoom position='top-right'/>
                </MapGL>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedMapBoxProperties = connect(mapStateToProps)(MapBoxProperties);
export {connectedMapBoxProperties as MapBoxProperties};

// export default MapBox;