import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class DropDownTvGuide extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: false,
            guide: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        const url = process.env.REACT_APP_API_URL+"/channels/guide";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url,axiosConfig)
            .then(res => {
                const guide = res.data;
                const loading = false;
                this.setState({guide, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
    }

    render() {
        return (
            <div>
                {this.state && this.state.guide &&
                <Select
                    placeholder="Channel Select"
                    name="guideSelect"
                    autoFocus={false}
                    multi={false}
                    isSearchable={true}
                    disabled={this.props.disabled}
                    value={this.props.guideChannelSelected}
                    options={this.state.guide}
                    onChange={value => this.handleSelect(value)}

                />
                }
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownTvGuide = connect(mapStateToProps)(DropDownTvGuide);
const connectedDropDownTvGuideTrans = withNamespaces('common')(connectedDropDownTvGuide);
export {connectedDropDownTvGuideTrans as DropDownTvGuide};

