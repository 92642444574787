import React from "react";
import connect from "react-redux/es/connect/connect";
import {Pie} from 'react-chartjs-2';
import {default_chart_colors} from "../../../variables/chartsVariables";
import {withNamespaces} from "react-i18next";
import {userActions} from "../../../authentication/_actions";
import axios from "axios";

class ChartSubscribersOverviewStbPie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            tempDataPie: {}
        };
    }

    componentDidMount() {

        this.updatePieChart();
    }


    updatePieChart() {

        let label1 = this.props.t('charts_stb_overview.stb_never_connected', {framework: "react-i18next"})
        let label2 = this.props.t('charts_stb_overview.stb_connected_once', {framework: "react-i18next"})

        let labels = [
            label1,
            label2
        ]

        let data = [];
        // data = [
        //     3071,
        //     45500
        // ]

        //console.log(data)

        let url = process.env.REACT_APP_API_URL + '/Reports/StbOverviewPieChart'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {
                const chartData = res.data;
                data.push(chartData[0].TotalDevices - chartData[0].ConnectedAtLeastOnce)
                data.push(chartData[0].ConnectedAtLeastOnce)

                // console.log(chartData)
                // console.log(chartData[0])
                //console.log(chartData[0].ConnectedAtLeastOnce)
                //console.log(chartData[0].TotalDevices)
                //console.log(data)

                let tempDataPie = {
                    labels: labels,
                    datasets: [{
                        label: 'STBs Connection Analysis',
                        backgroundColor: default_chart_colors,
                        hoverBackgroundColor: default_chart_colors,
                        data: data
                    }]
                };

                this.setState({tempDataPie});

            });


    }



    render() {
        return (
            <div>
                <div className="ChartSubscribersOverviewPie">
                    <Pie
                        data={this.state.tempDataPie}
                        width={100}
                        height={300}
                        options={
                            {
                                legend: {
                                    display: true,
                                    position: 'right'
                                },
                                maintainAspectRatio: false
                            }
                        }
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedChartSubscribersOverviewStbPie = connect(mapStateToProps)(ChartSubscribersOverviewStbPie);
const connectedChartSubscribersOverviewStbPieTrans = withNamespaces('common')(connectedChartSubscribersOverviewStbPie);
export {connectedChartSubscribersOverviewStbPieTrans as ChartSubscribersOverviewStbPie};