import React, {Component} from "react";
import ReactPlayer from 'react-player'
import ControlLabel from "react-bootstrap/es/ControlLabel";
import axios from "axios";

export class VideoPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            videoUrl: 'https://',
            videoStatus: null,
            duration: null,
            progress: null,
            playing: false,
            token: ""
        }

        this.handleParent = this.handleParent.bind(this);

    }

    handleParent() {
        this.props.handlerFromParent(this.state.videoStatus, this.state.playing, this.props.handlerType);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.handlerSource !== prevProps.handlerSource || this.props.handlerType !== prevProps.handlerType || this.props.handlerState !== prevProps.handlerState) {

            // console.log(this.props.handlerSource)
            // console.log(this.props.handlerType)
            // console.log(this.props.handlerState)

            this.getTokenAndPlay();

        }

    }

    componentDidMount() {
        this.getToken();
    }

    componentWillUnmount() {
        this.setState({playing: false, videoStatus: "paused"})
    }

    getToken() {

        let dataUrl = process.env.REACT_APP_API_URL + '/Wms/Token'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""
        let wmsToken = ""

        if (user) {

            token = user.token

            let axiosConfig = {
                headers: {
                    'X-Id-Token': token
                }
            };

            axios
                .get(dataUrl, axiosConfig)
                .then(res => {
                    const data = res.data;
                    wmsToken = data.token
                    //console.log(wmsToken)
                    this.setState({
                        token: wmsToken
                    })

                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        }


    }

    getTokenAndPlay() {

        let url = "";
        let status = this.props.handlerState === "playing"

        if (this.props.handlerSource === null || this.props.handlerSource === "") {
            this.setState({playing: false, videoStatus: "paused"})
            this.handleParent()

        } else {

            url = this.props.handlerSource;

            this.setState({
                videoUrl: url,
                playing: status
            })
        }


    }

    onError(e) {
        //console.log(e)
        if (e === "hlsError") {
            this.setState({videoStatus: "failed to load", videoUrl: "https://", playing: false})
            this.handleParent()
        }
    }

    onDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({duration})
    }

    onProgress = state => {
        //console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    onEnded = () => {
        //console.log('onEnded')
        this.setState({playing: false, videoStatus: "finished"})
        this.handleParent()
    }

    onPause = () => {
        //console.log('onPause')
        this.setState({playing: false, videoStatus: "paused"})
        this.handleParent()
    }

    onPlay = () => {
        //console.log('onPlay')
        this.setState({playing: true, videoStatus: "playing", videoUrl: this.props.handlerSource})
        this.handleParent()
    }

    onReady = () => {
        //console.log('onReady')
        this.setState({videoStatus: "ready"})
    }

    onStart = () => {
        //console.log('onStart')
        this.setState({videoStatus: "onStart"})
    }

    render() {
        return (
            <div>
                <div>
                    <ControlLabel>Status: </ControlLabel>
                    {this.state.videoStatus}
                </div>
                <div className='playerWrapperCSS'>
                    <ReactPlayer
                        className='reactPlayerCSS'
                        //url='https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8'
                        url={this.state.videoUrl + this.state.token}
                        width='100%'
                        height='100%'
                        playing={this.state.playing}
                        controls
                        onError={(e) => this.onError(e)}
                        onReady={() => this.onReady}
                        onStart={() => this.onStart}
                        onPlay={this.onPlay}
                        onEnablePIP={this.onEnablePIP}
                        onDisablePIP={this.onDisablePIP}
                        onPause={this.onPause}
                        //onBuffer={() => console.log('onBuffer')}
                        //onSeek={(e) => console.log('onSeek', e)}
                        onEnded={this.onEnded}
                        onProgress={(e) => this.onProgress(e)}
                        onDuration={(e) => this.onDuration(e)}
                        //preload={false}
                    />
                </div>
            </div>
        );
    }
}

export default VideoPlayer;
