import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import Button from "../../components/CustomButton/CustomButton";
import Switch from "react-bootstrap-switch";
import connect from "react-redux/es/connect/connect";
import axios from "axios";
import {userActions} from '../../authentication/_actions';
import {Redirect} from "react-router";
import ModalSave from "../../components/CustomModals/ModalSave";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

class EditPpvPriceGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            redirect: false,
            ppvRedirect: false,

            back_button_text: "Back",
            showSaveModal: false,

            ppvPriceGroupArray: [],

            ppgroup_description: null,
            ppgroup_duration: null,
            ppgroup_id: null,
            ppgroup_name: null,
            ppgroup_price: null,
            ppgroup_status: false,

            statusSwitch: false,

            _notificationSystem: null,

            custom_data: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            this.setState({loading: false, back_button_text: "cancel", ppgroup_name: null, ppgroup_id: 0})
        } else {

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            let dataUrl = process.env.REACT_APP_API_URL + "/PriceGroupPpv/" + handle;

            axios.get(dataUrl, axiosConfig).then(response => {
                const ppvPriceGroupArray = response.data[0];
                //console.log(ppvPriceGroupArray);

                let statusSwitch = ppvPriceGroupArray.ppgroup_status === 1 ? true : false

                this.setState({
                        ppvPriceGroupArray,
                        statusSwitch,
                        loading: false,
                        ppgroup_description: ppvPriceGroupArray.ppgroup_description,
                        ppgroup_duration: ppvPriceGroupArray.ppgroup_duration,
                        ppgroup_id: ppvPriceGroupArray.ppgroup_id,
                        ppgroup_name: ppvPriceGroupArray.ppgroup_name,
                        ppgroup_price: ppvPriceGroupArray.ppgroup_price,
                        custom_data: ppvPriceGroupArray.custom_data,
                        //ppgroup_status: ppvPriceGroupArray.ppgroup_status
                    }
                );

            }).catch(error => {
                this.setState({loading: false});
                console.log(error);
                this.props.dispatch(userActions.logoutUnauthorized());
            });
        }


    }

    cancelPpvGroupEdit() {
        this.setRedirect()
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/pricing/ppv-price";
        let pathPpv = "/edit-ppv-price/" + this.state.ppgroup_id;

        if (this.state.redirect) {
            this.setState({redirect: false, ppvRedirect: false})
            return <Redirect push to={path}/>
        }

        if (this.state.ppvRedirect) {
            this.setState({redirect: false, ppvRedirect: false})
            return <Redirect push to={pathPpv}/>
        }
    }

    createPpvPriceGroup() {

        const url = process.env.REACT_APP_API_URL + "/PriceGroupPpv";

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0

        var postData = {
            ppv_group: {
                ppgroup_description: this.state.ppgroup_description,
                ppgroup_duration: this.state.ppgroup_duration,
                ppgroup_name: this.state.ppgroup_name,
                ppgroup_price: this.state.ppgroup_price,
                custom_data: this.state.custom_data,
                ppgroup_status: statusSwitch
            }
        };


        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    const ppvPriceGroupArray = res.data;
                    this.setState({ppgroup_id: ppvPriceGroupArray.ppgroup_id})
                    this.notification("success")
                    this.setPpvPriceGroupRedirect();
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    updatePpvPriceGroup() {


        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/PriceGroupPpv/" + handle;

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0

        // Send a PUT request
        var postData = {
            ppv_group: {
                ppgroup_description: this.state.ppgroup_description,
                ppgroup_duration: this.state.ppgroup_duration,
                ppgroup_id: this.state.ppgroup_id,
                ppgroup_name: this.state.ppgroup_name,
                ppgroup_price: this.state.ppgroup_price,
                custom_data: this.state.custom_data,
                ppgroup_status: statusSwitch
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createPpvPriceGroup();
            } else {
                this.updatePpvPriceGroup();
            }
        }
    }

    setPpvPriceGroupRedirect = () => {

        this.setState({
            ppvRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"PPV Price Group: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.ppgroup_id === 0 ? '' : this.state.ppgroup_id}
            />
        }

    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Channel Category has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Category</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    title() {
        if (this.state.ppgroup_name === null) {
            return "New - PPV Configuration";
        } else {
            return this.state.ppgroup_name + " - PPV Configuration";
        }
    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>


                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                title={this.title()}
                                content={
                                    <div>
                                        <FormGroup>
                                            <p className="category">Status</p>
                                            <Switch
                                                name={"statusSwitch"}
                                                disabled={this.state.loading}
                                                value={this.state.statusSwitch}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>ID</ControlLabel>
                                            <FormControl placeholder="ppgroup_id"
                                                         type="number"
                                                         name={"ppgroup_id"}
                                                         disabled
                                                         value={this.state.ppgroup_id || 0}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Name</ControlLabel>
                                            <FormControl placeholder="Enter Short Name"
                                                         type="text"
                                                         name={"ppgroup_name"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.ppgroup_name || ''}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price <code>(USD)</code></ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill default><i
                                                        className="fa fa-dollar"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter price"
                                                             type="text"
                                                             name="ppgroup_price"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.ppgroup_price || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Duration <code>(Hours)</code> </ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill twitter><i
                                                        className="fa fa-clock-o"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter duration"
                                                             type="text"
                                                             name="ppgroup_duration"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.ppgroup_duration || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="ppgroup_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.ppgroup_description || ''}
                                            />
                                        </FormGroup>

                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelPpvGroupEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                    </Row>
                </Grid>


            </div>

        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedEditPpvPriceGroup = connect(mapStateToProps)(EditPpvPriceGroup);
const connectedEditPpvPriceGroupTrans = withNamespaces('common')(connectedEditPpvPriceGroup);
export {connectedEditPpvPriceGroupTrans as EditPpvPriceGroup};
