import React, {Component} from "react";
import {Grid} from "react-bootstrap";
import {DataTvGuideStatic} from "../../components/Middleware/DataTvGuideStatic.jsx";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ListTvGuideStatic extends Component {

    constructor(props) {
        super(props);
        this.state = {};

    }


    render() {
        return (
            <div className="main-content">
                <Grid fluid>
                    <DataTvGuideStatic/>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedListTvGuideStatic = connect(mapStateToProps)(ListTvGuideStatic);
const connectedListTvGuideStaticTrans = withNamespaces('common')(connectedListTvGuideStatic);
export {connectedListTvGuideStaticTrans as ListTvGuideStatic};
