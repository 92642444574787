import React, {Component} from "react";
import {Grid, Modal, Row, Table} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ModalManualySelectEpisode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditEpisode: true,

            vodEpisodeStatusSwitch: false,
            vod_series_id: 0,

            vodEpisodeArray: [],
            vod_id: null,
            vod_status: null,
            vod_name_short: null,
            vod_position: 0,
            vod_description: null,
            vod_year: null,
            vod_image: null,
            vod_trailer_iptv_url: null,
            vod_trailer_ott_url: null,
            vod_iptv_url: null,
            vod_ott_url: null,
            vod_pre_roll: null,
            vod_mid_roll: null,
            vod_tmdb_id: null,
            vod_vut_billing_id: null,

            vod_image_temp: false,
            vod_image_folder_temp: "",

            vodEpisodesList: [],
            selected: [],
            selectedToDisplay: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleSelect = (e) => {

        const selected = this.state.selected;
        //selected[e.target.name] = e.target.checked;

        //console.log(e.target.name)
        //console.log(e.target.checked)
        //console.log(selected[e.target.name])

        selected[e.target.name] = e.target.checked;

        this.setState({selected});
        //console.log(selected)

        this.parseArray()
    }

    parseArray() {

        let episodesArraySelected = [];

        let a = this.state.selected;

        Object.keys(a).forEach(function (keyName, keyIndex) {
            // use keyName to get current key's name
            // and a[keyName] to get its value
            if (a[keyName] === true) {
                episodesArraySelected.push(keyName.replace("e", "").toString())
            }
        })

        let selectedToDisplay = episodesArraySelected.join(',');
        this.setState({selectedToDisplay});
        //console.log(selectedToDisplay)

    }

    //TMDB
    loadTMDBEpisodes() {

        //let tmdb_id = sessionStorage.getItem('series_main_tmdb_id')
        //let season = sessionStorage.getItem('series_season_selected')

        let tmdb_id = this.props.handlerProviderID;
        let season = this.props.handlerSeasonNumber;

        //console.log("tmdb_id:" + tmdb_id)
        //console.log("season:" + season)

        //if (sessionStorage.getItem('series_main_tmdb_id') !== undefined && sessionStorage.getItem('series_main_tmdb_id') !== 0) {
        if (tmdb_id !== undefined && tmdb_id !== 0) {

            const url = "https://api.themoviedb.org/3/tv/" + tmdb_id + "/season/" + season + "?api_key=1a310ae853a5b25f2ca396e205bcfa6d";

            axios
                .get(url)
                .then(res => {

                    let episodesData = res.data.episodes
                    const loading = false;

                    //console.log(episodesData);

                    //console.log(episodesData);
                    this.setState({loading: true});

                    for (let i = 0; i < episodesData.length; i++) {
                        //TODO BELOW
                        //this.createEpisode(episodesData[i]);
                    }

                    this.setState({
                        vodEpisodesList: episodesData,
                        loading
                    });

                    //TODO BELOW
                    //this.loadFromMiddlewareEpisodes()

                }).catch(error => {
                this.setState({loading: false});
                console.log(error);
            });
        }


    }

    loadTMDBEpisodesToMiddleware() {

        for (let i = 0; i < this.state.vodEpisodesList.length; i++) {

            if (this.state.selectedToDisplay.includes(this.state.vodEpisodesList[i].id)) {
                //console.log(this.state.vodEpisodesList[i])
                this.createEpisode(this.state.vodEpisodesList[i]);
            }
        }

        this.setState({showEditEpisode: false})
        this.props.handlerFromParent(false, "save");

    }

    createEpisode(data) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode";

        //seriesCatID
        //seriesCatDisplay

        var postData = {
            vod_episode: {
                vod_position: data.episode_number,
                vod_name_short: data.name,
                vod_name_long: data.name,
                vod_description: data.overview,
                vod_image: data.still_path,
                vod_status: 1,
                vod_year: data.air_date,
                vod_seriesid: sessionStorage.getItem('series_edit'),
                vod_seasonid: sessionStorage.getItem('series_season_id'),
                vod_cat_id: this.props.seriesCatID === null ? null : this.props.handlerSeriesCatID.join(),
                vod_vut_category_display: this.props.handlerSeriesCatDisplay,
                vod_tmdb_id: data.id,
                vod_vut_type: 'SVOD'
            }
        };


        //console.log(postData);
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());

            })

        this.submitTmdbPosterImage(data.still_path)

    }

    submitTmdbPosterImage(image) {


        if (image !== null) {
            const url = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrl = "https://image.tmdb.org/t/p/w500" + image;

            let bodyFormData = new FormData();
            bodyFormData.set('filename', image);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('url', imgUrl);

            axios.post(url, bodyFormData)
                .then((res) => {
                    //this.setState({channel_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'vodEpisodeStatusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'vodEpisodeStatusSwitch') {
            this.setState({vodEpisodeStatusSwitch: state})
        }
    }

    handleClose = () => {
        this.setState({showEditEpisode: false})
        this.props.handlerFromParent(false, "nothing");
    }

    handleSave = () => {
        this.loadTMDBEpisodesToMiddleware()
    }

    componentDidMount() {

        this.loadTMDBEpisodes();

        //console.log(this.props.handlerSeriesCatID)
        //console.log(this.props.handlerSeriesCatDisplay)
    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "vod_image") {

            this.setState({
                vod_image_temp: true,
                vod_image: filename,
                vod_image_folder_temp: folder
            });
        }

    }

    submitPosterImage() {

        if (this.state.vod_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_image_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', this.state.vod_image);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    render() {
        return (
            <div className="modal-edit-episode">
                <Modal show={this.state.showEditEpisode} onHide={this.handleClose} bsSize="large">
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>
                            {this.props.handlerSeriesName + " - Season:" + this.props.handlerSeasonNumber}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'maxHeight': '600px', 'overflowY': 'auto'}}>
                        <div>
                            <p> {"Select episodes to add"}</p>
                            <p> {"Selected id's: [" + this.state.selectedToDisplay.toString() + "]"} </p>

                            <Grid fluid>
                                <Row>
                                    <Table striped responsive>
                                        <thead>
                                        <tr>
                                            <th className="text-center">ID</th>
                                            <th className="text-center">poster_path</th>
                                            <th className="text-center">episode_number</th>
                                            <th className="text-center">name</th>
                                            <th className="text-center">air_date</th>
                                            <th className="text-center">Select</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.vodEpisodesList.map((episodes) => {
                                                return (
                                                    <tr key={"episodesTr" + episodes.id}>
                                                        <td className="text-center">{episodes.id}</td>
                                                        <td className="text-center">
                                                            <img
                                                                src={episodes.still_path !== null ? "https://image.tmdb.org/t/p/w500" + episodes.still_path : ""}
                                                                width="60px" alt={""}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">{episodes.episode_number}</td>
                                                        <td className="text-center">{episodes.name}</td>
                                                        <td className="text-center">{episodes.air_date}</td>
                                                        <td className="text-center">
                                                            <input
                                                                name={"e" + episodes.id}
                                                                key={episodes.id}
                                                                type="checkbox"
                                                                checked={this.state.selected[episodes.id]}
                                                                onChange={this.handleSelect.bind(this)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </Grid>

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button bsStyle="warning" fill onClick={this.handleSave}>
                            save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalManualySelectEpisode = connect(mapStateToProps)(ModalManualySelectEpisode);
const connectedModalManualySelectEpisodeTrans = withNamespaces('common')(connectedModalManualySelectEpisode);
export {connectedModalManualySelectEpisodeTrans as ModalManualySelectEpisode};