import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import Switch from "react-bootstrap-switch";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";

class ModalDeviceMenuItem extends Component {

    constructor(props) {
        super(props);

        this.state = {

            showModalCustomerDevice: true,

            statusSwitch: false,

            id: null,
            menu_item_category_id: null,
            menu_item_device_type: null,
            menu_item_name: null,
            menu_item_position: null,
            menu_item_poster: null,
            menu_item_status: null,
            menu_item_type: null,

            menu_item_poster_temp: false,
            menu_item_poster_folder_temp: ""

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleMenuItemImageSelect = this.handleMenuItemImageSelect.bind(this);

    }

    handleMenuItemImageSelect(folder, filename, imagePosition) {

        console.log("" + folder);
        console.log("" + filename);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "menu_item_poster") {
            this.setState({
                menu_item_poster_temp: true,
                menu_item_poster: filename,
                menu_item_poster_folder_temp: folder
            });
        }

    }

    submitPosterImage(filename_poster) {

        if (this.state.menu_item_poster_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.menu_item_poster_folder_temp);
            bodyFormData.set('location', "device_menu");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({menu_item_poster_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalCustomerDevice: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    manageSaveDevice() {

        if (this.props.newMenuItem === true) {
            this.createMenuItem();
        } else {
            this.editMenuItem();
        }

    }

    createMenuItem() {

        const url = process.env.REACT_APP_API_URL + "/Device";

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        let postData = {
            device: {
                type: "add_device_menu",
                menu_item_category_id: this.state.menu_item_category_id,
                menu_item_device_type: this.props.deviceType,
                menu_item_name: this.state.menu_item_name,
                menu_item_position: this.state.menu_item_position,
                menu_item_poster: filename_poster,
                menu_item_status: menuItemStatus,
                menu_item_type: this.state.menu_item_type
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                //const device = res.data;

                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.handlerID, false, "reload");
                //this.submitPosterImage();

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })
    }

    editMenuItem() {

        const url = process.env.REACT_APP_API_URL + "/Device";

        let menuItemStatus = this.state.statusSwitch === true ? 1 : 0;

        //this.submitPosterImage();

        //RENAME FUNCTIONS START POSTER NORMAL
        let filename_poster = ""

        if (this.state.menu_item_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_poster = timeUTC + "_" + this.state.menu_item_poster
            this.setState({menu_item_poster: filename_poster})
            console.log("*****" + filename_poster)
            this.submitPosterImage(filename_poster);
        } else {
            filename_poster = this.state.menu_item_poster
        }

        let postData = {
            device: {
                type: "update_device_menu",
                menu_item_category_id: this.state.menu_item_category_id,
                menu_item_device_type: this.props.deviceType,
                menu_item_name: this.state.menu_item_name,
                menu_item_position: this.state.menu_item_position,
                menu_item_poster: filename_poster,
                menu_item_status: menuItemStatus,
                menu_item_type: this.state.menu_item_type,
                id: this.state.id
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if(user){
            token = user.token
        }
        else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                //this.setState({loading: false})
                this.setState({showModalMessage: false})
                this.props.handlerFromParent(this.props.handlerID, false, "reload");
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })
    }

    componentDidMount() {

        if (this.props.newMenuItem === false) {

            let statusSwitch = false

            if (this.props.menuItemObject.menu_item_status === 1) {
                statusSwitch = true
            }

            let id = this.props.menuItemObject.id
            let menu_item_category_id = this.props.menuItemObject.menu_item_category_id
            let menu_item_device_type = this.props.deviceType
            let menu_item_name = this.props.menuItemObject.menu_item_name
            let menu_item_position = this.props.menuItemObject.menu_item_position
            let menu_item_poster = this.props.menuItemObject.menu_item_poster
            let menu_item_status = this.props.menuItemObject.menu_item_status
            let menu_item_type = this.props.menuItemObject.menu_item_type

            this.setState(
                {
                    id,
                    menu_item_category_id,
                    menu_item_device_type,
                    menu_item_name,
                    menu_item_position,
                    menu_item_poster,
                    menu_item_status,
                    menu_item_type,
                    statusSwitch
                }
            )

        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });


    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }

    }

    render() {
        return (
            <div>
                <Modal show={this.state.showModalCustomerDevice} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={5}>

                                    <Row>
                                        <Col sm={6}>

                                            <FormGroup>
                                                <ControlLabel>Status</ControlLabel>
                                                <p className="category"/>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='statusSwitch'
                                                    value={this.state.statusSwitch}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>ID</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="id"
                                                    disabled
                                                    value={this.state.id || ''}
                                                    placeholder="id"
                                                    onChange={this.handleInputChange}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Device Type</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="menu_item_device_type"
                                                disabled
                                                value={this.state.menu_item_device_type || ''}
                                                placeholder="menu_item_device_type"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>

                                    </Row>
                                    <Row>
                                        <ImageUpload
                                            imageName={this.state.menu_item_poster}
                                            imageType="device_menu"
                                            imagePosition={"menu_item_poster"}
                                            imageTemp={this.state.menu_item_poster_temp}
                                            imageFolderTemp={this.state.menu_item_poster_folder_temp}
                                            //imageSource={this.state.tmdb_status === undefined ? false : this.state.tmdb_status}
                                            handlerFromParent={this.handleMenuItemImageSelect}
                                            //imageTmdbType={"poster_path"}

                                        />
                                    </Row>

                                </Col>
                                <Col sm={1}>
                                </Col>
                                <Col sm={5}>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Name</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="menu_item_name"
                                                value={this.state.menu_item_name || ''}
                                                placeholder="menu_item_name"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Position</ControlLabel>
                                            <FormControl
                                                type="number"
                                                name="menu_item_position"
                                                value={this.state.menu_item_position || ''}
                                                placeholder="menu_item_position"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Item Type</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="menu_item_type"
                                                value={this.state.menu_item_type || ''}
                                                placeholder="menu_item_type"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup>
                                            <ControlLabel>Category ID</ControlLabel>
                                            <FormControl
                                                type="text"
                                                name="menu_item_category_id"
                                                value={this.state.menu_item_category_id || ''}
                                                placeholder="menu_item_category_id"
                                                onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>


                        </Grid>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalDeviceMenuItem = connect(mapStateToProps)(ModalDeviceMenuItem);
const connectedModalDeviceMenuItemTrans = withNamespaces('common')(connectedModalDeviceMenuItem);
export {connectedModalDeviceMenuItemTrans as ModalDeviceMenuItem};