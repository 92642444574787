import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";

class ModalRestore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalRestore: true
        };

    }

    handleClose = () => {
        this.setState({showModalRestore: false})
        this.props.handlerFromParent(this.props.handlerID, false,"nothing");
    }

    handleRestore = () => {
        this.setState({showModalRestore: false})
        this.props.handlerFromParent(this.props.handlerID, false,"restore");
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="modal-restore">
                <Modal show={this.state.showModalRestore} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + "-" + this.props.handlerID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p> {this.props.bodyMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button  bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button  bsStyle="warning" fill onClick={this.handleRestore}>
                            restore
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ModalRestore;