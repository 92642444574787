import React, {Component} from "react";
import {Redirect} from 'react-router'
import { Row, Col} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import {DataCustomersPackageStatus} from "../../components/Middleware/DataCustomersPackageStatus.jsx";
import {StatsCustomersView} from "../../components/CustomStatsCard/StatsCustomersView";
import {withNamespaces} from "react-i18next";

class ListCustomersPackageStatus extends Component {

    constructor(props) {

        let user = JSON.parse(localStorage.getItem('user'));

        super(props);
        this.state = {
            redirect: false,
            lastUpdate: "",
            access_level: user.level
        };

        this.handleClick = this.handleClick.bind(this)
    }

    handleClick = () => {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/add-customer/new";

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }
    }


    renderCustomerList = () => {

        return (
            <div>
                <Card
                    title="Expiring Customers"
                    content={
                        <DataCustomersPackageStatus
                            lastUpdate={this.state.lastUpdate}
                            listState="active"/>
                    }
                />
            </div>
        );
    }


    render() {
        return (
            <div>
                {this.renderRedirect()}
                    <StatsCustomersView/>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                   <div>
                                       {this.renderCustomerList()}
                                   </div>
                                }
                            />
                        </Col>
                    </Row>
            </div>
        );
    }
}

export default withNamespaces('common')(ListCustomersPackageStatus);
