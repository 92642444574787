import { middlewareConstants } from '../_constants';

export const middlewareActions = {
    refresh,
    clear
};

function refresh(message) {
    //console.log("REDUX: REFRESH");
    return { type: middlewareConstants.REFRESH, message };
}

function clear() {
    //console.log("REDUX: CLEAR");
    return { type: middlewareConstants.CLEAR };
}