import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";

class ModalDelete extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalDelete: true
        };

    }

    handleClose = () => {
        this.setState({showModalDelete: false})
        this.props.handlerFromParent(this.props.handlerID, false,"nothing");
    }

    handleDelete = () => {
        this.setState({showModalDelete: false})
        this.props.handlerFromParent(this.props.handlerID, false,"delete");
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="modal-delete">
                <Modal show={this.state.showModalDelete} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage+ "-" + this.props.handlerID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p> {this.props.bodyMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button  bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button  bsStyle="danger" fill onClick={this.handleDelete}>
                            delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ModalDelete;