import React, {Component} from "react";
import axios from "axios/index";
import Datetime from "react-datetime";
import {Grid, Row, Col, FormGroup, InputGroup, ControlLabel} from "react-bootstrap";
// import Button from "../../components/CustomButton/CustomButton.jsx"
import Card from "../../components/Card/Card.jsx";
import {DataTvGuideDynamic} from "../../components/Middleware/DataTvGuideDynamic.jsx";
import {DropDownTvGuide} from "../../components/CustomDropDown/DropDownTvGuide";
import {StatsTvGuide} from "../../components/CustomStatsCard/StatsTvGuide";
import moment from 'moment';
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ListTvGuideDynamic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            channel_id: null,
            channel_guide_data: null,
            playingNow: false,
            dateStart: null,
            dateEnd: null,
            channel_name: "",
            UnixMaxDate: null,
            UnixMinDate: null
        };

        this.handleChildChannelGuideSelect = this.handleChildChannelGuideSelect.bind(this);

    }

    handleChildChannelGuideSelect(data) {

        let channel_id = data === null ? 0 : data.value
        let channel_name = data === null ? "All Channels" : data.label

        console.log(data)

        this.setState({
            channel_guide_data: data,
            playingNow: false,
            channel_id,
            channel_name
        });

    }

    handleDateFrom(date) {
        console.log(moment.utc(date).format('X'))
        //console.log(moment.utc(date).format('LLL').toString())
        let dateStart = moment.utc(date).format('X')

        this.setState({dateStart, UnixMinDate: date})
    };

    handleDateTill(date) {
        console.log(moment.utc(date).format('X'))
        //console.log(moment.utc(date).format('LLL').toString())

        let dateEnd = moment.utc(date).format('X')

        this.setState({dateEnd, UnixMaxDate: date})
    };

    componentDidMount() {

        const url = process.env.REACT_APP_API_URL + "/TvGuide/status";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const guideStatusValue = res.data[0];

                //console.log(moment.unix(guideStatusValue.UnixMinDate).local().format('YYYY-MM-DD HH:mm:ss'))
                //console.log(moment.unix(guideStatusValue.UnixMaxDate).local().format('YYYY-MM-DD HH:mm:ss'))

                this.setState({
                    loading: false,
                    channel_id: guideStatusValue.FirstID,
                    UnixMaxDate: moment.unix(guideStatusValue.UnixMaxDate).local(),
                    UnixMinDate: moment.unix(guideStatusValue.UnixMinDate).local()
                });
            });
    }

    renderStartField = () => {

        if (!this.state.playingNow) {
            return (
                <FormGroup>
                    <ControlLabel>Start</ControlLabel>
                    <InputGroup>
                        {/*<InputGroup.Addon>*/}
                        {/*<span>From</span>*/}
                        {/*</InputGroup.Addon>*/}
                        <Datetime
                            name={"fromDate"}
                            inputProps={{placeholder: "From"}}
                            //defaultValue={new Date()}
                            value={this.state.UnixMinDate}
                            onChange={this.handleDateFrom.bind(this)}
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            timeFormat={true}
                        />
                        <InputGroup.Addon>
                            <i className="glyphicon glyphicon-calendar"/>
                        </InputGroup.Addon>
                    </InputGroup>
                </FormGroup>
            )
        }
    }

    renderEndField = () => {

        if (!this.state.playingNow) {
            return (
                <FormGroup>
                    <ControlLabel>End</ControlLabel>
                    <InputGroup>
                        {/*<InputGroup.Addon>*/}
                        {/*<span>Till</span>*/}
                        {/*</InputGroup.Addon>*/}
                        <Datetime
                            name={"tillDate"}
                            inputProps={{placeholder: "Till"}}
                            value={this.state.UnixMaxDate}
                            //defaultValue={new Date()}
                            onChange={this.handleDateTill.bind(this)}
                            dateFormat="YYYY-MM-DD HH:mm:ss"
                            timeFormat={true}
                        />
                        <InputGroup.Addon>
                            <i className="glyphicon glyphicon-calendar"/>
                        </InputGroup.Addon>
                    </InputGroup>
                </FormGroup>
            )
        }
    }

    renderChannelDropDown = () => {

        if (!this.state.playingNow) {
            return (
                <FormGroup>
                    <ControlLabel>Channels</ControlLabel>
                    <DropDownTvGuide
                        guideChannelSelected={this.state.channel_id}
                        name='categorySelect'
                        handlerFromParent={this.handleChildChannelGuideSelect}
                    />
                </FormGroup>
            )
        }
    }

    render() {
        return (
            <div className="main-content">
                <Grid fluid>
                    <StatsTvGuide/>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col lg={4} sm={6}>
                                                {this.renderChannelDropDown()}
                                            </Col>
                                            <Col lg={4} sm={6}>
                                                {this.renderStartField()}
                                            </Col>
                                            <Col lg={4} sm={6}>
                                                {this.renderEndField()}
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>

                    <DataTvGuideDynamic
                        epgChannelName={this.state.channel_name}
                        epgForChannel={this.state.channel_id}
                        dateStart={this.state.dateStart}
                        dateEnd={this.state.dateEnd}
                        playingNow={false}
                    />

                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedListTvGuideDynamic = connect(mapStateToProps)(ListTvGuideDynamic);
const connectedListTvGuideDynamicTrans = withNamespaces('common')(connectedListTvGuideDynamic);
export {connectedListTvGuideDynamicTrans as ListTvGuideDynamic};
