import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownCustomerProperties extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            loading: true,
            propertiesArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.handlerDealerId !== prevProps.handlerDealerId) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData(){

        if(this.props.handlerDealerId){

            if (AxiosHeader.getConfig() !== null) {

                const url = process.env.REACT_APP_API_URL+"/Customer/Dealers/"+this.props.handlerDealerId+"/properties";

                axios
                    .get(url,AxiosHeader.getConfig())
                    .then(res => {
                        const propertiesArray = res.data;
                        const loading = false;
                        this.setState({propertiesArray, loading});
                    })
                    .catch(error => {
                        this.setState({loading: false});
                        console.log(error);
                    });

            }else{
                this.props.dispatch(userActions.logoutUnauthorized());
            }

        }
    }

    render() {
        return (
            <Select
                placeholder="Property Select"
                name="propertySelect"
                autoFocus={false}
                multi={false}
                isSearchable={true}
                disabled={this.props.disabled}
                value={this.props.propertySelected}
                options={this.state.propertiesArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownCustomerProperties = connect(mapStateToProps)(DropDownCustomerProperties);
const connectedDropDownCustomerPropertiesTrans = withNamespaces('common')(connectedDropDownCustomerProperties);
export {connectedDropDownCustomerPropertiesTrans as DropDownCustomerProperties};

