import React, {Component} from "react";
import MapGL, {Marker, NavigationControl, FullscreenControl} from '@urbica/react-map-gl';
// import Cluster from '@urbica/react-map-gl-cluster';
import 'mapbox-gl/dist/mapbox-gl.css';
import connect from "react-redux/es/connect/connect";
// import {userActions} from "../../authentication/_actions";
// import axios from "axios";
// import {Link} from "react-router-dom";

// const style = {
//     width: '20px',
//     height: '20px',
//     color: '#fff',
//     background: '#1978c8',
//     borderRadius: '20px',
//     textAlign: 'center'
// };
//
// const style1k = {
//     width: '40px',
//     height: '40px',
//     color: '#fff',
//     background: '#1978c8',
//     borderRadius: '20px',
//     textAlign: 'center',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// };
//
// const style100k = {
//     width: '60px',
//     height: '60px',
//     color: '#fff',
//     background: '#1978c8',
//     borderRadius: '20px',
//     textAlign: 'center',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
// };

class MapBoxProperty extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewport: {
                width: 400,
                height: 400,
                latitude: 0,
                longitude: 0,
                zoom: 5
            },
            propertiesOnMap: [],
            displayPopUp: false,
            popUpData: null,
            styleId: 'streets',
            markerLatitude: 0,
            markerLongitude: 0
        };


    }

    _onViewportChange = viewport => {

        if (viewport.longitude > 0) {
            viewport.longitude = 0;
        }

        this.setState({viewport});

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.handlerLat !== prevProps.handlerLat) {

            this.setState(prevState => ({
                markerLatitude: this.props.handlerLat,
                markerLongitude: this.props.handlerLong,
                viewport: {
                    ...prevState.viewport,
                    latitude: this.props.handlerLat,
                    longitude: this.props.handlerLong
                }
            }))
        }
    }

    getMarker = () => {

        if (this.props.handlerPropertyType !== undefined) {
            if (this.props.handlerPropertyType === "HOME") {
                return (
                    <i className="fa fa-home fa-2x text-default" style={{color: "blueviolet"}}/>
                )
            } else if (this.props.handlerPropertyType === "UNIVERSITY") {
                return (
                    <i className="fa fa-university fa-2x text-default" style={{color: "blueviolet"}}/>
                )
            } else if (this.props.handlerPropertyType === "HOSPITAL") {
                return (
                    <i className="fa fa-h-square fa-2x text-default" style={{color: "blueviolet"}}/>
                )
            } else if (this.props.handlerPropertyType === "MDU") {
                return (
                    <i className="fa fa-building fa-2x text-default" style={{color: "blueviolet"}}/>
                )
            } else {
                return (
                    <i className="fa fa-building fa-2x text-default" style={{color: "blueviolet"}}/>
                )
            }
        }


    }

    addMarker(event) {

        const {lngLat} = event;

        console.log(event)
        console.log(lngLat.lat)
        console.log(lngLat.lng)

        this.props.handlerFromParent(lngLat.lat, lngLat.lng);

        this.setState(prevState => ({
            markerLatitude: lngLat.lat,
            markerLongitude: lngLat.lng,
            // viewport: {
            //     ...prevState.viewport,
            //     latitude: lngLat.lat,
            //     longitude: lngLat.lng
            // }
        }))
    }

    render() {

        return (
            <div style={{marginBottom: "10px"}}>
                <MapGL
                    style={{width: '100%', height: '256px'}}
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    accessToken={process.env.REACT_APP_MAPBOX_KEY}
                    scrollZoom={false}
                    doubleClickZoom={false}
                    onViewportChange={this._onViewportChange}
                    onClick={this.addMarker.bind(this)}
                    zoom={this.state.viewport.zoom}
                    {...this.state.viewport}
                >
                    <Marker
                        longitude={this.state.markerLongitude}
                        latitude={this.state.markerLatitude}
                        // onClick={onMarkerClick}

                    >
                        {/*<div> <i className="fa fa-building fa-2x text-default" style={{color: "blueviolet"}}/></div>*/}
                        <div>{this.getMarker(this.state.property_type)}</div>
                    </Marker>
                    <NavigationControl showCompass showZoom position='top-right'/>
                    <FullscreenControl position='top-left'/>
                </MapGL>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedMapBoxProperty = connect(mapStateToProps)(MapBoxProperty);
export {connectedMapBoxProperty as MapBoxProperty};
