import React from "react";
import axios from "axios/index";
import connect from "react-redux/es/connect/connect";
import {userActions} from "../../../authentication/_actions";
import {Pie} from 'react-chartjs-2';
import {default_chart_colors} from "../../../variables/chartsVariables";

class ChartDeviceRegistration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            tempData: {}
        };

    }

    componentDidMount() {

        this.updateData();
    }

    updateChart(data,labels) {

        //let labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        let tempData = {
            labels: labels,
            datasets: [{
                label: 'Devices',
                animation: true,
                backgroundColor: default_chart_colors,
                hoverBackgroundColor: default_chart_colors,
                data: data
            }]
        };

        this.setState({tempData});
    }

    updateData() {

        let url = process.env.REACT_APP_API_URL + '/Reports/Devices'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                const chartData = res.data;
                let data = [];
                let labels =[];

                for (let i = 0; i < chartData.length; i++) {
                    data.push(chartData[i].DeviceCount)
                    labels.push(chartData[i].DeviceType)
                }
                this.updateChart(data,labels);

            });


    }


    render() {
        return (
            <div className="ChartGraphDeviceRegistration">
                <Pie
                    data={this.state.tempData}
                    width={100}
                    height={300}
                    options={
                        {
                            legend: {
                                display: true,
                                position: 'right'
                            },
                            maintainAspectRatio: false
                        }
                    }
                />
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedChartDeviceRegistration = connect(mapStateToProps)(ChartDeviceRegistration);
export {connectedChartDeviceRegistration as ChartDeviceRegistration};