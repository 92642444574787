import React from "react";
import connect from "react-redux/es/connect/connect";
import {HorizontalBar} from 'react-chartjs-2';
import {default_chart_colors} from "../../../variables/chartsVariables";
import {userActions} from "../../../authentication/_actions";
import axios from "axios";
import {withNamespaces} from "react-i18next";

class ChartSubscribersOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            tempDataBar: {},
        };

    }

    componentDidMount() {

        this.updateData();
        this.updateBarChart();
    }

    updateBarChart(data) {

        let label1 = this.props.t('charts_stb_subscribers.weekly_active_subs', { framework: "react-i18next" })
        let label2 = this.props.t('charts_stb_subscribers.distinct_active_subs', { framework: "react-i18next" })
        let label3 = this.props.t('charts_stb_subscribers.total_subs', { framework: "react-i18next" })
        let label4 = this.props.t('charts_stb_subscribers.stb_conected_once', { framework: "react-i18next" })
        let label5 = this.props.t('charts_stb_subscribers.stb_delivered', { framework: "react-i18next" })


        let labels = [
            label1,
            label2,
            label3,
            label4,
            label5
        ]

        let tempDataBar = {
            labels: labels,
            datasets: [{
                backgroundColor: default_chart_colors,
                hoverBackgroundColor: default_chart_colors,
                data: data
            }]
        };

        this.setState({tempDataBar});
    }

    updateData() {

        let url = process.env.REACT_APP_API_URL + '/Reports/StbSubscribers'

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {'X-Id-Token': token}
        };

        axios
            .get(url, axiosConfig)
            .then(res => {
                //console.log(res.data)
                let data = []

                data.push(res.data['week'][0])
                data.push(res.data['month'][0])
                data.push(res.data['total'][0])
                data.push(res.data['once'][0])
                data.push(res.data['delivered'][0])

                this.updateBarChart(data)
            });


    }


    render() {
        return (
            <div>
                <div className="ChartSubscribersOverviewBar">
                    <HorizontalBar
                        data={this.state.tempDataBar}
                        width={100}
                        height={300}
                        options={
                            {
                                legend: {
                                    display: false
                                },
                                maintainAspectRatio: false,
                                layout: {
                                    padding: {
                                        left: 50,
                                        right: 50
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                            stepSize: 10000,
                                            callback: function (value) {
                                                var ranges = [
                                                    {divider: 1e6, suffix: 'M'},
                                                    {divider: 1e3, suffix: 'K'}
                                                ];

                                                function formatNumber(n) {
                                                    for (var i = 0; i < ranges.length; i++) {
                                                        if (n >= ranges[i].divider) {
                                                            return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                                        }
                                                    }
                                                    return n;
                                                }

                                                //return '$' + formatNumber(value);
                                                return formatNumber(value);
                                            }
                                        }
                                    }]
                                }
                            }
                        }
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedChartSubscribersOverview = connect(mapStateToProps)(ChartSubscribersOverview);
const connectedChartSubscribersOverviewTrans = withNamespaces('common')(connectedChartSubscribersOverview);
export {connectedChartSubscribersOverviewTrans as ChartSubscribersOverview};