import React, {Component, Suspense, lazy} from "react";

import {Nav, NavItem, Tab} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {withNamespaces} from "react-i18next";
import {ModalPlaylist} from "../../components/CustomModals/ModalPlaylist";

const EditCustomerGeneral = lazy(() => import('./indexGeneral.js'));
const EditCustomerDevices = lazy(() => import('./EditCustomerDevices'));
const EditCustomerPackages = lazy(() => import('./EditCustomerPackages'));
const EditCustomerNPVR = lazy(() => import('./EditCustomerNPVR'));
const EditCustomerVOD = lazy(() => import('./EditCustomerVOD'));
const EditCustomerPpv = lazy(() => import('./EditCustomerPpv'));
const EditCustomerMessages = lazy(() => import('./EditCustomerMessages'));
const EditCustomerSpeedTest = lazy(() => import('./EditCustomerSpeedTest'));
const EditCustomerHelp = lazy(() => import('./EditCustomerHelp'));
// const EditCustomerSupport = lazy(() => import('./EditCustomerSupport'));

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;


class EditCustomer extends Component {

    constructor(props) {
        super(props);

        //const {handle} = this.props.match.params
        //console.log(this.props.match)
        //console.log(this.props.match.params.handle)
        //console.log(this.props.match.params.parent)

        let action = null;
        if (this.props.match.url.includes('/add-child/')) {
            action = 'new-child';
        }

        if (this.props.match.url.includes('/add-bedroom/')) {
            action = 'new-bed';
        }

        this.state = {
            customerArray: [],
            loading: false,
            customer_id: action === null ? this.props.match.params.handle : action,
            parent_id: this.props.match.params.parent,
            common_area_id: this.props.match.params.common_area,
            lastUpdate: "",
            selectedTab: "customer_tab_general",
            showModalPlaylist: false,

            renderGeneral: false,
            renderDevices: false,
            renderPackages: false,
            renderVod: false,
            renderNpvr: false,
            renderPpv: false,
            renderMessages: false,
            renderSpeedTest: false,
            renderSupport: false,
            renderHelp: false

        };

        this.handleTabSelect = this.handleTabSelect.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);
        EditCustomer.handleInitialLoad();

    }

    static handleInitialLoad() {

        if (sessionStorage.getItem('customer_tab_key') === null) {
            sessionStorage.setItem('customer_tab_key', "customer_tab_general");
        }

    }

    handleTabSelect(selectedTab) {

        let dt = new Date();
        let utcDate = dt.toUTCString();

        this.setState({
            lastUpdate: utcDate
        })

        if (selectedTab !== undefined) {
            this.setState({selectedTab});
            sessionStorage.setItem('customer_tab_key', selectedTab);
        }

        if (selectedTab === "customer_tab_general") {
            this.setState({
                renderGeneral: true,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
            // this.renderCustomerGeneral()
        }
        if (selectedTab === "customer_tab_devices") {
            // this.renderCustomerDevices()
            this.setState({
                renderGeneral: false,
                renderDevices: true,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_packages") {
            // this.renderCustomerPackages()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: true,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_vod") {
            // this.renderCustomerVod()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: true,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_npvr") {
            // this.renderCustomerNpvr()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: true,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_ppv") {
            // this.renderCustomerPpv()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: true,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_messages") {
            // this.renderCustomerMessages()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: true,
                renderSpeedTest: false,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_speed_test") {
            // this.renderCustomerSpeedTest()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: true,
                renderSupport: false,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_help") {
            // this.renderCustomerHelp()
            // this.setState({
            //     renderGeneral: false,
            //     renderDevices: false,
            //     renderPackages: false,
            //     renderVod: false,
            //     renderNpvr: false,
            //     renderPpv: false,
            //     renderMessages: false,
            //     renderSpeedTest: false,
            //     renderSupport: true,
            //     lastUpdate: utcDate
            // })
        }
        if (selectedTab === "customer_support_tickets") {
            // this.renderCustomerHelp()
            this.setState({
                renderGeneral: false,
                renderDevices: false,
                renderPackages: false,
                renderVod: false,
                renderNpvr: false,
                renderPpv: false,
                renderMessages: false,
                renderSpeedTest: false,
                renderSupport: true,
                lastUpdate: utcDate
            })
        }
        if (selectedTab === "customer_tab_playlist") {
            this.setState({
                showModalPlaylist: true

            })
        }
    }

    dowloadPlaylistAction() {
        this.setState({
            showModalPlaylist: true

        })
    }

    renderCustomerGeneral = () => {

        if (this.state.renderGeneral) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerGeneral
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                            parentID={this.state.parent_id}
                            commonAreaID={this.state.common_area_id}
                        />
                    </Suspense>
                </div>
            );
        }

    }

    renderCustomerDevices = () => {

        if (this.state.renderDevices) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerDevices
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerPackages = () => {

        if (this.state.renderPackages) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerPackages
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerVod = () => {

        if (this.state.renderVod) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerVOD
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerNpvr = () => {

        if (this.state.renderNpvr) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerNPVR
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerPpv = () => {

        if (this.state.renderPpv) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerPpv
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerMessages = () => {

        if (this.state.renderMessages) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerMessages
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerSpeedTest = () => {

        if (this.state.renderSpeedTest) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerSpeedTest
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    renderCustomerHelp = () => {

        if (this.state.renderHelp) {
            return (
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditCustomerHelp
                            lastUpdate={this.state.lastUpdate}
                            customerID={this.state.customer_id}
                        />
                    </Suspense>
                </div>
            );
        }
    }

    // renderCustomerSupport = () => {
    //
    //     if (this.state.renderSupport) {
    //         return (
    //             <div>
    //                 <Suspense fallback={<div>Loading...</div>}>
    //                     <EditCustomerSupport
    //                         lastUpdate={this.state.lastUpdate}
    //                         customerID={this.state.customer_id}
    //                     />
    //                 </Suspense>
    //             </div>
    //         );
    //     }
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        window.scroll(0, 0)
        this.handleTabSelect(sessionStorage.getItem('customer_tab_key'))
    }

    componentWillUnmount() {

        if (sessionStorage.getItem('customer_tab_key') !== null) {
            sessionStorage.setItem('customer_tab_key', "customer_tab_general");
        }
    }

    renderPlaylistModal = () => {

        if (this.state.showModalPlaylist) {

            return (
                <ModalPlaylist
                    handlerFromParent={this.setDisplayPlaylistModal}
                    handlerSource="customer"
                    customer={this.state.customer_id}/>
            );
        }

    }

    setDisplayPlaylistModal = (id, value) => {

        this.setState({
            showModalPlaylist: value

        })

    }

    renderNavigation = () => {

        const {handle} = this.props.match.params

        if (handle === "new") {
            return (
                <Card content={
                    <div className="nav-container">
                        <Nav bsStyle="tabs" bsClass="nav nav-icons">
                            <NavItem eventKey="customer_tab_general">
                                <i className="fa fa-user-circle"/><br/>{this.props.t('customer_edit.customer_tab_general', {framework: "react-i18next"})}
                            </NavItem>
                        </Nav>
                    </div>
                }
                />
            );
        } else {
            return (
                <Card content={
                    <div className="nav-container">
                        <Nav bsStyle="tabs" bsClass="nav nav-icons">
                            <NavItem eventKey="customer_tab_general">
                                <i className="fa fa-user-circle"/><br/>{this.props.t('customer_edit.customer_tab_general', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_devices">
                                <i className="fa fa-desktop"/><br/>{this.props.t('customer_edit.customer_tab_devices', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_packages">
                                <i className="fa fa-dropbox"/><br/>{this.props.t('customer_edit.customer_tab_packages', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_vod">
                                <i className="fa fa-film"/><br/>{this.props.t('customer_edit.customer_tab_vod', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_npvr">
                                <i className="fa fa-clock-o"/><br/>{this.props.t('customer_edit.customer_tab_npvr', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_ppv">
                                <i className="fa fa-credit-card-alt"/><br/>{this.props.t('customer_edit.customer_tab_ppv', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_messages">
                                <i className="fa fa-envelope-o"/><br/>{this.props.t('customer_edit.customer_tab_messages', {framework: "react-i18next"})}
                            </NavItem>
                            <NavItem eventKey="customer_tab_speed_test">
                                <i className="fa fa-tachometer"/><br/>{this.props.t('customer_edit.customer_tab_speed_test', {framework: "react-i18next"})}
                            </NavItem>
                            {/*<NavItem eventKey="customer_tab_help">*/}
                            {/*<i className="fa fa-life-ring"/><br/>{this.props.t('customer_edit.customer_tab_help', {framework: "react-i18next"})}*/}
                            {/*</NavItem>*/}
                            <NavItem onClick={this.dowloadPlaylistAction.bind(this)}>
                                <i className="fa fa-download"/><br/>{"Playlist"}
                            </NavItem>
                            {/*<NavItem eventKey="customer_support_tickets">*/}
                            {/*    <i className="fa fa-support"/><br/>Support*/}
                            {/*</NavItem>*/}
                        </Nav>
                    </div>
                }
                />
            );
        }


    }


    render() {
        return (
            <div className="main-content">

                {this.renderPlaylistModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Tab.Container id="nav-with-icons" defaultActiveKey="customer_tab_general"
                               activeKey={sessionStorage.getItem('customer_tab_key')}
                               onSelect={this.handleTabSelect}>
                    <div>
                        {this.renderNavigation()}

                        <Tab.Content>
                            <Tab.Pane eventKey="customer_tab_general">
                                {this.renderCustomerGeneral()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_devices">
                                {this.renderCustomerDevices()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_packages">
                                {this.renderCustomerPackages()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_vod">
                                {this.renderCustomerVod()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_npvr">
                                {this.renderCustomerNpvr()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_ppv">
                                {this.renderCustomerPpv()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_messages">
                                {this.renderCustomerMessages()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_speed_test">
                                {this.renderCustomerSpeedTest()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="customer_tab_help">
                                {this.renderCustomerHelp()}
                            </Tab.Pane>
                            {/*<Tab.Pane eventKey="customer_support_tickets">*/}
                            {/*    {this.renderCustomerSupport()}*/}
                            {/*</Tab.Pane>*/}
                        </Tab.Content>
                    </div>
                </Tab.Container>

            </div>
        );
    }
}

// export default EditCustomer;
export default withNamespaces('common')(EditCustomer);
