import React from "react";
import axios from "axios";
// import ReactTable from "react-table";
import Button from "../CustomButton/CustomButton.jsx";
import {Redirect} from 'react-router'
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";

import ModalDelete from "../CustomModals/ModalDelete";
import {ModalEditEpisode} from "../CustomModals/ModalEditEpisode";
import {ModalManualySelectEpisode} from "../CustomModals/ModalManualySelectEpisode";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import ReactTableV6Simple from "../CustomDataTables/ReactTableV6Simple";

class DataVodEpisodes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            remove_tooltip: (<Tooltip id="remove_tooltip">Remove</Tooltip>),
            edit_tooltip: (<Tooltip id="redit_tooltip">Edit</Tooltip>),

            showRestoreModal: false,
            showDeleteModal: false,
            showEditEpisodeModal: false,
            showEditEpisodeModalType: "",

            showManualSelectEpisodeModal: false,

            vod_seasons_id: null,
            redirect: false,
            loading: false,
            vod_id: null,
            vodEpisodesArray: [],
            columns: [
                {
                    accessor: "vod_id",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "vod_position",
                    Header: "#",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "vod_image",
                    Header: "Poster",
                    maxWidth: 100,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {this.setReactTableImage(row.value)}
                        </div>
                    )
                },
                {
                    accessor: "vod_status",
                    Header: "Status",
                    maxWidth: 100,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <Badge fill="true" style={row.value === 1 ? {
                                background: '#87cb16',
                                borderRadius: '2px',
                                lineHeight: "2"
                            } : {background: '#545557', borderRadius: '2px', lineHeight: "2"}}>
                                {
                                    row.value === 1 ? 'Active' : 'Disabled'
                                }
                            </Badge>
                        </div>
                    )
                },
                {
                    accessor: "vod_name_short",
                    Header: "Name",
                    sortable: true,
                    filterable: false
                },

                {
                    accessor: "vod_year",
                    Header: "Air Date",
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "vod_description",
                    Header: "overview",
                    sortable: true,
                    filterable: false
                },
                {
                    Header: 'actions',
                    sortable: false,
                    filterable: false,
                    accessor: "vod_id",
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                            {this.setDeleteButton(row.value)}
                        </div>
                    )
                }

            ]
        };

    }

    submitTmdbPosterImage(image) {

        //console.log("TMDB-> SUBMIT IMAGE")

        if (image !== null) {

            const url = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrl = "https://image.tmdb.org/t/p/w500" + image;

            let bodyFormData = new FormData();
            bodyFormData.set('filename', image);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('url', imgUrl);

            axios.post(url, bodyFormData)
                .then((res) => {
                    //this.setState({channel_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    setReactTableImage(vod_image) {

        return (
            <img
                src={process.env.REACT_APP_ASSET_URL + "/vod_series/" + vod_image}
                width="70px" alt={""}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                }}
            />
        )
    }

    componentDidMount() {
        this.loadFromMiddlewareEpisodes()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.seasonID !== prevProps.seasonID) {
            this.loadFromMiddlewareEpisodes()
        }

    }

    //REDIRECTS
    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/edit-vod-cat/" + this.state.vod_cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }
    }

    //TMDB
    loadTMDBEpisodesToMiddleware() {

        let tmdb_id = sessionStorage.getItem('series_main_tmdb_id')
        let season = sessionStorage.getItem('series_season_selected')

        if (sessionStorage.getItem('series_main_tmdb_id') !== undefined && sessionStorage.getItem('series_main_tmdb_id') !== 0) {
            const url = "https://api.themoviedb.org/3/tv/" + tmdb_id + "/season/" + season + "?api_key=1a310ae853a5b25f2ca396e205bcfa6d";

            axios
                .get(url)
                .then(res => {

                    let episodesData = res.data.episodes
                    const loading = false;

                    //console.log(episodesData);
                    this.setState({loading: true});

                    for (let i = 0; i < episodesData.length; i++) {
                        this.createEpisode(episodesData[i]);
                    }

                    this.setState({
                        vodEpisodesArray: episodesData,
                        loading
                    });

                    this.loadFromMiddlewareEpisodes()

                }).catch(error => {
                this.setState({loading: false});
                console.log(error);
            });
        }


    }

    //MIDDLEWARE
    loadFromMiddlewareEpisodes() {

        // if (sessionStorage.getItem('series_edit') !== undefined && sessionStorage.getItem('series_edit') !== 0) {

        if (this.props.seasonID !== null) {

            this.setState({loading: true})

            //console.log("*************" + this.props.seasonID)

            //const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode/" + sessionStorage.getItem('series_season_id');
            const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode/" + this.props.seasonID;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const vodEpisodesArray = res.data;
                    const loading = false;

                    this.setState({
                        vodEpisodesArray: vodEpisodesArray,
                        loading

                    });

                }).catch(error => {
                this.setState({loading: false});
                this.props.dispatch(userActions.logoutUnauthorized());
                console.log(error);
            });
        }

        // }
    }

    createEpisode(data) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesEpisode";

        var postData = {
            vod_episode: {
                vod_position: data.episode_number,
                vod_name_short: data.name,
                vod_name_long: data.name,
                vod_description: data.overview,
                vod_image: data.still_path,
                vod_status: 1,
                vod_year: data.air_date,
                vod_seriesid: sessionStorage.getItem('series_edit'),
                vod_seasonid: sessionStorage.getItem('series_season_id'),
                vod_cat_id: this.props.seriesCatID === null ? null : this.props.seriesCatID.join(),
                vod_vut_category_display: this.props.seriesCatDisplay,
                vod_tmdb_id: data.id,
                vod_vut_type: 'SVOD'
            }
        };


        //console.log(postData);
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());

            })

        this.submitTmdbPosterImage(data.still_path)

    }

    //BUTTON ACTIONS
    handleRefreshEpisodes() {
        this.loadFromMiddlewareEpisodes()
    }

    handleSaveEpisode() {
        if (this.props.seasonID !== null) {
            this.loadTMDBEpisodesToMiddleware()
        }
    }

    handlAddNewEpisode() {
        //ADD NEW EPISODE PROCESS
        this.setState({vod_id: 0, showEditEpisodeModal: true, showEditEpisodeModalType: "new"})
    }

    handlManualSelectEpisode() {
        //ADD NEW EPISODE PROCESS
        this.setState({showManualSelectEpisodeModal: true, showEditEpisodeModalType: "new"})
    }

    //BUTTONS
    setHelperButton(vod_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.edit_tooltip}>
            <Button
                onClick={() => {
                    this.setState({
                        vod_id: vod_id,
                        showEditEpisodeModal: true,
                        showEditEpisodeModalType: "edit"
                    })
                }}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-edit"/>
            </Button>
        </OverlayTrigger>)

    }

    setDeleteButton(vod_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.remove_tooltip}>
            <Button
                onClick={() => this.setState({vod_id: vod_id, showDeleteModal: true})}

                bsStyle="danger"
                simple
                icon
            >
                <i className="fa fa-times"/>
            </Button>
        </OverlayTrigger>)

    }

    //ACTIONS
    deleteEpisode(vod_id) {

        const deleteURL = process.env.REACT_APP_API_URL + "/VodSeriesEpisode/" + vod_id;

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios.delete(deleteURL, axiosConfig)
            .then((res) => {
                let array = [...this.state.vodEpisodesArray]; // make a separate copy of the array
                const index = array.indexOf(vod_id)
                array.splice(index, 1);
                this.setState({vodEpisodesArray: array});
                this.handleRefreshEpisodes();
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })


    }

    //MODALS
    setDisplayDeleteModal = (id, value, action) => {

        this.setState({
            showDeleteModal: value
        })

        if (action === "delete") {
            this.deleteEpisode(id)
        }
    }

    setEditEpisodeModal = (value, action) => {

        this.setState({
            showEditEpisodeModal: value
        })

        if (action === "save") {
            this.loadFromMiddlewareEpisodes()
        }
    }

    setManualSelectModal = (value, action) => {

        this.setState({
            showManualSelectEpisodeModal: value
        })

        if (action === "save") {
            this.loadFromMiddlewareEpisodes()
        }
    }

    renderDeleteModal = () => {

        let bodyMessageVar = "this action will delete the episode. This action can not be undone";

        if (this.state.showDeleteModal) {
            //console.log("Clicked Delete Modal...");
            return <ModalDelete
                headerMessage={"Delete Episode: "}
                bodyMessage={bodyMessageVar}
                handlerFromParent={this.setDisplayDeleteModal}
                handlerID={this.state.vod_id}
            />
        }
    }

    renderAutoPopulateButton = () => {

        if (this.state.vodEpisodesArray <= 0) {
            return (<Button
                bsStyle="info"
                fill wd
                name={"saveEpisodeAutomatically"}
                onClick={this.handleSaveEpisode.bind(this)}>
                Add Episodes (Auto)
            </Button>);
        }

    }

    renderManualySelectButton = () => {

        if (this.props.seasonNumber !== 0) {
            //console.log("renderManualySelectButton->"+this.props.seasonNumber)
            return (
                <Button
                    bsStyle="success"
                    fill wd
                    name={"refreshEpisodes"}
                    onClick={this.handlManualSelectEpisode.bind(this)}>
                    Manualy Select Episodes
                </Button>
            );
        }

    }

    renderEditEpisode = () => {

        if (this.state.showEditEpisodeModal) {
            return <ModalEditEpisode
                headerMessage={"Edit / Create Episode"}
                bodyMessage={""}
                handlerFromParent={this.setEditEpisodeModal}
                handlerID={this.state.vod_id}
                handlerSeasonID={sessionStorage.getItem('series_season_id')}
                handlerSeriesID={sessionStorage.getItem('series_edit')}
                handlerSeriesCatID={this.props.seriesCatID}
                handlerSeriesCategories={this.props.seriesCatDisplay}
                handlerType={this.state.showEditEpisodeModalType}
            />
        }
    }

    renderManualSelectEpisode = () => {

        if (this.state.showManualSelectEpisodeModal) {

            //console.log("renderManualSelectEpisode->" + this.props.seasonNumber)

            return <ModalManualySelectEpisode
                handlerFromParent={this.setManualSelectModal}
                handlerSeasonID={this.props.seasonID}
                handlerProviderID={this.props.providerID}
                handlerSeriesCategories={this.props.seriesCatDisplay}
                handlerType={this.state.showEditEpisodeModalType}
                handlerSeriesID={this.props.seriesID}
                handlerSeasonNumber={this.props.seasonNumber}
                handlerSeriesCatID={this.props.seriesCatID}
                handlerSeriesCatDisplay={this.props.seriesCatDisplay}
                handlerSeriesName={this.props.seriesName}
            />
        }
    }


    render() {
        return (
            <div>
                {this.renderRedirect()}
                {this.renderDeleteModal()}
                {this.renderEditEpisode()}
                {this.renderManualSelectEpisode()}


                <div className="buttons-with-margin">
                    <Button
                        bsStyle="default"
                        fill wd
                        name={"refreshEpisodes"}
                        onClick={this.handleRefreshEpisodes.bind(this)}>
                        Refresh
                    </Button>
                    {this.renderAutoPopulateButton()}
                    <Button
                        bsStyle="info"
                        fill wd
                        name={"refreshEpisodes"}
                        onClick={this.handlAddNewEpisode.bind(this)}>
                        Add new episode
                    </Button>
                    {this.renderManualySelectButton()}
                </div>

                <ReactTableV6Simple
                    data={this.state.vodEpisodesArray}
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={5}
                />
            </div>
        );

    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataVodEpisodes = connect(mapStateToProps)(DataVodEpisodes);
const connectedDataVodEpisodesTrans = withNamespaces('common')(connectedDataVodEpisodes);
export {connectedDataVodEpisodesTrans as DataVodEpisodes};
