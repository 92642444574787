import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownMode extends Component {

    constructor() {
        super();
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: false,
            mode: []
        };
    }


    handleSelect(value){
        this.props.handlerFromParent(value);

    }

    componentDidMount() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/Channels/mode";

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {
                    const mode = res.data;
                    const loading = false;
                    //console.log(mode);
                    this.setState({mode, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        }else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    render() {
        return (
            <div>
                <Select
                    placeholder="Mode Select"
                    name="modeSelect"
                    autoFocus={false}
                    multi={false}
                    value={this.props.modeSelected}
                    options={this.state.mode}
                    onChange={value => this.handleSelect(value)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownMode = connect(mapStateToProps)(DropDownMode);
const connectedDropDownModeTrans = withNamespaces('common')(connectedDropDownMode);
export {connectedDropDownModeTrans as DropDownMode};

