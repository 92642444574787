import React, {Component} from 'react';
import RSSParser from "rss-parser";
import {Badge, Col, Grid, Row, Table} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {BeatLoader} from "react-spinners";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class RssFeedViewer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            feedArray: null,
            loadingRssFeed: false
        };

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.handlerFeedUrl !== prevProps.handlerFeedUrl) {
            this.updateFeed();
        }
    }

    updateFeed() {

        this.setState({loading: true})

        if (this.props.handlerFeedUrl !== null) {
            const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
            let parser = new RSSParser();

            parser.parseURL(CORS_PROXY + this.props.handlerFeedUrl)
                .then(feed => {
                    this.setState({feedArray: feed, loading: false})
                })
                .catch(err => {
                        this.setState({loading: false})
                        console.log(err)
                    }
                )
        }

    }

    renderRssFeeds = () => {

        if (this.state.feedArray !== null) {

            //console.log(this.state.feedArray)
            let items = this.state.feedArray.items
            items.sort((a, b) => (a.isoDate < b.isoDate) ? 1 : -1)

            return (
                items.map((item, index) => (
                    <tr key={index}>
                        <td style={{
                            maxHeight: "250px",
                            maxWidth: "200px",
                            overflow: "hidden",
                            wordWrap: "break-word"
                        }}>
                            <label>{item.title}</label>
                            <br/>
                            <label>{item.pubDate}</label>
                            <br/>
                            {/*{item.content === undefined ? "" : parse(item.content)}*/}
                            {/*{item.content}*/}
                            {/*<br/>*/}
                            <a href={item.link}>{item.link}</a>
                        </td>
                    </tr>
                ))
            );


        }

    }

    render() {
        return (
            <div>

                <Card
                    tableFullWidth
                    content={
                        <div>
                            <Grid fluid>
                                <div>
                                    <div>
                                        <h5>{this.props.t('rss_edit.rss_title_feeds', {framework: "react-i18next"})}</h5>
                                    </div>
                                    <div>
                                        <Badge fill="false" style={{backgroundColor: "#7770"}}>
                                            <BeatLoader
                                                size={5}
                                                color={"#2c67a4"}
                                                loading={this.state.loading}
                                            />
                                        </Badge>
                                    </div>
                                </div>
                                <legend/>
                                <Row>
                                    <Col sm={12}>
                                        <Table striped responsive>
                                            <tbody>
                                            {this.renderRssFeeds()}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </Grid>
                        </div>
                    }
                />

            </div>
        );
    }
}

// export default RssFeedViewer;

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedRssFeedViewer = connect(mapStateToProps)(RssFeedViewer);
const connectedRssFeedViewerTrans = withNamespaces('common')(connectedRssFeedViewer);
export {connectedRssFeedViewerTrans as RssFeedViewer};



