import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";

class ModalSave extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalSave: true
        };

    }

    handleClose = () => {
        this.setState({showModalSave: false})
        this.props.handlerFromParent(false, "nothing");
    }

    handleSave = () => {
        this.setState({showModalSave: false})
        this.props.handlerFromParent(false, "save");
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="modal-save">
                <Modal show={this.state.showModalSave} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + "" + this.props.handlerID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p> {this.props.bodyMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button bsStyle="warning" fill onClick={this.handleSave}>
                            save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ModalSave;