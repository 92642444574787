import React, {Component} from "react";
import Button from "../CustomButton/CustomButton.jsx";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from 'react-i18next';
import {Col, Grid, Modal, Row, FormGroup, Tooltip, OverlayTrigger, ButtonGroup, Badge} from "react-bootstrap";
import Card from "../Card/Card";
import {exportMain} from "../../functions/ExportManagement";
import {BeatLoader} from 'react-spinners';
import moment from 'moment';

class ExportData extends Component {

    constructor(props) {

        super(props);
        this.state = {

            xls_tooltip: (<Tooltip id="xsl_tooltip">Export to Excel</Tooltip>),
            csv_tooltip: (<Tooltip id="csv_tooltip">Export to CSV</Tooltip>),
            pdf_tooltip: (<Tooltip id="pdf_tooltip">Export to PDF</Tooltip>),
            html_tooltip: (<Tooltip id="html_tooltip">Export to HTML</Tooltip>),
            download_tooltip: (<Tooltip id="download_tooltip">Download in progress</Tooltip>),

            showModalExport: false,
            checkBoxArray: [],
            selected: [],
            exportType: null,
            requestURL: null,
            fileName: null,
            downloading: false
        }
    }

    handleClose = () => {
        this.setState({showModalExport: false})
    }

    handleSelect = (e) => {

        const selected = this.state.selected;
        selected[e.target.name] = e.target.checked
        this.setState({selected});

    }

    handleExportClick(type) {

        let param = this.props.exportDataFor;

        let returnObject = null;

        if (param === 'devices') {
            returnObject = exportMain.loadDevices();
        } else if (param === 'customers') {
            returnObject = exportMain.loadCustomers();
        } else if (param === 'applications') {
            returnObject = exportMain.loadApplications();
        } else if (param === 'radio') {
            returnObject = exportMain.loadRadio();
        } else if (param === 'music') {
            returnObject = exportMain.loadMusic();
        }  else if (param === 'karaoke') {
            returnObject = exportMain.loadKaraoke();
        }  else if (param === 'channels') {
            returnObject = exportMain.loadChannels();
        } else if (param === 'tvod') {
            returnObject = exportMain.loadTvod();
        } else if (param === 'svod') {
            returnObject = exportMain.loadSvod();
        } else if (param === 'avod') {
            returnObject = exportMain.loadAvod();
        } else if (param === 'vodCategories') {
            returnObject = exportMain.loadVodCategories();
        } else if (param === 'tv_series_all') {
            returnObject = exportMain.loadSeries();
        } else if (param === 'tv_series_all_episodes') {
            returnObject = exportMain.loadSeriesEpisodes();
        } else if (param === 'tv_series_all_seasons') {
            returnObject = exportMain.loadSeriesSeasons();
        } else if (param === 'customer_speedtest') {
            returnObject = exportMain.loadCustomerSpeedTest(this.props.customerID);
        } else if (param === 'customer_vod') {
            returnObject = exportMain.loadCustomerVod(this.props.customerID);
        } else if (param === 'export_customer_npvr_jobs') {
            returnObject = exportMain.loadCustomerNpvrJobs(this.props.customerID);
        } else if (param === 'export_customer_npvr_history') {
            returnObject = exportMain.loadCustomerNpvrHIstory(this.props.customerID);
        } else if (param === 'export_customer_npvr_recordings_for_job') {
            returnObject = exportMain.loadCustomerNpvrRecordingsForJob(this.props.jobID);
        }else if (param === 'customer_ppv') {
            returnObject = exportMain.loadCustomerPpv(this.props.customerID);
        } else if (param === 'customer_devices') {
            returnObject = exportMain.loadCustomerDevices(this.props.customerID);
        } else if (param === 'customers_expire') {
            returnObject = exportMain.loadCustomersPackageExpire();
        }else if (param === 'eas_alerts') {
            returnObject = exportMain.loadEasAlerts();
        } else {
            console.log("Nothing to do")
        }

        this.setState({
            showModalExport: true,
            checkBoxArray: returnObject.tempArray,
            selected: returnObject.tempSelected,
            requestURL: returnObject.tempGetURL,
            fileName: returnObject.tempFileName,
            exportType: type
        })

    }

    exportProcess() {

        //console.log(moment().utcOffset() );

        this.setState({showModalExport: false, downloading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        ////////////////////////////////////////////
        let columnsArray = [];
        let a = this.state.selected;

        Object.keys(a).forEach(function (keyName, keyIndex) {
            // use keyName to get current key's name
            // and a[keyName] to get its value
            if (a[keyName] === true) {
                columnsArray.push(keyName.toString())
            }
        })

        let columnsString = columnsArray.join(',');
        ////////////////////////////////////////////

        // let dataUrl = process.env.REACT_APP_API_URL + "/Exports/Customer/" + this.props.customerID + "/Npvr/History?fields="
        let dataUrl = process.env.REACT_APP_API_URL + this.state.requestURL + "?offset="+moment().utcOffset()+"&type=" + this.state.exportType + "&fields=" + columnsString;

        fetch(dataUrl, {
            method: 'GET',
            headers: {'X-Id-Token': token}
        })
            .then(response => {

                    let headerLine = response.headers.get('content-disposition')
                    let filename = "";

                    const regex = /filename=(.+)/; // match 'filename=' and capture everything that follows

                    if (headerLine !== null) {
                        let parseFilename = headerLine.match(regex);
                        if (parseFilename) {
                            filename = parseFilename[1];  // captured group, in this case, '7'
                        }

                        if (filename !== "") {
                            response.blob().then(blob => {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                a.href = url;
                                a.download = filename;
                                a.click();

                                //this.notification("download")
                            });
                        }
                    }

                    this.setState({downloading: false})

                }
            ).catch((err) => {
            console.log("fetch: ", err);

        });


    }

    renderExportModal = () => {

        if (this.state.showModalExport) {
            return (
                <div>
                    <Modal show={this.state.showModalExport} onHide={this.handleClose}>
                        <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                            {/*<Modal.Title>{"Select Fields to Export (" + this.state.exportType + ")"}</Modal.Title>*/}
                            <h5 style={{marginBottom: '5px'}}>{"Select Fields to Export (" + this.state.exportType + ")"}</h5>
                        </Modal.Header>
                        <Modal.Body style={{'maxHeight': '800px', 'overflowY': 'auto'}}>
                            <Card
                                tableFullWidth
                                content={
                                    <div className="checkbox-inline">
                                        <Grid fluid>
                                            <Row>
                                                {
                                                    this.state.checkBoxArray.map((item) => {
                                                        return (
                                                            <Col md={6} key={item.id}>
                                                                <FormGroup>
                                                                    <input
                                                                        name={item.id}
                                                                        key={item.name}
                                                                        type="checkbox"
                                                                        disabled={item.disabled !== undefined ? item.disabled : false}
                                                                        checked={this.state.selected[item.id]}
                                                                        onChange={this.handleSelect.bind(this)}
                                                                    />
                                                                    <label> {item.name}</label>
                                                                </FormGroup>
                                                            </Col>

                                                        );
                                                    })
                                                }

                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup>
                                <Button bsStyle="default" fill onClick={this.handleClose}>
                                    Close
                                </Button>
                                <Button bsStyle="success" fill onClick={this.exportProcess.bind(this)}>
                                    Export
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }

    renderDownloading = () => {

        if (this.state.downloading) {
            return (
                <OverlayTrigger placement="top" overlay={this.state.download_tooltip}>
                    <Badge fill="false" style={{backgroundColor: "#7770"}}>
                        <BeatLoader
                            size={5}
                            color={"#2c67a4"}
                            loading={this.state.loading}
                        />
                    </Badge>
                </OverlayTrigger>
            );
        }

    }

    renderExportButton = () => {

        return (
            <div>
                {this.renderExportModal()}
                <OverlayTrigger placement="top" overlay={this.state.xls_tooltip}>
                    <Button simple icon onClick={this.handleExportClick.bind(this, "xls")} bsStyle="success"
                            disabled={this.state.downloading}>
                        <i className="fa fa-file-excel-o"> </i>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.state.csv_tooltip}>
                    <Button simple icon onClick={this.handleExportClick.bind(this, "csv")} bsStyle="warning"
                            disabled={this.state.downloading}>
                        <i className="fa fa-file-text-o"> </i>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.state.pdf_tooltip}>
                    <Button simple icon onClick={this.handleExportClick.bind(this, "pdf")} bsStyle="danger"
                            disabled={this.state.downloading}>
                        <i className="fa fa-file-pdf-o"> </i>
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={this.state.html_tooltip}>
                    <Button simple icon onClick={this.handleExportClick.bind(this, "html")} bsStyle="primary"
                            disabled={this.state.downloading}>
                        <i className="fa fa-globe"> </i>
                    </Button>
                </OverlayTrigger>
                {this.renderDownloading()}
            </div>
        );


    }

    render() {
        return (
            <div>
                {this.renderExportButton()}
            </div>

        )
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedExportData = connect(mapStateToProps)(ExportData);
const connectedExportDataTrans = withNamespaces('common')(connectedExportData);
export {connectedExportDataTrans as ExportData};
