import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl} from "react-bootstrap";

import Card from "../../components/Card/Card.jsx";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownCustomerDealers} from "../../components/CustomDropDown/DropDownCustomerDealers";
import {DropDownCustomerProperties} from "../../components/CustomDropDown/DropDownCustomerProperties";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditApplicationCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            loading: false,
            saving: false,
            redirect: false,
            appCatRedirect: false,
            showSaveModal: false,

            back_button_text: "Cancel",
            appCatStatus: false,
            appsCategoryObject: [],

            apps_cat_id: 0,
            apps_cat_position: 0,
            apps_cat_status: false,
            apps_cat_image: "",
            apps_cat_name_short: "",
            apps_cat_name_long: "",
            apps_cat_description: "",

            apps_cat_image_temp: false,
            apps_cat_image_folder_temp: "",
            apps_cat_dealer_id: null,
            apps_cat_property_id: null,

            apps_cat_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleCustomerDealerSelect = this.handleCustomerDealerSelect.bind(this);
        this.handleCustomerPropertySelect = this.handleCustomerPropertySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/categories/app-categories";
        let pathCategory = "/categories/edit-app-cat/" + this.state.apps_cat_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.appCatRedirect) {
            console.log(pathCategory);
            return <Redirect push to={pathCategory}/>
        }

    }

    submitCatImage(filename) {

        if (this.state.apps_cat_image_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.apps_cat_image_folder_temp);
            bodyFormData.set('location', "apps_cat");
            bodyFormData.set('filename', filename);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({apps_cat_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleChildImageSelect(folder, filename) {

        this.setState({
            apps_cat_image_temp: filename !== null,
            apps_cat_image: filename,
            apps_cat_image_folder_temp: folder
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem, saving: false, loading: true})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/ApplicationCategory/" + handle;

        if (handle === 'new') {
            this.setState({loading: false})
            this.getPosition()
        } else {

            axios
                .get(url, axiosConfig)
                .then(res => {

                    if (res.data.error === 401) {
                        this.props.dispatch(userActions.logoutUnauthorized());
                    }

                    const appsCategoryObject = res.data[0];
                    const loading = false;

                    let appCatStatus = appsCategoryObject.apps_cat_status === 0 ? false : true;


                    this.setState({
                        back_button_text: "Back",
                        appCatStatus,
                        apps_cat_id: appsCategoryObject.apps_cat_id,
                        apps_cat_position: appsCategoryObject.apps_cat_position,
                        apps_cat_status: appCatStatus,
                        apps_cat_image: appsCategoryObject.apps_cat_image,
                        apps_cat_name_short: appsCategoryObject.apps_cat_name_short,
                        apps_cat_name_long: appsCategoryObject.apps_cat_name_long,
                        apps_cat_description: appsCategoryObject.apps_cat_description,
                        apps_cat_dealer_id: appsCategoryObject.apps_cat_dealer_id,
                        apps_cat_property_id: appsCategoryObject.apps_cat_property_id,
                        custom_data: appsCategoryObject.custom_data,

                        loading,
                        appsCategoryObject: appsCategoryObject,
                        current_user: user,
                        user_content_provider: appsCategoryObject.apps_cat_content_provider
                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        }
    }

    getPosition() {

        const url = process.env.REACT_APP_API_URL + "/ApplicationCategory/position";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        axios
            .get(url, axiosConfig)
            .then(res => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                const applicationCatPosition = res.data[0];
                this.setState({
                    apps_cat_position: applicationCatPosition.ApplicationCatPosition
                });
            })
            .catch(error => {
                console.log(error);
            });

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'appCatStatus' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateAppCategory() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/ApplicationCategory/" + handle;

        let tempAppStatus = this.state.appCatStatus === true ? 1 : 0;

        let filename = ""

        if (this.state.apps_cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.apps_cat_image
            this.setState({apps_cat_image: filename})
            this.submitCatImage(filename);
        } else {
            filename = this.state.apps_cat_image
        }

        // Send a PUT request
        var postData = {
            app_category: {
                apps_cat_id: handle,
                apps_cat_position: this.state.apps_cat_position,
                apps_cat_status: tempAppStatus,
                apps_cat_image: filename,
                apps_cat_name_short: this.state.apps_cat_name_short,
                apps_cat_name_long: this.state.apps_cat_name_long,
                apps_cat_description: this.state.apps_cat_description,
                apps_cat_dealer_id: this.state.apps_cat_dealer_id,
                apps_cat_property_id: this.state.apps_cat_property_id,
                apps_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.setState({saving: false, loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({saving: false, loading: false})
                this.notification("failed")
            })

    }

    createAppCategory() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/ApplicationCategory";

        let tempAppStatus = this.state.appCatStatus === true ? 1 : 0;

        let filename = ""

        if (this.state.apps_cat_image_temp) {
            let timeUTC = new Date().getTime()
            filename = timeUTC + "_" + this.state.apps_cat_image
            this.setState({apps_cat_image: filename})
        } else {
            filename = this.state.apps_cat_image
        }

        let postData = {
            app_category: {
                apps_cat_position: this.state.apps_cat_position,
                apps_cat_status: tempAppStatus,
                apps_cat_image: filename,
                apps_cat_name_short: this.state.apps_cat_name_short,
                apps_cat_name_long: this.state.apps_cat_name_long,
                apps_cat_description: this.state.apps_cat_description,
                apps_cat_dealer_id: this.state.apps_cat_dealer_id,
                apps_cat_property_id: this.state.apps_cat_property_id,
                apps_cat_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.submitCatImage(filename);
                const appsCategoryObject = res.data;
                this.setState({apps_cat_id: appsCategoryObject.apps_cat_id, saving: false, loading: false})
                this.setAppCatRedirect();
                this.notification("success")

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({saving: false, loading: false})
                this.notification("failed")
            })

    }

    setAppCatRedirect = () => {
        this.setState({
            appCatRedirect: true,
            back_button_text: "Back"
        })
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'appCatStatus') {
            this.setState({appCatStatus: state})
        }
    }

    cancelAppCatEdit() {
        this.setRedirect();
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createAppCategory();
            } else {
                this.updateAppCategory();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Application category: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.apps_cat_id}
            />
        }
    }

    title() {
        if (this.state.apps_cat_name_short === null) {
            return "New - " + this.props.t('application_category_edit.main_title', {framework: "react-i18next"});
        } else {
            return this.state.apps_cat_name_short + " - " + this.props.t('application_category_edit.main_title', {framework: "react-i18next"});
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Application Category has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Application Category</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    handleCustomerDealerSelect(data) {

        if (data === null) {
            this.setState({
                apps_cat_dealer_id: 0,
                apps_cat_property_id: 0
            });
        } else {
            this.setState({
                apps_cat_dealer_id: data.value,
            });
        }

    }

    handleCustomerPropertySelect(data) {
        this.setState({
            apps_cat_property_id: data === null ? 0 : data.value,
        });
    }

    renderPropertyDropDown = () => {

        if (process.env.REACT_APP_PROPERTY_MANAGEMENT === "1") {
            return (
                <div>
                    <FormGroup>
                        <ControlLabel>Property Owner (Dealer)</ControlLabel>
                        <DropDownCustomerDealers
                            dealerSelected={this.state.apps_cat_dealer_id}
                            name='apps_cat_dealer_id'
                            disabled={this.state.access === "dealer"}
                            handlerFromParent={this.handleCustomerDealerSelect}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Property</ControlLabel>
                        <DropDownCustomerProperties
                            propertySelected={this.state.apps_cat_property_id}
                            name='apps_cat_property_id'
                            handlerFromParent={this.handleCustomerPropertySelect}
                            handlerDealerId={this.state.apps_cat_dealer_id}
                        />
                    </FormGroup>
                </div>
            );
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                        <FormGroup>
                            <ControlLabel>Content Provider</ControlLabel>
                            <DropDownContentProviders
                                contentProviderSelected={this.state.user_content_provider}
                                name='contentProviderSelect'
                                handlerFromParent={this.handleChildContentProviderSelect}
                                disabled={this.state.loading}
                            />
                        </FormGroup>

                )
            }
        }

    }


    render() {
        return (
            <div className="main-content">
                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row style={{display: "flex", justifyContent: "center"}}>
                        <Col md={10}>
                            <Card
                                title={this.title()}
                                content={
                                    <div>
                                        <legend></legend>
                                        <FormGroup>
                                            <ImageUpload
                                                imageName={this.state.apps_cat_image}
                                                imageType="apps_cat"
                                                imagePosition={"apps_cat_image"}
                                                imageTemp={this.state.apps_cat_image_temp}
                                                imageFolderTemp={this.state.apps_cat_image_folder_temp}
                                                handlerFromParent={this.handleChildImageSelect}
                                            />
                                        </FormGroup>
                                        <legend></legend>

                                        <FormGroup>
                                            <p className="category">{this.props.t('global.status', {framework: "react-i18next"})}</p>
                                            <Switch
                                                name={"appCatStatus"}
                                                disabled={this.state.loading}
                                                value={this.state.appCatStatus}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('global.position', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('application_category_edit.position_placeholder', {framework: "react-i18next"})}
                                                type="number"
                                                name={"apps_cat_position"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.apps_cat_position}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('application_category_edit.name_short', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('application_category_edit.name_short_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"apps_cat_name_short"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.apps_cat_name_short}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('application_category_edit.name_long', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl
                                                placeholder={this.props.t('application_category_edit.name_long_placeholder', {framework: "react-i18next"})}
                                                type="text"
                                                name={"apps_cat_name_long"}
                                                disabled={this.state.loading}
                                                onChange={this.handleInputChange}
                                                value={this.state.apps_cat_name_long}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>{this.props.t('application_category_edit.description', {framework: "react-i18next"})}</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         name="apps_cat_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder={this.props.t('application_category_edit.description_placeholder', {framework: "react-i18next"})}
                                                         value={this.state.apps_cat_description}
                                            />
                                        </FormGroup>


                                        {this.renderPropertyDropDown()}

                                        {this.renderContentProvider()}


                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelAppCatEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditApplicationCategory = connect(mapStateToProps)(EditApplicationCategory);
const connectedEditApplicationCategoryTrans = withNamespaces('common')(connectedEditApplicationCategory);
export {connectedEditApplicationCategoryTrans as EditApplicationCategory};