import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import {withNamespaces} from "react-i18next";
import {connect} from 'react-redux';
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import ImageUpload from "../ImageUpload/ImageUpload";
import {MapBoxProperty} from "../../components/MapBox/MapBoxProperty";
import {DropDownCustomerDealers} from "../../components/CustomDropDown/DropDownCustomerDealers";
import {style} from "../../variables/Variables";
import NotificationSystem from "react-notification-system";
import {AxiosHeader} from "../../functions/GlobalFunctions";
import Select from "react-select";

const propertyType = [
    {value: 'HOME', label: 'Home'},
    {value: 'MDU', label: 'MDU'},
    {value: 'UNIVERSITY', label: 'University'},
    {value: 'HOSPITAL', label: 'Hospital'}
];

class ModalProperty extends Component {

    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {

            access: user.level,

            showModalProperty: true,

            property_id: null,
            property_name: null,
            property_description: null,
            property_logo: null,
            property_lat: 0,
            property_long: 0,
            property_dealer_id: user.id !== undefined ? user.id : null,
            property_type: null,
            property_media_url: null,
            property_vast_url: null,

            property_background: null,

            property_logo_temp: false,
            property_logo_folder_temp: "",

            property_background_temp: false,
            property_background_folder_temp: "",

            propertyArray: [],

            _notificationSystem: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePropertyImageSelect = this.handlePropertyImageSelect.bind(this);
        this.handlePropertyBackgroundImageSelect = this.handlePropertyBackgroundImageSelect.bind(this);
        this.handlePropertyLocationOnMap = this.handlePropertyLocationOnMap.bind(this);
        this.handleDealerSelect = this.handleDealerSelect.bind(this);

    }

    handleDealerSelect(data) {
        this.setState({
            property_dealer_id: data === null ? 0 : data.value,
        });
    }

    handlePropertyImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "property_logo") {
            if (filename !== null) {
                this.setState({
                    property_logo_temp: true,
                    property_logo: filename,
                    property_logo_folder_temp: folder
                });
            } else {
                this.setState({
                    property_logo_temp: false,
                    property_logo: null,
                    property_logo_folder_temp: ""
                });
            }

        }

    }

    handlePropertyBackgroundImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "property_background") {
            if (filename !== null) {
                this.setState({
                    property_background_temp: true,
                    property_background: filename,
                    property_background_folder_temp: folder
                });
            } else {
                this.setState({
                    property_background_temp: false,
                    property_background: null,
                    property_background_folder_temp: ""
                });
            }

        }

    }

    handlePropertyLocationOnMap(property_lat, property_long) {

        this.setState({
            property_lat: property_lat,
            property_long: property_long,
        });
    }

    submitPosterImage(filename_poster) {

        if (this.state.property_logo_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.property_logo_folder_temp);
            bodyFormData.set('location', "properties");
            bodyFormData.set('filename', filename_poster);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({property_logo_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitBackgroundImage(filename_background) {

        if (this.state.property_background_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.property_background_folder_temp);
            bodyFormData.set('location', "properties");
            bodyFormData.set('filename', filename_background);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({property_background_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    handleClose = () => {
        this.setState({showModalProperty: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }

    manageSaveDevice() {

        if (this.props.handlerID === "new") {
            this.createProperty();
        } else {
            this.editProperty();
        }

    }

    createProperty() {

        if (AxiosHeader.getConfig() !== null) {
            const url = process.env.REACT_APP_API_URL + "/Properties";

            let filename_poster = ""

            if (this.state.property_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.property_logo
                this.setState({property_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.property_logo
            }

            let filename_background = ""

            if (this.state.property_background_temp) {
                let timeUTC = new Date().getTime()
                filename_background = timeUTC + "_" + this.state.property_background
                this.setState({property_background: filename_background})
                // console.log("*****" + filename_poster)
                this.submitBackgroundImage(filename_background);
            } else {
                filename_background = this.state.property_background
            }

            let postData = {
                property: {
                    property_name: this.state.property_name,
                    property_description: this.state.property_description,
                    property_logo: filename_poster,
                    property_background: filename_poster,
                    property_lat: this.state.property_lat,
                    property_long: this.state.property_long,
                    property_dealer_id: this.state.property_dealer_id,
                    property_type: this.state.property_type,
                    property_media_url: this.state.property_media_url,
                    property_vast_url: this.state.property_vast_url
                }
            };

            axios.post(url, postData, AxiosHeader.getConfig())
                .then((res) => {

                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to Create Property")
                    } else {
                        this.setState({showModalMessage: false})
                        //this.notification("success", "Property Created")
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    editProperty() {

        if (AxiosHeader.getConfig() !== null) {

            this.setState({loading: true});
            const url = process.env.REACT_APP_API_URL + "/Properties";

            let filename_poster = ""

            if (this.state.property_logo_temp) {
                let timeUTC = new Date().getTime()
                filename_poster = timeUTC + "_" + this.state.property_logo
                this.setState({property_logo: filename_poster})
                // console.log("*****" + filename_poster)
                this.submitPosterImage(filename_poster);
            } else {
                filename_poster = this.state.property_logo
            }

            let filename_background = ""

            if (this.state.property_background_temp) {
                let timeUTC = new Date().getTime()
                filename_background = timeUTC + "_" + this.state.property_background
                this.setState({property_background: filename_background})
                // console.log("*****" + filename_poster)
                this.submitBackgroundImage(filename_background);
            } else {
                filename_background = this.state.property_background
            }

            let postData = {
                property: {
                    property_name: this.state.property_name,
                    property_description: this.state.property_description,
                    property_logo: filename_poster,
                    property_background: filename_background,
                    property_lat: this.state.property_lat,
                    property_long: this.state.property_long,
                    property_dealer_id: this.state.property_dealer_id,
                    property_type: this.state.property_type,
                    property_id: this.state.property_id,
                    property_media_url: this.state.property_media_url,
                    property_vast_url: this.state.property_vast_url
                }
            };

            axios.put(url, postData, AxiosHeader.getConfig())
                .then((res) => {
                    if (res.data.error === 301) {
                        this.notification("failed", "Failed to updated property")
                        this.setState({loading: false});
                    } else {
                        //this.notification("success", "Property Updated")
                        this.setState({showModalMessage: false, loading: false})
                        this.props.handlerFromParent(this.props.handlerID, false, "reload");
                    }

                })
                .catch((err) => {
                    this.setState({loading: false});
                    console.log("AXIOS ERROR: ", err);
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    componentDidMount() {
        this.setState({_notificationSystem: this.refs.notificationSystem})
        this.loadProperty();
    }

    loadProperty() {

        if (AxiosHeader.getConfig() !== null) {

            if (this.props.handlerID !== "new") {

                this.setState({loading: true});

                let dataUrl = process.env.REACT_APP_API_URL + "/Properties/" + this.props.handlerID;

                axios.get(dataUrl, AxiosHeader.getConfig()).then(res => {

                    const propertyArray = res.data;
                    // console.log(propertyArray)

                    this.setState({
                        propertyArray,
                        loading: false,

                        property_id: propertyArray[0].property_id,
                        property_name: propertyArray[0].property_name,
                        property_description: propertyArray[0].property_description,
                        property_logo: propertyArray[0].property_logo,
                        property_background: propertyArray[0].property_background,
                        property_lat: propertyArray[0].property_lat,
                        property_long: propertyArray[0].property_long,
                        property_dealer_id: propertyArray[0].property_dealer_id,
                        property_type: propertyArray[0].property_type,
                        property_media_url: propertyArray[0].property_media_url,
                        property_vast_url: propertyArray[0].property_vast_url
                    });

                }).catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
            }
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    notification(status, message) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>{message}</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    handlePropertyTypeChange = (propertyTypeDropDown) => {

        this.setState({
            // packageTypeDropDown,
            property_type: propertyTypeDropDown === null ? null : propertyTypeDropDown.value
        });
        // console.log(`Option selected:`, packageTypeDropDown);

    }

    render() {
        return (
            <div>
                <NotificationSystem ref="notificationSystem" style={style}/>

                <Modal show={this.state.showModalProperty} onHide={this.handleClose} bsSize="lg" animation={false}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Grid fluid>
                            <Row>
                                <Col sm={7}>
                                    <FormGroup>
                                        <ControlLabel>ID</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="id"
                                            disabled
                                            value={this.state.property_id || ''}
                                            placeholder="property_id"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Property Name</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_name"
                                            value={this.state.property_name || ''}
                                            placeholder="property_name"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Property Description</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_description"
                                            value={this.state.property_description || ''}
                                            placeholder="property_description"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Owner</ControlLabel>
                                        <DropDownCustomerDealers
                                            dealerSelected={this.state.property_dealer_id}
                                            name='property_dealer_id'
                                            disabled={this.state.access === "dealer"}
                                            handlerFromParent={this.handleDealerSelect}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Type</ControlLabel>
                                        <Select
                                            value={this.state.property_type}
                                            onChange={this.handlePropertyTypeChange}
                                            options={propertyType}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Property Media URL</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_media_url"
                                            value={this.state.property_media_url || ''}
                                            placeholder="Enter the media url for the property"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Property VAST URL</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_vast_url"
                                            value={this.state.property_vast_url || ''}
                                            placeholder="Enter the VAST URL"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel>Latitude</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_lat"
                                            value={this.state.property_lat || ''}
                                            placeholder="property_lat"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Longitude</ControlLabel>
                                        <FormControl
                                            type="text"
                                            name="property_long"
                                            value={this.state.property_long || ''}
                                            placeholder="property_long"
                                            onChange={this.handleInputChange}
                                        />
                                    </FormGroup>


                                </Col>
                                <Col sm={5}>

                                    <FormGroup
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            padding: '10px',
                                            borderColor: '#377cb3'
                                        }}>

                                        <ControlLabel>Property Logo</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.property_logo}
                                            imageType="properties"
                                            imagePosition={"property_logo"}
                                            imageTemp={this.state.property_logo_temp}
                                            imageFolderTemp={this.state.property_logo_folder_temp}
                                            handlerFromParent={this.handlePropertyImageSelect}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            padding: '10px',
                                            borderColor: '#bc1843'
                                        }}>
                                        <ControlLabel>Property Background</ControlLabel>
                                        <ImageUpload
                                            imageName={this.state.property_background}
                                            imageType="properties"
                                            imagePosition={"property_background"}
                                            imageTemp={this.state.property_background_temp}
                                            imageFolderTemp={this.state.property_background_folder_temp}
                                            handlerFromParent={this.handlePropertyBackgroundImageSelect}
                                        />
                                    </FormGroup>


                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>

                                    <FormGroup
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            padding: '10px',
                                            borderColor: '#5e5e5e'
                                        }}>
                                        <ControlLabel>Property Location</ControlLabel>
                                        <MapBoxProperty
                                            handlerLat={this.state.property_lat}
                                            handlerLong={this.state.property_long}
                                            handlerPropertyType={this.state.property_type}
                                            handlerFromParent={this.handlePropertyLocationOnMap}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                        </Grid>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="info" fill onClick={this.manageSaveDevice.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalProperty = connect(mapStateToProps)(ModalProperty);
const connectedModalPropertyTrans = withNamespaces('common')(connectedModalProperty);
export {connectedModalPropertyTrans as ModalProperty};