import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, FormControl, InputGroup, ControlLabel, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios/index";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {Redirect} from "react-router";
import Button from "../../components/CustomButton/CustomButton";
import ModalSave from "../../components/CustomModals/ModalSave";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import {userActions} from "../../authentication/_actions";
import Switch from "react-bootstrap-switch";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {DualListBoxPackageSportEvent} from "../../components/DualListBox/DualListBoxPackageSportEvent";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";
import {DropDownSpePriceList} from "../../components/CustomDropDown/DropDownSpePriceList";

const override = css`
  position: fixed;
  left: 50%;
  top: 35%;
  display: block;
  z-index: 1000;
  height: 31px;
  width: 100px;
`;

const adminArray = ['super-admin', 'admin']

class EditPackagesSportEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,

            redirect: false,
            sportEventPackageRedirect: false,

            back_button_text: "Cancel",
            showSaveModal: false,

            sportEventPackageObject: [],

            sport_event_package_id: 0,
            sport_event_package_name_short: null,
            sport_event_package_name_long: null,
            sport_event_package_description: null,
            sport_event_package_status: false,
            sport_event_package_price: 0.0,
            sport_event_package_is_adult: false,
            sport_event_package_display_while_inactive: false,
            sport_event_package_billing_code: null,
            sport_event_package_subscription_url: null,
            sport_event_package_trial: null,

            statusSwitch: false,
            expirySwitch: false,
            trialSwitch: false,
            creditCardSwitch: false,
            codeSwitch: false,
            accountSwitch: false,

            assignedSportEventsInPackage: [],

            sport_event_package_duration: null,

            newPackage: true,

            _notificationSystem: null,

            sport_event_package_content_provider: null,
            current_user: null,
            user_content_provider: null,

            sport_event_package_image_temp: false,
            sport_event_package_image: null,
            sport_event_package_image_folder_temp: "",

            sport_event_package_inactive_image_temp: false,
            sport_event_package_inactive_image: null,
            sport_event_package_inactive_image_folder_temp: "",

            custom_data: null,

            sport_event_package_credit_card_status: false,
            sport_event_package_credit_card_product_id: null,
            sport_event_package_credit_card_control_text: null,
            sport_event_package_credit_card_help_url: null,

            sport_event_package_code_status: false,
            sport_event_package_code_control_text: null,
            sport_event_package_code_help_url: null,

            sport_event_package_account_status: false,
            sport_event_package_account_control_text: null,
            sport_event_package_account_help_url: null,
            sport_event_pgroups: [],

            sport_event_pgroups_cards: null,
            sport_event_pgroups_codes: null,
            // spe_group_names_selected: null


        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleChildSpePriceGroupsCreditCardSelected = this.handleChildSpePriceGroupsCreditCardSelected.bind(this);
        this.handleChildSpePriceGroupsCodesSelected = this.handleChildSpePriceGroupsCodesSelected.bind(this);
        this.handleSportEventPackageChange = this.handleSportEventPackageChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "sport_event_package_image") {
            this.setState({
                sport_event_package_image_temp: filename !== null,
                sport_event_package_image: filename,
                sport_event_package_image_folder_temp: folder
            });
        }
        if (imagePosition === "sport_event_package_inactive_image") {
            this.setState({
                sport_event_package_inactive_image_temp: filename !== null,
                sport_event_package_inactive_image: filename,
                sport_event_package_inactive_image_folder_temp: folder
            });
        }

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
                assignedSportEventsInPackage: []
            });
        }
    }

    handleChildSpePriceGroupsCreditCardSelected(data) {

        if (data !== undefined) {
            let speSelectedIds = []
            let speSelectedNames = []

            for (let i = 0; i < data.length; i++) {
                speSelectedIds.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                speSelectedNames.push(data[i].label)
            }

            this.setState({
                sport_event_pgroups_cards: data === null ? null : speSelectedIds,
                // spe_group_names_selected: data === null ? [] : speSelectedNames
            });
        }
    }

    handleChildSpePriceGroupsCodesSelected(data) {

        if (data !== undefined) {
            let speSelectedIds = []
            let speSelectedNames = []

            for (let i = 0; i < data.length; i++) {
                speSelectedIds.push(data[i].value)
            }

            for (let i = 0; i < data.length; i++) {
                speSelectedNames.push(data[i].label)
            }

            this.setState({
                sport_event_pgroups_codes: data === null ? null : speSelectedIds,
                // spe_group_names_selected: data === null ? [] : speSelectedNames
            });
        }
    }


    handleSportEventPackageChange(selectedFromChild) {

        let selectedSportEvents = []

        if (selectedFromChild !== undefined !== null) {

            for (let i = 0; i < selectedFromChild.length; i++) {
                selectedSportEvents.push(selectedFromChild[i].toString())
            }
        }

        this.setState({assignedSportEventsInPackage: selectedSportEvents})
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
        if (elem.props.name === 'expirySwitch') {
            if (state) {
                this.setState({expirySwitch: state, trialSwitch: false})
            } else {
                this.setState({expirySwitch: state})
            }

        }
        if (elem.props.name === 'trialSwitch') {
            if (state) {
                this.setState({trialSwitch: state, expirySwitch: false})
            } else {
                this.setState({trialSwitch: state})
            }
        }

        if (elem.props.name === 'creditCardSwitch') {
            if (state) {
                this.setState({creditCardSwitch: state})
            } else {
                this.setState({creditCardSwitch: state})
            }
        }

        if (elem.props.name === 'codeSwitch') {
            if (state) {
                this.setState({codeSwitch: state})
            } else {
                this.setState({codeSwitch: state})
            }
        }

        if (elem.props.name === 'accountSwitch') {
            if (state) {
                this.setState({accountSwitch: state})
            } else {
                this.setState({accountSwitch: state})
            }
        }

    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({loading: false, newPackage: false, current_user: user})

        } else {

            this.setState({loading: true})
            const url = process.env.REACT_APP_API_URL + "/PackageSportEvent/" + handle;

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(url, axiosConfig)
                .then(res => {

                    const sportEventPackageObject = res.data[0];
                    const loading = false;

                    let selectedContent = []

                    if (sportEventPackageObject.sport_event_package_assigned_content !== null) {
                        selectedContent = sportEventPackageObject.sport_event_package_assigned_content.toString().split(",")
                    } else {
                        selectedContent = []
                    }

                    let speGroupCardIds = []

                    if (sportEventPackageObject.sport_event_pgroups_cards !== null) {
                        let temp = sportEventPackageObject.sport_event_pgroups_cards.toString();
                        speGroupCardIds = temp.split(",")
                    }

                    let speGroupCodeIds = []

                    if (sportEventPackageObject.sport_event_pgroups_codes !== null) {
                        let temp = sportEventPackageObject.sport_event_pgroups_codes.toString();
                        speGroupCodeIds = temp.split(",")
                    }

                    let status = sportEventPackageObject.sport_event_package_status === 1
                    let expirySwitch = sportEventPackageObject.sport_event_package_no_expiry === 1
                    let trialSwitch = sportEventPackageObject.sport_event_package_trial === 1

                    let creditCardSwitch = sportEventPackageObject.sport_event_package_credit_card_status === 1
                    let codeSwitch = sportEventPackageObject.sport_event_package_code_status === 1
                    let accountSwitch = sportEventPackageObject.sport_event_package_account_status === 1

                    this.setState({
                        back_button_text: "Back",
                        sport_event_package_id: sportEventPackageObject.sport_event_package_id,
                        sport_event_package_name_short: sportEventPackageObject.sport_event_package_name_short,
                        sport_event_package_name_long: sportEventPackageObject.sport_event_package_name_long,
                        sport_event_package_description: sportEventPackageObject.sport_event_package_description,
                        sport_event_package_status: sportEventPackageObject.sport_event_package_status,
                        sport_event_package_price: sportEventPackageObject.sport_event_package_price,
                        sport_event_package_image: sportEventPackageObject.sport_event_package_image,
                        sport_event_package_inactive_image: sportEventPackageObject.sport_event_package_inactive_image,
                        sport_event_package_is_adult: sportEventPackageObject.sport_event_package_is_adult,
                        sport_event_package_display_while_inactive: sportEventPackageObject.sport_event_package_display_while_inactive,
                        sport_event_package_billing_code: sportEventPackageObject.sport_event_package_billing_code,
                        sport_event_package_subscription_url: sportEventPackageObject.sport_event_package_subscription_url,
                        sport_event_package_trial: trialSwitch,
                        sport_event_package_duration: sportEventPackageObject.sport_event_package_duration,
                        statusSwitch: status,
                        assignedSportEventsInPackage: selectedContent,
                        expirySwitch,
                        loading,
                        trialSwitch,
                        sportEventPackageObject,
                        creditCardSwitch,
                        codeSwitch,
                        accountSwitch,
                        current_user: user,
                        user_content_provider: sportEventPackageObject.sport_event_package_content_provider,
                        custom_data: sportEventPackageObject.custom_data,

                        sport_event_package_credit_card_status: sportEventPackageObject.sport_event_package_credit_card_status,
                        sport_event_package_credit_card_product_id: sportEventPackageObject.sport_event_package_credit_card_product_id,
                        sport_event_package_credit_card_control_text: sportEventPackageObject.sport_event_package_credit_card_control_text,
                        sport_event_package_credit_card_help_url: sportEventPackageObject.sport_event_package_credit_card_help_url,

                        sport_event_package_code_status: sportEventPackageObject.sport_event_package_code_status,
                        sport_event_package_code_control_text: sportEventPackageObject.sport_event_package_code_control_text,
                        sport_event_package_code_help_url: sportEventPackageObject.sport_event_package_code_help_url,

                        sport_event_package_account_status: sportEventPackageObject.sport_event_package_account_status,
                        sport_event_package_account_control_text: sportEventPackageObject.sport_event_package_account_control_text,
                        sport_event_package_account_help_url: sportEventPackageObject.sport_event_package_account_help_url,
                        sport_event_pgroups_cards: speGroupCardIds,
                        sport_event_pgroups_codes: speGroupCodeIds,

                    });
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    //this.props.dispatch(userActions.logoutUnauthorized());
                });
        }
    }

    updateSportEventPackage() {

        this.setState({loading: true})

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/PackageSportEvent/" + handle;

        let status = this.state.statusSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1
        let creditCardSwitch = this.state.creditCardSwitch === false ? 0 : 1
        let codeSwitch = this.state.codeSwitch === false ? 0 : 1
        let accountSwitch = this.state.accountSwitch === false ? 0 : 1

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedSportEventsInPackage.length; i++) {
            contentInPackage.push(this.state.assignedSportEventsInPackage[i])
        }

        //RENAME FUNCTIONS START IMAGE ACTIVE
        let filename_active = ""

        if (this.state.sport_event_package_image_temp) {
            let timeUTC = new Date().getTime()
            filename_active = timeUTC + "_" + this.state.sport_event_package_image
            this.setState({sport_event_package_image: filename_active})
            console.log("*****" + filename_active)
            this.submitActiveImage(filename_active);
        } else {
            filename_active = this.state.sport_event_package_image
        }

        //RENAME FUNCTIONS START IMAGE INACTIVE
        let filename_inactive = ""

        if (this.state.sport_event_package_inactive_image_temp) {
            let timeUTC = new Date().getTime()
            filename_inactive = timeUTC + "_" + this.state.sport_event_package_inactive_image
            this.setState({sport_event_package_inactive_image: filename_inactive})
            console.log("*****" + filename_inactive)
            this.submitInactiveImage(filename_inactive);
        } else {
            filename_inactive = this.state.sport_event_package_inactive_image
        }

        // Send a PUT request
        let postData = {
            sport_event_package_id: this.state.sport_event_package_id,
            sport_event_package_name_short: this.state.sport_event_package_name_short,
            sport_event_package_name_long: this.state.sport_event_package_name_long,
            sport_event_package_description: this.state.sport_event_package_description,
            sport_event_package_status: status,
            sport_event_package_price: this.state.sport_event_package_price,
            sport_event_package_image: filename_active,
            sport_event_package_inactive_image: filename_inactive,
            sport_event_package_no_expiry: expirySwitch,
            sport_event_package_display_while_inactive: null,
            sport_event_package_billing_code: this.state.sport_event_package_billing_code,
            sport_event_package_subscription_url: this.state.sport_event_package_subscription_url,
            sport_event_package_trial: trialSwitch,
            sport_event_package_duration: this.state.sport_event_package_duration,
            sport_event_package_assigned_content: contentInPackage.join(),
            sport_event_package_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,
            sport_event_package_credit_card_status: creditCardSwitch,
            sport_event_package_credit_card_product_id: this.state.sport_event_package_credit_card_product_id,
            sport_event_package_credit_card_control_text: this.state.sport_event_package_credit_card_control_text,
            sport_event_package_credit_card_help_url: this.state.sport_event_package_credit_card_help_url,
            sport_event_package_code_status: codeSwitch,
            sport_event_package_code_control_text: this.state.sport_event_package_code_control_text,
            sport_event_package_code_help_url: this.state.sport_event_package_code_help_url,
            sport_event_package_account_status: accountSwitch,
            sport_event_package_account_control_text: this.state.sport_event_package_account_control_text,
            sport_event_package_account_help_url: this.state.sport_event_package_account_help_url,
            sport_event_pgroups_codes: this.state.sport_event_pgroups_codes != null ? this.state.sport_event_pgroups_codes.join() : null,
            sport_event_pgroups_cards: this.state.sport_event_pgroups_cards != null ? this.state.sport_event_pgroups_cards.join() : null,

        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    createSportEventPackage() {

        this.setState({loading: true})

        const url = process.env.REACT_APP_API_URL + "/PackageSportEvent";

        let contentInPackage = []

        for (let i = 0; i < this.state.assignedSportEventsInPackage.length; i++) {
            contentInPackage.push(this.state.assignedSportEventsInPackage[i])
        }

        let status = this.state.statusSwitch === false ? 0 : 1
        let expirySwitch = this.state.expirySwitch === false ? 0 : 1
        let trialSwitch = this.state.trialSwitch === false ? 0 : 1
        let creditCardSwitch = this.state.creditCardSwitch === false ? 0 : 1
        let codeSwitch = this.state.codeSwitch === false ? 0 : 1
        let accountSwitch = this.state.accountSwitch === false ? 0 : 1

        //RENAME FUNCTIONS START IMAGE ACTIVE
        let filename_active = ""

        if (this.state.sport_event_package_image_temp) {
            let timeUTC = new Date().getTime()
            filename_active = timeUTC + "_" + this.state.sport_event_package_image
            this.setState({sport_event_package_image: filename_active})
            console.log("*****" + filename_active)
            this.submitActiveImage(filename_active);
        } else {
            filename_active = this.state.sport_event_package_image
        }

        //RENAME FUNCTIONS START IMAGE INACTIVE
        let filename_inactive = ""

        if (this.state.sport_event_package_inactive_image_temp) {
            let timeUTC = new Date().getTime()
            filename_inactive = timeUTC + "_" + this.state.sport_event_package_inactive_image
            this.setState({sport_event_package_inactive_image: filename_inactive})
            console.log("*****" + filename_inactive)
            this.submitInactiveImage(filename_inactive);
        } else {
            filename_inactive = this.state.sport_event_package_inactive_image
        }

        let postData = {
            sport_event_package_name_short: this.state.sport_event_package_name_short,
            sport_event_package_name_long: this.state.sport_event_package_name_long,
            sport_event_package_description: this.state.sport_event_package_description,
            sport_event_package_status: status,
            sport_event_package_price: this.state.sport_event_package_price,
            sport_event_package_image: filename_active,
            sport_event_package_inactive_image: filename_inactive,
            sport_event_package_no_expiry: expirySwitch,
            sport_event_package_trial: trialSwitch,
            sport_event_package_display_while_inactive: null,
            sport_event_package_billing_code: this.state.sport_event_package_billing_code,
            sport_event_package_assigned_content: contentInPackage.join(),
            sport_event_package_subscription_url: this.state.sport_event_package_subscription_url,
            sport_event_package_duration: this.state.sport_event_package_duration,
            sport_event_package_content_provider: this.state.user_content_provider,
            custom_data: this.state.custom_data,
            sport_event_credit_card_status: creditCardSwitch,
            sport_event_credit_card_product_id: this.state.sport_event_package_credit_card_product_id,
            sport_event_credit_card_control_text: this.state.sport_event_package_credit_card_control_text,
            sport_event_credit_card_help_url: this.state.sport_event_package_credit_card_help_url,
            sport_event_code_status: codeSwitch,
            sport_event_code_control_text: this.state.sport_event_package_code_control_text,
            sport_event_code_help_url: this.state.sport_event_package_code_help_url,
            sport_event_account_status: accountSwitch,
            sport_event_account_control_text: this.state.sport_event_package_account_control_text,
            sport_event_account_help_url: this.state.sport_event_package_account_help_url,
            sport_event_pgroups_codes: this.state.sport_event_pgroups_codes != null ? this.state.sport_event_pgroups_codes.join() : null,
            sport_event_pgroups_cards: this.state.sport_event_pgroups_cards != null ? this.state.sport_event_pgroups_cards.join() : null,
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    const sportEventPackageObject = res.data;
                    this.setState({sport_event_package_id: sportEventPackageObject.sport_event_package_id})
                    this.setSportEventPackageRedirect();
                    this.notification("success")
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    submitActiveImage(filename_active) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.sport_event_package_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.sport_event_package_image_folder_temp);
            bodyFormData.set('location', "package_sports");
            bodyFormData.set('filename', filename_active);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({sport_event_package_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitInactiveImage(filename_inactive) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.sport_event_package_inactive_image_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.sport_event_package_inactive_image_folder_temp);
            bodyFormData.set('location', "package_sports");
            bodyFormData.set('filename', filename_inactive);


            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({sport_event_package_inactive_image_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createSportEventPackage();
            } else {
                this.updateSportEventPackage();
            }
        }
    }

    cancelSportEventPackageEdit() {
        this.setRedirect();
    }

    setSportEventPackageRedirect = () => {
        this.setState({
            sportEventPackageRedirect: true,
            back_button_text: "Back"
        })
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Sport Event Package: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.sport_event_package_id === 0 ? '' : this.state.sport_event_package_id}
            />
        }

    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/packages/sport-event-package";
        let pathSportEventpackage = "/edit-sport-event-package/" + this.state.sport_event_package_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.sportEventPackageRedirect) {

            this.setState({
                sportEventPackageRedirect: false,
                redirect: false
            })

            return <Redirect push to={pathSportEventpackage}/>
        }

    }

    renderSelection = () => {

        return (
            <Card
                content={
                    <div>
                        <h5>Sport Event Selection</h5>
                        <legend/>
                        <Grid fluid>
                            <Row>
                                <DualListBoxPackageSportEvent
                                    sportEventsInPackage={this.state.assignedSportEventsInPackage}
                                    packageID={this.state.sport_event_package_id}
                                    handlerFromParent={this.handleSportEventPackageChange}
                                    sportEventProvider={this.state.user_content_provider}
                                />
                            </Row>
                        </Grid>
                    </div>
                }
            />
        )


    }

    renderDurationFields = () => {

        if (!this.state.expirySwitch) {
            return (
                <FormGroup>
                    <ControlLabel>Duration (Days)</ControlLabel>
                    <FormControl placeholder="Enter the duration"
                                 type="number"
                                 name={"sport_event_package_duration"}
                                 disabled={this.state.loading}
                                 onChange={this.handleInputChange}
                                 value={this.state.sport_event_package_duration || ""}/>
                </FormGroup>
            );
        }


    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Sport Event Package has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Sport Event Package</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <FormGroup>
                        <ControlLabel>Content Provider</ControlLabel>
                        <DropDownContentProviders
                            contentProviderSelected={this.state.user_content_provider}
                            name='contentProviderSelect'
                            handlerFromParent={this.handleChildContentProviderSelect}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                )
            }
        }

    }


    renderCreditCardFields = () => {

        //            creditCardStatus: false,
        //             credit_card_control_text: null,
        //             credit_card_product_id: null,
        //             credit_card_help_url: null,

        if (this.state.creditCardSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>sport_event_package_credit_card_control_text</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_credit_card_control_text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_credit_card_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_credit_card_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>sport_event_package_credit_card_product_id</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_credit_card_product_id"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_credit_card_product_id"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_credit_card_product_id || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>sport_event_package_credit_card_help_url</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_credit_card_help_url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_credit_card_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_credit_card_help_url || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Card price groups</ControlLabel>
                        <DropDownSpePriceList
                            priceGroupsSelected={this.state.sport_event_pgroups_cards}
                            name='speCardPriceGroupsSelected'
                            handlerFromParent={this.handleChildSpePriceGroupsCreditCardSelected}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                </div>
            );
        }
    }

    renderCodeFields = () => {


        if (this.state.codeSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>sport_event_package_code_control_text</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_code_control_text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_code_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_code_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>sport_event_package_code_help_url</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_code_help_url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_code_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_code_help_url || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>Code price groups</ControlLabel>
                        <DropDownSpePriceList
                            priceGroupsSelected={this.state.sport_event_pgroups_codes}
                            name='speCodePriceGroupsSelected'
                            handlerFromParent={this.handleChildSpePriceGroupsCodesSelected}
                            disabled={this.state.loading}
                        />
                    </FormGroup>

                </div>
            );
        }
    }

    renderAccountFields = () => {

        if (this.state.accountSwitch) {
            return (
                <div>

                    <FormGroup>
                        <ControlLabel>sport_event_package_account_control_text</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_account_control_text"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_account_control_text"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_account_control_text || ""}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel>sport_event_package_account_help_url</ControlLabel>
                        <FormControl placeholder="Enter sport_event_package_account_help_url"
                                     type="text"
                                     disabled={this.state.loading}
                                     name="sport_event_package_account_help_url"
                                     onChange={this.handleInputChange}
                                     value={this.state.sport_event_package_account_help_url || ""}
                        />
                    </FormGroup>

                </div>
            );
        }
    }


    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                        //loading={true}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Sport Event Package Information</h5>
                                        <legend/>

                                        <FormGroup>
                                            <Row>
                                                <Col md={3}>
                                                    <ControlLabel>Status</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"statusSwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.statusSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>No Expiry</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"expirySwitch"}
                                                        disabled={this.state.loading}
                                                        value={this.state.expirySwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <ControlLabel>Trial</ControlLabel>
                                                    <br/>
                                                    <Switch
                                                        name={"trialSwitch"}
                                                        disabled={this.state.loading || this.state.expirySwitch}
                                                        value={this.state.trialSwitch}
                                                        onChange={(el, state) => this.handleSwitch(el, state)}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Short Name</ControlLabel>
                                            <FormControl placeholder="Enter Short Name"
                                                         type="text"
                                                         name={"sport_event_package_name_short"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.sport_event_package_name_short || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Long Name</ControlLabel>
                                            <FormControl placeholder="Enter Long Name"
                                                         type="text"
                                                         name={"sport_event_package_name_long"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.sport_event_package_name_long || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="sport_event_package_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.sport_event_package_description || ""}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Reference ID</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="text"
                                                         name={"sport_event_package_billing_code"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.sport_event_package_billing_code || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price</ControlLabel>
                                            <FormControl placeholder="Enter the price"
                                                         type="number"
                                                         name={"sport_event_package_price"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.sport_event_package_price || ""}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>External URL</ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Addon style={{backgroundColor: "lightgray"}}>
                                                    <Glyphicon glyph="globe"/>
                                                </InputGroup.Addon>
                                                <FormControl
                                                    placeholder="Enter external url"
                                                    type="text"
                                                    name="sport_event_package_subscription_url"
                                                    disabled={this.state.loading}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.sport_event_package_subscription_url || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        {this.renderDurationFields()}

                                        {this.renderContentProvider()}

                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Credit Card</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='creditCardSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.creditCardSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderCreditCardFields()}
                                        </div>

                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Codes</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='codeSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.codeSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderCodeFields()}
                                        </div>

                                        <div>
                                            <br/>
                                            <legend/>
                                            <ControlLabel>Account</ControlLabel>
                                            <FormGroup>
                                                <Switch
                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                    name='accountSwitch'
                                                    disabled={this.state.loading}
                                                    value={this.state.accountSwitch}
                                                    defaultValue={false}
                                                />
                                            </FormGroup>
                                            {this.renderAccountFields()}
                                        </div>


                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelSportEventPackageEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>Assets</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Active</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.sport_event_package_image}
                                                        imageType="package_sports"
                                                        imagePosition={"sport_event_package_image"}
                                                        imageTemp={this.state.sport_event_package_image_temp}
                                                        imageFolderTemp={this.state.sport_event_package_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <div className="text-center">
                                                        <p>Inactive</p>
                                                    </div>
                                                    <ImageUpload
                                                        imageName={this.state.sport_event_package_inactive_image}
                                                        imageType="package_sports"
                                                        imagePosition={"sport_event_package_inactive_image"}
                                                        imageTemp={this.state.sport_event_package_inactive_image_temp}
                                                        imageFolderTemp={this.state.sport_event_package_inactive_image_folder_temp}
                                                        handlerFromParent={this.handleChildImageSelect}
                                                    />
                                                </Col>
                                            </Row>
                                        </Grid>
                                    </div>
                                }
                            />
                        </Col>


                        <Col xs={12} sm={12} md={12} lg={6}>
                            {this.renderSelection()}
                        </Col>

                    </Row>
                </Grid>


            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditPackagesSportEvent = connect(mapStateToProps)(EditPackagesSportEvent);
const connectedEditPackagesSportEventTrans = withNamespaces('common')(connectedEditPackagesSportEvent);
export {connectedEditPackagesSportEventTrans as EditPackagesSportEvent};
