import React, {Component} from "react";
import {Collapse} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import {HeaderLinks} from "../../components/Header/HeaderLinks.jsx";
import image from "../../assets/img/flat_color.png";
import logo from "logo.svg";
import dashboardRoutes from "../../routes/dashboard.jsx";
import {UserSidebarComponent} from "../CustomSidebar/UserSidebarComponent";

const bgImage = {backgroundImage: "url(" + image + ")"};

let ps;

class Sidebar extends Component {

    constructor(props) {

        let user = JSON.parse(localStorage.getItem('user'));

        super(props);
        this.state = {
            openAvatar: false,

            // openComponents: this.activeRoute("/components") !== "" ? true : false,
            // openForms: this.activeRoute("/forms") !== "" ? true : false,
            // openTables: this.activeRoute("/tables") !== "" ? true : false,
            // openMaps: this.activeRoute("/maps") !== "" ? true : false,
            // openPages: this.activeRoute("/pages") !== "" ? true : false,
            // openVod: this.activeRoute("/movies") !== "" ? true : false,
            // openLive: this.activeRoute("/live") !== "" ? true : false,

            openVod: false,
            openLive: false,
            openGuide: false,
            openApplications: false,
            openMusic: false,
            openKaraoke: false,
            openCategories: false,
            openCustomers: false,
            openDevices: false,
            openNotifications: false,
            openPackages: false,
            openPricing: false,
            openReports: false,
            openSettings: false,


            isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
            width: window.innerWidth,
            user_firstname: user.firstName,
            user_lastname: user.lastName === null ? '' : user.lastName,
            username: user.username,
            access: user.level,
            user_id: user.id,
            userObject: user,

            live_manager_array: [100, 200, 201, 202, 203, 204, 300, 301, 302, 303, 3000, 3001, 3003, 800, 801, 802, 803, 804],
            vod_manager_array: [100, 400, 401, 402, 403, 404, 405, 406, 1101,3000,3002],
            customer_manager_array: [100, 900, 1000],
            dealer_array: [100, 900, 1000, 6000],
            reports_manager_array: [100, 5000]

        };
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    }

    activeRouteInner(routeName, editPage) {

        if (this.props.location.pathname.includes(editPage)) {
            return "active"
        } else {
            return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
        }
    }

    // if the windows width changes CSS has to make some changes
    // this functions tell react what width is the window
    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {

        this.locationManagement(this.props.location.pathname)

        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    locationManagement(path) {

        if (path.includes('movies')) {
            this.setState({
                openVod: true,
                openLive: false,
                openGuide: false,
                openCategories: false,
                openPackages: false,
                openPricing: false,
                openReports: false
            })
        } else if (path.includes('live') && !path.includes('edit-live-package')) {
            this.setState({
                openVod: false,
                openLive: true,
                openGuide: false,
                openCategories: false,
                openPackages: false,
                openPricing: false,
                openReports: false
            })
        } else if (path.includes('guide')) {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: true,
                openCategories: false,
                openPackages: false,
                openPricing: false,
                openReports: false
            })
        } else if (path.includes('categories')) {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: false,
                openCategories: true,
                openPackages: false,
                openPricing: false,
                openReports: false
            })
        } else if (path.includes('packages')) {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: false,
                openCategories: false,
                openPackages: true,
                openPricing: false,
                openReports: false
            })
        } else if (path.includes('pricing')) {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: false,
                openCategories: false,
                openPackages: false,
                openPricing: true,
                openReports: false
            })
        } else if (path.includes('reports')) {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: false,
                openCategories: false,
                openPackages: false,
                openPricing: false,
                openReports: true
            })
        } else if (path.includes('edit')) {
            //DO NOTHING
        } else if (path.includes('add')) {
            //DO NOTHING
        } else {
            this.setState({
                openVod: false,
                openLive: false,
                openGuide: false,
                openCategories: false,
                openPackages: false,
                openPricing: false,
                openReports: false
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.locationManagement(this.props.location.pathname)
            //console.log("componentDidUpdate")
        }

        if (navigator.platform.indexOf("Win") > -1) {
            setTimeout(() => {
                ps.update();
            }, 350);
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {
        return (
            <div className="sidebar" data-color="middleware" data-image={image}>
                <div className="sidebar-background" style={bgImage}/>

                {/*<div className="logo" style={{borderBottom: "1px solid rgba(255, 255, 255, 0.2)"}}>*/}
                <div className="logo">
                    <a
                        href={process.env.REACT_APP_HOME_URL}
                        className="simple-text logo-mini"
                    >
                        <div className="logo-img">
                            <img src={logo} alt="react-logo"/>
                        </div>
                    </a>
                    <a
                        href={process.env.REACT_APP_HOME_URL}
                        className="simple-text logo-normal"
                    >
                        {process.env.REACT_APP_NAME + " "}
                    </a>
                </div>

                <div className="sidebar-wrapper" ref="sidebarWrapper">

                    <UserSidebarComponent
                        userObject={this.state.userObject}
                    />

                    <ul className="nav">
                        {/* If we are on responsive, we want both links from navbar and sidebar
                            to appear in sidebar, so we render here HeaderLinks */}
                        {this.state.width <= 992 ? <HeaderLinks/> : null}
                        {/*
                            here we render the links in the sidebar
                            if the link is simple, we make a simple link, if not,
                            we have to create a collapsible group,
                            with the speciffic parent button and with it's children which are the links
                        */}

                        {dashboardRoutes.map((prop, key) => {

                            if (this.state.access === "live_manager") {
                                if (!this.state.live_manager_array.includes(prop.access_level)) {
                                    return null;
                                }
                            }

                            if (this.state.access === "vod_manager") {
                                if (!this.state.vod_manager_array.includes(prop.access_level)) {
                                    return null;
                                }
                            }

                            if (this.state.access === "csr_customer") {
                                if (!this.state.customer_manager_array.includes(prop.access_level)) {
                                    return null;
                                }
                            }

                            if (this.state.access === "dealer") {
                                if (!this.state.dealer_array.includes(prop.access_level)) {
                                    return null;
                                }
                            }

                            if (this.state.access === "reports_manager") {
                                if (!this.state.reports_manager_array.includes(prop.access_level)) {
                                    return null;
                                }
                            }


                            if (prop.invisible) {
                                return null;
                            }

                            let st = {};
                            st[prop["state"]] = !this.state[prop.state];

                            if (prop.collapse) {
                                return (
                                    <li className={this.activeRoute(prop.path)} key={key}>
                                        <a onClick={() => this.setState(st)}>
                                            <i className={prop.icon}/>
                                            <p>
                                                {prop.name}
                                                <b className={this.state[prop.state] ? "caret rotate-180" : "caret"}/>
                                            </p>
                                        </a>
                                        <Collapse in={this.state[prop.state]}>
                                            <ul className="nav">

                                                {prop.views.map((prop, key) => {

                                                        //console.log(prop)
                                                        //console.log(key)

                                                        if (this.state.access === "live_manager") {
                                                            if (!this.state.live_manager_array.includes(prop.access_level)) {
                                                                return null;
                                                            }
                                                        }

                                                        if (this.state.access === "vod_manager") {
                                                            if (!this.state.vod_manager_array.includes(prop.access_level)) {
                                                                return null;
                                                            }
                                                        }

                                                        if (this.state.access === "csr_customer") {
                                                            if (!this.state.customer_manager_array.includes(prop.access_level)) {
                                                                return null;
                                                            }
                                                        }

                                                        if (this.state.access === "dealer") {
                                                            if (!this.state.dealer_array.includes(prop.access_level)) {
                                                                return null;
                                                            }
                                                        }

                                                        if (this.state.access === "reports_manager") {
                                                            if (!this.state.reports_manager_array.includes(prop.access_level)) {
                                                                return null;
                                                            }
                                                        } else {
                                                            return (
                                                                <li
                                                                    className={this.activeRouteInner(prop.path, prop.editPage)}
                                                                    key={key}
                                                                >
                                                                    <NavLink
                                                                        to={prop.path}
                                                                        className="nav-link"
                                                                        activeClassName="active"
                                                                    >
                                                                <span className="sidebar-mini">
                                                                  {prop.mini}
                                                                </span>

                                                                        <span className="sidebar-mini-icon">
                                                                  <i className={prop.icon}/>
                                                                </span>

                                                                        <span className="sidebar-normal" style={
                                                                            this.props.location.pathname.includes(prop.editPage) ? {color: '#87cb16'} : {color: '#FFFFFF'}
                                                                        }
                                                                        >
                                                                  {prop.name}
                                                                </span>

                                                                    </NavLink>
                                                                </li>
                                                            );
                                                        }
                                                    }
                                                )
                                                }
                                            </ul>
                                        </Collapse>
                                    </li>
                                );
                            } //END OF COLLAPSE

                            else {
                                if (prop.redirect) {
                                    return null;
                                }  //END OF REDIRECT

                                else {
                                    return (
                                        <li className={this.activeRoute(prop.path)} key={key}>
                                            <NavLink
                                                to={prop.path}
                                                className="nav-link"
                                                activeClassName="active"
                                            >
                                                <i className={prop.icon}/>
                                                <p>{prop.name}</p>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            }

                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;
