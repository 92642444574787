import React, {Component} from "react";
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, InputGroup} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import Button from "../../components/CustomButton/CustomButton";
import Switch from "react-bootstrap-switch";
import axios from "axios";
import {Redirect} from "react-router";
import connect from "react-redux/es/connect/connect";
import {userActions} from "../../authentication/_actions";
import ModalSave from "../../components/CustomModals/ModalSave";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

class EditTvPriceGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {

            loading: false,
            redirect: false,
            tvRedirect: false,

            back_button_text: "Back",
            showSaveModal: false,

            tvPriceGroupArray: [],

            tv_pgroup_description: null,
            tv_pgroup_duration: null,
            tv_pgroup_id: null,
            tv_pgroup_name: null,
            tv_pgroup_price: null,
            tv_pgroup_status: null,

            tv_pgroup_billing_id: null,
            tv_pgroup_control_text: null,
            tv_pgroup_help_url: null,

            statusSwitch: false,

            _notificationSystem: null,

            custom_data: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleSwitch(elem, state) {


        if (elem.props.name === 'statusSwitch') {
            this.setState({statusSwitch: state})
        }
    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params

        if (handle === 'new') {
            this.setState({loading: false, back_button_text: "cancel", tv_pgroup_name: null, tv_pgroup_id: 0})
        } else {

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            let dataUrl = process.env.REACT_APP_API_URL + "/PriceGroupTv/" + handle;

            axios.get(dataUrl, axiosConfig).then(response => {
                const tvPriceGroupArray = response.data[0];
                //console.log(tvPriceGroupArray);

                let statusSwitch = tvPriceGroupArray.tv_pgroup_status === 1 ? true : false

                this.setState({
                        tvPriceGroupArray,
                        statusSwitch,
                        loading: false,
                        tv_pgroup_description: tvPriceGroupArray.tv_pgroup_description,
                        tv_pgroup_duration: tvPriceGroupArray.tv_pgroup_duration,
                        tv_pgroup_id: tvPriceGroupArray.tv_pgroup_id,
                        tv_pgroup_name: tvPriceGroupArray.tv_pgroup_name,
                        tv_pgroup_price: tvPriceGroupArray.tv_pgroup_price,
                        //tv_pgroup_status: tvPriceGroupArray.tv_pgroup_status,
                        tv_pgroup_billing_id: tvPriceGroupArray.tv_pgroup_billing_id,
                        custom_data: tvPriceGroupArray.custom_data,
                        tv_pgroup_control_text: tvPriceGroupArray.tv_pgroup_control_text,
                        tv_pgroup_help_url: tvPriceGroupArray.tv_pgroup_help_url
                    }
                );

            })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                    this.props.dispatch(userActions.logoutUnauthorized());
                });
        }


    }

    createTVPriceGroup() {

        const url = process.env.REACT_APP_API_URL + "/PriceGroupTv";

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0

        var postData = {
            tv_pgroup_description: this.state.tv_pgroup_description,
            tv_pgroup_duration: this.state.tv_pgroup_duration,
            tv_pgroup_name: this.state.tv_pgroup_name,
            tv_pgroup_price: this.state.tv_pgroup_price,
            tv_pgroup_status: statusSwitch,
            tv_pgroup_billing_id: this.state.tv_pgroup_billing_id,
            custom_data: this.state.custom_data,
            tv_pgroup_control_text: this.state.tv_pgroup_control_text,
            tv_pgroup_help_url: this.state.tv_pgroup_help_url

        };


        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    const tvPriceGroupArray = res.data;
                    this.setState({tv_pgroup_id: tvPriceGroupArray.tv_pgroup_id})
                    this.setTVPriceGroupRedirect();
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    updateTVPriceGroup() {


        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/PriceGroupTv/" + handle;

        let statusSwitch = this.state.statusSwitch === true ? 1 : 0

        // Send a PUT request
        var postData = {
            tv_pgroup_description: this.state.tv_pgroup_description,
            tv_pgroup_duration: this.state.tv_pgroup_duration,
            tv_pgroup_id: this.state.tv_pgroup_id,
            tv_pgroup_name: this.state.tv_pgroup_name,
            tv_pgroup_price: this.state.tv_pgroup_price,
            tv_pgroup_status: statusSwitch,
            tv_pgroup_billing_id: this.state.tv_pgroup_billing_id,
            custom_data: this.state.custom_data,
            tv_pgroup_control_text: this.state.tv_pgroup_control_text,
            tv_pgroup_help_url: this.state.tv_pgroup_help_url
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 301) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else if (res.data.error === 302) {
                    this.setState({
                        loading: false
                    })
                    this.notification("failed")
                } else {
                    this.setState({loading: false})
                    this.notification("success")
                }

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.props.dispatch(userActions.logoutUnauthorized());
            })

    }

    cancelTVGroupEdit() {
        this.setRedirect()
    }

    setTVPriceGroupRedirect = () => {
        this.setState({
            tvRedirect: true,
            back_button_text: "Back"
        })
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    renderRedirect = () => {

        let path = "/pricing/tv-price";
        let pathTV = "/edit-tv-price/" + this.state.tv_pgroup_id;

        if (this.state.redirect) {
            this.setState({redirect: false, tvRedirect: false})
            return <Redirect push to={path}/>
        }

        if (this.state.tvRedirect) {
            this.setState({redirect: false, tvRedirect: false})
            return <Redirect push to={pathTV}/>
        }
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({loading: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createTVPriceGroup();
            } else {
                this.updateTVPriceGroup();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"TV Price Group: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.tv_pgroup_id === 0 ? '' : this.state.tv_pgroup_id}
            />
        }

    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>TV Price Group has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update TV Price Group</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    title() {
        if (this.state.tv_pgroup_name === null) {
            return "New - STV Price Configuration";
        } else {
            return this.state.tv_pgroup_name + " -  TV Price Configuration";
        }
    }

    render() {
        return (
            <div className="main-content">

                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}


                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                                title={this.title()}
                                content={
                                    <div>
                                        <FormGroup>
                                            <p className="category">Status</p>
                                            <Switch
                                                name={"statusSwitch"}
                                                disabled={this.state.loading}
                                                value={this.state.statusSwitch}
                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>ID</ControlLabel>
                                            <FormControl placeholder="tv_pgroup_id"
                                                         type="number"
                                                         name={"tv_pgroup_id"}
                                                         disabled
                                                         value={this.state.tv_pgroup_id || ''}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Name</ControlLabel>
                                            <FormControl placeholder="Enter Name"
                                                         type="text"
                                                         name={"tv_pgroup_name"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.tv_pgroup_name || ''}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Price <code>(USD)</code></ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill default><i
                                                        className="fa fa-dollar"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter price"
                                                             type="text"
                                                             name="tv_pgroup_price"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.tv_pgroup_price || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Duration <code>(Hours)</code> </ControlLabel>
                                            <InputGroup>
                                                <InputGroup.Button>
                                                    <Button fill twitter><i
                                                        className="fa fa-clock-o"/></Button>
                                                </InputGroup.Button>
                                                <FormControl placeholder="Enter duration"
                                                             type="text"
                                                             name="tv_pgroup_duration"
                                                             disabled={this.state.loading}
                                                             onChange={this.handleInputChange}
                                                             value={this.state.tv_pgroup_duration || ""}
                                                />
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Description</ControlLabel>
                                            <FormControl componentClass="textarea"
                                                         type="text"
                                                         rows={4}
                                                         name="tv_pgroup_description"
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         placeholder="Enter the description"
                                                         value={this.state.tv_pgroup_description || ''}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Billing ID</ControlLabel>
                                            <FormControl placeholder="Enter billing id"
                                                         type="text"
                                                         name={"tv_pgroup_billing_id"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.tv_pgroup_billing_id || ''}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Control Text</ControlLabel>
                                            <FormControl placeholder="Enter control text"
                                                         type="text"
                                                         name={"tv_pgroup_control_text"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.tv_pgroup_control_text || ''}/>
                                        </FormGroup>

                                        <FormGroup>
                                            <ControlLabel>Help URL</ControlLabel>
                                            <FormControl placeholder="Enter help url"
                                                         type="text"
                                                         name={"tv_pgroup_help_url"}
                                                         disabled={this.state.loading}
                                                         onChange={this.handleInputChange}
                                                         value={this.state.tv_pgroup_help_url || ''}/>
                                        </FormGroup>


                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <div className="buttons-with-margin">
                                        <Button bsStyle="info" fill wd
                                                name={"saveButton"}
                                                disabled={this.state.loading}
                                                onClick={() => this.setState({showSaveModal: true})}
                                        >
                                            Save
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelTVGroupEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </div>
                                }
                            />
                        </Col>

                    </Row>
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedEditTvPriceGroup = connect(mapStateToProps)(EditTvPriceGroup);
const connectedEditTvPriceGroupTrans = withNamespaces('common')(connectedEditTvPriceGroup);
export {connectedEditTvPriceGroupTrans as EditTvPriceGroup};
