import React, {Component} from "react";
import axios from "axios/index";
import StatsCard from "../../components/Card/StatsCard.jsx";
import {Row, Col} from "react-bootstrap";
import {withNamespaces} from 'react-i18next';
import {middlewareActions, userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";

const accessArray = ["super-admin", "admin", "csr_manager", "dealer","csr_customer"];

class StatsCustomersView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeCustomers: 0,
            disabledCustomers: 0,
            deletedCustomers: 0,
            totalCustomers: 0,
            activeViewers: 0,
            customerStatusArray: [],
            access: "",
            renderStats: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.middleware !== prevProps.middleware) {
           //console.log(this.props.middleware)
            if(this.props.middleware.type === "middleware-refresh"){
                this.updateStatsFromMiddleware()
            }
        }
    }

    componentDidMount() {

        this.updateStatsFromMiddleware()
    }

    updateStatsFromMiddleware(){


        const url = process.env.REACT_APP_API_URL + "/Customer/status";

        let user = JSON.parse(localStorage.getItem('user'));

        let token = ""
        let access = ""

        if (user) {

            token = user.token
            access = user.level

            if (accessArray.includes(access)) {

                this.setState({
                        access,
                        renderStats: true
                    }
                );

                let axiosConfig = {
                    headers: {
                        'X-Id-Token': token
                    }
                };

                axios
                    .get(url, axiosConfig)
                    .then(res => {
                        const customerStatusArray = res.data[0];
                        const loading = false;
                        const activeCustomers = customerStatusArray.ActiveCustomers;
                        const disabledCustomers = customerStatusArray.DisabledCustomers;
                        const deletedCustomers = customerStatusArray.DeletedCustomers;
                        const totalCustomers = customerStatusArray.TotalCustomers;
                        //console.log(customerStatusArray);
                        this.setState({
                            customerStatusArray,
                            loading,
                            activeCustomers,
                            disabledCustomers,
                            deletedCustomers,
                            totalCustomers
                        });
                        this.props.dispatch(middlewareActions.clear());
                        //console.log(customerStatusArray)
                    })
                    .catch(error => {
                        this.setState({loading: false});
                        //this.props.dispatch(userActions.logoutUnauthorized());
                        this.props.dispatch(middlewareActions.clear());
                        console.log(error);
                    });
            }


        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    renderStatsCustomersView = () => {

        if (this.state.renderStats) {
            return (
                <Row>
                    <Col lg={3} sm={6}>
                        <StatsCard
                            bigIcon={<i className="fa fa-check-circle text-success"/>}
                            statsText={this.props.t('cards.customers', {framework: "react-i18next"})}
                            statsValue={this.state.activeCustomers}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <StatsCard
                            bigIcon={<i className="fa fa-ban text-warning"/>}
                            statsText={this.props.t('cards.disabled', {framework: "react-i18next"})}
                            statsValue={this.state.disabledCustomers}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <StatsCard
                            bigIcon={<i className="fa fa-times-circle text-danger"/>}
                            statsText={this.props.t('cards.removed', {framework: "react-i18next"})}
                            statsValue={this.state.deletedCustomers}
                        />
                    </Col>
                    <Col lg={3} sm={6}>
                        <StatsCard
                            bigIcon={<i className="fa fa-users text-info"/>}
                            statsText={this.props.t('cards.total', {framework: "react-i18next"})}
                            statsValue={this.state.totalCustomers}
                        />
                    </Col>
                </Row>
            )
        }

    }

    render() {

        return (
            <div>
                <div>
                </div>
                {this.renderStatsCustomersView()}
            </div>

        );
    }
}

function mapStateToProps(state) {

    const {user,middleware} = state;
    return {
        user,
        middleware
    };
}

const connectedStatsCustomersView = connect(mapStateToProps)(StatsCustomersView);
const connectedStatsCustomersViewTrans = withNamespaces('common')(connectedStatsCustomersView);
export {connectedStatsCustomersViewTrans as StatsCustomersView};
