import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import Datetime from "react-datetime";
import moment from 'moment';
import Select from 'react-select';
import {Grid, Row, Col, FormGroup, ControlLabel, FormControl, ButtonGroup, Well, Glyphicon} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import ModalSave from "../../components/CustomModals/ModalSave";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {DropDownMatchCategory} from "../../components/CustomDropDown/DropDownMatchCategory";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";
import {DropDownCountries} from "../../components/CustomDropDown/DropDownCountries";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const matchType = [
    {value: 'Football', label: 'Football'},
    {value: 'AmericanFootball', label: 'American Football'},
    {value: 'Cricket', label: 'Cricket'},
    {value: 'Basketball', label: 'Basketball'},
    {value: 'Tennis', label: 'Tennis'},
    {value: 'Cycling', label: 'Cycling'},
    {value: 'Athletics', label: 'Athletics'},
    {value: 'Multi-Sports', label: 'Multi-Sports'},
    {value: 'Wrestling', label: 'Wrestling'},
];

const matchStatus = [
    {value: 'pending', label: 'Pending'},
    {value: 'started', label: 'Started'},
    {value: 'finished', label: 'Finished'}
];

const adminArray = ['super-admin', 'admin']

class EditLiveMatches extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            matchArray: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",
            file_poster_server_id: "",
            file_poster_name: "",
            channel_image_temp: false,
            matchRedirect: false,
            showSaveModal: false,

            matchStatus: false,
            matchCastingStatus: false,
            matchMobileStatus: false,

            match_id: 0,
            match_datetime: "",
            match_status: "",
            match_group: "",
            match_stadium: "",
            match_team_a: "",
            match_team_b: "",
            match_location: "",
            match_stage: "",
            match_goals_team_a: 0,
            match_goals_team_b: 0,

            match_streaming_url: "",

            match_team_a_logo: "",
            match_team_b_logo: "",
            match_poster: "",
            match_team_a_layout: "",
            match_team_b_layout: "",
            match_enabled: false,
            match_airing: "",
            match_pre_roll: "",
            match_mid_roll: "",
            match_category_id: 0,

            match_description: "",
            match_casting_status: false,
            match_mobile_status: false,

            match_poster_temp: false,
            match_poster_folder_temp: "",

            match_team_a_logo_temp: false,
            match_team_a_logo_folder_temp: "",

            match_team_a_layout_temp: false,
            match_team_a_layout_folder_temp: "",

            match_team_b_logo_temp: false,
            match_team_b_logo_folder_temp: "",

            match_team_b_layout_temp: false,
            match_team_b_layout_folder_temp: "",

            match_content_provider: null,
            current_user: null,
            user_content_provider: null,
            custom_data: null,

            match_type: null,
            match_external_league_id: null,
            match_external_id: null,
            private_data: null,
            match_time: null,

            match_billing_id: null,
            match_dvr_duration: null,
            match_blackout_countries: null,
            match_blackout_url: null,
            match_country_block_countries: null,
            match_p2p_status: null,

            p2pStatus: false,
            webLoginStatus: false,
            castingStatus: false,
            timeshiftStatus: false,
            countryBlockStatus: false,
            blackOutStatus: false,

            addAllCountriesInCountryBlock: false,
            addAllCountriesInBlackOut: false,

        };

        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);
        this.handleMatchDate = this.handleMatchDate.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);
        this.handleChildCountrySelect = this.handleChildCountrySelect.bind(this);
        this.handleChildBlockCountrySelect = this.handleChildBlockCountrySelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    handleChildCategorySelect(data) {

        //console.log(data);
        this.setState({
            match_category_id: data === null ? 0 : data.value
        });
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveMatch/" + handle;

        if (handle === 'new') {
            this.setState({loading: false, current_user: user})
        } else {
            axios
                .get(url, axiosConfig)
                .then(res => {

                    const matchArray = res.data[0];
                    const loading = false;

                    let matchStatus = matchArray.match_enabled === 0 ? false : true
                    let matchCastingStatus = matchArray.match_casting_status === 0 ? false : true
                    let matchMobileStatus = matchArray.match_mobile_status === 0 ? false : true
                    let localMatchDatetime = moment.utc(matchArray.match_datetime).local();


                    let blackOutStatus = matchArray.match_blackout_status === 1
                    let countryBlockStatus = matchArray.match_country_block_status === 1
                    let p2pStatus = matchArray.match_p2p_status === 1

                    let countries = []
                    if (matchArray.match_blackout_countries !== null) {
                        let tempCountry = matchArray.match_blackout_countries.toString();
                        countries = tempCountry.split(",")
                    }

                    let countriesBlocked = []
                    if (matchArray.match_country_block_countries !== null) {
                        let tempCountry = matchArray.match_country_block_countries.toString();
                        countriesBlocked = tempCountry.split(",")
                    }

                    this.setState({
                        back_button_text: "Back",
                        matchArray,
                        loading,

                        matchStatus,
                        matchCastingStatus,
                        matchMobileStatus,
                        blackOutStatus,
                        countryBlockStatus,
                        p2pStatus,

                        matchStatusDropDown: null,

                        match_id: matchArray.match_id,
                        match_datetime: localMatchDatetime,
                        match_status: matchArray.match_status,
                        match_group: matchArray.match_group,
                        match_stadium: matchArray.match_stadium,
                        match_team_a: matchArray.match_team_a,
                        match_team_b: matchArray.match_team_b,
                        match_location: matchArray.match_location,
                        match_stage: matchArray.match_stage,
                        match_goals_team_a: matchArray.match_goals_team_a,
                        match_goals_team_b: matchArray.match_goals_team_b,
                        match_streaming_url: matchArray.match_streaming_url,
                        match_team_a_logo: matchArray.match_team_a_logo,
                        match_team_b_logo: matchArray.match_team_b_logo,
                        match_poster: matchArray.match_poster,
                        match_team_a_layout: matchArray.match_team_a_layout,
                        match_team_b_layout: matchArray.match_team_b_layout,
                        match_enabled: matchStatus,
                        match_airing: matchArray.match_airing,
                        match_pre_roll: matchArray.match_pre_roll,
                        match_mid_roll: matchArray.match_mid_roll,
                        match_category_id: matchArray.match_category_id === null ? 0 : matchArray.match_category_id,
                        match_description: matchArray.match_description,
                        match_casting_status: matchCastingStatus,
                        match_mobile_status: matchMobileStatus,
                        current_user: user,
                        user_content_provider: matchArray.match_content_provider,
                        custom_data: matchArray.custom_data,
                        match_type: matchArray.match_type,
                        match_external_league_id: matchArray.match_external_league_id,
                        match_external_id: matchArray.match_external_id,
                        private_data: matchArray.private_data,
                        match_time: matchArray.match_time,

                        match_blackout_status: blackOutStatus,
                        match_country_block_status: countryBlockStatus,
                        match_blackout_countries: countriesBlocked,
                        match_blackout_url: matchArray.match_blackout_url,
                        match_country_block_countries: countries,
                        match_p2p_status: p2pStatus,


                    });
                })
                .catch((err) => {
                    console.log("PUT AXIOS ERROR: ", err);
                    this.setState({loading: false})
                })
        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'statusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    updateMatch() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        let m_match_datetime = moment.utc(this.state.match_datetime).format('YYYY-MM-DD HH:mm:ss').toString();


        //  1.  ///////////////////////////////////////////////////////////////////////
        let filename_poster_team_a = ""

        if (this.state.match_team_a_logo_temp) {
            let timeUTC = new Date().getTime()
            filename_poster_team_a = timeUTC + "_" + this.state.match_team_a_logo
            this.setState({match_team_a_logo: filename_poster_team_a})
            //console.log("*****" + filename_poster_team_a)
            this.submitTeamA(filename_poster_team_a);
        } else {
            filename_poster_team_a = this.state.match_team_a_logo
        }

        //  2.  ///////////////////////////////////////////////////////////////////////
        let filename_poster_team_b = ""

        if (this.state.match_team_b_logo_temp) {
            let timeUTC = new Date().getTime()
            filename_poster_team_b = timeUTC + "_" + this.state.match_team_b_logo
            this.setState({match_team_b_logo: filename_poster_team_b})
            //console.log("*****" + filename_poster_team_b)
            this.submitTeamB(filename_poster_team_b);
        } else {
            filename_poster_team_b = this.state.match_team_b_logo
        }

        //  3.  ///////////////////////////////////////////////////////////////////////
        let filename_match_poster = ""

        if (this.state.match_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_match_poster = timeUTC + "_" + this.state.match_poster
            this.setState({match_poster: filename_match_poster})
            //console.log("*****" + filename_match_poster)
            this.submitPoster(filename_match_poster);
        } else {
            filename_match_poster = this.state.match_poster
        }

        //  4.  ///////////////////////////////////////////////////////////////////////
        let filename_team_layout_a = ""

        if (this.state.match_team_a_layout_temp) {
            let timeUTC = new Date().getTime()
            filename_team_layout_a = timeUTC + "_" + this.state.match_team_a_layout
            this.setState({match_team_a_layout: filename_team_layout_a})
            //console.log("*****" + filename_team_layout_a)
            this.submitTeamLayoutA(filename_team_layout_a);
        } else {
            filename_team_layout_a = this.state.match_team_a_layout
        }

        //  5.  ///////////////////////////////////////////////////////////////////////
        let filename_team_layout_b = ""

        if (this.state.match_team_b_layout_temp) {
            let timeUTC = new Date().getTime()
            filename_team_layout_b = timeUTC + "_" + this.state.match_team_b_layout
            this.setState({match_team_b_layout: filename_team_layout_b})
            //console.log("*****" + filename_team_layout_b)
            this.submitTeamLayoutB(filename_team_layout_b);
        } else {
            filename_team_layout_b = this.state.match_team_b_layout
        }

        //  END  ///////////////////////////////////////////////////////////////////////

        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/LiveMatch/" + handle;

        let match_enabled = this.state.matchStatus === true ? 1 : 0;
        let match_casting_status = this.state.matchCastingStatus === true ? 1 : 0;
        let match_mobile_status = this.state.matchMobileStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            match: {
                match_id: this.state.match_id,
                match_datetime: m_match_datetime,
                match_status: this.state.match_status,
                match_group: this.state.match_group,
                match_stadium: this.state.match_stadium,
                match_team_a: this.state.match_team_a,
                match_team_b: this.state.match_team_b,
                match_location: this.state.match_location,
                match_stage: this.state.match_stage,
                match_goals_team_a: this.state.match_goals_team_a,
                match_goals_team_b: this.state.match_goals_team_b,
                match_streaming_url: this.state.match_streaming_url,
                match_team_a_logo: filename_poster_team_a,
                match_team_b_logo: filename_poster_team_b,
                match_poster: filename_match_poster,
                match_team_a_layout: filename_team_layout_a,
                match_team_b_layout: filename_team_layout_b,
                match_enabled: match_enabled,
                match_airing: this.state.match_airing,
                match_pre_roll: this.state.match_pre_roll,
                match_mid_roll: this.state.match_mid_roll,
                match_category_id: this.state.match_category_id,
                match_description: this.state.match_description,
                match_casting_status: match_casting_status,
                match_mobile_status: match_mobile_status,
                match_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
                match_type: this.state.match_type,
                match_time: this.state.match_time,
                match_external_league_id: this.state.match_external_league_id,
                match_external_id: this.state.match_external_id,
                private_data: this.state.private_data,

                match_blackout_countries: this.state.match_blackout_countries === null ? null : this.state.match_blackout_countries.join(),
                match_blackout_url: this.state.match_blackout_url,
                match_blackout_status: this.state.blackOutStatus === true ? 1 : 0,
                match_country_block_status: this.state.countryBlockStatus === true ? 1 : 0,
                match_country_block_countries: this.state.match_country_block_countries === null ? null : this.state.match_country_block_countries.join(),
                match_p2p_status: this.state.p2pStatus === true ? 1 : 0,
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false})
                this.setState({loading: false})
                this.notification("success")
            })
            .catch((err) => {
                console.log("PUT AXIOS ERROR: ", err);
                this.notification("failed")
                this.setState({loading: false})
            })

    }

    createMatch() {


        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/LiveMatch";

        let match_enabled = this.state.matchStatus === true ? 1 : 0;
        let match_casting_status = this.state.matchCastingStatus === true ? 1 : 0;
        let match_mobile_status = this.state.matchMobileStatus === true ? 1 : 0;
        let m_match_datetime = moment.utc(this.state.match_datetime).format('YYYY-MM-DD HH:mm:ss').toString();

        //  1.  ///////////////////////////////////////////////////////////////////////
        let filename_poster_team_a = ""

        if (this.state.match_team_a_logo_temp) {
            let timeUTC = new Date().getTime()
            filename_poster_team_a = timeUTC + "_" + this.state.match_team_a_logo
            this.setState({match_team_a_logo: filename_poster_team_a})
            //console.log("*****" + filename_poster_team_a)
            this.submitTeamA(filename_poster_team_a);
        } else {
            filename_poster_team_a = this.state.match_team_a_logo
        }

        //  2.  ///////////////////////////////////////////////////////////////////////
        let filename_poster_team_b = ""

        if (this.state.match_team_b_logo_temp) {
            let timeUTC = new Date().getTime()
            filename_poster_team_b = timeUTC + "_" + this.state.match_team_b_logo
            this.setState({match_team_b_logo: filename_poster_team_b})
            //console.log("*****" + filename_poster_team_b)
            this.submitTeamB(filename_poster_team_b);
        } else {
            filename_poster_team_b = this.state.match_team_b_logo
        }

        //  3.  ///////////////////////////////////////////////////////////////////////
        let filename_match_poster = ""

        if (this.state.match_poster_temp) {
            let timeUTC = new Date().getTime()
            filename_match_poster = timeUTC + "_" + this.state.match_poster
            this.setState({match_poster: filename_match_poster})
            //console.log("*****" + filename_match_poster)
            this.submitPoster(filename_match_poster);
        } else {
            filename_match_poster = this.state.match_poster
        }

        //  4.  ///////////////////////////////////////////////////////////////////////
        let filename_team_layout_a = ""

        if (this.state.match_team_a_layout_temp) {
            let timeUTC = new Date().getTime()
            filename_team_layout_a = timeUTC + "_" + this.state.match_team_a_layout
            this.setState({match_team_a_layout: filename_team_layout_a})
            //console.log("*****" + filename_team_layout_a)
            this.submitTeamLayoutA(filename_team_layout_a);
        } else {
            filename_team_layout_a = this.state.match_team_a_layout
        }

        //  5.  ///////////////////////////////////////////////////////////////////////
        let filename_team_layout_b = ""

        if (this.state.match_team_b_layout_temp) {
            let timeUTC = new Date().getTime()
            filename_team_layout_b = timeUTC + "_" + this.state.match_team_b_layout
            this.setState({match_team_b_layout: filename_team_layout_b})
            //console.log("*****" + filename_team_layout_b)
            this.submitTeamLayoutB(filename_team_layout_b);
        } else {
            filename_team_layout_b = this.state.match_team_b_layout
        }

        //  END  ///////////////////////////////////////////////////////////////////////

        var postData = {
            match: {
                match_datetime: m_match_datetime,
                match_status: this.state.match_status,
                match_group: this.state.match_group,
                match_stadium: this.state.match_stadium,
                match_team_a: this.state.match_team_a,
                match_team_b: this.state.match_team_b,
                match_location: this.state.match_location,
                match_stage: this.state.match_stage,
                match_goals_team_a: this.state.match_goals_team_a,
                match_goals_team_b: this.state.match_goals_team_b,
                match_streaming_url: this.state.match_streaming_url,
                match_team_a_logo: filename_poster_team_a,
                match_team_b_logo: filename_poster_team_b,
                match_poster: filename_match_poster,
                match_team_a_layout: filename_team_layout_a,
                match_team_b_layout: filename_team_layout_b,
                match_enabled: match_enabled,
                match_airing: this.state.match_airing,
                match_pre_roll: this.state.match_pre_roll,
                match_mid_roll: this.state.match_mid_roll,
                match_category_id: this.state.match_category_id,
                match_description: this.state.match_description,
                match_casting_status: match_casting_status,
                match_mobile_status: match_mobile_status,
                match_content_provider: this.state.user_content_provider,
                custom_data: this.state.custom_data,
                match_type: this.state.match_type,
                match_time: this.state.match_time,
                match_external_league_id: this.state.match_external_league_id,
                match_external_id: this.state.match_external_id,
                private_data: this.state.private_data,

                match_blackout_countries: this.state.match_blackout_countries === null ? null : this.state.match_blackout_countries.join(),
                match_blackout_url: this.state.match_blackout_url,
                match_blackout_status: this.state.blackOutStatus === true ? 1 : 0,
                match_country_block_status: this.state.countryBlockStatus === true ? 1 : 0,
                match_country_block_countries: this.state.match_country_block_countries === null ? null : this.state.match_country_block_countries.join(),
                match_p2p_status: this.state.p2pStatus === true ? 1 : 0,
            }
        };
        // console.log(postData);

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({saving: false})
                this.setState({loading: false})
                //this.submitAllImages();

                if (res.data.code === 300) {
                    // console.log("*1: ", res.data.code);
                    // console.log("*2: ", res.data.message);
                    //this.handleValidationError(res.data)

                }
                const matchData = res.data;
                this.setState({match_id: matchData.match_id})
                // console.log("MATCH SAVED: " + matchData.match_id);
                this.notification("success")
                this.setMatchRedirect();
            })
            .catch((err) => {
                console.log("POST AXIOS ERROR: ", err);
                this.notification("failed")
                this.setState({loading: false})
            })

    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'matchStatus') {
            this.setState({matchStatus: state})
        }
        if (elem.props.name === 'matchCastingStatus') {
            this.setState({matchCastingStatus: state})
        }
        if (elem.props.name === 'matchMobileStatus') {
            this.setState({matchMobileStatus: state})
        }

        if (elem.props.name === 'p2pStatus') {
            this.setState({p2pStatus: state})
        }

        if (elem.props.name === 'blackOutSwitch') {
            this.setState({blackOutStatus: state})
        }

        if (elem.props.name === 'countryBlockSwitch') {
            this.setState({countryBlockStatus: state})
        }


        // if (elem.props.name === 'webLoginSwitch') {
        //     this.setState({webLoginStatus: state})
        // }
        //
        // if (elem.props.name === 'castingSwitch') {
        //     this.setState({castingStatus: state})
        // }
        //
        // if (elem.props.name === 'timeshiftSwitch') {
        //     this.setState({timeshiftStatus: state})
        // }
    }

    handleMatchDate(date) {

        console.log(moment.utc(date).format('X'));
        console.log(moment.utc(date).format('LLL').toString());
        console.log(moment.utc(date).format('YYYY-MM-DD hh:mm:ss').toString());

        // let m = moment.utc(date).format('YYYY-MM-DD hh:mm:ss').toString();
        //
        // this.setState({
        //     match_datetime: m
        // });

        let m = moment(date)

        this.setState({
            match_datetime: m
        });

    };

    handleChildImageSelect(folder, filename, imagePosition) {

        console.log("" + folder);
        console.log("" + filename);
        console.log("" + imagePosition);
        console.log(process.env.REACT_APP_ASSET_URL + "/tmp/" + folder + "/" + filename);

        if (imagePosition === "match_poster") {
            this.setState({
                match_poster_temp: filename !== null,
                match_poster: filename,
                match_poster_folder_temp: folder
            });
        }
        if (imagePosition === "match_team_a_logo") {
            this.setState({
                match_team_a_logo_temp: filename !== null,
                match_team_a_logo: filename,
                match_team_a_logo_folder_temp: folder
            });
        }
        if (imagePosition === "match_team_a_layout") {
            this.setState({
                match_team_a_layout_temp: filename !== null,
                match_team_a_layout: filename,
                match_team_a_layout_folder_temp: folder
            });
        }
        if (imagePosition === "match_team_b_logo") {
            this.setState({
                match_team_b_logo_temp: filename !== null,
                match_team_b_logo: filename,
                match_team_b_logo_folder_temp: folder
            });
        }
        if (imagePosition === "match_team_b_layout") {
            this.setState({
                match_team_b_layout_temp: filename !== null,
                match_team_b_layout: filename,
                match_team_b_layout_folder_temp: folder
            });
        }

    }

    handleMatchStatusChange = (matchStatusDropDown) => {

        this.setState({
            matchStatusDropDown,
            match_status: matchStatusDropDown === null ? "pending" : matchStatusDropDown.value
        });
        // console.log(`Option selected:`, matchStatusDropDown);

    }

    handleMatchTypeChange = (matchTypeDropDown) => {

        this.setState({
            matchTypeDropDown,
            match_type: matchTypeDropDown === null ? "pending" : matchTypeDropDown.value
        });
        // console.log(`Option selected:`, matchTypeDropDown);

    }

    handleChildCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        this.setState({
            match_blackout_countries: data === null ? 0 : countryIDs, addAllCountriesInCountryBlock: false
        });
    }

    handleChildBlockCountrySelect(data) {

        let countryIDs = []
        let countryNames = []

        for (let i = 0; i < data.length; i++) {
            countryIDs.push(data[i].value)
        }

        for (let i = 0; i < data.length; i++) {
            countryNames.push(data[i].label)
        }

        this.setState({
            match_country_block_countries: data === null ? 0 : countryIDs, addAllCountriesInBlackOut: false
        });
    }

    onClickAddAllCountriesCountryBlock() {
        this.setState({addAllCountriesInCountryBlock: true})
    }

    onClickAddAllCountriesBlackOut() {
        this.setState({addAllCountriesInBlackOut: true})
    }

    submitTeamA(filename_poster_team_a) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.match_team_a_logo_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.match_team_a_logo_folder_temp);
            bodyFormData.set('location', "live_match");
            bodyFormData.set('filename', filename_poster_team_a);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({match_team_a_logo_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitTeamB(filename_poster_team_b) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.match_team_b_logo_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.match_team_b_logo_folder_temp);
            bodyFormData.set('location', "live_match");
            bodyFormData.set('filename', filename_poster_team_b);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({match_team_b_logo_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitPoster(filename_match_poster) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.match_poster_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.match_poster_folder_temp);
            bodyFormData.set('location', "live_match");
            bodyFormData.set('filename', filename_match_poster);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({match_poster_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitTeamLayoutA(filename_team_layout_a) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.match_team_a_layout_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.match_team_a_layout_folder_temp);
            bodyFormData.set('location', "live_match");
            bodyFormData.set('filename', filename_team_layout_a);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({match_team_a_layout_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    submitTeamLayoutB(filename_team_layout_b) {

        const imageUploadUrl = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

        if (this.state.match_team_b_layout_temp) {

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.match_team_b_layout_folder_temp);
            bodyFormData.set('location', "live_match");
            bodyFormData.set('filename', filename_team_layout_b);

            axios.post(imageUploadUrl, bodyFormData)
                .then((res) => {
                    this.setState({match_team_b_layout_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }

    }

    cancelMatchEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setMatchRedirect = () => {
        this.setState({
            matchRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        let path = "/live/sports";
        let pathChannel = "/edit-match/" + this.state.match_id;

        if (this.state.redirect) {
            return <Redirect push to={path}/>
        }

        if (this.state.matchRedirect) {
            // console.log(pathChannel);
            return <Redirect push to={pathChannel}/>
        }

    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"Sport Event: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.match_id}
            />
        }
    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createMatch();
            } else {
                this.updateMatch();
            }
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Sport Event has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>failed to update Sport Event</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <FormGroup>
                            <ControlLabel>Content Provider</ControlLabel>
                            <DropDownContentProviders
                                contentProviderSelected={this.state.user_content_provider}
                                name='contentProviderSelect'
                                handlerFromParent={this.handleChildContentProviderSelect}
                                disabled={this.state.loading}
                            />
                        </FormGroup>
                    </Row>
                )
            }
        }

    }

    render() {

        // var background = {backgroundSize : 'cover'};

        // var imageStyleLeft = {
        //     position: 'absolute',
        //     top: '15px',
        //     left: '30px',
        //     width: 'auto',
        //     height: 'auto',
        //     maxWidth: '60%',
        //     maxHeight: '60%'
        // };
        //
        // var imageStyleRight = {
        //     position: 'absolute',
        //     top: '15px',
        //     right: '30px',
        //     width: 'auto',
        //     height: 'auto',
        //     maxWidth: '60%',
        //     maxHeight: '60%'
        // };

        // let backgroundStyle = {
        //     position: 'relative',
        //     zIndex: '2',
        //     overflow: 'hidden',
        //     height: '371px',
        //     textAlign: 'center',
        //     backgroundRepeat: 'no-repeat',
        //     // -moz-background-size: '100% auto',
        //     // -o-background-size: '100% auto',
        //     // -webkit-background-size: '100% auto',
        //     backgroundSize: '100% auto'
        // };

        // let backgroundImage = {
        //     opacity: '1',
        //     // -moz-transition: opacity .5s ease,
        //     // -o-transition: opacity .5s ease,
        //     // -webkit-transition: opacity .5s ease,
        //     transition: 'opacity .5s ease',
        //     position: 'absolute',
        //     zIndex: '-1',
        //     top: '0',
        //     left: '0',
        //     width: '100%',
        //     height: 'auto'
        // };
        //
        // var textStyle = {
        //     position: 'absolute',
        //     top: '50px',
        //     left: '36%',
        //     color: '#f5f5f5'
        // };
        //
        // var divStyle = {
        //     position: 'absolute',
        //     top: '50px',
        //     color: '#f5f5f5'
        // };

        return (
            <form>
                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="main-content">

                    <div className="sweet-loader">
                        <ScaleLoader
                            css={override}
                            color={'#e5e5e5'}
                            loading={this.state.loading}
                        />
                    </div>


                    <Grid fluid>
                        <Row>
                            <Col md={6}>
                                <Card
                                    content={
                                        <div>
                                            <h5>Event Information</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={3}>
                                                            <ControlLabel>Status</ControlLabel>
                                                            <FormGroup>
                                                                <Switch
                                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                                    name='matchStatus'
                                                                    disabled={this.state.loading}
                                                                    value={this.state.matchStatus}
                                                                    defaultValue={false}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <ControlLabel>Casting</ControlLabel>
                                                            <FormGroup>
                                                                <Switch
                                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                                    name='matchCastingStatus'
                                                                    disabled={this.state.loading}
                                                                    value={this.state.matchCastingStatus}
                                                                    defaultValue={false}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <ControlLabel>Mobile</ControlLabel>
                                                            <FormGroup>
                                                                <Switch
                                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                                    name='matchMobileStatus'
                                                                    disabled={this.state.loading}
                                                                    value={this.state.matchMobileStatus}
                                                                    defaultValue={false}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={3}>
                                                            <ControlLabel>P2P</ControlLabel>
                                                            <FormGroup>
                                                                <Switch
                                                                    onChange={(el, state) => this.handleSwitch(el, state)}
                                                                    name='p2pStatus'
                                                                    disabled={this.state.loading}
                                                                    value={this.state.p2pStatus}
                                                                    defaultValue={false}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Match Status</ControlLabel>
                                                            <Select
                                                                value={this.state.match_status}
                                                                disabled={this.state.loading}
                                                                onChange={this.handleMatchStatusChange}
                                                                options={matchStatus}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Match Type</ControlLabel>
                                                            <Select
                                                                value={this.state.match_type}
                                                                disabled={this.state.loading}
                                                                onChange={this.handleMatchTypeChange}
                                                                options={matchType}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>STREAMING URL</ControlLabel>
                                                            <FormControl placeholder="Enter streaming url"
                                                                         type="text"
                                                                         name="match_streaming_url"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_streaming_url || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>


                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>MATCH TIME</ControlLabel>
                                                            <FormControl placeholder="Enter match time"
                                                                         type="text"
                                                                         name="match_time"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_time || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    {/*<Row>*/}
                                                    {/*    <FormGroup>*/}
                                                    {/*        <ControlLabel>MATCH AIRING</ControlLabel>*/}
                                                    {/*        <FormControl placeholder="match_airing"*/}
                                                    {/*                     type="text"*/}
                                                    {/*                     name="match_airing"*/}
                                                    {/*                     disabled={this.state.loading}*/}
                                                    {/*                     onChange={this.handleInputChange}*/}
                                                    {/*                     value={this.state.match_airing|| ""}*/}
                                                    {/*        />*/}
                                                    {/*    </FormGroup>*/}
                                                    {/*</Row>*/}

                                                    <Row>
                                                        {/*<FormGroup>*/}
                                                        {/*<ControlLabel>match_category_id</ControlLabel>*/}
                                                        {/*<FormControl placeholder="match_category_id"*/}
                                                        {/*type="text"*/}
                                                        {/*name="match_category_id"*/}
                                                        {/*onChange={this.handleInputChange}*/}
                                                        {/*value={this.state.match_category_id}*/}
                                                        {/*/>*/}
                                                        {/*</FormGroup>*/}
                                                        <FormGroup>
                                                            <ControlLabel>Category</ControlLabel>
                                                            <DropDownMatchCategory
                                                                categorySelected={this.state.match_category_id}
                                                                name='categorySelect'
                                                                disabled={this.state.loading}
                                                                handlerFromParent={this.handleChildCategorySelect}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    {this.renderContentProvider()}
                                                </Col>
                                            </Grid>

                                        </div>
                                    }
                                />

                                <Card
                                    content={
                                        <div>
                                            <h5>Event details</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <ControlLabel>Poster</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.match_poster}
                                                                    imageType="live_match"
                                                                    imagePosition={"match_poster"}
                                                                    imageTemp={this.state.match_poster_temp}
                                                                    imageFolderTemp={this.state.match_poster_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Date and Time </ControlLabel>
                                                            <Datetime
                                                                name={"match_datetime"}
                                                                inputProps={{placeholder: "Match Data and Time"}}
                                                                // defaultValue={new Date()}
                                                                disabled={this.state.loading}
                                                                value={this.state.match_datetime}
                                                                onChange={this.handleMatchDate}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>GROUP</ControlLabel>
                                                            <FormControl placeholder="match_group"
                                                                         type="text"
                                                                         name="match_group"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_group || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>STADIUM</ControlLabel>
                                                            <FormControl placeholder="match_stadium"
                                                                         type="text"
                                                                         name="match_stadium"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_stadium || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>LOCATION</ControlLabel>
                                                            <FormControl placeholder="match_location"
                                                                         type="text"
                                                                         name="match_location"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_location || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>STAGE</ControlLabel>
                                                            <FormControl placeholder="match_stage"
                                                                         type="text"
                                                                         name="match_stage"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_stage || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Description</ControlLabel>
                                                            <FormControl componentClass="textarea"
                                                                         type="text"
                                                                         name="match_description"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         placeholder="Enter the description"
                                                                         value={this.state.match_description || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                                {/*<Card*/}
                                {/*    content={*/}
                                {/*        <div>*/}
                                {/*            <h5>Other Settings</h5>*/}
                                {/*            <legend/>*/}
                                {/*            <Grid fluid>*/}
                                {/*                <Row>*/}
                                {/*                    <Col md={3}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>P2P</ControlLabel>*/}
                                {/*                            <br/>*/}
                                {/*                            <Switch*/}
                                {/*                                onChange={(el, state) => this.handleSwitch(el, state)}*/}
                                {/*                                name='p2pSwitch'*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                value={this.state.p2pStatus}*/}
                                {/*                                defaultValue={false}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                    <Col md={3}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>Require Login</ControlLabel>*/}
                                {/*                            <br/>*/}
                                {/*                            <Switch*/}
                                {/*                                onChange={(el, state) => this.handleSwitch(el, state)}*/}
                                {/*                                name='webLoginSwitch'*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                value={this.state.webLoginStatus}*/}
                                {/*                                defaultValue={false}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                    <Col md={3}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>Casting</ControlLabel>*/}
                                {/*                            <br/>*/}
                                {/*                            <Switch*/}
                                {/*                                onChange={(el, state) => this.handleSwitch(el, state)}*/}
                                {/*                                name='castingSwitch'*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                value={this.state.castingStatus}*/}
                                {/*                                defaultValue={false}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                    <Col md={3}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>Time shift</ControlLabel>*/}
                                {/*                            <br/>*/}
                                {/*                            <Switch*/}
                                {/*                                onChange={(el, state) => this.handleSwitch(el, state)}*/}
                                {/*                                name='timeshiftSwitch'*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                value={this.state.timeshiftStatus}*/}
                                {/*                                defaultValue={false}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*                <Row>*/}
                                {/*                    <Col md={12}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>DVR Duration (Minutes)</ControlLabel>*/}
                                {/*                            <FormControl*/}
                                {/*                                placeholder={this.props.t('events_edit.live_event_name_short_placeholder', {framework: "react-i18next"})}*/}
                                {/*                                type="number"*/}
                                {/*                                name="live_event_dvr_duration"*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                onChange={this.handleInputChange}*/}
                                {/*                                value={this.state.match_dvr_duration || ""}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}
                                {/*                <Row>*/}
                                {/*                    <Col md={12}>*/}
                                {/*                        <FormGroup>*/}
                                {/*                            <ControlLabel>Billing Product ID</ControlLabel>*/}
                                {/*                            <FormControl*/}
                                {/*                                placeholder="Billing Product ID"*/}
                                {/*                                type="text"*/}
                                {/*                                name="live_event_billing_id"*/}
                                {/*                                disabled={this.state.loading}*/}
                                {/*                                onChange={this.handleInputChange}*/}
                                {/*                                value={this.state.match_billing_id || ""}*/}
                                {/*                            />*/}
                                {/*                        </FormGroup>*/}
                                {/*                    </Col>*/}
                                {/*                </Row>*/}

                                {/*                {this.renderContentProvider()}*/}

                                {/*            </Grid>*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*/>*/}

                                <Card
                                    content={
                                        <div>
                                            <h5>Blackout Settings</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            {/*<p className="category">{this.props.t('channels_edit.channel_blackout_status', {framework: "react-i18next"})}</p>*/}
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                name='blackOutSwitch'
                                                                disabled={this.state.loading}
                                                                value={this.state.blackOutStatus}
                                                                defaultValue={false}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel
                                                                className="category">{this.props.t('channels_edit.channel_blackout_countries', {framework: "react-i18next"})}</ControlLabel>
                                                            <DropDownCountries
                                                                countrySelected={this.state.match_blackout_countries}
                                                                name='blackOutSelect'
                                                                handlerFromParent={this.handleChildCountrySelect}
                                                                hadlerAddAllCountries={this.state.addAllCountriesInBlackOut}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <Button
                                                                onClick={this.onClickAddAllCountriesBlackOut.bind(this)}
                                                            >
                                                                Add All countries
                                                            </Button>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>{this.props.t('channels_edit.channel_blackout_url', {framework: "react-i18next"})}</ControlLabel>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={this.props.t('channels_edit.channel_blackout_url_placeholder', {framework: "react-i18next"})}
                                                                onChange={this.handleInputChange}
                                                                name="match_blackout_url"
                                                                disabled={this.state.loading}
                                                                value={this.state.match_blackout_url || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <Well> <Glyphicon glyph="question-sign"/> By enabling the
                                                            Blackout
                                                            functionality any countries added in the "Blackout Country
                                                            list"
                                                            field will be redirected to the "Blackout URL"
                                                        </Well>
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                                <Card
                                    content={
                                        <div>
                                            <h5>Country Block Settings</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            {/*<p className="category">Country Block</p>*/}
                                                            <Switch
                                                                onChange={(el, state) => this.handleSwitch(el, state)}
                                                                name='countryBlockSwitch'
                                                                disabled={this.state.loading}
                                                                value={this.state.countryBlockStatus}
                                                                defaultValue={false}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel className="category">Countries to
                                                                Block</ControlLabel>
                                                            <DropDownCountries
                                                                countrySelected={this.state.match_country_block_countries}
                                                                name='countryBlockSelect'
                                                                handlerFromParent={this.handleChildBlockCountrySelect}
                                                                hadlerAddAllCountries={this.state.addAllCountriesInCountryBlock}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <Button
                                                                onClick={this.onClickAddAllCountriesCountryBlock.bind(this)}
                                                            >
                                                                Add All countries
                                                            </Button>
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <Well> <Glyphicon glyph="question-sign"/> By enabling the
                                                            Country
                                                            Block
                                                            functionality,the live event will be hidden for the
                                                            countries
                                                            specified.
                                                        </Well>
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                                <Card
                                    content={
                                        <div>
                                            <h5>VAST Configuration</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>PRE ROLL</ControlLabel>
                                                            <FormControl placeholder="match_pre_roll"
                                                                         type="text"
                                                                         name="match_pre_roll"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_pre_roll || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>MID ROLL</ControlLabel>
                                                            <FormControl placeholder="match_mid_roll"
                                                                         type="text"
                                                                         name="match_mid_roll"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_mid_roll || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>
                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                                <Card
                                    content={
                                        <div>
                                            <h5>External Configuration</h5>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>League ID</ControlLabel>
                                                            <FormControl placeholder="Enter league id"
                                                                         type="text"
                                                                         name="match_external_league_id"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_external_league_id || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                    <Row>
                                                        <FormGroup>
                                                            <ControlLabel>Match ID</ControlLabel>
                                                            <FormControl placeholder="Enter match id"
                                                                         type="text"
                                                                         name="match_external_id"
                                                                         disabled={this.state.loading}
                                                                         onChange={this.handleInputChange}
                                                                         value={this.state.match_external_id || ""}
                                                            />
                                                        </FormGroup>
                                                    </Row>

                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                            </Col>

                            <Col md={6}>

                                <Card
                                    tableFullWidth
                                    content={
                                        <div id="out-test" className="text-center center center-block">
                                            <div id="test" style={{textAlignLast: "center"}}>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => this.setState({showSaveModal: true})}
                                                        bsStyle="info"
                                                        fill wd
                                                        disabled={this.state.loading}
                                                        //type="submit"
                                                        name={"saveButton"}>
                                                        {this.props.t('global.save', {framework: "react-i18next"})}
                                                    </Button>
                                                    <Button bsStyle="default" fill wd
                                                            name={"cancelButton"}
                                                            disabled={this.state.loading}
                                                            onClick={this.cancelMatchEdit.bind(this)}>
                                                        {this.state.back_button_text}
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </div>

                                    }
                                />

                                {/*<Card*/}
                                {/*    content={*/}
                                {/*        <div style={{width: 'auto'}}>*/}
                                {/*            <img className="img-responsive " width={'100%'} height={"100%"}*/}
                                {/*                 src={fieldSunny} alt={fieldSunny}/>*/}
                                {/*            <img className="img-responsive" style={imageStyleLeft} src={npr}*/}
                                {/*                 alt={npr}/>*/}
                                {/*            <img className="img-responsive" style={imageStyleRight} src={apr}*/}
                                {/*                 alt={apr}/>*/}

                                {/*            <div*/}
                                {/*                style={{*/}
                                {/*                    display: "flex",*/}
                                {/*                    justifyContent: "center",*/}
                                {/*                    position: 'absolute',*/}
                                {/*                    top: '50px',*/}
                                {/*                    left: '30px',*/}
                                {/*                    width: '100%'*/}
                                {/*                }}*/}
                                {/*            >*/}
                                {/*                <li>*/}
                                {/*                    <h4 style={{*/}
                                {/*                        color: '#f5f5f5',*/}
                                {/*                        textAlign: 'center'*/}
                                {/*                    }}>{this.state.match_team_a + " VS " + this.state.match_team_b}</h4>*/}
                                {/*                    <h4 style={{*/}
                                {/*                        color: '#f5f5f5',*/}
                                {/*                        textAlign: 'center'*/}
                                {/*                    }}>{"Feb 21th 2020"}</h4>*/}
                                {/*                </li>*/}

                                {/*            </div>*/}

                                {/*        </div>*/}


                                {/*    }*/}
                                {/*    ftTextCenter*/}
                                {/*    legend={*/}
                                {/*        <div className="buttons-with-margin">*/}
                                {/*            <Button*/}
                                {/*                onClick={() => this.setState({showSaveModal: true})}*/}
                                {/*                bsStyle="info"*/}
                                {/*                fill wd*/}
                                {/*                disabled={this.state.loading}*/}
                                {/*                //type="submit"*/}
                                {/*                name={"saveButton"}>*/}
                                {/*                Save*/}
                                {/*            </Button>*/}
                                {/*            <Button bsStyle="default" fill wd*/}
                                {/*                    name={"cancelButton"}*/}
                                {/*                    disabled={this.state.loading}*/}
                                {/*                    onClick={this.cancelMatchEdit.bind(this)}>*/}
                                {/*                {this.state.back_button_text}*/}
                                {/*            </Button>*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*/>*/}

                                <Card
                                    content={
                                        <div>
                                            <h5>Team A</h5>
                                            <h6>configuration parameters for Team A</h6>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={8}>
                                                            <FormGroup>
                                                                <ControlLabel>Team A</ControlLabel>
                                                                <FormControl placeholder="match_team_a"
                                                                             type="text"
                                                                             name="match_team_a"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.match_team_a || ""}
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <ControlLabel>Goals</ControlLabel>
                                                                <FormControl placeholder="match_goals_team_a"
                                                                             type="number"
                                                                             name="match_goals_team_a"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.match_goals_team_a}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Logo</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.match_team_a_logo}
                                                                    imageType="live_match"
                                                                    imagePosition={"match_team_a_logo"}
                                                                    imageTemp={this.state.match_team_a_logo_temp}
                                                                    imageFolderTemp={this.state.match_team_a_logo_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Layout</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.match_team_a_layout}
                                                                    imageType="live_match"
                                                                    imagePosition={"match_team_a_layout"}
                                                                    imageTemp={this.state.match_team_a_layout_temp}
                                                                    imageFolderTemp={this.state.match_team_a_layout_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                </Col>
                                            </Grid>

                                        </div>
                                    }
                                />

                                <Card
                                    content={
                                        <div>
                                            <h5>Team B</h5>
                                            <h6>configuration parameters for Team B</h6>
                                            <legend/>
                                            <Grid fluid>
                                                <Col md={12}>
                                                    <Row>
                                                        <Col md={8}>
                                                            <FormGroup>
                                                                <ControlLabel>Team B</ControlLabel>
                                                                <FormControl placeholder="match_team_b"
                                                                             type="text"
                                                                             name="match_team_b"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.match_team_b || ""}
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <ControlLabel>Goals</ControlLabel>
                                                                <FormControl placeholder="match_goals_team_b"
                                                                             type="number"
                                                                             name="match_goals_team_b"
                                                                             disabled={this.state.loading}
                                                                             onChange={this.handleInputChange}
                                                                             value={this.state.match_goals_team_b}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Logo</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.match_team_b_logo}
                                                                    imageType="live_match"
                                                                    imagePosition={"match_team_b_logo"}
                                                                    imageTemp={this.state.match_team_b_logo_temp}
                                                                    imageFolderTemp={this.state.match_team_b_logo_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <ControlLabel>Layout</ControlLabel>
                                                                <ImageUpload
                                                                    imageName={this.state.match_team_b_layout}
                                                                    imageType="live_match"
                                                                    imagePosition={"match_team_b_layout"}
                                                                    imageTemp={this.state.match_team_b_layout_temp}
                                                                    imageFolderTemp={this.state.match_team_b_layout_folder_temp}
                                                                    handlerFromParent={this.handleChildImageSelect}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>

                                                </Col>
                                            </Grid>
                                        </div>
                                    }
                                />

                            </Col>
                        </Row>
                    </Grid>


                </div>
            </form>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditLiveMatches = connect(mapStateToProps)(EditLiveMatches);
const connectedEditLiveMatchesTrans = withNamespaces('common')(connectedEditLiveMatches);
export {connectedEditLiveMatchesTrans as EditLiveMatches};
