import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import './main.scss'
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import ModalViewCalendarEvent from "../CustomModals/ModalViewCalendarEvent";

class CalendarLiveChannels extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            calendarWeekends: true,

            showCalendarEventModal: false,
            eventTitle: null,
            eventStart: null,
            eventEnd: null,
            eventDescription: null,

            calendarEvents: [ // initial event data
                {title: 'Event Now', start: new Date()}
            ]
        }

    }

    getData() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token
            }
        };

        const url = process.env.REACT_APP_API_URL + "/Channels/Schedule/" + this.props.channelId;


        axios
            .get(url, axiosConfig)
            .then(res => {
                this.setState({calendarEvents: res.data});
            })
            .catch(error => {
                console.log(error);
            });


    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.channelId !== prevProps.channelId) {
            this.getData();
        }
    }

    renderCalendarEventModal = () => {

        if (this.state.showCalendarEventModal) {
            return <ModalViewCalendarEvent
                headerMessage={this.state.eventTitle}
                eventStart={this.state.eventStart}
                eventEnd={this.state.eventEnd}
                eventDescription={this.state.eventDescription}
                handlerFromParent={this.setCloseCalendarEventModal}
            />
        }
    }

    setCloseCalendarEventModal = (value, action) => {

        this.setState({showCalendarEventModal: value, eventTitle: null, eventStart: null, eventEnd: null})

    }


    render() {
        return (
            <div className='channel-mini-guide'>

                {this.renderCalendarEventModal()}

                <div className='channel-mini-guide-calendar'>
                    <FullCalendar
                        defaultView="timeGridDay"
                        header={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'timeGridDay'
                        }}
                        plugins={[timeGridPlugin]}
                        weekends={this.state.calendarWeekends}
                        events={this.state.calendarEvents}
                        eventClick={this.handleDateClick}
                        nowIndicator={true}
                        height={1280}
                    />
                </div>
            </div>
        )
    }

    handleDateClick = (arg) => {

        //console.log(arg)

        let eventTitle = arg.event.title
        let eventStart = arg.event.start.toString()
        let eventEnd = arg.event.end.toString()
        let eventDescription = arg.event.extendedProps.description.toString()

        this.setState({showCalendarEventModal: true, eventTitle, eventStart,eventEnd,eventDescription})
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedCalendarLiveChannels = connect(mapStateToProps)(CalendarLiveChannels);
const connectedCalendarLiveChannelsTrans = withNamespaces('common')(connectedCalendarLiveChannels);
export {connectedCalendarLiveChannelsTrans as CalendarLiveChannels};