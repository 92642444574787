import React, {Component} from "react";
import {Col, ControlLabel, FormGroup, Grid, Row, FormControl} from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton";
import {userActions} from "../../authentication/_actions";
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Card from "../../components/Card/Card";
import qrCodeImage from "../../assets/img/qr-code.png";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class QrCodeManagement extends Component {

    constructor(props) {
        super(props);

        this.state = {

            fire_tv_url: null,
            mobile_ios_url: null,
            mobile_android_url: null,
            information_portal_android_url: null,
            information_portal_ios_url: null,

            fire_tv_url_qr: process.env.REACT_APP_ASSET_URL + "/global/landscape_image_empty.png",
            mobile_ios_url_qr: process.env.REACT_APP_ASSET_URL + "/global/landscape_image_empty.png",
            mobile_android_url_qr: process.env.REACT_APP_ASSET_URL + "/global/landscape_image_empty.png",
            information_portal_android_url_qr: process.env.REACT_APP_ASSET_URL + "/global/landscape_image_empty.png",
            information_portal_ios_url_qr: process.env.REACT_APP_ASSET_URL + "/global/landscape_image_empty.png",

            mobile_redirect: null,
            information_portal_redirect: null,

            loading: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        this.getDataFromMiddleware();
    }

    getDataFromMiddleware() {

        if (AxiosHeader.getConfig() !== null) {

            this.setState({loading: true});

            const url = process.env.REACT_APP_API_URL + "/Global/GetQrCodes";

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {

                    let qrCodeData = res.data;
                    this.setState({
                        fire_tv_url: qrCodeData[0].fire_tv_url,
                        mobile_ios_url: qrCodeData[1].mobile_ios_url,
                        mobile_android_url: qrCodeData[2].mobile_android_url,
                        information_portal_android_url: qrCodeData[3].information_portal_android_url,
                        information_portal_ios_url: qrCodeData[4].information_portal_ios_url,

                        fire_tv_url_qr: qrCodeData[0].fire_tv_url_qr,
                        mobile_ios_url_qr: qrCodeData[1].mobile_ios_url_qr,
                        mobile_android_url_qr: qrCodeData[2].mobile_android_url_qr,
                        information_portal_android_url_qr: qrCodeData[3].information_portal_android_url_qr,
                        information_portal_ios_url_qr: qrCodeData[4].information_portal_ios_url_qr,

                        mobile_redirect: qrCodeData[2].mobile_android_url_redirect,
                        information_portal_redirect: qrCodeData[3].information_portal_android_url_redirect,

                        loading: false
                    })
                }).catch(error => {
                console.log(error);
                this.setState({loading: false})
            });
        }else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    submitChanges() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/Global/QrCodes";

            let putData = {
                fire_tv_url: this.state.fire_tv_url,
                mobile_ios_url: this.state.mobile_ios_url,
                mobile_android_url: this.state.mobile_android_url,
                information_portal_android_url: this.state.information_portal_android_url,
                information_portal_ios_url: this.state.information_portal_ios_url
            };

            axios.put(url, putData, AxiosHeader.getConfig())
                .then((res) => {
                    console.log("AXIOS SUCCESS: ", res);
                    this.getDataFromMiddleware();
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        }else{
            this.props.dispatch(userActions.logoutUnauthorized());
        }


    }

    render() {
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="center">
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '50px', maxWidth: '100%'}}
                                                         src={qrCodeImage}
                                                         alt={"qrCodeImage"}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card
                                title={<legend>QR Codes</legend>}
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Fire TV Application</ControlLabel>
                                                    <FormControl placeholder="Confirm password"
                                                                 type="text"
                                                                 disabled={this.state.loading}
                                                                 name="fire_tv_url"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.fire_tv_url || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Fire TV Application</ControlLabel>
                                                    </div>
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '150px', maxWidth: '90%'}}
                                                         src={this.state.fire_tv_url_qr}
                                                         alt={"fire_tv_url_qr"}
                                                         onError={(e) => {
                                                             e.target.onerror = null;
                                                             e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                                         }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <legend/>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Mobile iOS</ControlLabel>
                                                    <FormControl placeholder="Enter ios url"
                                                                 type="text"
                                                                 disabled={this.state.loading}
                                                                 name="mobile_ios_url"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.mobile_ios_url || ''}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Mobile Android</ControlLabel>
                                                    <FormControl placeholder="Enter android url"
                                                                 type="text"
                                                                 disabled={this.state.loading}
                                                                 name="mobile_android_url"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.mobile_android_url || ''}
                                                    />


                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Mobile QR Code</ControlLabel>
                                                    </div>
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '150px', maxWidth: '90%'}}
                                                         src={this.state.mobile_ios_url_qr}
                                                         alt={"mobile_ios_url_qr"}
                                                         onError={(e) => {
                                                             e.target.onerror = null;
                                                             e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                                         }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <legend/>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>iOS Information Portal</ControlLabel>
                                                    <FormControl placeholder="Enter information portal ios url"
                                                                 type="text"
                                                                 disabled={this.state.loading}
                                                                 name="information_portal_ios_url"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.information_portal_ios_url || ''}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Android Information Portal </ControlLabel>
                                                    <FormControl placeholder="Enter information portal android url"
                                                                 type="text"
                                                                 disabled={this.state.loading}
                                                                 name="information_portal_android_url"
                                                                 onChange={this.handleInputChange}
                                                                 value={this.state.information_portal_android_url || ''}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <div className="center text-center center-block">
                                                        <ControlLabel>Information Portal QR Code</ControlLabel>
                                                    </div>
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '150px', maxWidth: '90%'}}
                                                         src={this.state.information_portal_ios_url_qr}
                                                         alt={"information_portal_ios_url_qr"}
                                                         onError={(e) => {
                                                             e.target.onerror = null;
                                                             e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                                         }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <legend/>
                                            <Col md={12}>
                                                <div className="pull-right" style={{marginTop: "15px"}}>
                                                    <FormGroup>
                                                        <Button
                                                            fill
                                                            bsStyle="primary"
                                                            onClick={this.submitChanges.bind(this)}
                                                        >
                                                            Apply changes
                                                        </Button>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>


                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedQrCodeManagement = connect(mapStateToProps)(QrCodeManagement);
const connectedQrCodeManagementTrans = withNamespaces('common')(connectedQrCodeManagement);
export {connectedQrCodeManagementTrans as QrCodeManagement};
