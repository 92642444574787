import React, {Component} from "react";
import {Grid, Modal, Row, Table} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";

class ModalManualySelectSeason extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditSeason: true,

            tmdb_status: false,
            vodSeasonStatusSwitch: false,
            vod_series_id: null,

            vodSeasonArray: [],
            vod_seasons_id: null,
            vod_seasons_status: null,
            vod_seasons_name: null,
            vod_seasons_number: null,
            vod_seasons_episode_count: null,
            vod_seasons_air_date: null,
            vod_seasons_tmdb_id: null,
            vod_seasons_imdb_id: null,
            vod_seasons_overview: null,
            vod_seasons_poster_path: null,
            vod_seasons_type: null,
            vod_seasons_series_id: null,

            vod_seasons_poster_path_temp: false,
            vod_seasons_poster_path_folder_temp: "",

            vodSeasonsList: [],
            selected: [],
            selectedToDisplay: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildImageSelect = this.handleChildImageSelect.bind(this);

    }

    handleSelect = (e) => {

        const selected = this.state.selected;
        //selected[e.target.name] = e.target.checked;

        //console.log(e.target.name)
        //console.log(e.target.checked)
        //console.log(selected[e.target.name])

        selected[e.target.name] = e.target.checked;

        this.setState({selected});
        //console.log(selected)

        this.parseArray()

    }

    parseArray(){

        let seasonsArraySelected = [];
        let a = this.state.selected;

        Object.keys(a).forEach(function (keyName, keyIndex) {
            // use keyName to get current key's name
            // and a[keyName] to get its value
            if (a[keyName] === true) {
                seasonsArraySelected.push(keyName.replace("s","").toString())
            }
        })

        let selectedToDisplay = seasonsArraySelected.join(',');
        this.setState({selectedToDisplay});
        console.log(selectedToDisplay)
    }

    //TMDB

    loadTMDBSeasons() {

        if (sessionStorage.getItem('series_main_tmdb_id') !== undefined && sessionStorage.getItem('series_main_tmdb_id') !== 0) {
            const url = "https://api.themoviedb.org/3/tv/" + sessionStorage.getItem('series_main_tmdb_id') + "?api_key=1a310ae853a5b25f2ca396e205bcfa6d";

            axios
                .get(url)
                .then(res => {

                    let seasonsData = res.data.seasons
                    const loading = false;

                    for (let i = 0; i < seasonsData.length; i++) {
                        //TODO BELOW
                        //this.createSeason(seasonsData[i]);
                    }

                    this.setState({
                        vodSeasonsList: seasonsData,
                        loading
                    });

                    //TODO BELOW
                    //this.loadFromMiddleware()
                });
        }
    }

    loadTMDBSeasonsToMiddleware() {

        for (let i = 0; i < this.state.vodSeasonsList.length; i++) {

            if(this.state.selectedToDisplay.includes(this.state.vodSeasonsList[i].id)){
                console.log(this.state.vodSeasonsList[i])
                this.createSeason(this.state.vodSeasonsList[i]);
            }
        }

        this.setState({showEditEpisode: false})
        this.props.handlerFromParent(false, "save");

    }

    createSeason(data) {

        const url = process.env.REACT_APP_API_URL + "/VodSeriesSeason";

        let vod_seasons_name = data.name;
        let vod_seasons_number = data.season_number;
        let vod_seasons_episode_count = data.episode_count;
        let vod_seasons_air_date = data.air_date;
        let vod_seasons_tmdb_id = data.id;
        let vod_seasons_imdb_id = null;
        let vod_seasons_overview = data.overview;
        let vod_seasons_poster_path = data.poster_path;
        let vod_seasons_type = null;
        let vod_seasons_series_id = sessionStorage.getItem('series_edit');

        let postData = {
            vod_season: {
                vod_seasons_name: vod_seasons_name,
                vod_seasons_number: vod_seasons_number,
                vod_seasons_episode_count: vod_seasons_episode_count,
                vod_seasons_air_date: vod_seasons_air_date,
                vod_seasons_tmdb_id: vod_seasons_tmdb_id,
                vod_seasons_imdb_id: vod_seasons_imdb_id,
                vod_seasons_overview: vod_seasons_overview,
                vod_seasons_poster_path: vod_seasons_poster_path,
                vod_seasons_type: vod_seasons_type,
                vod_seasons_series_id: vod_seasons_series_id,

            }
        };

        //console.log(postData);
        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            },

        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {
                this.setState({loading: false})
            }).catch(error => {
            this.setState({loading: false});
            this.props.dispatch(userActions.logoutUnauthorized());
            console.log(error);
        });

        this.submitTmdbPosterImage(vod_seasons_poster_path)
    }

    submitTmdbPosterImage(image) {

        if(image !== null){

            const url = process.env.REACT_APP_TMDB_IMAGE_API_URL;
            const imgUrl = "https://image.tmdb.org/t/p/w500" + image;

            let bodyFormData = new FormData();
            bodyFormData.set('filename', image);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('url', imgUrl);

            axios.post(url, bodyFormData)
                .then((res) => {
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })

        }

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'vodSeasonStatusSwitch' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {
        if (elem.props.name === 'vodSeasonStatusSwitch') {
            this.setState({vodSeasonStatusSwitch: state})
        }
    }

    handleClose = () => {
        this.setState({showEditSeason: false})
        this.props.handlerFromParent(false, "nothing");
    }

    handleSave = () => {
        this.loadTMDBSeasonsToMiddleware()
    }

    componentDidMount() {
        this.loadTMDBSeasons();
    }

    handleChildImageSelect(folder, filename, imagePosition) {

        if (imagePosition === "vod_seasons_poster_path") {
            this.setState({
                vod_seasons_poster_path_temp: true,
                vod_seasons_poster_path: filename,
                vod_seasons_poster_path_folder_temp: folder
            });
        }

    }

    submitPosterImage() {

        if (this.state.vod_seasons_poster_path_temp) {
            const url = process.env.REACT_APP_IMAGE_SUBMIT_API_URL;

            let bodyFormData = new FormData();
            bodyFormData.set('filepond[]', this.state.vod_seasons_poster_path_folder_temp);
            bodyFormData.set('location', "vod_series");
            bodyFormData.set('filename', this.state.vod_seasons_poster_path);

            axios.post(url, bodyFormData)
                .then((res) => {
                    this.setState({vod_seasons_poster_path_temp: false})
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                })
        }


    }

    render() {
        return (
            <div className="modal-edit-season">
                <Modal show={this.state.showEditSeason} onHide={this.handleClose} bsSize="large">
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + " - " + this.props.handlerID}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'maxHeight': '600px', 'overflowY': 'auto'}}>
                        <div>
                            <p> {"Selected: [" + this.state.selectedToDisplay.toString() + "]"}</p>
                            <Grid fluid>
                                <Row>
                                    <Table striped responsive>
                                        <thead>
                                        <tr>
                                            <th className="text-center">ID</th>
                                            <th className="text-center">poster_path</th>
                                            <th className="text-center">name</th>
                                            <th className="text-center">air_date</th>
                                            <th className="text-center">Select</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.vodSeasonsList.map((seasons) => {
                                                return (
                                                    <tr key={"seasonsTr" + seasons.id}>
                                                        <td className="text-center">{seasons.id}</td>
                                                        <td className="text-center">
                                                            <img
                                                                src={"https://image.tmdb.org/t/p/w500" + seasons.poster_path}
                                                                width="60px" alt={""}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;
                                                                    e.target.src = process.env.REACT_APP_ASSET_URL + "/global/landscape_image_broken.png"
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="text-center">{seasons.name}</td>
                                                        <td className="text-center">{seasons.air_date}</td>
                                                        <td className="text-center">
                                                            <input
                                                                name={"s"+seasons.id }
                                                                key={seasons.id}
                                                                type="checkbox"
                                                                checked={this.state.selected[seasons.id]}
                                                                onChange={this.handleSelect.bind(this)}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </Grid>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                        <Button bsStyle="warning" fill onClick={this.handleSave}>
                            save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalManualySelectSeason = connect(mapStateToProps)(ModalManualySelectSeason);
const connectedModalManualySelectSeasonTrans = withNamespaces('common')(connectedModalManualySelectSeason);
export {connectedModalManualySelectSeasonTrans as ModalManualySelectSeason};