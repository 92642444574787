import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownGenres extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            gGenresArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/Global/Genres";

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {
                    const gGenresArray = res.data;
                    const loading = false;
                    this.setState({gGenresArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);

                });

        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    render() {
        return (
            <Select
                placeholder="Genre Select"
                name="genreSelect"
                autoFocus={false}
                multi={true}
                searchable={true}
                value={this.props.genreSelected}
                options={this.state.gGenresArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownGenres = connect(mapStateToProps)(DropDownGenres);
const connectedDropDownGenresTrans = withNamespaces('common')(connectedDropDownGenres);
export {connectedDropDownGenresTrans as DropDownGenres};

