import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "../../components/Footer/Footer.jsx";
import PagesHeader from "../../components/Header/PagesHeader.jsx";

// dinamically create pages routes
import userRoutes from "../../routes/user.jsx";

import bgImage from "../../assets/img/bgImage.jpg";

class User extends Component {
  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/user/login-page":
        pageClass = " login-page";
        break;
      case "/user/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  }

  componentDidMount() {
      if (document.documentElement.className.indexOf("nav-open") !== -1) {
          document.documentElement.classList.toggle("nav-open");
      }
  }


  render() {
    return (
      <div>
        <PagesHeader />
        <div className="wrapper wrapper-full-page">
          <div
            className={"full-page" + this.getPageClass()}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content">
              <Switch>
                {userRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
              </Switch>
            </div>
            <Footer transparent />
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default User;
