import React from "react";
import axios from "axios";
import ReactTable from "react-table";
import {connect} from 'react-redux';
import {Tooltip, OverlayTrigger, Grid, Row, Col, FormGroup, InputGroup} from 'react-bootstrap';
import Button from "../CustomButton/CustomButton.jsx";
import moment from 'moment';
import {withNamespaces} from "react-i18next";
import {ModalViewTvGuideProgram} from "../CustomModals/ModalViewTvGuideProgram";
import {userActions} from "../../authentication/_actions";
import Card from "../Card/Card";

class DataTvGuide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            info_tooltip: (<Tooltip id="info_tooltip">Info</Tooltip>),

            program_id: null,
            loading: false,
            guideArray: [],
            columns: [],
            data: [],
            showModalTvGuide: false,

            sorted: [],
            filtered: [],

            pages: null,
            page: 1,
            pageSize: 10,

            lastUpdate: null,
            filter: "",
            searchTvGuideAll: ""

        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    loadColumnsTvGuide() {

        this.setState({
            columns: [
                {
                    accessor: "ID",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "ppv",
                    Header: "ppv",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {row.value === "Yes" ? <i className="fa fa-dollar"> </i> : ""}
                        </div>
                    )
                },
                {
                    accessor: "icon_poster",
                    Header: "Poster",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="60px" height="80px" alt={""}
                            />
                        </div>
                    )
                },
                {
                    accessor: "title",
                    Header: "title",
                    sortable: true,
                    filterable: false,
                    maxWidth: 250
                },
                {
                    accessor: "desc_str",
                    Header: "Description",
                    sortable: true,
                    filterable: false
                    // Cell: row => (
                    //     <div
                    //         // style={{
                    //         //     wordWrap: 'break-word'
                    //         // }}
                    //     >
                    //         {row.value}
                    //     </div>
                    // )
                },
                {
                    accessor: "start_date",
                    Header: "start_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )

                },
                {
                    accessor: "stop_date",
                    Header: "stop_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                },
                {
                    accessor: "ID",
                    Header: "More Info",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                        </div>
                    )
                }]
        })
    }

    loadColumnsTvGuideNow() {

        this.setState({
            columns: [
                {
                    accessor: "ID",
                    Header: "ID",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "ppv",
                    Header: "ppv",
                    maxWidth: 50,
                    sortable: true,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            {row.value === "Yes" ? <i className="fa fa-dollar"> </i> : ""}
                        </div>
                    )
                },
                {
                    accessor: "iconurl",
                    Header: "Channel Logo",
                    maxWidth: 110,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="65px" height="38px" alt={""}
                            />
                        </div>
                    )
                },
                {
                    accessor: "channel_name",
                    Header: "Channel Name",
                    maxWidth: 200,
                    sortable: true,
                    filterable: false
                },
                {
                    accessor: "title",
                    Header: "title",
                    sortable: true,
                    filterable: false,
                    maxWidth: 200
                },
                {
                    accessor: "icon_poster",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="center">
                            <img
                                src={this.loadImage(row.value)}
                                width="60px" height="80px" alt={""}
                            />
                        </div>
                    )
                },
                {
                    accessor: "desc_str",
                    Header: "Description",
                    maxWidth: 200,
                    sortable: true,
                    filterable: false,
                    style: {'whiteSpace': 'unset', 'maxHeight': '83px'}
                },

                {
                    accessor: "start_date",
                    Header: "start_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )

                },
                {
                    accessor: "stop_date",
                    Header: "stop_date",
                    sortable: true,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div>
                            {moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    )
                },
                {
                    Header: 'Progress',
                    accessor: 'progress',
                    sortable: false,
                    filterable: false,
                    maxWidth: 150,
                    Cell: row => (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#dadada',
                                borderRadius: '2px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${row.value}%`,
                                    height: '100%',
                                    backgroundColor: row.value > 66 ? '#ffbf00' : '#85cc00',
                                    borderRadius: '2px',
                                    transition: 'all .2s ease-out'
                                }}
                            >
                                <div style={{textAlign: 'center'}}>
                                    <span>{row.value}%</span>
                                </div>
                            </div>

                        </div>
                    )
                },
                {
                    accessor: "ID",
                    Header: "More Info",
                    maxWidth: 80,
                    sortable: false,
                    filterable: false,
                    Cell: row => (
                        <div className="actions-right">
                            {this.setHelperButton(row.value)}
                        </div>
                    )
                }]
        })
    }

    //BUTTONS
    setHelperButton(program_id) {

        return (<OverlayTrigger placement="top" overlay={this.state.info_tooltip}>
            <Button
                onClick={() => {
                    this.setState({
                        program_id: program_id,
                        showModalTvGuide: true,
                    })
                }}

                bsStyle="info"
                simple
                icon
            >
                <i className="fa fa-info"/>
            </Button>
        </OverlayTrigger>)

    }

    loadImage(value) {

        if (value === null || value === undefined || value === '' || value === 'comp_') {
            let image = process.env.REACT_APP_ASSET_URL + "/global/poster_image_empty.png"
            return image;
        } else {
            // let image = process.env.REACT_APP_ASSET_URL + "/live_tv/" + value
            return value;
        }

    }

    getData(channel_id) {

        this.loadColumnsTvGuide();

        if (channel_id !== undefined) {
            this.setState({
                loading: true
            })

            let dataUrl = process.env.REACT_APP_API_URL + "/TvGuide";

            if (channel_id !== 0) {
                dataUrl = process.env.REACT_APP_API_URL + "/TvGuide/" + channel_id;
            }

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios.get(dataUrl, axiosConfig)
                .then(res => {
                    const guideArray = res.data;
                    const loading = false;
                    this.setState({guideArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });

        } else {
            const loading = false;
            this.setState({loading});
        }


    }

    getTvGuide(channel_id, start, stop) {

        if (channel_id !== undefined) {
            this.setState({
                loading: true
            })

            let dateStart = 0;
            let dateStop = 0;

            if (start === undefined || start === null || start === '') {
                dateStart = 978307200 //2000-01-01
            } else {
                dateStart = start
            }

            if (stop === undefined || stop === null || stop === '') {
                dateStop = 2524608000  //2050-01-01
            } else {
                dateStop = stop
            }


            let dataUrl = process.env.REACT_APP_API_URL + "/TvGuide";

            if (channel_id !== 0) {
                dataUrl = process.env.REACT_APP_API_URL + "/TvGuide/" + channel_id + "/" + dateStart + "/" + dateStop;
            }

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {'X-Id-Token': token}
            };

            axios
                .get(dataUrl, axiosConfig)
                .then(res => {
                    const guideArray = res.data;
                    const loading = false;
                    this.setState({guideArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    this.props.dispatch(userActions.logoutUnauthorized());
                    console.log(error);
                });

        } else {
            const loading = false;
            this.setState({loading});
        }


    }

    getTvGuidePlayingNow() {

        this.loadColumnsTvGuideNow();
        this.setState({
            loading: true
        })

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        let postData = {
            tvguide: {
                type: "now"
            }
        };

        let dataUrl = process.env.REACT_APP_API_URL + "/TvGuide";

        axios.post(dataUrl, postData, axiosConfig)
            .then((res) => {
                const guideArray = res.data;
                const loading = false;
                this.setState({guideArray, loading});
            })
            .catch(error => {
                this.setState({loading: false});
                console.log(error);
            });

    }

    componentDidMount() {

        this.loadColumnsTvGuide();
        //this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // Typical usage (don't forget to compare props):
        if (this.props.dateStart !== prevProps.dateStart) {
            console.log("Start changed: " + this.props.dateStart);
            //this.getTvGuide(this.props.epgForChannel, this.props.dateStart, this.props.dateEnd)
        }

        if (this.props.dateEnd !== prevProps.dateEnd) {
            console.log("End changed: " + this.props.dateEnd);
            //this.getTvGuide(this.props.epgForChannel, this.props.dateStart, this.props.dateEnd)
        }

        if (this.props.playingNow !== prevProps.playingNow) {
            if (this.props.playingNow === true) {
                //console.log("playing now: " + this.props.playingNow);
                this.getTvGuidePlayingNow()
            } else {
                this.loadColumnsTvGuide();
            }

        }

        if (this.props.epgForChannel !== prevProps.epgForChannel) {
            //console.log("GUIDE FOR: " + this.props.epgForChannel);

            this.setState({
                filtered: [''],
            })
            //this.getData(this.props.epgForChannel);
        }

    }

    renderViewTvGuideProgram = () => {

        //console.log("ID:"+this.state.program_id)

        if (this.state.showModalTvGuide) {
            return <ModalViewTvGuideProgram
                headerMessage={"View Program Info"}
                bodyMessage={""}
                handlerFromParent={this.setViewTvGuideProgramModal}
                programId={this.state.program_id}
            />
        }
    }

    setViewTvGuideProgramModal = (value, action) => {

        this.setState({
            showModalTvGuide: value
        })

    }

    handleInputChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        let dt = new Date();
        let utcDate = dt.toUTCString();

        if (name === "searchTvGuideAll") {
            this.setState({
                lastUpdate: utcDate,
                filtered: [''],
                filter: value
            })
        }

    }

    updateTvGuideAll() {

        this.setState({
            filtered: ['']
        })

    }

    clearSearchButton() {

        let dt = new Date();
        let utcDate = dt.toUTCString();
        let value = "";

        this.setState({
            lastUpdate: utcDate,
            filtered: [''],
            filter: value,
            activePage: 1,
            searchTvGuideAll: ""
        })

    }

    renderViewTvGuideAll = () => {

        if (this.props.epgForChannel === 0 && !this.props.playingNow) {

            return (
                <div>

                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <div className="pull-left">
                                    <h5>
                                        {"Program List - " + this.props.epgChannelName}
                                    </h5>
                                </div>

                                <div className="pull-right" style={{width: "250px"}}>

                                    <FormGroup>
                                        <InputGroup>
                                            <input
                                                className="form-control form-control-sm ml-3 w-75"
                                                type="text"
                                                placeholder="Search"
                                                aria-label="Search"
                                                name="searchTvGuideAll"
                                                onChange={this.handleInputChange}
                                                value={this.state.searchTvGuideAll || ""}
                                            />
                                            <InputGroup.Addon>
                                                <i style={{color: this.state.searchTvGuideAll !== "" ? "#f7263b" : "#343434"}}
                                                   className={this.state.searchTvGuideAll !== "" ? "fa fa-times" : "fa fa-search"}
                                                   onClick={this.clearSearchButton.bind(this)}/>
                                            </InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>

                                </div>

                            </Col>
                        </Row>
                    </Grid>

                    <legend/>

                    <ReactTable
                        columns={this.state.columns}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={this.state.guideArray}
                        pages={this.state.pages} // Display the total number of pages
                        loading={this.state.loading} // Display the loading overlay when we need it
                        filtered={this.state.filtered}
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"

                        onFetchData={(state, instance) => {
                            // show the loading overlay
                            this.setState({loading: true})

                            let myUrl = process.env.REACT_APP_API_URL + "/TvGuide/";

                            let user = JSON.parse(localStorage.getItem('user'));
                            let token = user.token


                            let axiosConfig = {
                                headers: {
                                    'X-Id-Token': token,
                                    'Content-Type': 'application/json',
                                }
                            };


                            let postData = {
                                tvguide: {
                                    type: "all",
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: this.state.filtered,
                                    filter: this.state.filter
                                }

                            };

                            axios.post(myUrl, postData, axiosConfig)
                                .then((res) => {

                                    let totalPages = res.data['data'] === undefined ? 0 : res.data['data'][1][0].pages;

                                    this.setState({
                                        guideArray: res.data['tvguide'],
                                        pages: totalPages,
                                        loading: false,
                                        page: state.page
                                    })
                                }).catch(error => {
                                this.setState({loading: false});
                                //this.props.dispatch(userActions.logoutUnauthorized());
                                console.log(error);
                            });

                        }}

                        onFilteredChange={filtered => {
                            this.setState({filtered});
                        }}


                    />


                </div>

            )
        }
    }

    renderViewTvGuideNow = () => {

        if (this.props.playingNow) {

            return (
                <ReactTable
                    data={this.state.guideArray}
                    filterable
                    loading={this.state.loading}
                    columns={this.state.columns}
                    defaultPageSize={10}
                    showPaginationBottom
                    className="-striped -highlight"
                />

            )
        }
    }

    renderViewTvGuideChannel = () => {

        if (this.props.epgForChannel !== 0 && !this.props.playingNow) {

            return (
                <div>

                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                                <div className="pull-left">
                                    <h5>
                                        {"Program List - " + this.props.epgChannelName}
                                    </h5>
                                </div>

                                <div className="pull-right" style={{width: "250px"}}>

                                    <FormGroup>
                                        <InputGroup>
                                            <input
                                                className="form-control form-control-sm ml-3 w-75"
                                                type="text"
                                                placeholder="Search"
                                                aria-label="Search"
                                                name="searchTvGuideAll"
                                                onChange={this.handleInputChange}
                                                value={this.state.searchTvGuideAll || ""}
                                            />
                                            <InputGroup.Addon>
                                                <i style={{color: this.state.searchTvGuideAll !== "" ? "#f7263b" : "#343434"}}
                                                   className={this.state.searchTvGuideAll !== "" ? "fa fa-times" : "fa fa-search"}
                                                   onClick={this.clearSearchButton.bind(this)}/>
                                            </InputGroup.Addon>
                                        </InputGroup>
                                    </FormGroup>

                                </div>

                            </Col>
                        </Row>
                    </Grid>

                    <legend/>

                    {/*<div className="row">*/}
                        {/*<div className="col-md-2">*/}

                        {/*</div>*/}
                        {/*<div className="col-md-2 col-md-offset-8">*/}
                            {/*<input*/}
                                {/*className="form-control form-control-sm ml-3 w-75"*/}
                                {/*type="text"*/}
                                {/*placeholder="Search"*/}
                                {/*aria-label="Search"*/}
                                {/*name="searchTvGuideAll"*/}
                                {/*onChange={this.handleInputChange}*/}
                                {/*value={this.state.searchTvGuideAll || ""}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}


                    <ReactTable
                        columns={this.state.columns}
                        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                        data={this.state.guideArray}
                        pages={this.state.pages} // Display the total number of pages
                        loading={this.state.loading} // Display the loading overlay when we need it
                        filtered={this.state.filtered}
                        filterable
                        defaultPageSize={10}
                        className="-striped -highlight"

                        onFetchData={(state, instance) => {
                            // show the loading overlay
                            this.setState({loading: true})

                            let myUrl = process.env.REACT_APP_API_URL + "/TvGuide/";

                            let user = JSON.parse(localStorage.getItem('user'));
                            let token = ""

                            if (user) {
                                token = user.token
                            } else {
                                this.props.dispatch(userActions.logoutUnauthorized());
                            }

                            let axiosConfig = {
                                headers: {
                                    'X-Id-Token': token,
                                    'Content-Type': 'application/json',
                                }
                            };


                            let postData = {
                                tvguide: {
                                    type: "channel",
                                    channel_id: this.props.epgForChannel,
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: this.state.filtered,
                                    filter: this.state.filter
                                }

                            };

                            axios.post(myUrl, postData, axiosConfig)
                                .then((res) => {

                                    let totalPages = res.data['data'] === undefined ? 0 : res.data['data'][1][0].pages;

                                    this.setState({
                                        guideArray: res.data['tvguide'],
                                        pages: totalPages,
                                        loading: false,
                                        page: state.page
                                    })
                                }).catch(error => {
                                this.setState({loading: false});
                                //this.props.dispatch(userActions.logoutUnauthorized());
                                console.log(error);
                            });

                        }}

                        onFilteredChange={filtered => {
                            this.setState({filtered});
                        }}


                    />


                </div>

            )
        }
    }

    render() {
        return (
            <div>
                {this.renderViewTvGuideProgram()}
                <Card
                    content={
                        <div>
                            {this.renderViewTvGuideAll()}

                            {this.renderViewTvGuideNow()}

                            {this.renderViewTvGuideChannel()}
                        </div>
                    }
                />
            </div>
        );

    }
}


function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDataTvGuide = connect(mapStateToProps)(DataTvGuide);
const connectedDataTvGuideTrans = withNamespaces('common')(connectedDataTvGuide);
export {connectedDataTvGuideTrans as DataTvGuide};