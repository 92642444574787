import {LoginPage} from "../views/LoginPage/LoginPage";
// import RegisterPage from "../views/Pages/RegisterPage.jsx";
// import LockScreenPage from "../views/Pages/LockScreenPage.jsx";

var userRoutes = [
  {
    path: "/user/login-page",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  // {
  //   path: "/user/register-page",
  //   name: "Register",
  //   mini: "RP",
  //   component: RegisterPage
  // },
  // {
  //   path: "/user/lock-screen-page",
  //   name: "Lock Screen Page",
  //   mini: "LSP",
  //   component: LockScreenPage
  // }
];

export default userRoutes;
