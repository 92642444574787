import React, {Component} from "react";
import {Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {withNamespaces} from "react-i18next"
import indexRoutes from "../../routes/index.jsx";
import {history} from '../../authentication/_helpers';
import {alertActions} from '../../authentication/_actions';
import {PrivateRoute} from '../../authentication/_components';

class App extends Component {

    constructor(props) {
        super(props);

        const {dispatch} = this.props;

        history.listen((location, action) => {
            dispatch(alertActions.clear());
        });

        const {i18n} = this.props;

        if (localStorage.getItem('language') === undefined || localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'en');
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage(localStorage.getItem('language'))
        }

    }

    render() {
        const {alert} = this.props;
        return (
            <div>
                {alert.message &&
                <div className={`alert ${alert.type}`}>{alert.message}</div>
                }
                <Router history={history}>
                    <Switch>
                        {indexRoutes.map((prop, key) => {
                            if (key === 1) {
                                return <PrivateRoute path={prop.path} component={prop.component} key={key}/>;
                            } else {
                                return <Route path={prop.path} component={prop.component} key={key}/>;
                            }
                        })}
                    </Switch>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(App);
const connectedAppTrans = withNamespaces('common')(connectedApp);
export {connectedAppTrans as App};

