import React, {Component} from 'react'
import logo from './logo_square.svg'
import Autosuggest from 'react-autosuggest'
// import theme from './search.css'
import {URL_TV_SEARCH, API_KEY_ALT, URL_IMG, IMG_SIZE_XSMALL} from './const';
import axios from 'axios'
import {withNamespaces} from "react-i18next";

class SearchBarTvShows extends Component {
    constructor(props) {
        super(props);

        this.handleChildTmdbSelect = this.handleChildTmdbSelect.bind(this);

        this.state = {
            value: '',
            suggestions: []
        };
    }

    //COMMUNICATE WITH PARENT
    handleChildTmdbSelect(data) {
        this.props.handlerFromParent(data);
    }


    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            return this.handleSubmit(this.state.value);
        }
    }

    handleSubmit = (searchText) => {
        //this.props.dispatch(push('/search/' + searchText));
        this.setState({value: ''});
    }

    getSuggestionValue = (suggestion) => {
        return suggestion.name;
    };

    onSuggestionsFetchRequested = ({value}) => {
        const trimmedValue = value.trim();

        if (trimmedValue.length > 0) {
            let url = URL_TV_SEARCH + trimmedValue + API_KEY_ALT;
            fetch(url)
                .then(response => response.json())
                .then(json => json.results)
                .then(data => {
                   // console.log(data)
                    const results = data.map(tvShow => {
                        let temp = {}
                        temp.id = tvShow.id
                        temp.name = tvShow.name
                        temp.img = tvShow.poster_path
                        temp.year = (tvShow.first_air_date === "") ? "0000" : tvShow.first_air_date.substring(0, 4)
                        return temp
                    });
                    this.setState({
                        suggestions: results
                    });
                }).catch(error => console.log('Exception to get Suggestions'))
        }
        else {
            this.setState({
                suggestions: []
            })
        }
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    renderSuggestion = (suggestion) => {
        return (
           <div>
                <img className="searchResult-image"
                     src={suggestion.img == null ? logo : URL_IMG + IMG_SIZE_XSMALL + suggestion.img}
                     alt={suggestion.img == null ? logo :suggestion.img}
                />
                <div className="searchResult-text">
                    <div className="searchResult-name">
                        {suggestion.name}
                    </div>
                    {suggestion.year}
                </div>
           </div>
        );
    };

    onSuggestionSelected = (event, {suggestion, method}) => {
        if (method === 'enter')
            event.preventDefault();
        this.fetcTvShowDetail(suggestion.id)
        this.setState({value: ''});
    };


    fetcTvShowDetail(id) {
        const url = 'https://api.themoviedb.org/3/tv/' + id + '?api_key=1a310ae853a5b25f2ca396e205bcfa6d';

        axios
            .get(url)
            .then(res => {
                //console.log(res)
                this.handleChildTmdbSelect(res)
            });
    }

    render() {

        const {value, suggestions} = this.state;
        const inputProps = {
            value,
            onChange: this.onChange,
            onKeyPress: this.handleKeyDown,
            placeholder: this.props.t('tmdb.search_bar_tv_shows', {framework: "react-i18next"})
        };

        return (
            <div>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}/>
            </div>
        );

    }
}

//export default connect()(SearchBar);
// export default SearchBarTvShows;

const SearchBarTvShowsTrans = withNamespaces('common')(SearchBarTvShows);
export {SearchBarTvShowsTrans as SearchBarTvShows};


