import React, {Component} from "react";
import {Col, Grid, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import {DataProperties} from "../../components/Middleware/DataProperties";
import building from "../../assets/img/building.png";
import {MapBoxProperties} from "../../components/MapBox/MapBoxProperties";

class PropertyManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastUpdate: ""
        };

        this.handleChildUpdate = this.handleChildUpdate.bind(this);
    }

    handleChildUpdate(){
        console.log("Request MAP Update")
        this.updateMap();
    }

    updateMap(){

        let dt = new Date();
        let utcDate = dt.toUTCString();

        console.log("Request MAP Update ->" + utcDate )

        this.setState({
            lastUpdate: utcDate
        })

    }

    render() {
        return (
            <div className="main-content">

                <Grid fluid>
                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <Grid fluid>
                                        <Row>
                                            <Col sm={5}>
                                            </Col>
                                            <Col sm={2}>
                                                <div className="center">
                                                    <img className="img-responsive center-block"
                                                         style={{maxHeight: '60px', maxWidth: '100%'}}
                                                         src={building}
                                                         alt={"networking"}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm={5}>
                                            </Col>
                                        </Row>
                                    </Grid>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <DataProperties
                                        listName={"List of Properties"}
                                        handlerFromParent={this.handleChildUpdate}
                                    />

                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Card
                                content={
                                    <MapBoxProperties
                                        lastUpdate={this.state.lastUpdate}
                                    />
                                }
                            />
                        </Col>
                    </Row>



                </Grid>
            </div>
        )
    }
}

export default PropertyManagement;
