import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";

class ModalViewCalendarEvent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalCalendarEvent: true
        };

    }

    handleClose = () => {
        this.setState({showModalCalendarEvent: false})
        this.props.handlerFromParent(this.props.handlerID, false, "nothing");
    }


    render() {
        return (
            <div className="modal-calendar-event">
                <Modal show={this.state.showModalCalendarEvent} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <h5 style={{marginBottom: '5px'}}>{this.props.headerMessage || ""}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h6>Starts:</h6>{this.props.eventStart || ""}
                            <h6>Ends:</h6>{this.props.eventEnd || ""}
                        </div>
                        <div>
                            <h6>Description:</h6>{this.props.eventDescription || ""}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ModalViewCalendarEvent;