import React, {Component} from "react";
import {Redirect} from "react-router";
import Switch from "react-bootstrap-switch";
import axios from "axios/index";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    FormControl,
    InputGroup,
    ButtonGroup
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {css} from "@emotion/core";
import {ScaleLoader} from 'react-spinners';
import {DropDownRssCategory} from "../../components/CustomDropDown/DropDownRssCategory";
import ModalSave from "../../components/CustomModals/ModalSave";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import NotificationSystem from "react-notification-system";
import {style} from "../../variables/Variables";
import {RssFeedViewer} from "./RssFeedViewer";
import {DropDownContentProviders} from "../../components/CustomDropDown/DropDownContentProviders";

const override = css`
    position: fixed;
    left: 50%;
    top: 35%;
    display: block;
    z-index: 1000;
    height: 31px;
    width: 100px;
    `;

const adminArray = ['super-admin', 'admin']

class EditRssFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {

            _notificationSystem: null,

            rssFeedArray: [],
            loading: true,
            redirect: false,
            back_button_text: "Cancel",
            showSaveModal: false,

            feedRedirect: false,
            feedStatus: false,

            rss_feed_id: null,
            rss_feed_status: false,
            rss_feed_name: null,
            rss_feed_description: null,
            rss_feed_category: null,
            rss_feed_url: null,

            rss_feed_content_provider: null,
            current_user: null,
            user_content_provider: null

        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChildCategorySelect = this.handleChildCategorySelect.bind(this);
        this.handleChildContentProviderSelect = this.handleChildContentProviderSelect.bind(this);

    }

    handleChildContentProviderSelect(data) {

        if (data !== undefined) {
            this.setState({
                user_content_provider: data === null ? null : data.value,
            });
        }
    }

    componentDidMount() {

        this.setState({_notificationSystem: this.refs.notificationSystem})

        const {handle} = this.props.match.params
        if (handle === 'new') {
            this.setState({loading: false})

        } else {

            let user = JSON.parse(localStorage.getItem('user'));
            let token = ""

            if (user) {
                token = user.token
            } else {
                this.props.dispatch(userActions.logoutUnauthorized());
            }

            let axiosConfig = {
                headers: {
                    'X-Id-Token': token
                }
            };

            const {handle} = this.props.match.params
            const url = process.env.REACT_APP_API_URL + "/Rss/" + handle;

            if (handle === 'new') {
                this.setState({loading: false})
            } else {
                axios
                    .get(url, axiosConfig)
                    .then(res => {

                        if (res.data.error === 401) {
                            this.props.dispatch(userActions.logoutUnauthorized());
                        }

                        const rssFeedArray = res.data[0];
                        const loading = false;

                        let feedStatus = rssFeedArray.rss_feed_status === 1

                        this.setState({
                            back_button_text: "Back",

                            rssFeedArray,
                            feedStatus,
                            loading,

                            rss_feed_id: rssFeedArray.id,
                            rss_feed_status: feedStatus,
                            rss_feed_name: rssFeedArray.rss_feed_name,
                            rss_feed_description: rssFeedArray.rss_feed_description,
                            rss_feed_category: rssFeedArray.rss_feed_category,
                            rss_feed_url: rssFeedArray.rss_feed_url,
                            current_user: user,
                            user_content_provider: rssFeedArray.rss_feed_content_provider

                        });
                    })
                    .catch(error => {
                        this.setState({loading: false});
                        console.log(error);
                    });
            }

        }

    }

    handleChildCategorySelect(data) {

        this.setState({
            rss_feed_category: data === null ? 0 : data.value
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSwitch(elem, state) {

        if (elem.props.name === 'feedStatus') {
            this.setState({feedStatus: state})
        }
    }

    updateFeed() {

        this.setState({loading: true})
        const {handle} = this.props.match.params
        const url = process.env.REACT_APP_API_URL + "/Rss/" + handle;

        let feedStatus = this.state.feedStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            rss: {
                rss_feed_id: this.state.rss_feed_id,
                rss_feed_status: feedStatus,
                rss_feed_name: this.state.rss_feed_name,
                rss_feed_description: this.state.rss_feed_description,
                rss_feed_category: this.state.rss_feed_category,
                rss_feed_url: this.state.rss_feed_url,
                rss_feed_content_provider: this.state.user_content_provider
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.put(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                let feed = res.data;
                this.setState({rss_feed_id: feed.id, saving: false, loading: false})

                this.notification("success")


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    createFeed() {

        this.setState({loading: true})
        const url = process.env.REACT_APP_API_URL + "/Rss";

        let feedStatus = this.state.feedStatus === true ? 1 : 0;

        // Send a PUT request
        var postData = {
            rss: {
                rss_feed_status: feedStatus,
                rss_feed_name: this.state.rss_feed_name,
                rss_feed_description: this.state.rss_feed_description,
                rss_feed_category: this.state.rss_feed_category,
                rss_feed_url: this.state.rss_feed_url,
                rss_feed_content_provider: this.state.user_content_provider
            }
        };

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json',
            }
        };

        axios.post(url, postData, axiosConfig)
            .then((res) => {

                if (res.data.error === 401) {
                    this.props.dispatch(userActions.logoutUnauthorized());
                }

                this.setState({loading: false})

                const feed = res.data;
                this.setState({
                        rss_feed_id: feed.id,

                    }
                )
                this.setFeedRedirect();
                this.notification("success")


            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
                this.setState({loading: false})
                this.notification("failed")
            })

    }

    cancelFeedEdit() {
        this.setRedirect();
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    setFeedRedirect = () => {
        this.setState({
            feedRedirect: true,
            back_button_text: "Back"
        })
    }

    renderRedirect = () => {

        if (this.state.redirect) {
            let path = "/live/rss";
            return <Redirect push to={path}/>
        }

        if (this.state.feedRedirect) {
            let pathFeed = "/edit-rss/" + this.state.rss_feed_id;
            this.setState({
                redirect: false,
                feedRedirect: false
            })
            return <Redirect push to={pathFeed}/>
        }

    }

    setSaveModal = (value, action) => {

        this.setState({
            showSaveModal: value

        })

        if (action === "save") {
            this.setState({saving: true})

            const {handle} = this.props.match.params

            if (handle === 'new') {
                this.createFeed();
            } else {
                this.updateFeed();
            }
        }
    }

    renderSaveModal = () => {

        if (this.state.showSaveModal) {
            return <ModalSave
                headerMessage={"RSS Feed: "}
                bodyMessage={"this action will save all changes, click close to cancel saving"}
                handlerFromParent={this.setSaveModal}
                handlerID={this.state.rss_feed_id}
            />
        }
    }

    notification(status) {

        if (status === "success") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>RSS Fed has been updated</b>
                    </div>
                ),
                level: 'success',
                position: 'tr',
                autoDismiss: 5,
            });
        }

        if (status === "failed") {
            this.state._notificationSystem.addNotification({
                title: (<span data-notify="icon" className="fa fa-check"></span>),
                message: (
                    <div>
                        <b>Failed to update rss feed</b>
                    </div>
                ),
                level: 'error',
                position: 'tr',
                autoDismiss: 10,
            });
        }

    }

    //TODO
    renderRssFeeds = () => {

        if (this.state.rss_feed_id !== null || this.state.rss_feed_url !==null) {
            return (
                <RssFeedViewer
                    handlerFeedUrl={this.state.rss_feed_url}
                />
            )
        }
    }

    renderContentProvider = () => {

        if (this.state.current_user) {
            if (adminArray.includes(this.state.current_user.level)) {
                return (
                    <Row>
                        <FormGroup>
                            <ControlLabel>Content Provider</ControlLabel>
                            <DropDownContentProviders
                                contentProviderSelected={this.state.user_content_provider}
                                name='contentProviderSelect'
                                handlerFromParent={this.handleChildContentProviderSelect}
                                disabled={this.state.loading}
                            />
                        </FormGroup>
                    </Row>

                )
            }
        }

    }

    render() {
        return (
            <div className="main-content">
                <NotificationSystem ref="notificationSystem" style={style}/>

                {this.renderRedirect()}
                {this.renderSaveModal()}

                <div className="sweet-loader">
                    <ScaleLoader
                        css={override}
                        color={'#797979'}
                        loading={this.state.loading}
                    />
                </div>

                <Grid fluid>
                    <Row>
                        <Col md={6}>
                            <Card
                                content={
                                    <div>
                                        <h5>{this.props.t('rss_edit.rss_title', {framework: "react-i18next"})}</h5>
                                        <legend/>
                                        <Grid fluid>
                                            <Col md={12}>
                                                <Row>
                                                    <FormGroup>
                                                        <Switch
                                                            onChange={(el, state) => this.handleSwitch(el, state)}
                                                            name='feedStatus'
                                                            disabled={this.state.loading}
                                                            value={this.state.feedStatus}
                                                            defaultValue={false}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('rss_edit.rss_name', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl
                                                            placeholder={this.props.t('rss_edit.rss_name_placeholder', {framework: "react-i18next"})}
                                                            type="text"
                                                            name="rss_feed_name"
                                                            disabled={this.state.loading}
                                                            onChange={this.handleInputChange}
                                                            value={this.state.rss_feed_name || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('rss_edit.rss_description', {framework: "react-i18next"})}</ControlLabel>
                                                        <FormControl componentClass="textarea"
                                                                     placeholder={this.props.t('rss_edit.rss_description_placeholder', {framework: "react-i18next"})}
                                                                     type="text"
                                                                     name="rss_feed_description"
                                                                     disabled={this.state.loading}
                                                                     rows={4}
                                                                     onChange={this.handleInputChange}
                                                                     value={this.state.rss_feed_description || ""}
                                                        />
                                                    </FormGroup>
                                                </Row>


                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('rss_edit.rss_url', {framework: "react-i18next"})}</ControlLabel>
                                                        <InputGroup>
                                                            <InputGroup.Addon style={{backgroundColor: "#383838"}}>
                                                                <i className="fa fa-link" style={{color: "#ffffff"}}/>
                                                            </InputGroup.Addon>
                                                            <FormControl
                                                                placeholder={this.props.t('rss_edit.rss_url_placeholder', {framework: "react-i18next"})}
                                                                type="text"
                                                                name="rss_feed_url"
                                                                disabled={this.state.loading}
                                                                onChange={this.handleInputChange}
                                                                value={this.state.rss_feed_url || ""}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Row>

                                                <Row>
                                                    <FormGroup>
                                                        <ControlLabel>{this.props.t('rss_edit.rss_category', {framework: "react-i18next"})}</ControlLabel>
                                                        <DropDownRssCategory
                                                            categorySelected={this.state.rss_feed_category}
                                                            name='categorySelect'
                                                            disabled={this.state.loading}
                                                            handlerFromParent={this.handleChildCategorySelect}
                                                        />
                                                    </FormGroup>
                                                </Row>

                                                {this.renderContentProvider()}

                                            </Col>
                                        </Grid>
                                    </div>
                                }
                                ftTextCenter
                                legend={
                                    <ButtonGroup aria-label="Basic example">
                                        <Button
                                            onClick={() => this.setState({showSaveModal: true})}
                                            bsStyle="info"
                                            fill wd
                                            disabled={this.state.loading}
                                            name={"saveButton"}>
                                            {this.state.loading ? 'Loading...' : this.props.t('global.save', {framework: "react-i18next"})}
                                        </Button>
                                        <Button bsStyle="default" fill wd
                                                name={"cancelButton"}
                                                disabled={this.state.loading}
                                                onClick={this.cancelFeedEdit.bind(this)}>
                                            {this.state.back_button_text}
                                        </Button>
                                    </ButtonGroup>
                                }
                            />
                        </Col>

                        <Col md={6}>
                            {/*<RssFeedViewer*/}
                                {/*handlerFeedUrl={this.state.rss_feed_url}*/}
                            {/*/>*/}
                            {this.renderRssFeeds()}

                        </Col>

                    </Row>
                    {/*<Row>*/}
                    {/*<Col md={12}>*/}
                    {/*<RssFeedViewer*/}
                    {/*handlerFeedUrl={this.state.rss_feed_url}*/}
                    {/*/>*/}
                    {/*</Col>*/}
                    {/*</Row>*/}
                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedEditRssFeed = connect(mapStateToProps)(EditRssFeed);
const connectedEditRssFeedTrans = withNamespaces('common')(connectedEditRssFeed);
export {connectedEditRssFeedTrans as EditRssFeed};
