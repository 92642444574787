import React from "react";
import avatar from "../../assets/img/avatars/006-boy-1.png";
// import {ProgressBar} from "react-bootstrap";
import jwt_decode from "jwt-decode";
import moment from "moment";
import connect from "react-redux/es/connect/connect";
// import Button from "react-bootstrap";
import {userActions} from "../../authentication/_actions";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

class UserSidebarComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            session_tooltip: (<Tooltip id="session_tooltip">Time Remaining before session expires</Tooltip>),

            time: new Date().toLocaleString(),
            progressBarLabel: "00:00",
            progressBarIndicator: 0
        }
    }

    componentDidMount() {

        this.intervalSideBar = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.intervalSideBar);
    }

    tick() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
            let decoded = jwt_decode(token);
            let diff = decoded.exp - moment().unix();
            let percentage = UserSidebarComponent.percentage(diff, user.duration)
            // let percentageLabel = "SessionExpiresIn:" + Math.round((diff / 60)) + "(min)"
            let formatted = `${Math.round(diff / 60 - 1).toString().padStart(2, '0')}:${Math.round(diff % 60).toString().padStart(2, '0')}`

            this.setState({progressBarIndicator: percentage, progressBarLabel: formatted});
        } else {
            this.setState({progressBarIndicator: 0, progressBarLabel: ""});
        }

    }

    static percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    refreshToken() {

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        const {dispatch} = this.props;
        dispatch(userActions.refreshToken(user.username, token));
    }

    //border-bottom = 1px solid rgba(255, 255, 255, 0.2)

    render() {
        return (
            <div>
                <div className="user" style={{paddingBottom: "5px", marginTop: "5px"}}>
                    <div className="media">
                        <span className="media-left media-middle">
                            <div className="photo">
                            <img src={avatar} alt="Avatar"/>
                            </div>
                        </span>
                        <span className="media-body  media-middle">
                            <h6 className="media-heading">{this.props.userObject.firstName + " " + (this.props.userObject.lastName === null ? "" : this.props.userObject.lastName )}</h6>

                            {/*<br/>*/}
                            {/*<ProgressBar now={this.state.progressBarIndicator}*/}
                            {/*label={this.state.progressBarLabel}*/}
                            {/*style={{width: "80%", height: "20px", backgroundColor: "#e5e5e56b"}}*/}
                            {/*/>*/}
                            {/*<h6 className="media-heading">{this.state.progressBarLabel}</h6>*/}
                            <OverlayTrigger placement="bottom" overlay={this.state.session_tooltip}>
                                <button type="button" className="btn btn-warning btn-sm"
                                        onClick={this.refreshToken.bind(this)}>
                                    {/*<span className="cil-contrast btn-icon mr-2"></span>*/}
                                    <span className="fa fa-refresh btn-icon"/>
                                    {this.state.progressBarLabel}
                                </button>
                            </OverlayTrigger>

                        </span>
                    </div>
                </div>

            </div>
        );

    }
}

// export default UserSidebarComponent;

function mapStateToProps(state) {
    const {loggingIn} = state.authentication;
    return {
        loggingIn
    };
}

const connectedUserSidebarComponent = connect(mapStateToProps)(UserSidebarComponent);
export {connectedUserSidebarComponent as UserSidebarComponent};
