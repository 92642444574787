import React, {Component} from "react";
import {Col, ControlLabel, FormControl, FormGroup, Grid, Modal, Row, HelpBlock} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import axios from "axios";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import Select from "react-select";
import {AxiosHeader} from "../../functions/GlobalFunctions";

const durationDropDown = [
    {value: 1, label: '1 hour'},
    {value: 2, label: '2 hour'},
    {value: 3, label: '3 hour'},
    {value: 4, label: '4 hour'},
    {value: 6, label: '6 hour'},
    {value: 8, label: '8 hour'},
    {value: 10, label: '10 hour'},
    {value: 12, label: '12 hour'},
    {value: 24, label: '24 hour'}
];

class ModalViewTvGuideStatic extends Component {

    constructor(props) {
        super(props);

        this.state = {

            showViewProgram: true,

            static_epg_channel_id: null,
            static_epg_channel_name: null,
            static_epg_date_added: null,
            static_epg_date_deleted: null,
            static_epg_description: null,
            static_epg_duration: 1,
            static_epg_id: null,
            static_epg_title: null,
            static_epg_user_added: null,
            static_epg_user_deleted: null,

            static_epg_channel_name_state: null,
            static_epg_duration_state: null,
            static_epg_title_state: null,
            static_epg_description_state: null,

            static_epg_channel_name_message: "",
            static_epg_duration_message: "",
            static_epg_title_message: "",
            static_epg_description_message: "",

            loading: false

        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleClose = () => {
        this.setState({showViewProgram: false})
        this.props.handlerFromParent(false, "nothing");
    }

    componentDidMount() {

        if (this.props.staticId !== "new") {
            this.loadFromMiddlewareTvGuideProgramData()
        }
    }

    loadFromMiddlewareTvGuideProgramData() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/TvGuide/static/" + this.props.staticId;

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {

                    this.setState({
                        static_epg_channel_id: res.data[0].static_epg_channel_id,
                        static_epg_channel_name: res.data[0].static_epg_channel_name,
                        static_epg_description: res.data[0].static_epg_description,
                        static_epg_duration: res.data[0].static_epg_duration,
                        static_epg_id: res.data[0].static_epg_id,
                        static_epg_title: res.data[0].static_epg_title,
                        loading: false
                    });
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({
                        loading: false
                    });
                });
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    updateProgram() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/TvGuide/static";

            let postData = {
                static_epg_channel_id: this.state.static_epg_channel_id,
                static_epg_channel_name: this.state.static_epg_channel_name,
                static_epg_description: this.state.static_epg_description,
                static_epg_duration: this.state.static_epg_duration,
                static_epg_id: this.state.static_epg_id,
                static_epg_title: this.state.static_epg_title,
            };

            axios.put(url, postData, AxiosHeader.getConfig())
                .then((res) => {

                    if (res.data.error !== undefined) {
                        this.setState({
                            static_epg_channel_name_state: res.data.error.static_epg_channel_name !== null ? "error" : null,
                            static_epg_duration_state: res.data.error.static_epg_duration !== null ? "error" : null,
                            static_epg_title_state: res.data.error.static_epg_title !== null ? "error" : null,
                            static_epg_description_state: res.data.error.static_epg_description !== null ? "error" : null,

                            static_epg_channel_name_message: res.data.error.static_epg_channel_name !== null ? res.data.error.static_epg_channel_name : "",
                            static_epg_duration_message: res.data.error.static_epg_duration !== null ? res.data.error.static_epg_duration : "",
                            static_epg_title_message: res.data.error.static_epg_title !== null ? res.data.error.static_epg_title : "",
                            static_epg_description_message: res.data.error.static_epg_description !== null ? res.data.error.static_epg_description : ""
                        })

                    } else {
                        this.setState({
                                static_epg_channel_name_state: null,
                                static_epg_duration_state: null,
                                static_epg_title_state: null,
                                static_epg_description_state: null,

                                static_epg_channel_name_message: "",
                                static_epg_duration_message: "",
                                static_epg_title_message: "",
                                static_epg_description_message: ""
                            }
                        )
                        this.setState({showViewProgram: false})
                        this.props.handlerFromParent(false, "reload");

                    }

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({showViewProgram: false})
                    this.props.handlerFromParent(false, "reload");
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    createProgram() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/TvGuide/static";

            let postData = {
                static_epg_channel_id: this.state.static_epg_channel_id,
                static_epg_channel_name: this.state.static_epg_channel_name,
                static_epg_description: this.state.static_epg_description,
                static_epg_duration: this.state.static_epg_duration,
                static_epg_id: this.state.static_epg_id,
                static_epg_title: this.state.static_epg_title,
            };

            axios.post(url, postData, AxiosHeader.getConfig())
                .then((res) => {

                    if (res.data.error !== undefined) {
                        this.setState({
                            static_epg_channel_name_state: res.data.error.static_epg_channel_name !== null ? "error" : null,
                            static_epg_duration_state: res.data.error.static_epg_duration !== null ? "error" : null,
                            static_epg_title_state: res.data.error.static_epg_title !== null ? "error" : null,
                            static_epg_description_state: res.data.error.static_epg_description !== null ? "error" : null,

                            static_epg_channel_name_message: res.data.error.static_epg_channel_name !== null ? res.data.error.static_epg_channel_name : "",
                            static_epg_duration_message: res.data.error.static_epg_duration !== null ? res.data.error.static_epg_duration : "",
                            static_epg_title_message: res.data.error.static_epg_title !== null ? res.data.error.static_epg_title : "",
                            static_epg_description_message: res.data.error.static_epg_description !== null ? res.data.error.static_epg_description : ""
                        })

                    } else {
                        this.setState({
                                static_epg_channel_name_state: null,
                                static_epg_duration_state: null,
                                static_epg_title_state: null,
                                static_epg_description_state: null,

                                static_epg_channel_name_message: "",
                                static_epg_duration_message: "",
                                static_epg_title_message: "",
                                static_epg_description_message: ""
                            }
                        )
                        this.setState({showViewProgram: false})
                        this.props.handlerFromParent(false, "reload");

                    }

                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err);
                    this.setState({showViewProgram: false})
                    this.props.handlerFromParent(false, "reload");
                })
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    manageSaveStaticProgram() {

        if (this.props.staticId === "new") {
            this.createProgram();
        } else {
            this.updateProgram();
        }

    }

    handleDurationChange = (staticDurationDropDown) => {

        this.setState({
            static_epg_duration: staticDurationDropDown === null ? 1 : staticDurationDropDown.value
        });

    }

    render() {
        return (
            <div className="modal-edit-season">
                <Modal show={this.state.showViewProgram} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor: '#fbfbfb'}}>
                        <Modal.Title>{this.props.headerMessage + " - " + this.props.staticId}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Grid fluid>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>ID</ControlLabel>
                                        <FormControl placeholder="ID"
                                                     type="text"
                                                     disabled
                                                     name={"static_epg_id"}
                                                     value={this.state.static_epg_id || ""}/>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <ControlLabel>Channel ID</ControlLabel>
                                        <FormControl placeholder="Channel ID"
                                                     type="text"
                                                     disabled
                                                     name={"static_epg_channel_id"}
                                                     value={this.state.static_epg_channel_id || ""}/>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="formValidationName"
                                        validationState={this.state.static_epg_channel_name_state}
                                    >
                                        <ControlLabel>Channel Name</ControlLabel>
                                        <FormControl placeholder="Enter channel name"
                                                     type="text"
                                                     disabled={this.state.loading}
                                                     name={"static_epg_channel_name"}
                                                     onChange={this.handleInputChange}
                                                     value={this.state.static_epg_channel_name || ""}/>
                                        <HelpBlock>{this.state.static_epg_channel_name_message}</HelpBlock>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="formValidationTitle"
                                        validationState={this.state.static_epg_title_state}
                                    >
                                        <ControlLabel>Program Title</ControlLabel>
                                        <FormControl placeholder="Enter program title"
                                                     type="text"
                                                     disabled={this.state.loading}
                                                     name={"static_epg_title"}
                                                     onChange={this.handleInputChange}
                                                     value={this.state.static_epg_title || ""}/>
                                        <HelpBlock>{this.state.static_epg_title_message}</HelpBlock>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="formValidationDuration"
                                        validationState={this.state.static_epg_duration_state}
                                    >
                                        <ControlLabel>Program Duration</ControlLabel>
                                        <Select
                                            value={this.state.static_epg_duration}
                                            onChange={this.handleDurationChange}
                                            options={durationDropDown}
                                            disabled={this.state.loading}
                                        />
                                        <HelpBlock>{this.state.static_epg_duration_message}</HelpBlock>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup
                                        controlId="formValidationDescription"
                                        validationState={this.state.static_epg_description_state}
                                    >
                                        <ControlLabel>Program Description</ControlLabel>
                                        <FormControl componentClass="textarea"
                                                     type="text"
                                                     rows={5}
                                                     disabled={this.state.loading}
                                                     name="static_epg_description"
                                                     onChange={this.handleInputChange}
                                                     placeholder="Enter program description"
                                                     value={this.state.static_epg_description || ""}
                                        />
                                        <HelpBlock>{this.state.static_epg_description_message}</HelpBlock>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Grid>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button bsStyle="primary" fill onClick={this.manageSaveStaticProgram.bind(this)}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedModalViewTvGuideStatic = connect(mapStateToProps)(ModalViewTvGuideStatic);
const connectedModalViewTvGuideStaticTrans = withNamespaces('common')(connectedModalViewTvGuideStatic);
export {connectedModalViewTvGuideStaticTrans as ModalViewTvGuideStatic};