import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownCustomerDealers extends Component {

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);

        this.state = {
            loading: true,
            dealerArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.dealerSelected !== prevProps.dealerSelected) {
            //console.log(this.props.dealerSelected)
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {

        if (AxiosHeader.getConfig() !== null) {

            const url = process.env.REACT_APP_API_URL + "/Customer/Dealers";

            axios
                .get(url, AxiosHeader.getConfig())
                .then(res => {
                    const dealerArray = res.data;
                    const loading = false;
                    this.setState({dealerArray, loading});
                })
                .catch(error => {
                    this.setState({loading: false});
                    console.log(error);
                });
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }
    }

    render() {
        return (
            <Select
                placeholder="Dealer Select"
                name="dealerSelect"
                autoFocus={false}
                multi={false}
                isSearchable={true}
                disabled={this.props.disabled}
                value={this.props.dealerSelected}
                options={this.state.dealerArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownCustomerDealers = connect(mapStateToProps)(DropDownCustomerDealers);
const connectedDropDownCustomerDealersTrans = withNamespaces('common')(connectedDropDownCustomerDealers);
export {connectedDropDownCustomerDealersTrans as DropDownCustomerDealers};

