import React, {Component} from "react";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import axios from "axios/index";
import {userActions} from "../../authentication/_actions";
import connect from "react-redux/es/connect/connect";
import {withNamespaces} from "react-i18next";
import {AxiosHeader} from "../../functions/GlobalFunctions";

class DropDownFeatureMenus extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            loading: true,
            menuArray: []
        };

    }

    handleSelect(value) {
        this.props.handlerFromParent(value);
    }

    componentDidMount() {

        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.applicationId !== prevProps.applicationId) {
            this.loadData();
        }

        if (this.props.deviceType !== prevProps.deviceType) {
            this.loadData();
        }
    }


    loadData(){

        const url = process.env.REACT_APP_API_URL + "/ApplicationsManagement";

        let user = JSON.parse(localStorage.getItem('user'));
        let token = ""

        if (user) {
            token = user.token
        } else {
            this.props.dispatch(userActions.logoutUnauthorized());
        }

        let axiosConfig = {
            headers: {
                'X-Id-Token': token,
                'Content-Type': 'application/json'
            }
        };


        let postData = {
            type: 'feature_menus',
            application_id: this.props.applicationId,
            device_type: this.props.deviceType,
        };


        axios
            .post(url, postData, axiosConfig)
            .then(res => {

                let temp = res.data;

                let menuArray = [];
                let obj = {};

                for (let i = 0; i < temp.length; i++) {

                    obj = {
                        value: temp[i].value.toString(),
                        label: temp[i].label
                    }

                    menuArray.push(obj)
                }

                const loading = false;

                this.setState({menuArray, loading});
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        return (
            <Select
                placeholder="Menu Select"
                name="menuSelect"
                autoFocus={false}
                multi={true}
                searchable={true}
                value={this.props.menuSelected}
                options={this.state.menuArray}
                onChange={value => this.handleSelect(value)}

            />
        );
    }

}

function mapStateToProps(state) {

    const {authentication} = state;
    const {user} = authentication;
    return {
        user
    };
}

const connectedDropDownFeatureMenus = connect(mapStateToProps)(DropDownFeatureMenus);
const connectedDropDownFeatureMenusTrans = withNamespaces('common')(connectedDropDownFeatureMenus);
export {connectedDropDownFeatureMenusTrans as DropDownFeatureMenus};

