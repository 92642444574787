import React, {Component, Suspense} from "react";
import {Modal} from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
// import Sound from "react-sound";
// import PlayerControls from '../SoundManager/PlayerControls';

const Sound = React.lazy(() => import('react-sound'));
const PlayerControls = React.lazy(() => import('../SoundManager/PlayerControls'));

class ModalMusicPlayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMusicModal: true,

            controlled: true,
            //currentSong: songs[0],
            position: 0,
            volume: 100,
            playbackRate: 1,
            loop: false,
            playStatus: "PAUSED",
        };

    }

    getStatusText() {
        switch (this.state.playStatus) {
            case Sound.status.PLAYING:
                return 'playing';
            case Sound.status.PAUSED:
                return 'paused';
            case Sound.status.STOPPED:
                return 'stopped';
            default:
                return '(unknown)';
        }
    }

    handleSongSelected = (song) => {
        this.setState({currentSong: song, position: 0});
    }

    handleControlledComponentChange = (ev) => {
        this.setState({
            controlled: ev.target.checked,
            position: 0
        });
    }

    renderCurrentSong() {
        return (
            <p>
                Current song {this.state.currentSong.title}. Song is {this.getStatusText()}
            </p>
        );
    }

    handleClose = () => {
        this.setState({showMusicModal: false})
        this.props.handlerFromParent();
    }

    componentDidMount() {
    }

    handleSongLoading() {
        //console.log("Loading....")
    }

    handleSongPlaying() {
        //console.log("handleSongPlaying....")
    }

    handleSongFinishedPlaying() {
        //console.log("handleSongFinishedPlaying....")
    }

    render() {
        const {volume, playbackRate, loop} = this.state;

        return (
            <div className="modal-save">
                <Modal show={this.state.showMusicModal} onHide={this.handleClose}>
                    <Modal.Header closeButton style={{backgroundColor:'#fbfbfb'}}>
                        <Modal.Title>Music Player</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {/*<Sound*/}
                            {/*url="http://173.244.200.173:8081/music/metallica/ride_the_lightning/02-RideTheLightning.mp3"*/}
                            {/*playFromPosition={300 /* in milliseconds *!/*/}
                            {/*playStatus={Sound.status.PLAYING}*/}
                            {/*onLoading={this.handleSongLoading}*/}
                            {/*onPlaying={this.handleSongPlaying}*/}
                            {/*onFinishedPlaying={this.handleSongFinishedPlaying}*/}
                            {/*/>*/}

                            <Suspense fallback={<div>Loading...</div>}>
                                <Sound
                                    //url={this.state.currentSong.url}
                                    url="http://173.244.200.173:8081/music/metallica/ride_the_lightning/02-RideTheLightning.mp3"
                                    playStatus={this.state.playStatus}
                                    playFromPosition={this.state.position}
                                    volume={volume}
                                    playbackRate={playbackRate}
                                    loop={loop}
                                    onLoading={({bytesLoaded, bytesTotal}) => console.log(`${bytesLoaded / bytesTotal * 100}% loaded`)}
                                    onLoad={() => console.log('Loaded')}
                                    onPlaying={({position}) => console.log('Position', position)}
                                    onPause={() => console.log('Paused')}
                                    onResume={() => console.log('Resumed')}
                                    onStop={() => console.log('Stopped')}
                                    onFinishedPlaying={() => this.setState({playStatus: Sound.status.STOPPED})}
                                />
                            </Suspense>

                            <Suspense fallback={<div>Loading...</div>}>
                                <PlayerControls
                                    playStatus={this.state.playStatus}
                                    loop={loop}
                                    onPlay={() => this.setState({playStatus: Sound.status.PLAYING})}
                                    onPause={() => this.setState({playStatus: Sound.status.PAUSED})}
                                    onResume={() => this.setState({playStatus: Sound.status.PLAYING})}
                                    onStop={() => this.setState({playStatus: Sound.status.STOPPED, position: 0})}
                                    onSeek={position => this.setState({position})}
                                    onVolumeUp={() => this.setState({volume: volume >= 100 ? volume : volume + 10})}
                                    onVolumeDown={() => this.setState({volume: volume <= 0 ? volume : volume - 10})}
                                    onPlaybackRateUp={() => this.setState({playbackRate: playbackRate >= 4 ? playbackRate : playbackRate + 0.5})}
                                    onPlaybackRateDown={() => this.setState({playbackRate: playbackRate <= 0.5 ? playbackRate : playbackRate - 0.5})}
                                    onToggleLoop={e => this.setState({loop: e.target.checked})}
                                    //duration={this.state.currentSong ? this.state.currentSong.duration : 0}
                                    position={this.state.position}
                                    playbackRate={playbackRate}
                                />
                            </Suspense>

                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="default" fill onClick={this.handleClose}>
                            close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default ModalMusicPlayer;

//https://github.com/leoasis/react-sound
//Props
// url (string): The url of the sound to play.
// playStatus (Sound.status.{PLAYING,STOPPED,PAUSED}): The current sound playing status. Change it in successive renders to play, stop, pause and resume the sound.
// playFromPosition (number): Seeks to the position specified by this prop, any time it changes. After that, the sound will continue playing (or not, if the playStatus is not PLAYING). Use this prop to seek to different positions in the sound, but not use it as a controlled component. You should use either this prop or position, but not both.
// position (number): The current position the sound is at. Use this to make the component a controlled component, meaning that you must update this prop on every onPlaying callback. You should use either this prop or playFromPosition, but not both.
// volume (number): The current sound's volume. A value between 0 and 100.
// playbackRate (number): Number affecting sound playback. A value between 0.5 and 4 of normal rate (1).
// autoLoad (boolean): If the sound should start loading automatically (defaults to false).
// loop (boolean): If the sound should continue playing in a loop (defaults to false).
// onError (function): Function that gets called when the sound fails to load, or fails during load or playback. It receives the arguments errorCode and description with details about the error.
// onLoading (function): Function that gets called while the sound is loading. It receives an object with properties bytesLoaded, bytesTotal and duration.
// onLoad (function): Function that gets called after the sound has finished loading. It receives an object with property loaded, a boolean set to true if the sound has finished loading successfully.
// onPlaying (function): Function that gets called while the sound is playing. It receives an object with properties position and duration.
// onPause (function): Function that gets called when the sound is paused. It receives an object with properties position and duration.
// onResume (function): Function that gets called while the sound is resumed playing. It receives an object with properties position and duration.
// onStop (function): Function that gets called while the sound playback is stopped. It receives an object with properties position and duration.
// onFinishedPlaying (function): Function that gets called when the sound finishes playing (reached end of sound). It receives no parameters.
// onBufferChange (function): Function that gets called when the sound buffering status changes. It receives a single boolean representing the buffer state.